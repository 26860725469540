import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
  CircularProgress,
  Input,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import {
  getDataWithFilterUrlwithLimit,
  getFilterDataWithPagination,
  getOnedata,
  StringLimit,
} from "../../controllers/APIController";
import SearchIcon from "../../Images/Search";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import { useNavigate, useParams } from "react-router-dom";
import { DateFormatorDD } from "../../utils/helper";
import { Colors, numColors } from "../../utils/constant";
import NullData from "../../components/ErrorPage/NullData";

import LoadingPage from "../../components/Loader/LoadingPage";

const FrameworkScore = ({
  environmentScore,
  governanceScore,
  socialScore,
  OverallScore,
}) => {
  return (
    <div className="flex flex-row flex-wrap gap-2 mt-4">
      <Card
        className="py-2 w-[200px] min-h-[150px] border border-divider overflow-auto"
        shadow="none"
      >
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
          <p className="text-tiny uppercase font-bold"></p>
          <small className="text-default-500">Completed</small>
          <h4 className="font-bold text-sm">Overall</h4>
        </CardHeader>
        <CardBody className="overflow-visible py-2">
          <div className="flex items-center justify-center w-full h-full">
            <CircularProgress
              classNames={{
                svg: "w-28 h-28 drop-shadow-md",
                indicator: "stroke-secondary",
                track: "stroke-secondary/10",
                value: `text-3xl font-semibold text-secondary`,
              }}
              aria-label="overall-score"
              value={OverallScore}
              strokeWidth={2}
              showValueLabel={true}
            />
          </div>
        </CardBody>
      </Card>
      <Card
        className="py-2 w-[200px] min-h-[150px] border border-divider overflow-auto"
        shadow="none"
      >
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
          <p className="text-tiny uppercase font-bold"></p>
          <small className="text-default-500">Completed</small>
          <h4 className="font-bold text-sm">Environment Metrics</h4>
        </CardHeader>
        <CardBody className="overflow-visible py-2">
          <div className="flex items-center justify-center w-full h-full">
            <CircularProgress
              classNames={{
                svg: "w-28 h-28 drop-shadow-md",
                indicator: "stroke-success",
                track: "stroke-success/10",
                value: `text-3xl font-semibold text-success`,
              }}
              value={environmentScore}
              aria-label="enviroment-score"
              strokeWidth={2}
              showValueLabel={true}
            />
          </div>
        </CardBody>
      </Card>
      <Card
        className="py-2 w-[200px] min-h-[150px] border border-divider overflow-auto"
        shadow="none"
      >
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
          <p className="text-tiny uppercase font-bold"></p>
          <small className="text-default-500">Completed</small>
          <h4 className="font-bold text-sm">Governance Metrics</h4>
        </CardHeader>
        <CardBody className="overflow-visible py-2">
          <div className="flex items-center justify-center w-full h-full">
            <CircularProgress
              classNames={{
                svg: "w-28 h-28 drop-shadow-md",
                indicator: "stroke-warning",
                track: "stroke-warning/10",
                value: `text-3xl font-semibold text-warning`,
              }}
              aria-label="governance-score"
              value={governanceScore}
              strokeWidth={2}
              showValueLabel={true}
            />
          </div>
        </CardBody>
      </Card>
      <Card
        className="py-2 w-[200px] min-h-[150px] border border-divider overflow-auto"
        shadow="none"
      >
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
          <p className="text-tiny uppercase font-bold"></p>
          <small className="text-default-500">Completed</small>
          <h4 className="font-bold text-sm">Social Metrics</h4>
        </CardHeader>
        <CardBody className="overflow-visible py-2">
          <div className="flex items-center justify-center w-full h-full">
            <CircularProgress
              classNames={{
                svg: "w-28 h-28 drop-shadow-md",
                indicator: "stroke-danger",
                track: "stroke-danger/10",
                value: `text-3xl font-semibold text-danger`,
              }}
              aria-label="social-score"
              value={socialScore}
              strokeWidth={2}
              showValueLabel={true}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const Dashboard = () => {
  const navigator = useNavigate();
  const { _orgId: currOrg } = useParams();

  const [responseData, setResponseData] = useState([]);
  const [categroyName, setCategoryName] = useState({});

  const [UserDetails, setUserDetails] = useState(null);
  const [Loader, setLoader] = useState(true);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  useEffect(() => {
    setLoader(true);
    getOnedata("users", UserId).then((data) => {
      setLoader(false);

      setUserDetails(data);
    });
  }, [UserId]);

  // const UserProfile =
  //   UserDetails && UserDetails.ProfilePicture !== null
  //     ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
  //         UserDetails.ProfilePicture && UserDetails.ProfilePicture.url
  //       }`
  //     : "";

  const navigateHandler = (metric, id) => {
    const category = categroyName[metric]?.toLowerCase().replaceAll(" ", "-");
    const Navigator = metric?.toLowerCase().replaceAll(" ", "-");
    if (category === "reference-data" || category === "framework") {
      navigator(`/${currOrg}/${category}/${Navigator}/${id}`);
    } else navigator(`/${currOrg}/metrics/${category}/${Navigator}/${id}`);
  };

  useEffect(() => {
    getDataWithFilterUrlwithLimit(
      "responses",
      `${orgFilterUrl}&sort[0]=updatedAt:desc&pagination[limit]=8`
    )
      .then((data) => {
        setResponseData(data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orgFilterUrl]);

  useEffect(() => {
    const categoryList = {};
    const result = responseData.map(async (item) => {
      const url =
        orgFilterUrl + "&filters[Name][$eqi]=" + item?.attributes?.Metric_Id;
      const response = await getFilterDataWithPagination("metrics", url, 1, 1);
      categoryList[item?.attributes?.Metric_Id] =
        response?.data[0]?.attributes?.Category || "Unknown";
      return response;
    });

    Promise.all(result).then(() => {
      setCategoryName(categoryList);
    });
  }, [responseData, orgFilterUrl]);

  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={Loader}>
        <div>
          <div className="text-3xl">
            Hello{" "}
            {UserDetails && UserDetails.FirstName !== null
              ? UserDetails.FirstName
              : UserDetails && UserDetails.email}
          </div>
          <div className="mt-8">
            <Input
              placeholder="Search for anything inside Audyt ESG"
              startContent={
                <SearchIcon
                  className={`text-large text- pointer-events-none flex-shrink-0 `}
                />
              }
            />
          </div>
          <div className="mt-8">
            <div className="flex flex-col xl:flex-row gap-6 justify-between">
              <div className="w-[100%] xl:w-[30%] flex-shrink-0">
                <Tabs className="w-full">
                  <Tab key="BRSR" title="BRSR">
                    <FrameworkScore
                      environmentScore={100} // You can dynamically change these values
                      governanceScore={70}
                      socialScore={90}
                      OverallScore={85}
                    />
                  </Tab>
                  <Tab key="GRI" title="GRI">
                    <FrameworkScore
                      environmentScore={62} // You can dynamically change these values
                      governanceScore={65}
                      socialScore={0}
                      OverallScore={0}
                    />
                  </Tab>
                  <Tab key="TCFD" title="TCFD">
                    <FrameworkScore
                      environmentScore={43} // You can dynamically change these values
                      governanceScore={70}
                      socialScore={90}
                      OverallScore={85}
                    />
                  </Tab>
                  <Tab key="SASB" title="SASB">
                    <FrameworkScore
                      environmentScore={80} // You can dynamically change these values
                      governanceScore={70}
                      socialScore={90}
                      OverallScore={85}
                    />
                  </Tab>
                </Tabs>
              </div>

              <Card
                className="py-2 w-full min-h-[300px] border border-divider"
                shadow="none"
              >
                <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                  <p className="text-tiny uppercase font-bold flex items-center gap-4">
                    Metrics Updated
                    <Tooltip content="View more documents">
                      <Link
                        onClick={() => navigator(`/${currOrg}/logs`)}
                        showAnchorIcon
                        color="danger"
                        className="cursor-pointer"
                      ></Link>
                    </Tooltip>
                  </p>
                </CardHeader>
                <CardBody className="overflow-visible pt-8">
                  <Table
                    removeWrapper
                    aria-label="Example static collection table"
                  >
                    <TableHeader>
                      <TableColumn>Metric</TableColumn>
                      <TableColumn>Updated at</TableColumn>
                      <TableColumn>Updated by</TableColumn>
                    </TableHeader>
                    <TableBody emptyContent={<NullData />}>
                      {responseData?.map((data) => {
                        const colorIndex =
                          data?.attributes?.users_permissions_user?.data?.id %
                          numColors;
                        return (
                          <TableRow
                            key="1"
                            className="cursor-pointer hover:bg-grey-200"
                            onClick={() =>
                              navigateHandler(
                                data?.attributes?.Metric_Id,
                                data?.id
                              )
                            }
                          >
                            <TableCell>
                              <div className="flex gap-4 items-center">
                                <lord-icon
                                  src="https://cdn.lordicon.com/nizfqlnk.json"
                                  trigger="hover"
                                  target="#Metrics"
                                  state="morph-home-2"
                                  colors={`primary: "#000000"`}
                                  style={{ width: "25px", height: "25px" }}
                                ></lord-icon>
                                {data?.attributes?.Metric_Id}
                              </div>
                            </TableCell>
                            <TableCell>
                              {DateFormatorDD(data?.attributes?.updatedAt)}
                            </TableCell>
                            <TableCell>
                              <div className="flex gap-4 items-center">
                                <Avatar
                                  className="h-6 w-6"
                                  color={Colors[colorIndex]}
                                  name={data?.attributes?.users_permissions_user?.data?.attributes?.email
                                    .toUpperCase()
                                    .slice(0, 1)}
                                  src={
                                    data?.attributes?.users_permissions_user
                                      ?.data?.attributes?.ProfilePicture &&
                                    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data?.attributes?.users_permissions_user?.data?.attributes?.ProfilePicture.url}`
                                  }
                                />
                                <div className="text-tiny text-default-500 font-bold">
                                  {StringLimit(
                                    data?.attributes?.users_permissions_user
                                      ?.data?.attributes?.email,
                                    30
                                  )}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default Dashboard;
