import { Tab, Tabs } from "@nextui-org/react";
import React from "react";
import JsonFormBuilder from "./JsonFormBuilder";
import UIFormBuilder from "./UIFormBuilder";
import { useParams, useSearchParams } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import useGetMetricInfo from "../../hooks/useGetMetricInfo";
import LoadingPage from "../../components/Loader/LoadingPage";

export const FormBuilder = () => {
  const { _orgId: currOrg } = useParams();
  const [searchParams] = useSearchParams();
  const duplicateId = searchParams?.get("id") || -1;
  const {
    metricInfo,
    isLoading: isLoadingMetricInfo,
    err: metricInfoError,
  } = useGetMetricInfo(duplicateId);

  let tabs = [
    {
      id: "Using Form Builder UI",
      label: "Using Form Builder UI",
      content: <UIFormBuilder metricInfo={metricInfo} />,
    },
    {
      id: "Using JSON",
      label: "Using JSON",
      content: <JsonFormBuilder metricInfo={metricInfo} />,
    },
  ];

  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={isLoadingMetricInfo}>
        <div className="w-full">
          <div className="text-3xl font-medium">Create a metric</div>
          <div className="my-8">
            Create a metric and build that would support your ESG Reporting
          </div>
          <Tabs aria-label="Dynamic tabs" items={tabs} variant="light">
            {(item) => (
              <Tab key={item.id} title={item.label}>
                {item.content}
              </Tab>
            )}
          </Tabs>
        </div>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};
