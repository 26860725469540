import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  PublishData,
  capitalize,
  getDataWithFilterUrl,
  separateDasFromUrl,
  updateFormSchemaWithRealtion,
} from "../../controllers/APIController";
import { Button, Image } from "@nextui-org/react";
import { useEffect, useState } from "react";
import logo from "../../Images/logo.svg";
import LoadingPage from "../../components/Loader/LoadingPage";
import {
  BooleanInput,
  DateInput,
  DropDownInput,
  FileInput,
  IntegerInput,
  PasswordInput,
  RelationDropDownInput,
  SliderInput,
  TextAreaInput,
  TextInput,
} from "../FormBuilder/InputField/InputField";
import { checkValidation } from "../../utils/checkValidation";
import { increasedMetricCurrentCount } from "../../utils/helper";

const getInitailState = (arr) => {
  const data = {};

  arr?.forEach((item) => {
    if (item[1].type === "boolean") {
      data[item[0]] = "false";
    }
    if (item[1].type === "slider") {
      data[item[0]] = item[1].value || 0;
    } else data[item[0]] = "";
  });
  return data;
};

const Survey = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [CollectionInfo, setCollectionInfo] = useState();
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [links, setLinks] = useState([]);

  const { _orgId } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const personEmail = searchParams.get("username");

  let paramsList = pathname.split("/");

  let HeadingTitle = capitalize(separateDasFromUrl(paramsList[2]));
  let description = CollectionInfo?.attributes?.Description;

  const filteredUrl =
    "filters[Name][$eqi]=" + paramsList[3].replaceAll("-", " ");

  const changeHandler = async (title, value, type) => {

    if (type === "file") {
      let formData = new FormData();
      formData.append("files", value);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();

        setFormData({
          ...formdata,
          [title]: { value: data[0].id, url: data[0].url },
        });
      } catch (error) {
        console.error("Upload error!", error);
      }
    } else if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };



  const submitHandler = async () => {
    const isValid = checkValidation(formSchema, formdata, setValidationErrors);
    if (!isValid) {
      setErrorMessage("Something went wrong!!");
      return;
    }

    const publishData = {
      Metric_Id: CollectionInfo.attributes.Name,
      JsonSchema: { ...formdata, "Submitted By": personEmail },
      Organization: +_orgId,
    };

    let result = await PublishData({ data: publishData }, "responses");
    await increasedMetricCurrentCount(
      CollectionInfo?.id,
      result?.data?.attributes?.createdAt
    );

    navigate(`/${_orgId}/external-survey/thank-you`);
  };


  const clickHandler = (metricName, relatedTo) => {
    let metric = metricName.replaceAll(" ", "-");
    let relatedToName = relatedTo.replaceAll(" ", "-");
    navigate(`/${_orgId}/${metric}/${relatedToName}/create`);

    window.location.reload();
  };

  useEffect(() => {
    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;
        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);
        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });
        setCollectionInfo(data.data[0]);
        setformSchema(responseArr);
        setLinks(data.data[0].attributes?.Links?.Links);
        setFormData(getInitailState(responseArr));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <>
      <div className="mx-auto min-h-[590px] w-full max-w-[850px] mt-8">
        <div className="flex justify-center w-full mb-8">
          <Image width={80} alt="NextUI hero Image" src={logo} />
        </div>
        <LoadingPage isLoading={isLoading}>
          <div className="metric-header">
            <p className="text-3xl font-medium flex justify-center w-full">
              {HeadingTitle}
            </p>
            <p className="mt-8">{description}</p>
          </div>
          <div className="flex gap-[160px]">
            <div className="w-full mt-8">
              {formSchema &&
                formSchema.map((row, index) => {
                  const uniqueId = Date.now().toString + index;
                  if (row[1].type === "string" || row[1].type === "text") {
                    return (
                      <TextInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "password") {
                    return (
                      <PasswordInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "integer") {
                    return (
                      <IntegerInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "dropdown") {
                    return (
                      <DropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "slider") {
                    return (
                      <SliderInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "boolean") {
                    return (
                      <BooleanInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "textarea") {
                    return (
                      <TextAreaInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "date") {
                    return (
                      <DateInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "file") {
                    return (
                      <FileInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        inputfiledId={uniqueId}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "relation") {
                    updateFormSchemaWithRealtion(
                      formSchema,
                      index,
                      setformSchema
                    );
                    return (
                      <RelationDropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                        onClickHandler={clickHandler}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          </div>
          <Button
            color="danger"
            variant="ghost"
            size="md"
            className="mb-8"
            onClick={submitHandler}
          >
            Submit
          </Button>
        </LoadingPage>
      </div>
    </>
  );
};

export default Survey;
