import { Button, Image, Input } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import logo from "../../Images/logo.svg";

import {
  getDataWithFilterUrl,
  sendResetPasswordEmail,
} from "../../controllers/APIController";
import { encryptedText } from "../../utils/helper";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loader/Loading";

const ResetPassword = ({ darkMode }) => {
  const [emailText, setEmailText] = useState("");
  const [allEmail, setAllEmail] = useState([]);
  const [validationError, setValidationError] = useState("");

  const [Loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const validateEmail = () => {
    let isValid = true;
    let errorMessage = "";
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!EmailRegex.test(emailText)) {
      isValid = false;
      errorMessage = "Please enter a valid email address.";
    } else if (!allEmail.includes(emailText.toLowerCase())) {
      isValid = false;
      errorMessage = "Email does not exist, Go to register.";
    }
    setValidationError(errorMessage);
    return isValid;
  };

  const SubmitHandler = async () => {
    if (!validateEmail()) {
      return;
    }
    setLoader(true);
    const encryptedEmail = encryptedText(emailText);

    const limit = Date.now() + 5 * 60 * 1000; // add 5 minutes time to expire the link

    const url = `${process.env.REACT_APP_IP_ADDRESS}/auth/new-password?email=${encryptedEmail}&limit=${limit}`;
    const res = await sendResetPasswordEmail({ to: emailText, url });

    toast.success(
      "Email sent successfully, Check your email to reset the password!"
    );
    setLoader(false);
    setTimeout(() => {
      navigate(`/sign-in`);
    }, 300);
  };

  useEffect(() => {
    getDataWithFilterUrl("users")
      .then((data) => {
        setAllEmail(data?.map((item) => item.email));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      {Loader ? (
        <div
          className={`absolute z-[100]  inset-0 h-screen w-screen  bg-slate-50'`}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
      <div className={`flex h-screen items-center justify-center`}>
        <main className="mx-auto min-h-[500px] w-full max-w-[450px]">
          <Image width={50} alt="NextUI hero Image" src={logo} />
          <h1 className="mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold ">
            Reset Password
          </h1>
          <p className="pb-8 mt-8 sm:text-left text-base text-slate-11 font-normal">
            Include the email address associated with your account and we’ll
            send you an email with instructions to reset your password.
          </p>
          <form data-gtm-form-interact-id="0">
            <div className="mb-4 space-y-2">
              <Input
                radius="sm"
                className="border-slate-6"
                key={"outside"}
                type="email"
                label="Email"
                variant={"faded"}
                labelPlacement={"outside"}
                placeholder="Enter your email"
                value={emailText}
                onChange={(e) => setEmailText(e.target.value)}
                isInvalid={validationError && validationError !== ""}
                errorMessage={validationError}
              />
            </div>
            <div className="mb-4 space-y-2"></div>
            <div className="pt-8">
              <Button
                id="CreateAccount"
                radius="sm"
                onClick={SubmitHandler}
                className={`w-full gap-1 lt-500 text-white bg-black hover:bg-gray-700 hover:text-white`}
              >
                Send reset instructions
                <lord-icon
                  src="https://cdn.lordicon.com/vduvxizq.json"
                  trigger="hover"
                  target="#CreateAccount"
                  colors={`primary: "#ffffff"`}
                  style={{
                    width: "18px",
                    height: "20px",
                  }}
                />
              </Button>
            </div>
          </form>
        </main>
      </div>
    </>
  );
};

export default ResetPassword;
