import { useEffect, useState } from "react";
import { getDataWithFilterUrl } from "../controllers/APIController";

// Get the data of  any metrics based on Id
export default function useGetMetricInfo(id) {
  const [metricInfo, setMetricInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [err, setErr] = useState(null);
  console.log(id);

  useEffect(() => {
    if (!id) return;
    if (id === -1) {
      setMetricInfo(null);
      setIsLoading(false);
      return;
    }
    getDataWithFilterUrl("metrics", `filters[id][$eqi]=${id}`)
      .then((data) => {
        const result = data?.data?.[0] || {};
        console.log(result);
        setMetricInfo(result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErr(err);
      });
  }, [id]);

  return { metricInfo, isLoading, err };
}
