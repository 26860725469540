import React from "react";

const SingleFrameworkInfo = ({ title, data }) => {
  return (
    <div className="flex flex-col">
      <p className="font-medium text-xs my-1">{title || "CBRR"} </p>
      {data["guidance"] || data["overview"] || data["reference"] ? (
        <div className="flex flex-col text-xs mx-2 text-gray">
          {data["guidance"] && (
            <p className="mb-1">
              <span className="text-black">Guidance : </span>
              <span>{data["guidance"] || ""}</span>
            </p>
          )}
          {data["overview"] && (
            <p className="mb-1">
              <span className="text-black">Overview : </span>
              <span>{data["overview"] || ""}</span>
            </p>
          )}

          {data["reference"] && (
            <p className="mb-1">
              <span className="text-black">Reference : </span>
              <span>{data["reference"] || ""}</span>
            </p>
          )}
        </div>
      ) : (
        <div>
          <p className="text-gray text-xs">
            No description available for this framework.
          </p>
        </div>
      )}
    </div>
  );
};

const FieldInputTooltip = ({
  data,
  selectedFramework,
  orgFrameworkDetails,
}) => {
  const getFrameworkName = (id) => {
    let name = "Unknown";
    orgFrameworkDetails?.forEach((item) => {
      if (item?.id === +id) {
        name = item?.attributes?.Name;
      }
    });
    return name;
  };
  return (
    <div className="flex flex-col px-2 py-2 w-[250px] max-h-[250px] overflow-auto hide-scrollbar">
      {selectedFramework?.map((id) => {
        const name = getFrameworkName(id);
        if (data?.[name]) {
          return <SingleFrameworkInfo title={name} data={data?.[name]} />;
        } else return null;
      })}
    </div>
  );
};

export default FieldInputTooltip;
