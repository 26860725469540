import React from "react";

const replacePlaceholders = (text, placeholders) => {
  let result = text;
  for (const key in placeholders) {
    const regex = new RegExp(`{{${key}}}`, "g");
    result = result.replace(regex, placeholders[key] || "--");
  }
  return result;
};

const SinglePolicyReport = ({ data }) => {

  const { Name, Description, Domain, PolicyFramework, Response } =
    data?.attributes;
  // Replacing placeholders in PolicyFramework
  const updatedPolicyFramework = replacePlaceholders(PolicyFramework, Response);

  return (
    <>
      <div className="flex flex-col gap-[2px] w-full my-8">
        <h1>
          <strong>Name:</strong> {Name}
        </h1>
        <p>
          <strong>Domain:</strong> {Domain}
        </p>
        <p>
          <strong>Description:</strong> {Description}
        </p>
        <p>
          <strong>Policy Framework:</strong>
        </p>
        <pre>{updatedPolicyFramework}</pre>
      </div>
    </>
  );
};

export default SinglePolicyReport;
