import React from "react";
import { Card, Skeleton } from "@nextui-org/react";

const CardSkeleten = ({ width = "400px" }) => {
  return (
    <Card className={`space-y-5 p-4 py-6`} radius="lg" style={{ width: width }}>
      <div className="flex">
        <Skeleton className="w-1/5 rounded-full">
          <div className="h-12 w-1/5 rounded-full bg-default-200"></div>
        </Skeleton>
        <div className="w-4/5 space-y-3 pl-4">
          <Skeleton className="w-4/5 rounded-lg">
            <div className="h-4 w-4/5 rounded-lg bg-default-200"></div>
          </Skeleton>
          <Skeleton className="w-2/5 rounded-lg">
            <div className="h-4 w-2/5 rounded-lg bg-default-200"></div>
          </Skeleton>
        </div>
      </div>
      <Skeleton className="rounded-lg">
        <div className="h-24 rounded-lg bg-default-300"></div>
      </Skeleton>
    </Card>
  );
};

export default CardSkeleten;
