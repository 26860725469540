import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import { ProgessColors } from "../../utils/constant";
import { Template1 } from "./sampleTemplate/template1";
import { Template2 } from "./sampleTemplate/template2";
import { Template3 } from "./sampleTemplate/template3";
import TemplateCard from "./TemplateCard";

const ReportWizardPart2 = ({
  DataList,
  setDataList,
  currentPage,
  setCurrrentPage,
}) => {
  const templateList = [
    {
      id: 1,
      Component: Template1,
    },
    {
      id: 2,
      Component: Template2,
    },
    {
      id: 3,
      Component: Template3,
    },
  ];
  const [validationErrors, setValidationErrors] = useState({});

  const ValidationHandler = () => {
    const err = {};
    let isValid = true;
    setValidationErrors({});

    if (DataList["TemplateIndex"] === null) {
      err["Template"] = "Please select a template!";
      isValid = false;
    }

    console.log(err);
    setValidationErrors(err);
    return isValid;
  };
  const handleNext = () => {
    if (ValidationHandler()) {
      setCurrrentPage((prevPage) => prevPage + 1);
    }
  };
  console.log(validationErrors["Template"]);

  return (
    <div>
      <div className="min-h-[500px]">
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-medium">Choose the Template</p>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum
            error quibusdam voluptatum maxime, nam quis iusto exercitationem
            tempora quaerat aliquid repellendus consequatur est illum ducimus
            soluta modi placeat dolor enim eius ex suscipit molestias cumque.
          </p>
        </div>
        <div className="flex w-full items-center justify-center mt-2 font-medium text-lg text-[#f76497]">
          {validationErrors["Template"]}
        </div>
        <div className=" flex items-center justify-around my-12">
          {templateList.map((data, index) => (
            <TemplateCard
              index={index}
              DataList={DataList}
              setDataList={setDataList}
              Component={data?.Component}
            />
          ))}
        </div>
      </div>
      <div className={`w-full flex items-center my-8 gap-4 justify-end`}>
        <Button
          color={ProgessColors[currentPage]}
          variant="light"
          className={`text-center cursor-pointer ${
            currentPage === 0 && "hidden"
          }`}
          onClick={() => {
            setCurrrentPage(currentPage - 1);
          }}
        >
          Previous
        </Button>
        <Button
          color={ProgessColors[currentPage]}
          className={`text-center cursor-pointer`}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ReportWizardPart2;
