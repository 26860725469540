import React, { useState } from "react";
import ReportWizardPart1 from "./ReportWizardPart1";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import toast, { Toaster } from "react-hot-toast";
import { generateReportChunk } from "../../controllers/APIController";
import { useNavigate } from "react-router-dom";

const CreateReportWizard = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrrentPage] = useState(0);
  const [DataList, setDataList] = useState({
    Name: "",
    "Fiscal Year": "",
    Description: "",
    TemplateIndex: null,
    frameworkId: null,
  });

  const submitHandler = () => {
    const uploadData = {
      name: DataList?.Name,
      description: DataList?.Description,
      framework: DataList?.frameworkId,
    };
    generateReportChunk(uploadData);
    toast.success("Report is generating , it takes some time.");

    navigate(-1);
  };

  return (
    <BreadCrumbsPage>
      <Toaster position="top-center" />
      <div>
        <div className="flex flex-col w-full mx-auto">
          <div className="mt-8">
            <ReportWizardPart1
              currentPage={currentPage}
              setCurrrentPage={setCurrrentPage}
              DataList={DataList}
              setDataList={setDataList}
              onSubmitHandler={submitHandler}
            />
          </div>
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default CreateReportWizard;
