import React, { useEffect, useState } from "react";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import Header from "../Header/Header";
import { Divider, Tooltip, useDisclosure } from "@nextui-org/react";
// import { ThemeProvider as NextThemesProvider } from 'next-themes';

import { getOnedata } from "../../controllers/APIController";
import useGetAllOrganization from "../../hooks/useGetAllOrganization";
import OrgPopUp from "../CreateOrg/OrgPopUp";
import {
  firstNavBarList,
  secondNavBarList,
  thirdNavbarList,
} from "../../constant/Navbar/navbar.constant";
import NavbarFooter from "./NavbarFooter";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const RenderNavList = ({ List, currentActiveUrl, orgId, isCollapsed }) => {
  return (
    <nav className="">
      <ul className="flex flex-col gap-1">
        {List &&
          List?.map((data, index) => {
            const Verified = `/${currentActiveUrl}` === data.path;
            return (
              <li key={index}>
                <NavLink
                  className="rounded-md"
                  to={`/${orgId}${data.path}`}
                  id={data.id}
                >
                  {!isCollapsed ? (
                    <span
                      className={`flex p-1 items-center gap-2 rounded-md px-2 text-sm hover:bg-[#f4f4f4] text-black hover:text-foreground ${
                        Verified && "bg-[#f4f4f4] text-foreground"
                      }`}
                    >
                      {data.icon}
                      {data.title}
                    </span>
                  ) : (
                    <Tooltip content={data.title} placement="right">
                      <span
                        className={`flex p-1 items-center gap-2 rounded-md px-2 text-sm hover:bg-[#f4f4f4] hover:opacity-80 text-black hover:text-foreground ${
                          Verified && "bg-[#f4f4f4] text-foreground"
                        }`}
                      >
                        {data.icon}
                      </span>
                    </Tooltip>
                  )}
                </NavLink>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};
const Navbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [UserDetails, setUserDetails] = useState(null);
  const JWT_Testing = JSON.parse(sessionStorage.getItem("userData"));
  // const [ThemeSelecter, setThemeSelecter] = useState(false);
  const { _orgId } = useParams();

  const { _orgId: currOrg } = useParams();
  const { OrgList, isLoading: isLoadingOrgList, err } = useGetAllOrganization();

  const Location = useLocation();
  const Navigate = useNavigate();

  const URLPath = Location.pathname.split("/");

  const changeCollapsedHandler = () => {
    setIsCollapsed((pre) => !pre);
  };

  // const theme=sessionStorage.getItem("Theme");

  useEffect(() => {
    if (JWT_Testing === null) {
      sessionStorage.setItem("lastRoute", Location.pathname);
      Navigate("/sign-in");
    }
  }, [Navigate]);

  useEffect(() => {
    getOnedata("users", JWT_Testing && JWT_Testing.user.id)
      .then((data) => setUserDetails(data))
      .catch((error) => error);
  }, []);

  useEffect(() => {
    if (!isLoadingOrgList && !OrgList?.includes(currOrg)) {
      Navigate("*");
      // window.location.reload();
    }
  }, [OrgList, currOrg, isLoadingOrgList]);

  return (
    <div className={`flex flex-row w-full max-w-full h-full overflow-hidden`}>
      <nav>
        <div
          // className={`border-r border-divider bg-inherit px-4 dark:border-gray-600 md:flex overflow-auto custom-scrollbar`}
          className={`flex flex-col justify-between  h-full dark:bg-root flex-shrink-0 border-r border-divider bg-inherit px-4 dark:border-gray-600 md:flex py-2 ${
            isCollapsed ? "w-16" : "w-64"
          } transition-all duration-300 ease-in-out overflow-auto custom-scrollbar`}
        >
          {/* Navbar top */}
          <div
            className={`flex gap-4 items-center justify-end ${
              isCollapsed ? "flex-col" : "flex-row"
            } mb-8`}
          >
            <OrgPopUp
              List={OrgList}
              currOrg={_orgId}
              isCollapsed={isCollapsed}
            />
            {!isCollapsed ? (
              <ChevronLeftIcon
                className="size-7 cursor-pointer"
                onClick={changeCollapsedHandler}
              />
            ) : (
              <ChevronRightIcon
                className="size-7 cursor-pointer"
                onClick={changeCollapsedHandler}
              />
            )}
          </div>

          {/* Navbar Mid */}

          <RenderNavList
            List={firstNavBarList}
            currentActiveUrl={URLPath[2]}
            orgId={_orgId}
            isCollapsed={isCollapsed}
          />
          <Divider className="my-4" />
          <RenderNavList
            List={secondNavBarList}
            currentActiveUrl={URLPath[2]}
            orgId={_orgId}
            isCollapsed={isCollapsed}
          />
          <Divider className="my-4" />
          <RenderNavList
            List={thirdNavbarList}
            currentActiveUrl={URLPath[2]}
            orgId={_orgId}
            isCollapsed={isCollapsed}
          />
          {/* Navbar Footer */}

          <NavbarFooter
            UserDetails={UserDetails}
            _orgId={_orgId}
            isCollapsed={isCollapsed}
          />
        </div>
      </nav>

      <div className="flex flex-1 flex-col overflow-auto">
        <Header />

        <div className="h-[calc(100vh-60px)] overflow-y-auto custom-scrollbar p-6 px-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
