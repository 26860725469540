import React, { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Pagination,
  Chip,
} from "@nextui-org/react";
import {
  StringLimit,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "../../Images/Search";
import LoadingPage from "../../components/Loader/LoadingPage";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import NullData from "../../components/ErrorPage/NullData";
import TableSkeleton from "../../components/Skeleten/TableSkeleton";

const ReferenceData = () => {
  const headers = ["", "Name", "Description", "Total Data Points"];
  const [currentPage, setCurrentPage] = useState(1);
  const [referenceData, setReferenceData] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [CountDataState, setCountDataState] = useState(true);
  const [ReferenceFullData, setReferenceFullData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const categoryName = "Reference Data";
  const categoryFilterUrl = "filters[Category][$eqi]=" + categoryName;

  useEffect(() => {
    getDataWithFilterUrl("metrics", categoryFilterUrl)
      .then((data) => {
        const sortedData = data.data.sort((a, b) => {
          const nameA = a.attributes.Name.toLowerCase();
          const nameB = b.attributes.Name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        setReferenceData(sortedData); // Set the fetched and sorted data to the state
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
      });
  }, [categoryFilterUrl]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const HandleToNavigate = (data) => {
    const URLPath =
      data &&
      data.attributes &&
      data.attributes.Name &&
      data.attributes.Name.replace(/\s+/g, "-");
    const PluralizedData = URLPath && URLPath;
    const Singularized =
      PluralizedData &&
      PluralizedData.split("-")
        .map(
          (word) =>
            word &&
            word.charAt(0) &&
            word.charAt(0).toLowerCase() + word.slice(1)
        )
        .join("-");
    navigate(`${pathname}/${Singularized}`);
  };

  useEffect(() => {
    setCountDataState(true);
    if (referenceData && referenceData.length > 0) {
      const promises = referenceData.map(async (data) => {
        const metricID = data && data.attributes && data.attributes.Name;
        const MetricIdFilterUrl =
          orgFilterUrl + "&filters[Metric_Id][$eqi]=" + metricID;
        return getDataWithFilterUrl("responses", MetricIdFilterUrl)
          .then((data) => data)
          .catch((err) => {
            console.log(err);
            return null;
          });
      });
      Promise.all(promises).then((data) => {
        const infoArray = data.map((responseData) => ({
          ReferenceData: responseData,
        }));

        setReferenceFullData(infoArray);
        setCountDataState(false);
      });
    }
  }, [referenceData, orgFilterUrl]);

  const filteredData = referenceData?.filter((item) =>
    item.attributes.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const ItemPerPage = 20;
  const totalPage = Math.ceil(filteredData?.length / ItemPerPage);

  return (
    <BreadCrumbsPage>
      <div>
        <div className="text-3xl font-medium">Reference Data</div>
        <div className="mt-8">
          Connecting to reference data helps provide relevant, reliable
          calculations across all ESG metrics.
        </div>
        <Input
          type="text"
          className="mt-4 mb-2"
          startContent={
            <SearchIcon
              className={`text-large text- pointer-events-none flex-shrink-0 `}
            />
          }
          placeholder="Search a Reference Data"
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
        <LoadingPage isLoading={isLoading} alterElement={<TableSkeleton />}>
          <div className="mt-4">
            <div className="mt-8">
              <Table
                removeWrapper
                selectionMode="single"
                aria-label="reference-table"
              >
                <TableHeader>
                  {headers.map((header) => (
                    <TableColumn key={header}>{header}</TableColumn>
                  ))}
                </TableHeader>
                <TableBody
                  emptyContent={<NullData title={`No reference data found.`} />}
                >
                  {filteredData &&
                    filteredData.map((item, index) => {
                      const lowerPageLimit = (currentPage - 1) * ItemPerPage;
                      const upperPageLimit = currentPage * ItemPerPage;
                      if (index < lowerPageLimit || index >= upperPageLimit)
                        return null;
                      return (
                        <TableRow
                          key={item.id}
                          size="tall"
                          className="hover:bg-grey-200 cursor-pointer"
                          onClick={() => HandleToNavigate(item)}
                        >
                          <TableCell className="pt-4">
                            <lord-icon
                              src="https://cdn.lordicon.com/prjooket.json"
                              trigger="hover"
                              target="#analytics"
                              state="morph-home-2"
                              colors={`primary: "#000000"`}
                              style={{ width: "16px", height: "16px" }}
                            ></lord-icon>
                          </TableCell>
                          <TableCell className="pt-4">
                            {item.attributes.Name}
                          </TableCell>
                          <TableCell className="pt-4">
                            {(item &&
                              item.attributes &&
                              item.attributes.Description &&
                              StringLimit(item.attributes.Description, 120)) ||
                              "--"}
                          </TableCell>
                          <TableCell>
                            {CountDataState ? (
                              <Chip color="default" className="skeleton-chip">fetching..</Chip>
                            ) : ReferenceFullData[index] &&
                              ReferenceFullData[index]?.ReferenceData?.meta ? (
                              String(
                                ReferenceFullData[index]?.ReferenceData?.meta
                                  ?.pagination?.total
                              ).padStart(2, "0")
                            ) : (
                              String(0).padStart(2, 0)
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {filteredData?.length > 0 && (
                <div className="w-full mt-8 flex items-center justify-center">
                  <Pagination
                    total={totalPage}
                    color="secondary"
                    page={currentPage}
                    onChange={setCurrentPage}
                  />
                </div>
              )}
            </div>
          </div>
        </LoadingPage>
      </div>
    </BreadCrumbsPage>
  );
};

export default ReferenceData;
