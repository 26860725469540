import React, { useEffect, useState } from "react";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import { useParams } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Pagination,
} from "@nextui-org/react";
import {
  GetAllMedia,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import NullData from "../../components/ErrorPage/NullData";
import SingleSearchComponent from "./SingleSearchComponent";
import LoadingPage from "../../components/Loader/LoadingPage";

const filterValuesList = [
  "Reference Data",
  "Environment",
  "Social",
  "Governance",
  "Evidence",
];

const SearchPage = () => {
  const { _searchText: searchText } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedData, setSearchedData] = useState([]);
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const [customizeItems, setCustomizeItems] = useState(new Set([]));

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const url = `filters[$or][0][Organization][id][$eqi]=${OrgInfo}&filters[$or][1][Category][$eqi]=Reference Data`;

  useEffect(() => {
    let dataList = [];
    getDataWithFilterUrl("metrics", url)
      .then((data) => {
        data?.data?.forEach((element) => {
          if (
            !element?.attributes?.Name?.toLowerCase().includes(
              searchText.toLowerCase()
            )
          )
            return;
          dataList.push({
            id: element.id,
            type: "Metric",
            Name: element?.attributes?.Name,
            category: element?.attributes?.Category,
            description: element?.attributes?.Description || "---",
            TagList: element?.attributes?.TagList || [],
          });
        });
        GetAllMedia().then((data) => {
          data?.forEach((element) => {
            if (
              !element?.name?.toLowerCase().includes(searchText.toLowerCase())
            )
              return;
            dataList.push({
              id: element.id,
              type: "Evidence",
              Name: element?.name,
              url: element?.url,
            });
          });
          setSearchedData(dataList);
          setFilteredSearchData(dataList);
          setIsLoading(false);
        });
      })
      .catch((err) => err);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedFilteredValues = Array.from(customizeItems);
    const list = searchedData?.filter((data) => {
      if (
        selectedFilteredValues.includes(data.type) ||
        selectedFilteredValues.includes(data.category) ||
        selectedFilteredValues.length === 0
      ) {
        return true;
      }
      return false;
    });

    setFilteredSearchData(list);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizeItems]);

  const resultCount = filteredSearchData.length;
  const ItemPerPage = 15;
  const totalPage = Math.ceil(resultCount / ItemPerPage);


  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={isLoading}>
        <div className="flex items-center justify-between text-xl font-medium text-grey-600">
          <span>Searched results : {searchText}</span>
          <span className="text-lg">Found {resultCount} results</span>
        </div>
        <div className="flex items-center justify-end mt-2">
          <Dropdown className="w-max">
            <DropdownTrigger>
              <Button variant="ghost" className="w-max">
                Filters
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Multiple selection"
              variant="flat"
              closeOnSelect={false}
              selectionMode="multiple"
              selectedKeys={customizeItems}
              onSelectionChange={setCustomizeItems}
              className="custom-dropdown-menu"
            >
              {filterValuesList?.map((name) => (
                <DropdownItem key={name}>{name}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="my-4">
          {filteredSearchData.length > 0 ? (
            <>
              {filteredSearchData.map((item, index) => {
                const lowerPageLimit = (currentPage - 1) * ItemPerPage;
                const upperPageLimit = currentPage * ItemPerPage;
                if (index < lowerPageLimit || index >= upperPageLimit)
                  return null;
                return <SingleSearchComponent key={item.id} data={item} />;
              })}
              {filteredSearchData.length > 0 && (
                <div className="w-full mt-8 flex items-center justify-center">
                  <Pagination
                    total={totalPage}
                    color="secondary"
                    page={currentPage}
                    onChange={setCurrentPage}
                    showControls
                    size="lg"
                  />
                </div>
              )}
            </>
          ) : (
            <NullData title="No search results found" />
          )}
        </div>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default SearchPage;
