import React, { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
} from "@nextui-org/table";
import { useNavigate, useParams } from "react-router-dom";
import {
  capitalize,
  getDataWithFilterUrl,
  StringLimit,
} from "../../controllers/APIController";

import {
  getUniqueClassName,
  getMetricStatus,
  getFrameworkUrl,
} from "../../utils/helper";
import { Accordion, AccordionItem, Chip, Divider } from "@nextui-org/react";
import LoadingPage from "../../components/Loader/LoadingPage";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import useGetOrgFramework from "../../hooks/useGetOrgFramework";

const MetricCategroy = () => {
  const [metricsData, setMetricsData] = useState([]);
  const [StatusList, setStatusList] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const navigate = useNavigate();

  const { _categoryId, _orgId: currOrg } = useParams();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const { frameworkList, isLoading: isLoadFramework } =
    useGetOrgFramework(OrgInfo);

  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const categoryName = capitalize(_categoryId?.replaceAll("-", " "));

  const categoryFilterUrl = "filters[Category][$eqi]=" + categoryName;

  const HandleToNavigate = (data) => {
    // const URLPath = data && data.attributes.Name && data.attributes.Name.replace(/\s+/g, '-')

    const URLPath =
      data &&
      data.attributes.Name &&
      data.attributes.Name.replace(/[\s_]+/g, "-")
        .replace(/-+/g, "-")
        .trim();
    // const PluralizedData = pluralize.singular(URLPath);

    const Singularized = URLPath.split("-")
      .map((word) => word && word.charAt(0).toLowerCase() + word.slice(1))
      .join("-");
    const Navigator = Singularized.toLowerCase();

    if (data?.attributes?.FrequencyValue === 0) {
      const id = data?.attributes?.lastResponseId;

      if (!id) {
        navigate(`/${currOrg}/metrics/${_categoryId}/${Navigator}/create`);
      } else {
        navigate(`/${currOrg}/metrics/${_categoryId}/${Navigator}/${id}`);
      }
    } else navigate(`/${currOrg}/metrics/${_categoryId}/${Navigator}`);
  };

  useEffect(() => {
    if (isLoadFramework) return;
    const frameworkUrl = getFrameworkUrl(frameworkList);
    getDataWithFilterUrl(
      "metrics",
      `${orgFilterUrl}&${categoryFilterUrl}${frameworkUrl}`
    )
      .then((data) => {
        const responseData = data.data;
        setMetricsData(responseData);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OrgInfo, isLoadFramework, orgFilterUrl, categoryFilterUrl]);

  const ClassNameList = getUniqueClassName(metricsData);

  const headers = ["Source", "Description", "Status"];

  useEffect(() => {
    if (metricsData?.length === 0) return;
    const getStatusList = async () => {
      const list = {};
      const result = metricsData.map(async (item) => {
        const statusRes = await getMetricStatus(item?.id);

        list[item.id] = statusRes?.Status;
        return statusRes;
      });

      await Promise.all(result);
      setStatusList(list);
    };
    getStatusList();
  }, [metricsData]);

  return (
    <BreadCrumbsPage>
      <div className="Container-container">
        <h1 className="text-3xl font-medium">{categoryName} Metrics</h1>
        <div className="mt-8">
          Here are the {categoryName} metrics of your ESG Reporting.
        </div>
        <LoadingPage isLoading={isLoading}>
          <div className="my-8">
            {ClassNameList.map((className, index) => (
              <>
                <Accordion key={index} aria-label="metrics-className-accordian">
                  <AccordionItem
                    title={className || "Others"}
                    aria-label="Accordion 1"
                  >
                    <div className="tableAccordion">
                      <Table
                        removeWrapper
                        selectionMode="single"
                        aria-label="table-className"
                      >
                        <TableHeader>
                          {headers.map((header, headerIndex) => (
                            <TableColumn key={headerIndex}>
                              {header}
                            </TableColumn>
                          ))}
                        </TableHeader>
                        <TableBody>
                          {metricsData &&
                            metricsData.map((item) => {
                              if (
                                item &&
                                item.attributes.ClassName === className
                              ) {
                                return (
                                  <TableRow
                                    key={item.id}
                                    onClick={() => HandleToNavigate(item)}
                                    className="cursor-pointer"
                                  >
                                    <TableCell>
                                      {item.attributes.Name}
                                    </TableCell>
                                    <TableCell>
                                      {StringLimit(
                                        item?.attributes?.Description || "---",
                                        80
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Chip
                                        color={
                                          StatusList[item?.id] === "Completed"
                                            ? "success"
                                            : StatusList[item?.id] ===
                                              "Incompleted"
                                            ? "danger"
                                            : "primary"
                                        }
                                        variant="flat"
                                        size="sm"
                                      >
                                        {StatusList[item?.id] || "Unknown"}
                                      </Chip>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                              return null;
                            })}
                        </TableBody>
                      </Table>
                    </div>
                  </AccordionItem>
                </Accordion>
                <Divider />
              </>
            ))}
          </div>
        </LoadingPage>
      </div>
    </BreadCrumbsPage>
  );
};

export default MetricCategroy;
