import React from "react";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import CreateReportWizard from "./CreateReportWizard";

const Report = () => {
  const navigate = useNavigate();

  return (
    <BreadCrumbsPage>
      <>
        <h2 className="text-3xl font-medium">Report</h2>
        <div className="mt-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa earum
          eos laboriosam, harum cupiditate repudiandae pariatur distinctio
          tempore laborum, velit vitae, aliquam magni aspernatur!
        </div>
        <div className="flex items-center justify-end my-4">
          <Button
            color="danger"
            onClick={() => navigate("create")}
            className="w-max"
          >
            Create Report
          </Button>
        </div>
        {/* <CreateReportWizard /> */}
      </>
    </BreadCrumbsPage>
  );
};

export default Report;
