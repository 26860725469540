import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { FaChevronDown } from "react-icons/fa";
import { capitalize, UpdateOneData } from "../../../controllers/APIController";
import {
  getMetricsAssignedToUser,
  getUserRoleFromOrg,
  timeAgo,
} from "../../../utils/helper";

const RoleDropDownValues = [
  {
    id: 0,
    name: "Authenticated",
  },
  {
    id: 1,
    name: "Admin",
  },
  {
    id: 2,
    name: "Reviewer",
  },
  {
    id: 3,
    name: "Approver",
  },
];
const orgName = {
  Admin: "admin_list",
  Authenticated: "",
  Reviewer: "reviewer_list",
  Approver: "approver_list",
};

const DrawerBody = ({ User, OrgInfo }) => {
  const [userRole, setUserRole] = useState("");
  const [intialRole, setIntitalRole] = useState("");
  const extractName = capitalize(User?.FirstName || User?.email.split("@")[0]);
  const [validationMessage, setValidationMessage] = useState("");

  const date = new Date(User?.createdAt || null);

  const DateValue = date.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const lastLoginAt = User?.lastLoginAt
    ? timeAgo(+User?.lastLoginAt)
    : "long time ago";
  const checkValidation = async () => {
    setValidationMessage("");
    const err = {};
    let isValid = true;

    if (intialRole === "Admin" || intialRole === "Authenticated") {
      return {
        isValid,
        err,
      };
    }

    if (intialRole === "Reviewer") {
      const response = await getMetricsAssignedToUser(
        User?.id,
        "ReviewerToMetric"
      );
      if (response?.length === 0) return { err, isValid };
    } else {
      const response = await getMetricsAssignedToUser(
        User?.id,
        "ApproverToMetric"
      );
      if (response?.length === 0) return { err, isValid };
    }
    return {
      isValid: false,
      err: `You need to re-assign ${intialRole} List before changing role`,
    };
  };
  const updateProfilehandler = async () => {
    if (userRole === intialRole) return;
    const validationErrors = await checkValidation();
    if (!validationErrors?.isValid) {
      setValidationMessage(validationErrors?.err);
      setUserRole(intialRole);
      return;
    }

    let connectedOrg = null;
    let disconnectedOrg = null;

    if (userRole !== "Authenticated") {
      const field = orgName[userRole];
      connectedOrg = {
        [field]: {
          connect: [User.id],
        },
      };
    }
    if (intialRole !== "Authenticated") {
      const field = orgName[intialRole];
      disconnectedOrg = {
        [field]: {
          disconnect: [User.id],
        },
      };
    }

    const uploadedData = {
      ...connectedOrg,
      ...disconnectedOrg,
    };

    const result = await UpdateOneData("organizations", OrgInfo, uploadedData);
    setIntitalRole(userRole);
    return result;
  };

  useEffect(() => {
    getUserRoleFromOrg(OrgInfo, User?.id)
      .then((data) => {
        setIntitalRole(data);
        setUserRole(data);
      })
      .catch((err) => console.log(err));
  }, [User, OrgInfo]);

  return (
    <div className="flex flex-col items-start space-y-2">
      <div className="flex items-center w-full font-medium h-8 text-sm">
        <span className="w-[25%]">Name :</span>{" "}
        <span className="text-warmGray-500 font-normal">{extractName}</span>
      </div>
      <div className="flex w-full font-medium text-sm">
        <span className="flex items-center w-[25%]">Role :</span>
        <Dropdown>
          <DropdownTrigger className="w-[50%]">
            <Button variant="faded" className="w-[50%] h-8" size="sm">
              <div className="w-full flex justify-between">
                <div className="flex justify-start w-full items-start">
                  {userRole}
                </div>
                <div className="flex justify-end w-full items-end">
                  <FaChevronDown />
                </div>
              </div>
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Static Actions"
            className="w-52 custom-dropdown-menu"
            selectionMode="single"
            selectedKeys={new Set([userRole])}
            onSelectionChange={(event) => {
              setUserRole(Array.from(event).join(", ").replaceAll("_", " "));
            }}
          >
            {RoleDropDownValues?.map((item) => (
              <DropdownItem key={item.name} value={item.name}>
                {item.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      {validationMessage !== "" && (
        <div className="flex items-center w-full">
          <span className="w-[25%]"></span>
          <span className="text-[#f76497] text-xs">{validationMessage}</span>
        </div>
      )}

      <div className="flex items-center w-full font-medium h-8 text-sm">
        <span className="w-[25%]">User Since :</span>
        <span className="text-warmGray-500 font-normal">
          {DateValue || "12 August, 2023"}
        </span>
      </div>
      <div className="flex items-center w-full font-medium h-8 text-sm">
        <span className="w-[25%]">Last Login :</span>
        <span className="text-warmGray-500 font-normal">{lastLoginAt}</span>
      </div>
      <div className="flex items-center justify-end w-full pt-4 pr-4">
        <Button color="danger" size="sm" onClick={updateProfilehandler}>
          Update Profile
        </Button>
      </div>
    </div>
  );
};

export default DrawerBody;
