import { Avatar } from "@nextui-org/react";
import { capitalize } from "../../../controllers/APIController";
import { DeleteTableItem } from "../../../utils/helper";
import { Colors, numColors } from "../../../utils/constant";

const DrawerHeader = ({ User, onOpenChange }) => {
  const extractName = capitalize(User?.FirstName || User?.email.split("@")[0]);

  const colorIndex = User.id % numColors;
  const deleteHandler = async (id) => {
    // const result = await DeleteOnedata("users", User?.id);
    onOpenChange();
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex items-center justify-end w-full mr-8 mt-2">
        <DeleteTableItem
          userRole={"Admin"}
          toolTiptext={"Remove User"}
          DropDownText={"Want to delete User"}
          deleteHandler={deleteHandler}
        />
      </div>
      <div className="flex flex-col items-center">
        <Avatar
          src={`${User?.ProfilePictureUrl ? `${User?.ProfilePictureUrl}` : ""}`}
          color={Colors[colorIndex]}
          name={User && User.email && User.email.slice(0, 1).toUpperCase()}
          className="w-28 h-28 my-4 mt-12"
          classNames={{ name: "w-8 h-8 text-3xl" }}
        />
        <div className="flex flex-col items-center">
          <p className="text-lg font-medium">
            {User?.FirstName || capitalize(extractName)}
          </p>
          <p className="font-normal text-grey-600 text-medium">
            {User?.email || "vipul.jain@nanoheal.com"}
          </p>
        </div>
      </div>
    </div>
  );
};
export default DrawerHeader;
