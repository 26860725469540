import React, { useEffect, useState } from "react";
import { getDataWithFilterUrl } from "../../controllers/APIController";
import {
  Table,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableColumn,
  Button,
  useDisclosure,
  Image,
  Pagination,
} from "@nextui-org/react";
import DownloadIcon from "../../Images/Icons/download.svg";

import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import NullData from "../../components/ErrorPage/NullData";
import LoadingPage from "../../components/Loader/LoadingPage";
import TableSkeleton from "../../components/Skeleten/TableSkeleton";
import AddEvidenceModal from "./AddEvidenceModal";

const Evidence = () => {
  const [reRenderPage, setReRenderPage] = useState(true);

  const [filterEvidenceData, setFilterEvidenceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const downloadHandler = (url) => {
    window.open(url);
  };

  useEffect(() => {
    getDataWithFilterUrl("evidences", orgFilterUrl)
      .then(async (data) => {
        const dataList = data?.data || [];
        setFilterEvidenceData(dataList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reRenderPage, orgFilterUrl]);

  const ItemPerPage = 10;
  const totalPage = Math.ceil(filterEvidenceData?.length / ItemPerPage);

  return (
    <BreadCrumbsPage>
      <>
        <AddEvidenceModal
          onSubmitHandler={1}
          id={1}
          isOpen={isOpen}
          onOpen={onOpen}
          onOpenChange={onOpenChange}
          setReRenderPage={setReRenderPage}
        />
        <div className="Container-container">
          <div className="text-3xl font-medium">Evidences</div>
          <div className="mt-8 flex justify-between items-center">
            View your evidences that backs your ESG reports.
            <Button
              color="danger"
              startContent={
                <lord-icon
                  src="https://cdn.lordicon.com/smwmetfi.json"
                  trigger="hover"
                  colors="primary:#ffffff"
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
              }
              onClick={() => onOpen()}
            >
              Add an evidence file
            </Button>
          </div>

          <LoadingPage isLoading={isLoading} alterElement={<TableSkeleton />}>
            {filterEvidenceData && filterEvidenceData?.length > 0 ? (
              <>
                <div className="mt-16">
                  <Table
                    removeWrapper
                    aria-label="Example static collection table"
                  >
                    <TableHeader>
                      <TableColumn>Name</TableColumn>
                      <TableColumn>Description</TableColumn>
                      <TableColumn>Reference To</TableColumn>
                      <TableColumn>Upload Date</TableColumn>
                      <TableColumn>Actions</TableColumn>
                    </TableHeader>
                    <TableBody
                      emptyContent={<NullData title={`No Evidence found.`} />}
                    >
                      {filterEvidenceData &&
                        Array.isArray(filterEvidenceData) &&
                        filterEvidenceData.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell>
                              <div className="flex gap-2">
                                <lord-icon
                                  src="https://cdn.lordicon.com/rbbnmpcf.json"
                                  trigger="hover"
                                  colors={`primary:#000000`}
                                  style={{ width: "20px", height: "20px" }}
                                ></lord-icon>
                                {item?.attributes?.Name}
                              </div>
                            </TableCell>
                            <TableCell>
                              {item?.attributes?.Description}
                            </TableCell>
                            <TableCell>
                              {item?.attributes?.ReferenceList?.data?.length > 0
                                ? item?.attributes?.ReferenceList?.data?.length
                                : "---"}
                            </TableCell>
                            <TableCell>
                              {new Date(
                                item?.attributes?.createdAt
                              ).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              <div
                                className="flex items-center gap-2 cursor-pointer"
                                onClick={() =>
                                  downloadHandler(item?.attributes?.EvidenceUrl)
                                }
                              >
                                <Image
                                  width={20}
                                  alt="NextUI hero Image"
                                  src={DownloadIcon}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
                {filterEvidenceData?.length > 0 && (
                  <div className="w-full mt-8 flex items-center justify-center">
                    <Pagination
                      showControls
                      total={totalPage}
                      color="secondary"
                      page={currentPage}
                      onChange={setCurrentPage}
                    />
                  </div>
                )}
              </>
            ) : (
              <NullData />
            )}
          </LoadingPage>
        </div>
      </>
    </BreadCrumbsPage>
  );
};

export default Evidence;
