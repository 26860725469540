export const templateList = [
  {
    id: 1,
    title: "Template 1",
    description:
      "This template is designed to render structured content with custom styling applied to various elements.",
    styling: {
      table: {
        tableProps: { isStriped: true },
      },
      h1: "text-gray text-lg py-2",
      h2: "text-gray text-lg py-2",
      h3: "text-gray text-lg py-2",
      h4: "text-gray text-lg py-2",
      h5: "text-gray text-lg py-2",
      h6: "text-gray text-lg py-2",
      span: "py-2",
      p: "text-blue-200 text-lg py-2",
    },
  },
  {
    id: 2,
    title: "Template 2",
    description:
      "This template is designed to render structured content with custom styling applied to various elements.",
    styling: {
      table: [],
      h1: "text-blue-400 py-2 text-lg",
      h2: "text-blue-400 py-2 text-lg",
      h3: "text-blue-400 py-2 text-lg",
      h4: "text-blue-400 py-2 text-lg",
      h5: "text-blue-400 py-2 text-lg",
      h6: "text-blue-400 py-2 text-lg",
      span: "py-2",
      p: "text-blue-400 text-lg",
    },
  },
];
