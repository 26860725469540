import { useEffect, useState } from "react";
import { getDataWithFilterUrl } from "../controllers/APIController";

export default function useGetOrgDetail() {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const [OrgDetail, setOrgDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDataWithFilterUrl("organizations", `filters[id][$eqi]=${OrgInfo}`)
      .then((data) => {
        setOrgDetail(data?.data?.[0] || []);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return { OrgDetail, isLoading };
}
