export const CreateReportFieldSchema = {
  Name: {
    id: "reportname",
    name: "Text Input",
    type: "text",
    title: "Name",
    required: true,
    char_limit: "",
    placeholder: "Enter Report Name",
  },
  Description: {
    id: "reportdescription",
    name: "Text Input",
    type: "text",
    title: "Description",
    required: false,
    char_limit: "",
    placeholder: "Write more about the report",
  },
  "Choose Your Fiscal Year": {
    id: 4,
    name: "Drop Down",
    type: "dropdown",
    title: "Choose Your Fiscal Year",
    placeholder: "Choose Your Fiscal Year",
    values: [],
    required: true,
    ismultiselect: false,
  },
};
