import React, { useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import {
  PublishData,
  capitalize,
  getDataWithFilterUrl,
  separateDasFromUrl,
  updateFormSchemaWithRealtion,
} from "../../controllers/APIController";
import { FaChevronDown } from "react-icons/fa";

import {
  Button,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Slider,
  Switch,
  Textarea,
} from "@nextui-org/react";
import { CreateNewEntryButton } from "../../utils/helper";
import { DateFormator } from "../../utils/helper";

const getFormulaData = (formules, formData) => {
  const response = {};
  formules?.map((item) => {
    const func = new Function("return (" + item?.fun + ")");
    const value = func()(formData);
    response[item.title] = value === undefined ? "null" : value;
  });
  return response;
};
const getInitailState = (arr) => {
  const data = {};

  arr?.forEach((item) => {
    if (item[1].type === "boolean") {
      data[item[0]] = "false";
    }
    if (item[1].type === "slider") {
      data[item[0]] = item[1].value || 0;
    } else data[item[0]] = "";
  });
  return data;
};

const CreateLibraryForm = () => {
  const [CollectionInfo, setCollectionInfo] = useState();
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [links, setLinks] = useState([]);

  const navigate = useNavigate();
  const {_orgId} = useParams();

  let paramsList = ["", "factor library", "library"];
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  let HeadingTitle = capitalize(separateDasFromUrl(paramsList[2]));
  let description = CollectionInfo?.attributes?.Description;
  const requiredArr = CollectionInfo?.attributes?.JsonSchema?.required;

  const filteredUrl =
    "filters[Category][$eqi]=" +
    paramsList[1].replaceAll("-", " ") +
    "&filters[Name][$eqi]=" +
    paramsList[2].replaceAll("-", " ");

  const changeHandler = async (title, value, type) => {

    if (type === "file") {
      let formData = new FormData();
      formData.append("files", value);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();

        setFormData({
          ...formdata,
          [title]: { value: data[0].id, url: data[0].url },
        });
      } catch (error) {
        console.error("Upload error!", error);
      }
    } else if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };



  const ValidationForm = () => {
    var numberRegex = /^[0-9]*$/;
    let isValid = true;
    const errors = {};

    formSchema.forEach((row) => {
      // [fieldName, fieldProps, fieldId, fieldValues]
      const fieldType = row[1].type;
      const fieldValue = formdata[row[0]];
      const isRequired = requiredArr.includes(row[0]);
      const fieldName = row[0];
      // You can add more validation rules based on the field type

      if (fieldType === "text" && fieldName === "Name" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }

      if (fieldType === "string" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "date" && isRequired) {
        if (fieldValue === "" || fieldValue === "NaN/NaN/NaN") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "dropdown" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "enumeration" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }

      if (
        isRequired &&
        (fieldType === "integer" ||
          fieldType == "biginteger" ||
          fieldType === "decimal" ||
          fieldType === "float")
      ) {
        if (!numberRegex.test(fieldValue)) {
          errors[fieldName] = `${fieldName} accept only numbers`;
          isValid = false;
        } else if (typeof fieldValue === "string") {
          errors[fieldName] = `${fieldName} can't be empty`;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "media") {
        if (fieldValue === "") {
          errors[fieldName] = `Upload a file `;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "relation") {
        if (fieldValue === "") {
          errors[fieldName] = `Select the ${fieldName} ralated`;
          isValid = false;
        }
      }

      if (isRequired && (fieldType === "datetime" || fieldType === "date")) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required.`;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "richtext") {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required `;
          isValid = false;
        }
      }
    });

    setValidationErrors(errors);
    return isValid;
  };

  const submitHandler = async () => {
    const isValid = ValidationForm();
    if (!isValid) {
      setErrorMessage("Something went wrong!!");
      return;
    }
    Object.keys(formdata).forEach((key) => {
      if (formdata[key] === "") {
        formdata[key] = "null";
      }
    });
    const formulaData = getFormulaData(
      CollectionInfo?.attributes?.JsonSchema?.formula,
      formdata
    );


    const publishData = {
      Metric_Id: CollectionInfo.attributes.Name,
      JsonSchema: { ...formdata, ...formulaData },
      Organization:OrgInfo,
      users_permissions_user: {
        disconnect: [],
        connect: [
          {
            id: UserId,
            position: {
              end: true,
            },
          },
        ],
      },
    };

    let result = await PublishData({ data: publishData }, "responses");

    navigate(`/${_orgId}/factor-library`);
  };


  const clickHandler = (metricName, relatedTo) => {
    let metric = metricName.replaceAll(" ", "-");
    let relatedToName = relatedTo.replaceAll(" ", "-");
    navigate(`/${_orgId}/${metric}/${relatedToName}/create`);

    // window.location.reload();
  };

  useEffect(() => {
    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;
        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);
        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });
        setCollectionInfo(data.data[0]);
        setformSchema(responseArr);
        setLinks(data?.data[0]?.attributes?.Links?.Links);
        setFormData(getInitailState(responseArr));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {}, []);
  return (
    
      <>
        <div className="metric-header"></div>
        <div className="flex justify-end my-4">
          <Button
            color="danger"
            variant="ghost"
            size="md"
            onClick={submitHandler}
          >
            Submit
          </Button>
        </div>
        <div className="flex gap-[160px]">
          <div className="w-full flex flex-wrap gap-x-12 gap-y-0">
            {formSchema &&
              formSchema.map((row, index) => {
                const fieldError = validationErrors[row[0]];
                const isRequired = requiredArr.includes(row[0]);

                if (row[1].type === "string" || row[1].type === "text") {
                  return (
                    <Input
                      type="text"
                      className="mb-16 w-[45%]"
                      isRequired={requiredArr.includes(row[0])}
                      label={row[1].title}
                      placeholder=" "
                      variant="faded"
                      labelPlacement="outside"
                      value={row[1].value}
                      onChange={(event) => {
                        changeHandler(row[0], event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type === "password") {
                  return (
                    <Input
                      type="password"
                      className="mb-16 w-[45%]"
                      isRequired={requiredArr.includes(row[0])}
                      label={row[1].title}
                      placeholder=" "
                      variant="faded"
                      labelPlacement="outside"
                      value={row[1].value}
                      onChange={(event) => {
                        changeHandler(row[0], event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type === "integer") {
                  return (
                    <Input
                      type="number"
                      className="mb-16 w-[45%]"
                      isRequired={requiredArr.includes(row[0])}
                      label={row[1].title}
                      placeholder="0"
                      variant="faded"
                      labelPlacement="outside"
                      value={row[1].value}
                      onChange={(event) => {
                        changeHandler(row[0], +event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type === "dropdown") {
                  return (
                    <div className="flex flex-col gap-2 text-sm w-[45%]">
                      <p>{row[1].title}</p>
                      <Dropdown>
                        <DropdownTrigger
                          className="w-full"
                          isInvalid={fieldError && isRequired}
                          errorMessage={fieldError}
                        >
                          <Button className="mb-16" variant="faded">
                            <div className="w-full flex justify-between">
                              <div className="flex justify-start w-full items-start">
                                {formdata[row[0]] === ""
                                  ? ""
                                  : formdata[row[0]]}
                              </div>
                              <div className="flex justify-end w-full items-end">
                                <FaChevronDown />
                              </div>
                            </div>
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Static Actions"
                          className="w-144 custom-dropdown-menu"
                          selectionMode="single"
                          //   selectedKeys={}
                          onSelectionChange={(event) => {
                            changeHandler(
                              row[0],
                              Array.from(event).join(", ").replaceAll("_", " "),
                              row[1].type
                            );
                          }}
                        >
                          {row[1]?.values?.map((item) => (
                            <DropdownItem key={item.name}>
                              {item.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  );
                }
                if (row[1].type === "slider") {
                  return (
                    <Slider
                      label={row[1].title}
                      isRequired={requiredArr.includes(row[0])}
                      className="mb-16 w-[45%]"
                      step={1}
                      maxValue={100}
                      minValue={0}
                      defaultValue={0}
                      onChange={(value) => {
                        changeHandler([row[0]], value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type === "boolean") {
                  return (
                    <div>
                      <p className="mb-2 w-[45%]">{row[1].title}</p>
                      <Switch
                        defaultSelected={formdata[row[0]] === "true"}
                        color="primary"
                        className="mb-16 w-[50%]"
                        label={row[1].title}
                        labelPlacement="outside"
                        onChange={(event) =>
                          changeHandler(
                            row[0],
                            event.target.checked.toString(),
                            row[1].type
                          )
                        }
                      >
                        {formdata[row[0]] === "true" ? "On" : "Off"}
                      </Switch>
                    </div>
                  );
                }
                if (row[1].type === "textarea") {
                  return (
                    <Textarea
                      className="mb-16 w-[45%]"
                      label={row[1].title}
                      key="1"
                      isRequired={requiredArr.includes(row[0])}
                      variant="faded"
                      labelPlacement="outside"
                      value={formdata[row[0]]}
                      placeholder=" "
                      onChange={(event) => {
                        changeHandler(row[0], event.target.value, row[1].type);
                      }}
                      isInvalid={fieldError && isRequired}
                      errorMessage={fieldError}
                    />
                  );
                }
                if (row[1].type === "date") {
                  return (
                    <>
                      <DatePicker
                        label={row[1].title}
                        labelPlacement="outside"
                        className="mb-16 w-[45%]"
                        isRequired={requiredArr.includes(row[0])}
                        onChange={(value) => {
                          // console.log(parseDate(value));
                          changeHandler(
                            row[0],
                            DateFormator(value),
                            row[1].type
                          );
                        }}
                        isInvalid={fieldError && isRequired}
                        errorMessage={fieldError}
                      />
                    </>
                  );
                }
                // if (row[1].type === "file") {
                //   return (
                //     <Input
                //       type="file"
                //       className="mb-16"
                //       // isRequired={requiredArr.includes(row[0])}
                //       label={row[1].title}
                //       placeholder=" "
                //       variant="faded"
                //       labelPlacement="outside"
                //       accept="image/*"
                //       // value={row[1].value}
                //       onChange={(event) => {
                //         console.log(event);
                //         changeHandler(
                //           row[0],
                //           event.target.files[0],
                //           row[1].type
                //         );
                //       }}
                //       // onValueChange={(event)=>{console.log(event)}}
                //       // isInvalid={fieldError && isRequired}
                //       // errorMessage={fieldError}
                //     /

                //   return (
                //     <div
                //       className="input-container"
                //       style={{ margin: "24px 0px" }}
                //     >
                //       <FileUploader
                //         labelTitle={
                //           <>
                //             {row[1].title}
                //             {requiredArr.includes(row[0]) && <span style={{ color: '#f76497'}}>*</span>}
                //           </>
                //         }
                //         labelDescription="Max file size is 500mb. Only .jpg files are supported."
                //         buttonLabel="Add file"
                //         buttonKind="primary"
                //         size="md"
                //         filenameStatus="edit"
                //         accept={[".jpg", ".png"]}
                //         multiple={true}
                //         disabled={false}
                //         iconDescription="Delete file"
                //         onChange={(event) => {
                //           console.log(event);
                //           changeHandler(
                //             row[0],
                //             event.target.files[0],
                //             row[1].type
                //           );
                //         }}
                //         name=""
                //         className="input-container"
                //         invalid={fieldError && isRequired}
                //         invalidText={fieldError}
                //       />
                //     </div>
                //   );
                // }
                if (row[1].type === "relation") {
                  updateFormSchemaWithRealtion(
                    formSchema,
                    index,
                    setformSchema
                  );
                  const metricIDList = row[1].relatedto.split(",");
                  const createEntryButtonList = metricIDList.map((metricId) => {
                    return {
                      id: "button",
                      name: (
                        <CreateNewEntryButton
                          name={metricId}
                          metricName={row[1].metricname}
                          onClickHandler={clickHandler}
                        />
                      ),
                    };
                  });
                  let newRawValue =
                    row[1].values === undefined
                      ? createEntryButtonList
                      : [...createEntryButtonList, ...row[1]?.values];
                  // row[1].values = [...newRawValue];
                  return (
                    <div className="flex flex-col gap-2 text-sm w-[45%]">
                      <p>
                        {row[1].title}
                        {requiredArr.includes(row[0]) && (
                          <span style={{ color: "#f76497" }}> *</span>
                        )}
                      </p>
                      <Dropdown>
                        <DropdownTrigger
                          invalid={fieldError && isRequired}
                          invalidText={fieldError}
                          className="w-full"
                        >
                          <Button className="mb-16" variant="faded">
                            <div className="w-full flex justify-between">
                              <div className="flex justify-start w-full items-start">
                                {formdata[row[0]] === ""
                                  ? ""
                                  : formdata[row[0]]}
                              </div>
                              <div className="flex justify-end w-full items-end">
                                <FaChevronDown />
                              </div>
                            </div>
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Static Actions"
                          className="w-144 custom-dropdown-menu"
                          selectionMode="single"
                          selectedKeys={"a"}
                          onSelectionChange={(event) => {
                            changeHandler(
                              row[0],
                              Array.from(event).join(", ").replaceAll("_", " "),
                              row[1].type
                            );
                          }}
                        >
                          {newRawValue?.map((item) => (
                            <DropdownItem key={item.name} className="my-1">
                              {item.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </>
    
  );
};

export default CreateLibraryForm;
