import { Button, Card, CardBody, useDisclosure } from "@nextui-org/react";
import React from "react";
import DeleteModal from "../../../components/DeleteModal";
import { deleteOrg } from "../../../controllers/APIController";

const DangerZoneOrg = () => {
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
  } = useDisclosure();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const DeleteHandler = async () => {
    // const r = await deleteOrg({ orgId: OrgInfo });

    onDeleteOpenChange();
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        DeleteHandler={DeleteHandler}
        Text="Organization"
      />
      <div className="my-8">
        This section allows you to manage and delete your organization. Please be cautious when performing this action, as it will permanently remove all associated data and access rights.
      </div>
      <div className="flex items-center">
        <Card className="shadow-none border border-red-400 rounded-2xl p-4 w-full my-4">
          <CardBody>
            <div className="flex items-center justify-between">
              <p className="text-lg font-normal">Delete your Organization</p>
              <Button
                size="sm"
                onClick={onDeleteOpen}
                className="text-sm"
                color="danger"
              >
                Delete Organization
              </Button>
            </div>

            <p className="text-sm pt-2">
              This will permanently remove the organization you will lose access
              to all your data inside your space.
            </p>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default DangerZoneOrg;
