import {
  Chip,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tab,
  Tabs,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import ChartGenerator from "./ChartGenerator";

import {
  ClipboardIcon,
  CheckIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { capitalizeString } from "../../controllers/basicControllers";
import { formatSingleLineSQL } from "../../utils/charts.utils";
import TableChart from "./TableChart";
import html2canvas from "html2canvas";
import AddChartToDashboard from "./AddChartToDashboard";
import {
  getDataWithFilterUrl,
  PublishData,
  UpdateOneData,
} from "../../controllers/APIController";
import { useParams } from "react-router-dom";
import { getUpdatedDashabordLayout } from "../../utils/dashbaord.utils";
import useGetOrgDetail from "../../hooks/useGetOrgDetail";
import useGetUserDetail from "../../hooks/useGetUserInfo";

function formatSQL(sql) {
  return sql
    .replace(/\b(SELECT|FROM|WHERE|GROUP BY|ORDER BY|LIMIT)\b/gi, "\n$1")
    .trim();
}

/*
 chartInfo->name,description,
 sql-> sql
 collectionId->
 chartType-->
*/

const MoreChartAction = ({
  downloadImage,
  dashboardList,
  dataProductId,
  chartType,
  sql,
  chartInfo,
}) => {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  const { OrgDetail: spaceInfo } = useGetOrgDetail();
  const { userDetails: userInfo } = useGetUserDetail();

  const {
    isOpen: isOpenSaveChartModal,
    onOpen: onOpenSaveChartModal,
    onOpenChange: onOpenChangeSaveChartModal,
  } = useDisclosure();

  const downloadHanler = () => {
    downloadImage();
    setIsPopOverOpen(false);
  };

  const openHandler = () => {
    onOpenSaveChartModal();
    setIsPopOverOpen(false);
  };

  const saveCharHandler = async (dashboardDetails) => {

    const chartId = Date.now().toString();
    const chartInfoData = {
      id: chartId,
      chart_type: chartType,
      chart_title: chartInfo?.name,
      collectionId: +dataProductId,
      poisitionInfo: {
        h: 12,
        i: chartId,
        w: 8,
        x: 0,
        y: 0,
        moved: false,
        static: false,
      },
      sql_statement: sql,
      chart_description: chartInfo?.description,
    };

    // create the dashboard if user added new one..
    if (dashboardDetails?.newDashboard) {
      const result = await PublishData("dashboards", {
        name: dashboardDetails?.name,
        description: dashboardDetails?.description,
        space: [spaceInfo[0]?.id],
        users_permissions_user: [userInfo?.id],
        chartsInfo: [chartInfoData],
      });
    } else {
      const updatedDashboardData = getUpdatedDashabordLayout(
        chartInfoData,
        dashboardList,
        dashboardDetails?.dashboard
      );

      const updatedDashboardResponse = await UpdateOneData(
        "dashboards",
        +dashboardDetails?.dashboard,
        {
          chartsInfo: updatedDashboardData,
        }
      );
    }
  };


  return (
    <>
      <Popover
        isOpen={isPopOverOpen}
        onOpenChange={(open) => setIsPopOverOpen(open)}
        placement="bottom"
        showArrow={true}
      >
        <PopoverTrigger>
          <EllipsisHorizontalIcon className="size-6 cursor-pointer" />
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <div className="flex flex-col gap-2 p-2">
            <div
              className="p-2 cursor-pointer hover:bg-gray-light rounded-md"
              onClick={downloadHanler}
            >
              Export as PNG
            </div>
            <div
              className="p-2 cursor-pointer hover:bg-gray-light rounded-md"
              onClick={openHandler}
            >
              Add to Dashboard
            </div>
          </div>
        </PopoverContent>
      </Popover>

      <AddChartToDashboard
        isOpen={isOpenSaveChartModal}
        onOpenChange={onOpenChangeSaveChartModal}
        dashboardList={dashboardList}
        saveCharHandler={saveCharHandler}
      />
    </>
  );
};
const SideDrawerChart = ({
  isOpen,
  onOpenChange,
  chartType,
  queryResult,
  sql,
  chartInfo,
  id,
  onAddCustomFilter,
  filterList,
  removeFilter,
  dataProductId,
}) => {
  const [dashboardList, setDashboardList] = useState([]);
  const [selected, setSelected] = useState("sql");
  const [isCopied, setIsCopied] = useState(false);
  const divRef = useRef(null);
  const params = useParams();

  const codeLines = formatSQL(sql).split("\n");
  const chartHeight =
    chartType !== "table chart" ? "h-[240px]" : "min-h-[240px]";

  const copySqlHandler = () => {
    navigator.clipboard.writeText(sql);
    setIsCopied(true);
  };

  const downloadImage = () => {
    // Ensure the div is rendered before capturing
    if (divRef.current) {
      html2canvas(divRef.current).then((canvas) => {
        // Convert canvas to PNG
        const imgData = canvas.toDataURL("image/png");

        // Create a link element to download the image
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "downloaded_image.png"; // Image name
        link.click(); // Trigger download
      });
    }
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  }, [isCopied]);

  useEffect(() => {
    getDataWithFilterUrl(
      "dashboards",
      `filters[space][Name][$eq]=${params._spaceid}`
    ).then((data) => {
      setDashboardList(data?.data);
    });
  }, []);

  return (
    <Modal
      radius="none"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className="absolute !m-0 top-0 right-0 h-screen z-100"
      // backdrop="transparent"
      hideCloseButton={true}
      size="3xl"
    >
      <ModalContent className="px-4 py-4">
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col w-full items-start ">
              <div className="flex w-full  flex-col gap-1">
                <div className="flex w-full items-center justify-between">
                  <p className="font-semibold">
                    {chartType !== "table chart"
                      ? capitalizeString(chartInfo?.name).replace(/"/g, "")
                      : "Data"}
                  </p>
                  <MoreChartAction
                    downloadImage={downloadImage}
                    dashboardList={dashboardList}
                    dataProductId={dataProductId}
                    chartType={chartType}
                    sql={sql}
                    chartInfo={chartInfo}
                  />
                </div>
              </div>

              <Divider className="mt-2" />
            </ModalHeader>
            <ModalBody className=" ">
              <div className="w-full flex flex-col gap-2 items-center justify-center">
                <div className="flex items-center flex-wrap gap-1 w-[450px]">
                  {filterList?.map((data) => {
                    return (
                      <Chip
                        color="danger"
                        variant="bordered"
                        size="sm"
                        onClose={() => {
                          removeFilter(data?.id);
                        }}
                      >
                        {data?.query}
                      </Chip>
                    );
                  })}
                </div>
                <div ref={divRef} className={`w-[450px] ` + chartHeight}>
                  <ChartGenerator
                    id={id}
                    chartType={chartType}
                    data={queryResult}
                    chartInfo={chartInfo}
                    onAddCustomFilter={onAddCustomFilter}
                  />
                </div>
              </div>

              {chartType !== "table chart" && (
                <div className="p-2">
                  <p className="font-bold">
                    {chartInfo?.name.replace(/"/g, "")}
                  </p>
                  <p className="text-sm text-gray">
                    {chartInfo?.description.replace(/"/g, "")}
                  </p>
                </div>
              )}
              <Divider />
              <div className="flex items-center justify-between">
                <Tabs
                  selectedKey={selected}
                  onSelectionChange={setSelected}
                  size="sm"
                  classNames={{
                    tabContent: "font-medium",
                  }}
                >
                  <Tab key="sql" title="SQL"></Tab>
                  <Tab key="table" title="Result"></Tab>
                </Tabs>

                <Tooltip
                  content="copy sql"
                  classNames={{
                    content: ["py-2 px-4 shadow-xl", "bg-black", "text-white"],
                  }}
                >
                  {!isCopied ? (
                    <ClipboardIcon
                      className="size-6 mr-4 cursor-pointer border-1 border-gray p-[3px] rounded-md"
                      onClick={copySqlHandler}
                    />
                  ) : (
                    <CheckIcon className="size-6 mr-4 cursor-pointer border-1 border-gray p-[2px] rounded-md" />
                  )}
                </Tooltip>
              </div>
              {selected === "sql" ? (
                <div
                  className="bg-black text-white p-4 overflow-x-auto text-lg rounded-md w-full flex"
                  style={{ fontFamily: "monospace" }}
                >
                  {/* Line Numbers Column */}
                  <div
                    className="line-numbers"
                    style={{
                      color: "gray",
                      textAlign: "right",
                      marginRight: "1em",
                      userSelect: "none",
                    }}
                  >
                    {codeLines.map((_, index) => (
                      <div key={index} style={{ lineHeight: "2em" }}>
                        {index + 1}
                      </div>
                    ))}
                  </div>

                  {/* Code Column */}
                  <div className="code-content">
                    {codeLines.map((line, index) => (
                      <div
                        style={{ lineHeight: "2em" }}
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: formatSingleLineSQL(line),
                        }}
                      />
                    ))}
                  </div>
                </div>
              ) : selected === "table" ? (
                <div className="w-full">
                  <TableChart TableData={queryResult} />
                </div>
              ) : (
                <></>
              )}
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SideDrawerChart;
