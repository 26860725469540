import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDataWithFilterUrl } from "../controllers/APIController";
import NullData from "./ErrorPage/NullData";
import {
  BreadcrumbItem,
  Breadcrumbs,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from "@nextui-org/react";
import SearchIcon from "../Images/Search";
import LoadingPage from "./Loader/LoadingPage";
import MetricDrawer from "./MetricDrawer";

const DefaultFrameworkView = () => {
  const [frameworkDetails, setFrameworkDetails] = useState();
  const [drawerMetric, setDrawerMetric] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [metricData, setMetricData] = useState([]);

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onOpenChange: onDrawerOpenChange,
  } = useDisclosure();

  const params = useParams();
  const id = params._id;

  useEffect(() => {
    getDataWithFilterUrl("frameworks", `filters[id][$eqi]=${id}`)
      .then((data) => {
        setFrameworkDetails(data?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!frameworkDetails) {
      return;
    }
    const metricIDs = frameworkDetails?.attributes?.ControlMetrics?.id;
    let metricDetails = [];

    const result = metricIDs.map(async (id) => {
      const metricResponse = await getDataWithFilterUrl(
        "metrics",
        `filters[id][$eqi]=${id}`
      );
      metricDetails.push(metricResponse?.data[0]);
      return metricResponse;
    });

    Promise.all(result).then((data) => {
      setIsLoading(false);
      setMetricData(metricDetails);
    });
  }, [frameworkDetails]);


  const filterDataList = metricData.filter((item) => {
    return item?.attributes?.Name?.toLowerCase()?.includes(
      searchText?.toLowerCase()
    );
  });
  const rowsPerPage = 8;

  const pages = Math.ceil(filterDataList?.length / rowsPerPage);

  return (
    <div className="w-full h-screen py-8 px-12">
      <div className="mb-2">
        <Breadcrumbs size="lg">
          <BreadcrumbItem>Framework</BreadcrumbItem>
          <BreadcrumbItem>{id}</BreadcrumbItem>
        </Breadcrumbs>
      </div>
      <LoadingPage isLoading={isLoading}>
        <div className="section">
          <div className="flex items-center justify-between">
            <h1 className="text-3xl font-medium">
              {frameworkDetails && frameworkDetails.attributes.Name}
            </h1>
          </div>
          <div className="my-4">
            {frameworkDetails && frameworkDetails.attributes.Description}
          </div>

          <div className="mt-12">
            <div className="font-medium">Control metrics</div>

            <div className="w-full">
              <Input
                classNames=""
                placeholder="Search control metrics"
                className="mt-4 w-full"
                startContent={
                  <SearchIcon
                    className={`text-large text- pointer-events-none flex-shrink-0 `}
                  />
                }
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />

              {filterDataList?.length > 0 ? (
                <>
                  <div className="mt-8 min-h-[400px]">
                    <Table
                      aria-label="Controlled table metrics"
                      removeWrapper
                      isHeaderSticky
                      selectionMode="single"
                    >
                      <TableHeader>
                        <TableColumn>Metric Name</TableColumn>
                        <TableColumn>Category</TableColumn>
                        <TableColumn>Description</TableColumn>
                        <TableColumn></TableColumn>
                      </TableHeader>
                      <TableBody>
                        {filterDataList?.map((item, index) => {
                          const lowerPageLimit = (page - 1) * rowsPerPage;
                          const upperPageLimit = page * rowsPerPage;

                          if (index < lowerPageLimit || index >= upperPageLimit)
                            return null;
                          return (
                            <TableRow
                              key={item?.id}
                              onClick={() => {
                                // setDrawerUser(user);
                                setDrawerMetric(item);
                                onDrawerOpen();
                              }}
                            >
                              <TableCell>{item?.attributes?.Name}</TableCell>
                              <TableCell>
                                {item?.attributes?.Category}
                              </TableCell>
                              <TableCell>
                                {item?.attributes?.Description || "--"}
                              </TableCell>
                              <TableCell>
                                <div
                                  className="text-blue-600 font-semibold cursor-pointer"
                                  //   onClick={() => HandleToNavigate(item)}
                                >
                                  View
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                  <div className="flex items-center justify-center mt-4">
                    <Pagination
                      isCompact
                      showShadow
                      showControls
                      color="secondary"
                      page={page}
                      total={pages}
                      onChange={(page) => setPage(page)}
                    />
                  </div>
                </>
              ) : (
                <NullData title="No Data Present!!" />
              )}
            </div>
          </div>
        </div>
      </LoadingPage>
      <MetricDrawer
        //   OrgInfo={OrgInfo}
        //   User={drawerUser}
        frameworkId={+id}
        metricInfo={drawerMetric}
        isOpen={isDrawerOpen}
        onOpen={onDrawerOpen}
        onOpenChange={onDrawerOpenChange}
      />
    </div>
  );
};

export default DefaultFrameworkView;
