import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";

const HayaTableRender = ({ markdownTable, tableCustomeCss = {} }) => {
  // Replace escape sequences (like \n for new lines)
  const formattedTable = markdownTable.map((row) =>
    row.replace(/\\n/g, "<br/>")
  );

  // Extracting header and data rows
  const [headerRow, ...dataRows] = formattedTable.map((row) =>
    row.split("|").map((cell) => cell.trim())
  );

  return (
    <div className="mt-4">
      <Table removeWrapper {...(tableCustomeCss?.tableProps || {})}>
        <TableHeader>
          {headerRow.map((cell, index) => (
            <TableColumn key={`${index}`}>{cell}</TableColumn>
          ))}
        </TableHeader>
        <TableBody>
          {dataRows
            .filter((row) => row.length === headerRow.length) // Filter rows where length matches header
            .map((row, rowIndex) => (
              <TableRow key={`${rowIndex}`}>
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>
                    {/* Render the content, handle HTML tags like <br/> */}
                    <span dangerouslySetInnerHTML={{ __html: cell }} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default HayaTableRender;
