import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import { ProgessColors } from "../../utils/constant";

const ReportWizardPart3 = ({
  DataList,
  setDataList,
  currentPage,
  setCurrrentPage,
}) => {
  return (
    <div>
      <div className="min-h-[500px]">
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-medium">View the Report</p>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum
            error quibusdam voluptatum maxime, nam quis iusto exercitationem
            tempora quaerat aliquid repellendus consequatur est illum ducimus
            soluta modi placeat dolor enim eius ex suscipit molestias cumque.
          </p>
        </div>
      </div>
      <div className={`w-full flex items-center my-8 gap-4 justify-end`}>
        <Button
          color={ProgessColors[currentPage]}
          variant="light"
          className={`text-center cursor-pointer ${
            currentPage === 0 && "hidden"
          }`}
          onClick={() => {
            setCurrrentPage(currentPage - 1);
          }}
        >
          Previous
        </Button>
        <Button
          color={ProgessColors[currentPage]}
          className={`text-center cursor-pointer`}
          onClick={1}
        >
          Print
        </Button>
      </div>
    </div>
  );
};

export default ReportWizardPart3;
