import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Textarea,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { AILogo } from "../../constant/FormBuilder/FormBuilderConstant";
import { getPromptData } from "../../controllers/vectordbController";
import Showdown from "showdown";

const CreateMarkDownWithAI = ({
  isOpen,
  onOpenChange,
  onSubmitHandler,
  setInitial,
}) => {
  const [text, SetText] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState("");
  const handleOpenChange = () => {};

  const submitHandler = async () => {
    if (!text?.trim() && text?.trim()?.length === 0) {
      setValidationErrors("Please enter some text");
      return;
    }
    setLoading(true);
    const policyPrompt = {
      prompt: "{policy}",
      variables: [
        {
          variable: "policy",
          value: text,
        },
      ],
    };

    const result = await getPromptData(policyPrompt);

    const converter = new Showdown.Converter();
    const html = converter.makeHtml(result.response);

    setInitial(false);
    onSubmitHandler(html);
    setLoading(false);
    SetText("");
    setValidationErrors("");
    onOpenChange();
  };


  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <p>Describe the Markdown that you wanted</p>
              </ModalHeader>
              <ModalBody className="mb-4">
                <Textarea
                  className=""
                  //   label={"Description"}
                  isRequired
                  value={text}
                  placeholder={" "}
                  variant="faded"
                  labelPlacement="outside"
                  onChange={(event) => {
                    SetText(event.target.value);
                  }}
                  isInvalid={validationErrors}
                  errorMessage={validationErrors}
                />
                <div className="flex items-center gap-2">
                  <span>{AILogo}</span>
                  <span className="text-xs text-gray">
                    Powered by <span className="font-semibold"> Audyt AI</span>
                  </span>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  variant="light"
                  size="sm"
                  onPress={onClose}
                >
                  Cancel
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => submitHandler(onClose)}
                >
                  {!isLoading ? (
                    "Generate"
                  ) : (
                    <Spinner size="sm" color="white" className="text-white" />
                  )}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateMarkDownWithAI;
