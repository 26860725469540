import { Button } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { ProgessColors } from "../../utils/constant";
import { CreateReportFieldSchema } from "../../constant/Report/reportConstant";
import { DropDownInput, TextInput } from "../FormBuilder/InputField/InputField";
import { getDataWithFilterUrl } from "../../controllers/APIController";

const FirstReportPart = ({
  DataList,
  setDataList,
  currentPage,
  setCurrrentPage,
}) => {
  let schemaData = CreateReportFieldSchema;

  const [allFiscalYear, setAllFiscalYear] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const ValidationHandler = () => {
    const err = {};
    let isValid = true;
    setValidationErrors({});

    const reportName = DataList?.Name;
    const fiscalYear = DataList?.["Choose Your Fiscal Year"];
    if (!reportName) {
      err["Name"] = "Report Name is required!";
      isValid = false;
    }
    if (!fiscalYear) {
      err["Choose Your Fiscal Year"] = "Fiscal Year is required!";
      isValid = false;
    }

    console.log(err);
    setValidationErrors(err);
    return isValid;
  };
  const handleNext = () => {
    if (ValidationHandler()) {
      setCurrrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    getDataWithFilterUrl("financial-years", orgFilterUrl)
      .then((data) => {
        const yearList =
          data?.data?.map((item) => {
            return {
              key: item?.attributes?.Name?.toLowerCase(),
              name: item?.attributes?.Name,
            };
          }) || [];
        setAllFiscalYear(yearList);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <div className="min-h-[500px]">
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-medium">Basic Information</p>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum
            error quibusdam voluptatum maxime, nam quis iusto exercitationem
            tempora quaerat aliquid repellendus consequatur est illum ducimus
            soluta modi placeat dolor enim eius ex suscipit molestias cumque.
          </p>
        </div>
        <TextInput
          data={schemaData["Name"]}
          value={DataList["Name"]}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) => {
            setDataList({ ...DataList, [title]: value });
          }}
          cssStyle="my-8"
        />

        <DropDownInput
          data={{
            ...schemaData["Choose Your Fiscal Year"],
            values: allFiscalYear,
          }}
          value={DataList["Choose Your Fiscal Year"]}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) =>
            setDataList({ ...DataList, [title]: value })
          }
          buttonCSS="my-8"
        />
      </div>
      <div className={`w-full flex items-center my-8 gap-4 justify-end`}>
        <Button
          color={ProgessColors[currentPage]}
          variant="light"
          className={`text-center cursor-pointer ${
            currentPage === 0 && "hidden"
          }`}
          onClick={() => {
            setCurrrentPage(currentPage - 1);
          }}
        >
          Previous
        </Button>
        <Button
          color={ProgessColors[currentPage]}
          className={`text-center cursor-pointer`}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default FirstReportPart;
