import { Link, useNavigate, useParams } from "react-router-dom";
import {
  UpdateOneData,
  capitalize,
  getDataWithFilterUrl,
  separateDasFromUrl,
  updateFormSchemaWithRealtion,
} from "../../controllers/APIController";

import { Avatar, Button } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { formatDate, getFormulaData } from "../../utils/helper";
import { Colors, numColors } from "../../utils/constant";
import WorkFlowComment from "../Metrics/WorkFlowComment";
import {
  BooleanInput,
  DateInput,
  DropDownInput,
  FileInput,
  IntegerInput,
  PasswordInput,
  RelationDropDownInput,
  SliderInput,
  TextAreaInput,
  TextInput,
} from "../FormBuilder/InputField/InputField";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import LoadingPage from "../../components/Loader/LoadingPage";

/* 
->I need to fetch the form Schema from Metric
->I need to fetch Form Data from Response
UpdateOneData(collectionName,id,data)
*/

const EditReferenceData = () => {
  const [isLoading, setisLoading] = useState(true);
  const [CollectionInfo, setCollectionInfo] = useState();
  const [metricInfo, setMetricInfo] = useState({});
  const [responseInfo, setResponseInfo] = useState({});
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [description, setDescription] = useState("");
  const [links, setLinks] = useState([]);
  const [submittedUser, setSubmittedUser] = useState(null);
  const navigate = useNavigate();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const { _pathId, _formId, _orgId } = useParams();
  const _categoryId = "reference-data";

  const isFormulaVisiable =
    CollectionInfo?.attributes?.JsonSchema?.formula?.length > 0 ? true : false;

  const ResponseId = +_formId;

  let HeadingTitle = capitalize(separateDasFromUrl(_pathId));
  const requiredArr = metricInfo?.attributes?.JsonSchema?.required;
  const metricFilteredUrl =
    "filters[Category][$eqi]=" +
    _categoryId.toLocaleLowerCase().replaceAll("-", " ") +
    "&filters[Name][$eqi]=" +
    _pathId.replaceAll("-", " ");

  const responseMetricUrl = orgFilterUrl + "&filters[id][$eqi]=" + ResponseId;

  const ValidationForm = () => {
    var numberRegex = /^[0-9]*$/;
    let isValid = true;
    const errors = {};

    formSchema.forEach((row) => {
      // [fieldName, fieldProps, fieldId, fieldValues]
      const fieldType = row[1].type;
      const fieldValue = formdata[row[0]];
      const isRequired = requiredArr.includes(row[0]);
      const fieldName = row[0];

      // You can add more validation rules based on the field type

      if (fieldType === "text" && fieldName === "Name" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }

      if (fieldType === "string" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "date" && isRequired) {
        if (fieldValue === "" || fieldValue === "NaN/NaN/NaN") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "dropdown" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "enumeration" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }

      if (
        isRequired &&
        (fieldType === "integer" ||
          fieldType === "biginteger" ||
          fieldType === "decimal" ||
          fieldType === "float")
      ) {
        if (!numberRegex.test(fieldValue)) {
          errors[fieldName] = `${fieldName} accept only numbers`;
          isValid = false;
        } else if (typeof fieldValue === "string") {
          errors[fieldName] = `${fieldName} can't be empty`;
          isValid = false;
        }
      }

      if (isRequired && (fieldType === "media" || fieldType === "file")) {
        if (fieldValue === "") {
          errors[fieldName] = `Upload a file `;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "relation") {
        if (fieldValue === "") {
          errors[fieldName] = `Select the ${fieldName} ralated`;
          isValid = false;
        }
      }

      if (isRequired && (fieldType === "datetime" || fieldType === "date")) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required.`;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "richtext") {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required `;
          isValid = false;
        }
      }
    });
    setValidationErrors(errors);
    return isValid;
  };

  const changeHandler = async (title, value, type) => {
    if (type === "file") {
      let formData = new FormData();
      formData.append("files", value);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();

        setFormData({
          ...formdata,
          [title]: { value: data[0].id, url: data[0].url },
        });
      } catch (error) {
        console.error("Upload error!", error);
      }
    } else if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };

  const testCalculationHandler = async () => {
    const isValid = ValidationForm();
    if (!isValid) {
      return;
    }
    Object.keys(formdata).forEach((key) => {
      if (formdata[key] === "") {
        formdata[key] = "null";
      }
    });
    // const formulaData = await getFormulaData(
    //   CollectionInfo?.attributes?.JsonSchema?.formula,
    //   formdata
    // );
  };

  const submitHandler = async () => {
    const isValid = ValidationForm();

    if (!isValid) {
      // setErrorMessage("Something went wrong!!");
      return;
    }

    const formulaData = await getFormulaData(
      CollectionInfo?.attributes?.JsonSchema?.formula,
      formdata
    );

    const publishData = {
      Metric_Id: _pathId.replaceAll("-", " "),
      JsonSchema: { ...formdata, ...formulaData },
    };

    await UpdateOneData("responses", ResponseId, publishData);

    navigate(`/${_orgId}/${_categoryId}/${_pathId}`);
  };

  useEffect(() => {
    // Firsrt We fetch the Schema Data from Metric Collection and then We fetch the data from Response collection for Particular Id

    getDataWithFilterUrl("metrics", metricFilteredUrl)
      .then((data) => {
        setMetricInfo(data.data[0]);
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;

        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);

        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });
        setCollectionInfo(data.data[0]);
        setformSchema(responseArr);
        setDescription(data.data[0]?.attributes?.Description);
        setLinks(data?.data[0]?.attributes?.Links?.Links || []);
        getDataWithFilterUrl("responses", responseMetricUrl).then(
          (responseData) => {
            setResponseInfo(responseData?.data[0]);
            const userData =
              responseData?.data[0]?.attributes?.users_permissions_user?.data;
            setSubmittedUser(userData);
            const reponseFormData =
              responseData?.data[0]?.attributes?.JsonSchema;

            Object.keys(reponseFormData).forEach((key) => {
              if (reponseFormData[key] === "null") {
                reponseFormData[key] = "";
              }
            });
            setFormData(reponseFormData);
            setisLoading(false);
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [metricFilteredUrl, responseMetricUrl]);

  const clickHandler = (metricName, relatedTo) => {
    let metric = metricName.replaceAll(" ", "-");
    let relatedToName = relatedTo.replaceAll(" ", "-");
    navigate(`/${_orgId}/${metric}/${relatedToName}/create`);

    window.location.reload();
  };

  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={isLoading}>
        <>
          <div className="metric-header">
            <p className="text-3xl font-medium">{HeadingTitle}</p>
            <p className="mt-8">{description}</p>
          </div>
          <div className="flex justify-between mt-8 relative">
            <div className={`w-[50%] ${links?.length === 0 && "hidden"}`}>
              <div className="text-lg font-semibold">
                Helpful Background Reading:
              </div>
              <div>
                <div className="link-list my-2 text-blue-600 font-semibold">
                  {links?.map((link, index) => (
                    <div className="links">
                      <Link
                        key={index}
                        href={link.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.name}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between my-4">
            <p className="flex items-center gap-2 mb-8">
              <Avatar
                showFallback
                className="h-8 w-8 cursor-pointer hover:scale-125 transition duration-700 ease-in-out"
                color={
                  Colors[
                    responseInfo?.attributes?.users_permissions_user?.data?.id %
                      numColors
                  ]
                }
                name={responseInfo?.attributes?.users_permissions_user?.data?.attributes?.email
                  .toUpperCase()
                  .slice(0, 1)}
                src="https://images.unsplash.com/broken"
              />
              <span>
                {submittedUser?.attributes?.FirstName ||
                  submittedUser?.attributes?.email}
                {" • "}
              </span>
              {"Updated at "}
              {formatDate(responseInfo?.attributes?.updatedAt)}
            </p>
            <div className="flex gap-2">
              {isFormulaVisiable && (
                <Button
                  color="secondary"
                  variant="ghost"
                  size="md"
                  onClick={testCalculationHandler}
                >
                  Test Calculation
                </Button>
              )}
              <Button
                color="danger"
                className={`cursor-pointer ${
                  submittedUser?.id !== UserId ? "hidden" : ""
                }`}
                variant="ghost"
                onClick={submitHandler}
              >
                Update
              </Button>
            </div>
          </div>

          <div className="flex gap-[160px]">
            <div className="w-[50%]">
              {formSchema &&
                formSchema.map((row, index) => {
                  const uniqueId = Date.now().toString + index;

                  if (row[1].type === "text" || row[1].type === "string") {
                    return (
                      <TextInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "password") {
                    return (
                      <PasswordInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "integer") {
                    return (
                      <IntegerInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "boolean") {
                    return (
                      <BooleanInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "textarea") {
                    return (
                      <TextAreaInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "file") {
                    return (
                      <FileInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        inputfiledId={uniqueId}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "slider") {
                    return (
                      <SliderInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "dropdown") {
                    return (
                      <DropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "date") {
                    return (
                      <DateInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "relation") {
                    updateFormSchemaWithRealtion(
                      formSchema,
                      index,
                      setformSchema
                    );
                    return (
                      <RelationDropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                        onClickHandler={clickHandler}
                      />
                    );
                  }
                  return null;
                })}
            </div>
            <div className="w-[40%]">
              {isFormulaVisiable && (
                <div className="bg-gray rounded-sm p-2">Test Calculation</div>
              )}
              <WorkFlowComment
                CollectionInfo={CollectionInfo}
                responseInfo={responseInfo}
              />
            </div>
          </div>
        </>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default EditReferenceData;
