import React from "react";
import { grid } from "ldrs";

grid.register();

const LoadingPage = ({
  children,
  isLoading = true,
  alterElement = (
    <div className="flex items-center justify-center w-full min-h-[500px]">
      <l-grid size="100" speed="1.5" color="black"></l-grid>
    </div>
  ),
}) => {
  return <>{isLoading ? alterElement : children}</>;
};

export default LoadingPage;
