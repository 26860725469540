import React from "react";
import { Tab, Tabs } from "@nextui-org/react";

import ApiKey from "./ApiKey/ApiKey";
import Organization from "./Organization";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import ReportingYear from "./ReportingYear/ReportingYear";
import DangerZoneOrg from "./DangerZone/DangerZoneOrg";

const Settings = () => {
  return (
    <BreadCrumbsPage>
      <div className="Container-container">
        <div className="text-3xl font-medium">Settings</div>

        <div className="mt-8">
          <Tabs variant="light">
            <Tab key="org" title="Company Profile">
              <Organization />
            </Tab>
            {/* <Tab key="report-year" title="Report Year">
              <ReportingYear />
            </Tab> */}
            <Tab key="api-key" title="API Key">
              <ApiKey />
            </Tab>
            <Tab key="danger-zone" title="Danger Zone">
              <DangerZoneOrg />
            </Tab>
          </Tabs>
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default Settings;
