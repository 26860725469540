import React, { useEffect, useRef } from "react";
import HayaTableRender from "./HayaTableRender";
const ChatBox = ({ messages, templateInfo = {} }) => {
  const messagesEndRef = useRef(null);

  const renderFormattedText = (text) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const italicRegex = /\*(.*?)\*/g;
    const headingRegex = /^(#{1,6})\s+(.*?)(\s*)$/gm;

    // Handle headings first
    return text.split("\n").map((line, index) => {
      // Remove extra spaces before matching
      const trimmedLine = line.trim();
      const match = headingRegex.exec(trimmedLine);
      if (match) {
        const level = match[1].length;
        const content = match[2];
        const HeadingTag = `h${level}`;
        const customCss = templateInfo?.styling?.[HeadingTag] || "";

        return (
          <HeadingTag key={index} className={customCss}>
            {renderFormattedText(content)}
          </HeadingTag>
        );
      }

      // If not a heading, apply bold and italic formatting
      const customCssForstrong = templateInfo?.styling?.["strong"] || "";
      const customCssForem = templateInfo?.styling?.["em"] || "";
      const processedText = trimmedLine
        .replace(
          boldRegex,
          `<strong className=${customCssForstrong}>$1</strong>`
        )
        .replace(italicRegex, `<em className=${customCssForem}>$1</em>`);

      const customCss = templateInfo?.styling?.["span"] || "";
      return (
        <span
          key={index}
          className={customCss}
          dangerouslySetInnerHTML={{
            __html: processedText,
          }}
        />
      );
    });
  };

  const responseTable = [];

  function apphend_table(props) {
    responseTable.push(props);
  }

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="p-2 flex flex-col gap-4">
      <div ref={messagesEndRef} className="flex flex-col  overflow-y-auto">
        <div className="w-full flex flex-col text-sm">
          {messages.split("\n").map((part, index) => {
            const tablepart = part.trim().startsWith("|");

            if (part.trim().startsWith("-")) {
              return renderFormattedText(part);
            } else if (tablepart) {
              if (part.trim().includes("---")) {
                return (
                  responseTable && (
                    <>
                      <div className="overflow-y-auto">
                        <HayaTableRender
                          markdownTable={responseTable}
                          tableCustomeCss={templateInfo?.styling?.table}
                        />
                      </div>
                    </>
                  )
                );
              } else {
                const appendedPart = part.trim() + "\n";
                apphend_table(appendedPart);
                return null;
              }
            } else {
              return renderFormattedText(part);
            }
          })}
        </div>
        <div className="h-0" ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ChatBox;
