import { Button, Select, SelectItem } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { ProgessColors } from "../../utils/constant";
import { CreateReportFieldSchema } from "../../constant/Report/reportConstant";
import { TextAreaInput, TextInput } from "../FormBuilder/InputField/InputField";
import { getDataWithFilterUrl } from "../../controllers/APIController";

const FirstReportPart = ({
  DataList,
  setDataList,
  currentPage,
  onSubmitHandler,
}) => {
  let schemaData = CreateReportFieldSchema;

  const [validationErrors, setValidationErrors] = useState({});
  const [orgFrameworkDetails, setOrgFrameworkDetails] = useState([]);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const selectedFramework = DataList?.frameworkId || null;
  const ValidationHandler = () => {
    const err = {};
    let isValid = true;
    setValidationErrors({});

    const reportName = DataList?.Name;
    const currentFrameworkId = DataList?.frameworkId;
    if (!reportName) {
      err["Name"] = "Report Name is required!";
      isValid = false;
    }

    if (!currentFrameworkId) {
      err["frameworkId"] = "Framework is required!";
      isValid = false;
    }

    setValidationErrors(err);
    return isValid;
  };
  const handleNext = () => {
    if (!ValidationHandler()) {
      return;
    }
    onSubmitHandler();
  };

  useEffect(() => {
    const url = "filters[organizations][id][$eqi]=" + OrgInfo;
    getDataWithFilterUrl("frameworks", url)
      .then((data) => {
        let response = data?.data;

        const list = response?.map((item) => {
          return {
            key: item?.attributes?.Name,
            name: item?.attributes?.Name,
          };
        });

        setOrgFrameworkDetails(list || []);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [OrgInfo]);

  return (
    <div>
      <div className="min-h-[500px]">
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-medium">Basic Information</p>
          <p>
            Please provide the basic details required to create your report.
            Ensure that all necessary fields are filled out accurately to avoid
            any issues during the report generation process.
          </p>
        </div>
        <div className="flex flex-col gap-2 my-4">
          <TextInput
            data={schemaData["Name"]}
            value={DataList["Name"]}
            validationErrors={validationErrors}
            onChangeHandler={(title, value, type) => {
              setDataList({ ...DataList, [title]: value });
            }}
            cssStyle="my-2"
          />
          <TextAreaInput
            data={schemaData["Description"]}
            value={DataList["Description"]}
            validationErrors={validationErrors}
            onChangeHandler={(title, value, type) => {
              setDataList({ ...DataList, [title]: value });
            }}
            cssStyle="my-4"
          />
          <Select
            isRequired
            label="Select Framework"
            placeholder="Select any Framework"
            variant="faded"
            selectionMode="single"
            labelPlacement="outside"
            className="my-8"
            selectedKeys={selectedFramework && new Set([selectedFramework])}
            onSelectionChange={(event) => {
              setDataList({ ...DataList, frameworkId: Array.from(event)[0] });
            }}
            errorMessage={validationErrors["frameworkId"]}
            isInvalid={validationErrors["frameworkId"]}
          >
            {orgFrameworkDetails?.map((item) => (
              <SelectItem key={item.key} value={item.name} className="py-2">
                {item.name}
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={`w-full flex items-center my-8 gap-4 justify-end`}>
        <Button
          color={ProgessColors[currentPage]}
          className={`text-center cursor-pointer`}
          onClick={handleNext}
        >
          Generate
        </Button>
      </div>
    </div>
  );
};

export default FirstReportPart;
