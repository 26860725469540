import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Image } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import {
  passwordlessCode,
  passwordlessSigninConsume,
} from "../../controllers/SupertokenController";

const VerifyLogin = () => {
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const inputRefs = useRef([]);
  const [resendTimer, setResendTimer] = useState(25);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleInputChange = (index, value) => {
    const sanitizedValue = value.replace(/\D/, "");
    const updatedCode = [...verificationCode];
    updatedCode[index] = sanitizedValue;
    setVerificationCode(updatedCode);

    if (sanitizedValue !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    if (/\D/.test(value)) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      (e.key === "Backspace" || e.key === "Delete") &&
      index > 0 &&
      verificationCode[index] === ""
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim();
    if (/^\d{6}$/.test(pastedData)) {
      setVerificationCode(pastedData.split(""));
      inputRefs.current[5].focus();
    }
  };

  const handleOnNavigate = async () => {
    navigate("/signup");
  };

  const email = sessionStorage.getItem("email");

  const verifyCode = async () => {
    const OTP = verificationCode.join("");
    const route = await passwordlessSigninConsume(OTP);
    navigate(route, { replace: true });
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    let timerId;

    if (resendTimer > 0) {
      timerId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [resendTimer]);

  const resendCode = async () => {
    const verifyBody = await passwordlessCode(email);
    window.location.reload();
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="max-w-md w-full justify-center  ">
        <Image
          width={50}
          alt="NextUI hero Image"
          src="Assets/logo.svg"
          className="mb-5 flex"
        />
      </div>
      <div className="max-w-md ">
        <h2 className="text-2xl font-bold mb-4">Sign in to Audyt ESG</h2>
        <p className="text-small text-default-400 mb-4">
          A 6 digit PIN has been sent to{" "}
          <span className="font-bold">{email}</span>. Enter the PIN to verify
          your email address.
        </p>
        <div className="flex mb-4">
          {verificationCode.map((digit, index) => (
            <Input
              key={index}
              maxLength="1"
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={(e) => handlePaste(e)}
              ref={(el) => (inputRefs.current[index] = el)}
              className="mr-2 w-12"
            />
          ))}
        </div>
        {showErrorMessage && (
          <div className="text-red-500 mb-2">Please enter numbers only.</div>
        )}
        <Button
          className="mb-5 w-full bg-black text-white mt-5 "
          onClick={verifyCode}
        >
          Verify your email address
        </Button>
        <div className="text-small text-default-400 mb-4">
          Resend your One Time Password (OTP){" "}
          {resendTimer > 0 ? (
            <strong>{formatTime(resendTimer)} seconds</strong>
          ) : (
            <span
              className=" text-blue-500 cursor-pointer"
              onClick={resendCode}
            >
              click here
            </span>
          )}
        </div>
        <div className="text-small text-default-400">
          By continuing, you acknowledge that you have read and understood, and
          agree to Audyt’s{" "}
          <span className=" text-blue-500">Terms & Conditions</span> and{" "}
          <span className=" text-blue-500">Privacy Policy.</span>
        </div>
      </div>
    </div>
  );
};

export default VerifyLogin;
