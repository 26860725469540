import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { PublishData, UpdateOneData } from "../../../controllers/APIController";
import { DateInput, TextInput } from "../../FormBuilder/InputField/InputField";
import { OnboardingOrgDataSchema } from "../../../constant/OnboardingOrganization/OnboardingOrganization";

const intialState = {
  "Financial Name": "",
  "Start Date": "",
  "End Date": "",
};

const EditReportYearModal = ({
  onSubmitHandler,
  isOpen,
  onOpen,
  onOpenChange,
  setReRender,
  data,
}) => {
  const [DataList, setDataList] = useState(intialState);
  const [validationErrors, setValidationErrors] = useState({});

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  let schemaData = OnboardingOrgDataSchema;

  const ValidationHandler = () => {
    const err = {};
    let isValid = true;
    setValidationErrors({});

    if (DataList["Financial Name"]?.trim() === "") {
      err["Financial Name"] = "Name is required!";
      isValid = false;
    }
    if (DataList["Start Date"]?.trim() === "") {
      err["Start Date"] = "Start Date is required!";
      isValid = false;
    }
    if (DataList["End Date"]?.trim() === "") {
      err["End Date"] = "End Date is required!";
      isValid = false;
    }

    setValidationErrors(err);
    return isValid;
  };

  const SubmitHandler = async (onClose) => {
    if (!ValidationHandler()) {
      return;
    }
    const uploadData = {
      Name: DataList?.["Financial Name"],
      StartDate: DataList?.["Start Date"],
      EndDate: DataList?.["End Date"],
      Organization: {
        connect: [OrgInfo],
      },
    };

    UpdateOneData("financial-years", data?.id, uploadData);
    setReRender((pre) => !pre);
    onClose();
  };
  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      setValidationErrors("");
    }
    onOpenChange(isOpen); // Call the original onOpenChange if needed
    setReRender((pre) => !pre);
  };

  useEffect(() => {
    if (!data) return;
    setDataList({
      "Financial Name": data?.attributes?.Name,
      "Start Date": data?.attributes?.StartDate,
      "End Date": data?.attributes?.EndDate,
    });
  }, [data]);
  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={handleOpenChange} size="xl">
        <ModalContent className="p-2">
          {(onClose) => (
            <div tabIndex="0" className="outline-none">
              <ModalHeader className="flex flex-col gap-1">
                Create Report Year
              </ModalHeader>
              <ModalBody>
                <div className="my-4">
                  <TextInput
                    data={schemaData["Financial Name"]}
                    value={DataList["Financial Name"]}
                    validationErrors={validationErrors}
                    onChangeHandler={(title, value, type) => {
                      setDataList({ ...DataList, [title]: value });
                    }}
                    cssStyle="mb-8"
                  />
                  <div className="grid grid-cols-2">
                    <DateInput
                      data={schemaData["Start Date"]}
                      value={DataList["Start Date"]}
                      validationErrors={validationErrors}
                      onChangeHandler={(title, value, type) => {
                        setDataList({ ...DataList, [title]: value });
                      }}
                      cssStyle="mb-8"
                    />
                    <DateInput
                      data={schemaData["End Date"]}
                      value={DataList["End Date"]}
                      validationErrors={validationErrors}
                      onChangeHandler={(title, value, type) => {
                        setDataList({ ...DataList, [title]: value });
                      }}
                      cssStyle="mb-4"
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => SubmitHandler(onClose)}>
                  Update
                </Button>
              </ModalFooter>
            </div>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditReportYearModal;
