import { Input, Textarea } from "@nextui-org/react";
import React from "react";

const SettingReportTab = ({ reportDetails }) => {
  const name = reportDetails?.attributes?.name;
  const description = reportDetails?.attributes?.description;
  const framework = reportDetails?.attributes?.framework;

  return (
    <div className="flex flex-col gap-4 my-4">
      <Input
        label="Name"
        isReadOnly
        type="text"
        placeholder=" "
        labelPlacement="outside"
        variant="faded"
        value={name}
      />
      <Textarea
        label="Description"
        isReadOnly
        placeholder=" "
        labelPlacement="outside"
        variant="faded"
        value={description}
      />
      <Input
        label="Framework"
        isReadOnly
        type="text"
        placeholder=" "
        labelPlacement="outside"
        variant="faded"
        value={framework}
      />
    </div>
  );
};

export default SettingReportTab;
