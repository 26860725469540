import { Link, useNavigate, useParams } from "react-router-dom";
import {
  UpdateOneData,
  capitalize,
  getDataWithFilterUrl,
  sendSubmissionUpdationAlert,
  separateDasFromUrl,
  updateFormSchemaWithRealtion,
} from "../../controllers/APIController";

import { Avatar, Button, Divider } from "@nextui-org/react";
import { useEffect, useState } from "react";
import {
  formatDate,
  getFormulaData,
  getSchemaForOrg,
  updatedMetriclastUpdateResponse,
} from "../../utils/helper";
import { Colors, numColors } from "../../utils/constant";
import {
  BooleanInput,
  DateInput,
  DropDownInput,
  IntegerInput,
  PasswordInput,
  RelationDropDownInput,
  SliderInput,
  TextAreaInput,
  TextInput,
} from "../FormBuilder/InputField/InputField";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import { checkValidation } from "../../utils/checkValidation";
import FileEvidenceInput from "../FormBuilder/InputField/FileEvidenceInput";
import LogsInfo from "./LogsInfo";
import LoadingPage from "../../components/Loader/LoadingPage";

/* 
->I need to fetch the form Schema from Metric
->I need to fetch Form Data from Response
UpdateOneData(collectionName,id,data)
*/

const updateEvidenceRefrences = async (
  formdata,
  formschemaArray,
  referenceId,
  IntitaloformData
) => {
  const PromisesReponses = formschemaArray.map(async (row) => {
    if (row[1]?.type === "file") {
      let list1 = IntitaloformData[row[0]];
      let list2 = formdata[row[0]];

      list2 = list2?.filter((id) => !list1.includes(id));
      list1 = list1?.filter((id) => !list2.includes(id));

      const result1 = list2?.map(async (id) => {
        const uploadData = {
          ReferenceList: {
            connect: [+referenceId],
          },
        };

        return await UpdateOneData("evidences", +id, uploadData);
      });
      const result2 = list1?.map(async (id) => {
        const uploadData = {
          ReferenceList: {
            disconnect: [+referenceId],
          },
        };

        return await UpdateOneData("evidences", +id, uploadData);
      });
      return { result1, result2 };
    }
    return null;
  });

  await Promise.all(PromisesReponses);
};
const EditMetricForm = () => {
  const [loader, setLoader] = useState(true);
  const [IntitalformData, setInitialFormData] = useState(null);
  const [CollectionInfo, setCollectionInfo] = useState();
  const [responseInfo, setResponseInfo] = useState({});
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [description, setDescription] = useState("");
  const [links, setLinks] = useState([]);
  const [submittedUser, setSubmittedUser] = useState(null);
  const navigate = useNavigate();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const { _categoryId, _metricId, _formId, _orgId: currOrg } = useParams();

  // const isFormulaVisiable =
  //   CollectionInfo?.attributes?.JsonSchema?.formula?.length > 0 ? true : false;

  const ResponseId = +_formId;

  let HeadingTitle = capitalize(separateDasFromUrl(_metricId));

  const metricFilteredUrl =
    orgFilterUrl +
    "&filters[Category][$eqi]=" +
    _categoryId?.toLocaleLowerCase()?.replaceAll("-", " ") +
    "&filters[Name][$eqi]=" +
    _metricId?.replaceAll("-", " ");

  const responseMetricUrl = orgFilterUrl + "&filters[id][$eqi]=" + ResponseId;

  const changeHandler = async (title, value, type) => {
    if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };

  // const testCalculationHandler = async () => {
  //   const isValid = checkValidation(formSchema, formdata, setValidationErrors);
  //   if (!isValid) {
  //     return;
  //   }
  //   Object.keys(formdata).forEach((key) => {
  //     if (formdata[key] === "") {
  //       formdata[key] = "null";
  //     }
  //   });

  //   const formulaData = await getFormulaData(
  //     CollectionInfo?.attributes?.JsonSchema?.formula,
  //     formdata
  //   );
  // };

  const submitHandler = async () => {
    const isValid = checkValidation(formSchema, formdata, setValidationErrors);

    if (!isValid) {
      return;
    }

    const formulaData = await getFormulaData(
      CollectionInfo?.attributes?.JsonSchema?.formula,
      formdata
    );

    const initialLogsInfo = responseInfo?.attributes?.Logs
      ? responseInfo?.attributes?.Logs
      : [];
    const updatedLogsInfo = [
      {
        by: UserId,
        operation: "updated",
        timestamp: Date.now(),
      },
      ...initialLogsInfo,
    ];
    const publishData = {
      Metric_Id: _metricId.replaceAll("-", " "),
      JsonSchema: { ...formdata, ...formulaData },
      Logs: updatedLogsInfo,
    };

    let result = await UpdateOneData("responses", ResponseId, publishData);

    const updationalertData = {
      orgId: OrgInfo,
      UserId: UserLogged && UserLogged.user.email,
      operation: "updated",
      entryId: CollectionInfo?.attributes?.Name,
      linkurl: `${process.env.REACT_APP_IP_ADDRESS}/${currOrg}/metrics/${_categoryId}/${_metricId}/${result?.data?.id}`,
    };
    await sendSubmissionUpdationAlert(updationalertData);

    await updatedMetriclastUpdateResponse(
      CollectionInfo?.id,
      result?.data?.attributes?.updatedAt
    );

    await updateEvidenceRefrences(
      formdata,
      formSchema,
      result?.data?.id,
      IntitalformData
    );

    if (CollectionInfo?.attributes?.FrequencyValue === 0) {
      navigate(`/${currOrg}/metrics/${_categoryId}`);
    } else navigate(`/${currOrg}/metrics/${_categoryId}/${_metricId}`);
  };

  useEffect(() => {
    // Firsrt We fetch the Schema Data from Metric Collection and then We fetch the data from Response collection for Particular Id

    getDataWithFilterUrl("metrics", metricFilteredUrl)
      .then((data) => {
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;

        setCollectionInfo(data.data[0]);
        getSchemaForOrg(responseData, OrgInfo).then((data) => {
          setformSchema(data);
        });
        setDescription(data.data[0]?.attributes?.Description);
        setLinks(data?.data[0]?.attributes?.Links?.Links || []);
        getDataWithFilterUrl("responses", responseMetricUrl).then(
          (responseData) => {
            setResponseInfo(responseData?.data[0]);
            const userData =
              responseData?.data[0]?.attributes?.users_permissions_user?.data;
            setSubmittedUser(userData);
            const reponseFormData =
              responseData?.data[0]?.attributes?.JsonSchema;

            Object.keys(reponseFormData).forEach((key) => {
              if (reponseFormData[key] === "null") {
                reponseFormData[key] = "";
              }
            });

            setFormData(reponseFormData);
            setInitialFormData(reponseFormData);
            setLoader(false);
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [OrgInfo, metricFilteredUrl, responseMetricUrl]);

  const clickHandler = (metricName, relatedTo) => {
    let metric = metricName.replaceAll(" ", "-");
    let relatedToName = relatedTo.replaceAll(" ", "-");
    navigate(`/${currOrg}/${metric}/${relatedToName}/create`);
    window.location.reload();
  };

  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={loader}>
        <div className="relative w-full">
          <div className="metric-header">
            <p className="text-3xl font-medium">{HeadingTitle}</p>
            <p className="mt-8">{description}</p>
          </div>
          <div className="flex justify-between mt-8 relative">
            <div className={`w-[50%] ${links?.length === 0 && "hidden"}`}>
              <div className="text-lg font-semibold">
                Helpful Background Reading:
              </div>
              <div>
                <div className="link-list my-2 text-blue-600 font-semibold">
                  {links?.map((link, index) => (
                    <div className="links">
                      <Link
                        key={index}
                        href={link.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.name}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between my-4">
            <p className="flex items-center gap-2 mb-8">
              <Avatar
                showFallback
                className="h-8 w-8 cursor-pointer hover:scale-125 transition duration-700 ease-in-out"
                color={
                  Colors[
                    responseInfo?.attributes?.users_permissions_user?.data?.id %
                      numColors
                  ]
                }
                name={responseInfo?.attributes?.users_permissions_user?.data?.attributes?.email
                  .toUpperCase()
                  .slice(0, 1)}
                src="https://images.unsplash.com/broken"
              />
              <span>
                {submittedUser?.attributes?.FirstName ||
                  submittedUser?.attributes?.email}
                {" • "}
              </span>
              {"Updated at "}
              {formatDate(responseInfo?.attributes?.updatedAt)}
            </p>
            {/* <div className="fixed bottom-[2%] right-[5%] flex gap-2">
              <Button
                color="danger"
                className={`cursor-pointer ${
                  submittedUser?.id !== UserId ? "hidden" : ""
                }`}
                variant="ghost"
                onClick={submitHandler}
              >
                Update
              </Button>
            </div> */}
          </div>

          <div className="flex gap-[160px]">
            <div className="w-[50%]">
              {formSchema &&
                formSchema.map((row, index) => {
                  if (row[1].type === "text" || row[1].type === "string") {
                    return (
                      <TextInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "password") {
                    return (
                      <PasswordInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "integer") {
                    return (
                      <IntegerInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "boolean") {
                    return (
                      <BooleanInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "textarea") {
                    return (
                      <TextAreaInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "file") {
                    return (
                      <FileEvidenceInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "slider") {
                    return (
                      <SliderInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "dropdown") {
                    return (
                      <DropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "date") {
                    return (
                      <DateInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "relation") {
                    updateFormSchemaWithRealtion(
                      formSchema,
                      index,
                      setformSchema
                    );
                    return (
                      <RelationDropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                        onClickHandler={clickHandler}
                      />
                    );
                  }
                  return null;
                })}
            </div>
            <div className="w-[40%]">
              {/* {isFormulaVisiable && (
              <div className="bg-gray rounded-sm p-2">Test Calculation</div>
            )}
            <WorkFlowComment
              CollectionInfo={CollectionInfo}
              responseInfo={responseInfo}
            /> */}

              <div>
                <p className="text-lg font-medium my-4">Activity Logs</p>
                <Divider className="my-4" />
                <div>
                  <LogsInfo data={responseInfo?.attributes?.Logs || []} />
                </div>
              </div>
            </div>
          </div>

          <div className="fixed w-[calc(100vw-16rem)] bg-white z-[800] right-0 bottom-0 flex justify-end gap-4">
            <Button
              color="danger"
              variant="ghost"
              size="md"
              onClick={submitHandler}
              className={`mx-16 my-4 cursor-pointer ${
                submittedUser?.id !== UserId ? "hidden" : ""
              }`}
            >
              Update
            </Button>
          </div>
        </div>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default EditMetricForm;
