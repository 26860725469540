import React, { useEffect } from "react";
import {
  capitalizeString,
  getSQLQueryResult,
} from "../../controllers/basicControllers";
import ChartGenerator from "./ChartGenerator";
import {
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Spinner,
  useDisclosure,
} from "@nextui-org/react";
import {
  defaultHeatMapResult,
  defaultTimeSeriesResults,
  iconList,
} from "../../utils/charts.utils";
import SideDrawerChart from "./SideDrawerChart";
import TableChart from "./TableChart";
import { useState } from "react";
import { ChartErrorComp } from "./ChartError";

const defaultChartType = "area chart";

const defaultSql =
  "SELECT Machine.host AS Machine_host, COUNT(DISTINCT AssetData.dataid) AS AssetData_dataid_count_distinct_of_dataid FROM AssetData INNER JOIN Machine ON AssetData.machineid = Machine.machineid GROUP BY Machine.host, Machine.cust LIMIT 100";

const heatMapDefaultSql =
  "SELECT t1_d185_aimx.state AS t1_d185_aimx_state, t1_d185_aimx.country AS t1_d185_aimx_country, t1_d185_aimx.groups AS t1_d185_aimx_groups, t1_d185_aimx.machine AS t1_d185_aimx_machine, t1_d185_aimx.metric AS t1_d185_aimx_metric FROM t1_d185_aimx LIMIT 100";

const defaultChartInfo = {
  name: "Trending up by 5.2% this month",
  description: "January - June 2024",
  xAxis: "t1_d185_aimx_metric",
  yAxis: "t1_d185_aimx_metric_average_of_metric",
  minThreshold: 23,
  maxThreshold: 30,
};
const defaultHeatMapResults = defaultHeatMapResult;
const defaultTimeSeriesResult = defaultTimeSeriesResults;

const defaultQueryResult = {
  query_result: [
    {
      customer: "MBAG_EMEA_Prod",
      total_users: 19,
    },
    {
      customer: "DART237_Testing",
      total_users: 17,
    },
    {
      customer: "Aug16_AndroidTesting",
      total_users: 15,
    },
    {
      customer: "MM_TestingDontChangeConfig",
      total_users: 8,
    },
    {
      customer: "TestQA_Testing",
      total_users: 4,
    },
  ],
};
const defaultId = 2;

const defaultDbConfig = {
  host: "pinot-use.nanoheal.app",
  port: "80",
  dbType: "pinot",
  protocol: "http",
};

const modifyType = (chartType) => {
  let type;
  if (chartType.includes("area chart")) {
    type = "area chart";
  } else if (chartType.includes("bar chart")) {
    type = "bar chart";
  } else if (chartType.includes("pie chart")) {
    type = "pie chart";
  } else if (chartType.includes("scatter chart")) {
    type = "scatter chart";
  } else if (chartType.includes("line chart")) {
    type = "line chart";
  } else if (chartType.includes("radar chart")) {
    type = "radar chart";
  } else if (chartType.includes("table chart")) {
    type = "table chart";
  } else if (chartType.includes("time series chart")) {
    type = "time series chart";
  } else if (chartType.includes("heat map")) {
    type = "heat map";
  } else {
    type = "";
  }
  return type;
};

const ViewChart = ({
  sql = defaultSql,
  chartType = defaultChartType,
  chartInfo = defaultChartInfo,
  data = defaultQueryResult,
  id = defaultId,
  dbConfig = defaultDbConfig,
  showCard = true,
  showAdditionalDetails = true,
  cardClasses = "w-[500px] h-[500px] border border-divider",
  chartHeight = "240px",
  dataProductId,
}) => {
  const [intialSql, setIntialSql] = useState(sql);
  const [currenSql, setCurrentSql] = useState(sql);
  const [queryResult, setQueryResult] = useState(data?.query_result || []);

  const [isQueryRunnig, setIsQueryRunning] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [filterList, setFilterList] = useState([]);

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onOpenChange: onDrawerOpenChange,
  } = useDisclosure();

  const dynamicCardClasses = showCard
    ? cardClasses
    : `${cardClasses} border-none`;
  const chartIcon = iconList?.[chartType];
  const type = modifyType(chartType);

  const addNewFilter = (data) => {
    setFilterList((pre) => {
      return [...pre, data];
    });
  };

  const removeFilter = (id) => {
    setFilterList((pre) => {
      return pre.filter((item) => item.id !== id);
    });
  };

  const runQueryHandler = async (query, dbConfig) => {
    const queryData = {
      sql: query,
      dbType: dbConfig?.dbType,
      dbConfig: { ...dbConfig, user: dbConfig?.username },
    };

    const result = await getSQLQueryResult(queryData);

    return result;
  };

  useEffect(() => {
    let whereCaluseList = "";

    filterList?.forEach((data, index) => {
      if (index === 0) {
        whereCaluseList += `WHERE ${data.query} `;
      } else {
        whereCaluseList += `AND ${data.query} `;
      }
    });

    const limitIndex = intialSql.indexOf("LIMIT");
    let newSql = "";

    if (limitIndex !== -1) {
      newSql =
        intialSql.substring(0, limitIndex) +
        whereCaluseList +
        intialSql.substring(limitIndex);
    }

    setCurrentSql(newSql);
  }, [filterList, intialSql]);

  // useEffect(() => {
  //   if (!(type === "heat map" || type === "time series chart")) {
  //     return;
  //   }

  //   setIsQueryRunning(true);

  //   runQueryHandler(currenSql, dbConfig)
  //     .then((data) => {
  //       setQueryResult(data);
  //       setIsQueryRunning(false);
  //     })
  //     .catch((error) => {
  //       setErrorMessage(error?.message);
  //       setIsQueryRunning(false);
  //     });
  // }, [currenSql]);


  return (
    <>
      {type === "table chart" ? (
        <div className="w-full">
          <div className="flex items-center justify-end w-full">
            <Button
              className="border border-divider"
              size="sm"
              variant="light"
              onClick={onDrawerOpen}
            >
              View SQL
            </Button>
          </div>
          <TableChart TableData={queryResult} />
        </div>
      ) : (
        <Card
          className={`w-full h-full overflow-auto flex items-center justify-center ${dynamicCardClasses}`}
          shadow="none"
        >
          {showAdditionalDetails && (
            <CardHeader className="flex gap-3">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-2">
                  {chartIcon}
                  <span className="text-sm">Chart</span>
                </div>
                <div className="flex items-center gap-4">
                  <Button
                    className="border border-divider"
                    size="sm"
                    variant="light"
                    onClick={onDrawerOpen}
                  >
                    View More
                  </Button>
                </div>
              </div>
            </CardHeader>
          )}
          {showAdditionalDetails && <Divider />}
          <div className="w-full h-full py-4 px-4">
            <div className="flex flex-col gap-8 w-full h-full">
              <div className="flex flex-col gap-1">
                <p className="font-semibold">{capitalizeString(chartType)}</p>
                <div className="flex items-center flex-wrap gap-1">
                  {filterList?.map((data) => {
                    return (
                      <Chip
                        color="danger"
                        variant="bordered"
                        size="sm"
                        key={data?.id}
                        onClose={() => {
                          removeFilter(data?.id);
                        }}
                      >
                        {data?.query}
                      </Chip>
                    );
                  })}
                </div>
              </div>
              <div className={`w-full flex h-[${chartHeight}] justify-center`}>
                {isQueryRunnig ? (
                  <Spinner color="secondary" labelColor="secondary" size="lg" />
                ) : errorMessage ? (
                  <ChartErrorComp errorMessage={errorMessage} />
                ) : queryResult.length === 0 ? (
                  <ChartErrorComp errorMessage={"No Data found"} />
                ) : (
                  <ChartGenerator
                    id={id}
                    chartType={type}
                    data={queryResult}
                    chartInfo={chartInfo}
                    onAddCustomFilter={addNewFilter}
                    dbConfig={dbConfig}
                    sql={sql}
                  />
                )}
              </div>
            </div>
          </div>

          {showAdditionalDetails && (
            <div className="pb-4 px-4">
              <p className="font-bold">{chartInfo?.name.replace(/"/g, "")}</p>
              <p className="text-sm text-gray">
                {chartInfo?.description.slice(0, 100).replace(/"/g, "")}
                <span>{chartInfo?.description.length > 100 ? "..." : ""}</span>
              </p>
            </div>
          )}
        </Card>
      )}
      <SideDrawerChart
        id={id}
        isOpen={isDrawerOpen}
        onOpenChange={onDrawerOpenChange}
        chartType={type}
        queryResult={queryResult}
        sql={sql}
        chartInfo={chartInfo}
        onAddCustomFilter={addNewFilter}
        filterList={filterList}
        removeFilter={removeFilter}
        dataProductId={dataProductId}
      />
    </>
  );
};

export default ViewChart;
