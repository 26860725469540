import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteOnedata,
  StringLimit,
  capitalize,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import { DeleteTableItem, SearchInput } from "../../utils/helper";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import NullData from "../../components/ErrorPage/NullData";
import TableSkeleton from "../../components/Skeleten/TableSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
const pulizirePolicyName = {
  domain: "domains",
  policy: "policies",
  procedure: "procedures",
};
const PoliciesBuilder = () => {
  const userRole = "Admin";
  const [isLoading, setisLoading] = useState(true);
  const [reRender, setReRender] = useState(false);
  const [searchPolicyText, setSearchPolicyText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [allDomainPolicyProcedure, setAllDomainPolicyProcedure] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const navigate = useNavigate();

  const { _orgId: currOrg } = useParams();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  // Handle filter selection changes
  const handleFilterSelection = (key) => {
    if (Array.from(key)?.[0] === "") {
      setSelectedFilter([]);
    } else setSelectedFilter(Array.from(key));
  };

  const handleNavigateToMetric = (item) => {
    navigate(`/${currOrg}/policies-builder/${item?.id}?type=${item?.type}`);
  };

  const deleteFormHandler = async (id, type) => {
    try {
      const collectionName = pulizirePolicyName?.[type];

      await DeleteOnedata(collectionName, id);
      setReRender((pre) => !pre);
    } catch (error) {
      console.log(error);
    }
  };

  const headers = ["Name", "Description", "Type", "Action"];

  const filteredPolicyList = allDomainPolicyProcedure.filter((item) => {
    const name = item?.name || "";
    const type = item?.type || "";
    const matchesSearchTerm =
      name.toLowerCase().includes(searchPolicyText.toLowerCase()) &&
      (selectedFilter?.length === 0 || type?.includes(selectedFilter[0]));

    return matchesSearchTerm;
  });

  useEffect(() => {
    const li = [];
    let randomId = 0;
    getDataWithFilterUrl("domains", orgFilterUrl)
      .then((domainData) => {
        domainData?.data?.forEach((element) => {
          li.push({
            randomId: randomId++,
            id: element.id,
            name: element?.attributes?.Name,
            description: element?.attributes?.Description || "--",
            type: "domain",
            dependencyCount: element?.attributes?.policies?.data?.length || 0,
          });
        });
        getDataWithFilterUrl("policies", orgFilterUrl).then((policyData) => {
          policyData?.data?.forEach((element) => {
            li.push({
              randomId: randomId++,
              id: element.id,
              name: element?.attributes?.Name,
              description: element?.attributes?.Description || "--",
              type: "policy",
              dependencyCount:
                element?.attributes?.procedures?.data?.length || 0,
            });
          });
          getDataWithFilterUrl("procedures", orgFilterUrl).then(
            (procedureData) => {
              procedureData?.data?.forEach((element) => {
                li.push({
                  randomId: randomId++,
                  id: element.id,
                  name: element?.attributes?.Name,
                  description: element?.attributes?.Description || "--",
                  type: "procedure",
                  dependencyCount: 0,
                });
              });

              setAllDomainPolicyProcedure(li);
              setisLoading(false);
            }
          );
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reRender, orgFilterUrl]);

  const ItemPerPage = 10;
  const totalPage = Math.ceil(filteredPolicyList.length / ItemPerPage);

  return (
    <BreadCrumbsPage>
      <div>
        <p className="text-3xl font-medium">Policies Builder</p>
        <div className="my-8">
          Create or edit ESG Policies that would support your ESG Reporting.
        </div>
        <div className={`flex justify-end cursor-pointer`}>
          {/* <CreatePolicyButtton /> */}
          <Button size="sm" color="danger" onClick={() => navigate("create")}>
            Create new Policy
          </Button>
        </div>
        <div className="mt-4 flex justify-end">
          <Dropdown>
            <DropdownTrigger>
              <Button
                size="sm"
                endContent={<FontAwesomeIcon icon={faFilter} />}
                variant="bordered"
              >
                {selectedFilter?.length > 0
                  ? capitalize(selectedFilter[0])
                  : "Filter"}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Single selection example"
              selectionMode="single"
              onSelectionChange={handleFilterSelection}
              selectedKeys={new Set(selectedFilter)}
            >
              <DropdownItem key="">Clear Filters</DropdownItem>
              <DropdownItem key="domain" color="primary" variant="flat">
                Domain
              </DropdownItem>
              <DropdownItem key="policy" color="success" variant="flat">
                Policy
              </DropdownItem>
              <DropdownItem key="procedure" variant="flat" color="warning">
                Procedure
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        {isLoading ? (
          <TableSkeleton />
        ) : (
          <>
            <div className="my-4">
              <SearchInput
                searchText={searchPolicyText}
                setSearchText={setSearchPolicyText}
                placeholderText={"Search for an any ESG Policies"}
              />
            </div>

            {filteredPolicyList.length === 0 ? (
              <NullData />
            ) : (
              <div className="">
                <div className="mt-4">
                  {filteredPolicyList.length > 0 && (
                    <div className="">
                      <Table
                        removeWrapper
                        selectionMode="single"
                        aria-label="all-policies-table"
                      >
                        <TableHeader>
                          {headers.map((header, headerIndex) => (
                            <TableColumn key={headerIndex}>
                              {header}
                            </TableColumn>
                          ))}
                        </TableHeader>
                        <TableBody>
                          {filteredPolicyList.map((item, index) => {
                            const isDeletableItem = !(
                              item?.dependencyCount > 0
                            );

                            const DropDownText = isDeletableItem
                              ? `Delete ${item?.name} ?`
                              : "Can't be deleted due to dependency";
                            const lowerPageLimit =
                              (currentPage - 1) * ItemPerPage;
                            const upperPageLimit = currentPage * ItemPerPage;
                            if (
                              index < lowerPageLimit ||
                              index >= upperPageLimit
                            )
                              return null;

                            return (
                              <TableRow
                                key={item.randomId}
                                onClick={() => handleNavigateToMetric(item)}
                                className="cursor-pointer"
                              >
                                <TableCell>{item?.name}</TableCell>

                                <TableCell>
                                  {StringLimit(item?.description, 100) || "--"}
                                </TableCell>
                                <TableCell>{capitalize(item?.type)}</TableCell>
                                <TableCell>
                                  <DeleteTableItem
                                    deleteHandler={deleteFormHandler}
                                    item={item}
                                    userRole={userRole}
                                    isDisabled={isDeletableItem}
                                    DropDownText={DropDownText}
                                    toolTiptext={"Delete Policy"}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </div>
                {filteredPolicyList.length > 0 && (
                  <div className="w-full mt-8 flex items-center justify-center">
                    <Pagination
                      showControls
                      total={totalPage}
                      color="secondary"
                      page={currentPage}
                      onChange={setCurrentPage}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </BreadCrumbsPage>
  );
};

export default PoliciesBuilder;
