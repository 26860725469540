import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  useDisclosure,
} from "@nextui-org/react";
import React from "react";
import Continue from "../../Images/Continue";
import { Colors } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import NavbarFooterModal from "./NavbarFooterModal";

const NavbarFooter = ({ UserDetails, _orgId, isCollapsed }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();
  const truncatedEmail =
    UserDetails?.email && UserDetails?.email.length > 20
      ? `${UserDetails?.email.slice(0, 20)}...`
      : UserDetails?.email;
  const numColors = Colors.length;
  const colorIndex = UserDetails && UserDetails.id % numColors;
  return (
    <>
      <NavbarFooterModal isOpen={isOpen} onOpenChange={onOpenChange} />
      <>
        <Dropdown
          // showArrow
          radius="sm"
          size="lg"
          classNames={{
            base: "before:bg-default-200", // change arrow background
            content: "p-0 border-small border-divider bg-background",
          }}
        >
          <DropdownTrigger>
            <div
              className={`flex items-center justify-between py-4 cursor-pointer ${
                isCollapsed ? "justify-center" : "justify-between px-2"
              }`}
            >
              <div className="flex items-center gap-2">
                <Avatar
                  className="h-6 w-6"
                  color={Colors[colorIndex]}
                  name={
                    UserDetails &&
                    UserDetails.email &&
                    UserDetails.email.toUpperCase().slice(0, 1)
                  }
                  src={
                    UserDetails &&
                    UserDetails.ProfilePictureUrl !== null &&
                    UserDetails.ProfilePictureUrl
                  }
                />
                {!isCollapsed && (
                  <span className="text-xs font-bold">
                    {UserDetails?.FirstName?.trim()
                      ? `${UserDetails.FirstName} ${UserDetails.LastName}`
                      : UserDetails && truncatedEmail}
                  </span>
                )}
              </div>
              {!isCollapsed && <Continue />}
            </div>
          </DropdownTrigger>
          {/* <NextThemesProvider> */}
          <DropdownMenu
            aria-label="Custom item styles"
            className="p-2 -mb-1"
            itemClasses={{
              base: [
                "rounded-md",
                "text-default-500",
                "transition-opacity",
                "data-[hover=true]:text-foreground",
                "data-[hover=true]:bg-default-100",
                "dark:data-[hover=true]:bg-default-50",
                "data-[selectable=true]:focus:bg-default-50",
                "data-[pressed=true]:opacity-70",
                "data-[focus-visible=true]:ring-default-500",
              ],
            }}
          >
            <DropdownSection aria-label="Profile & Actions">
              <DropdownItem
                id="Onboarding"
                onPress={onOpen}
                startContent={
                  <lord-icon
                    src="https://cdn.lordicon.com/zrtfxghu.json"
                    trigger="hover"
                    target="#Onboarding"
                    colors={`primary: "#000000"`}
                    style={{ width: "20px", height: "20px" }}
                  ></lord-icon>
                }
              >
                <span className="font-bold"> Feedback </span>
              </DropdownItem>

              <DropdownItem
                id="Profile"
                onClick={() => navigate(`/${_orgId}/profile`)}
                key="settings"
                startContent={
                  <lord-icon
                    src="https://cdn.lordicon.com/kthelypq.json"
                    trigger="hover"
                    target="#Profile"
                    colors={`primary: "#000000"`}
                    style={{ width: "20px", height: "20px" }}
                  ></lord-icon>
                }
              >
                <span className="font-bold">Profile</span>
              </DropdownItem>
              <DropdownItem
                id="Logout"
                onClick={() => {
                  sessionStorage.removeItem("userData");
                  navigate("/sign-in");
                }}
                startContent={
                  <lord-icon
                    src="https://cdn.lordicon.com/vduvxizq.json"
                    trigger="hover"
                    target="#Logout"
                    colors={`primary: "#000000"`}
                    style={{ width: "20px", height: "20px" }}
                  ></lord-icon>
                }
              >
                <span className="font-bold">Logout</span>
              </DropdownItem>
            </DropdownSection>
          </DropdownMenu>
          {/* </NextThemesProvider> */}
        </Dropdown>
      </>
    </>
  );
};

export default NavbarFooter;
