import React, { createContext, useEffect, useMemo, useState } from "react";
import { Button, Kbd, Tooltip } from "@nextui-org/react";
import InitialSearch from "./InitialSearch";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  createThread,
  deleteData,
  getMultipleFilters,
} from "../../controllers/vectordbController";
import "./styles.css";
import { getDateLabel, prompt } from "../../utils/helper.js";
import ThreadList from "./ThreadList";
import SearchModal from "./SearchModal";
import useGetOrgDetail from "../../hooks/useGetOrgDetail.js";
import useGetUserDetail from "../../hooks/useGetUserInfo.js";
import LoadingPage from "../../components/Loader/LoadingPage.jsx";

export const DataContext = createContext();

const Haya = () => {
  const [threads, setThreads] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [disableNavigation, setDisableNavigation] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [initialLoader, setInitialLoader] = useState(true);
  const { OrgDetail: spaceInfo } = useGetOrgDetail();
  const { userDetails } = useGetUserDetail();
  const { _orgId, _threadId } = useParams();
  const navigate = useNavigate();
  function createThreadUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  const handleDelete = async (id, chatId) => {
    try {
      await deleteData(id, spaceInfo[0].attributes.Name);
      setThreads((prev) => prev.filter((it) => it.payload.id !== id));
      if (_threadId === chatId) {
        navigate(`/${_orgId}/audyt-ai`);
      }
    } catch (e) {}
  };
  // "ENTER" KEY TO SEARCH
  const handleKeyPress = async (event, data) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      setLoading(true);
      const val = inputValue.trim();
      const app_id ="audyt";
      const chatId = createThreadUUID();
      const shareId = createThreadUUID();
      const timeStamp = Date.now();
      const formData = prompt(val);
      try {
        const resp = await fetch(
          `${process.env.REACT_APP_HAYA_IP_ADDRESS}/prompt/execute`,
          {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { response: title } = await resp.json();
        try {
          const formData = {
            title,
            description: "This is a thread",
            thread: [{ query: val, timeStamp }],
            chatId,
            shareId,
            createdAt: timeStamp,
            updatedAt: timeStamp,
            userId: String(userDetails.id),
            pinned: false,
          };
          await createThread(timeStamp, formData, app_id);
          setThreads((prev) => [
            ...prev,
            { payload: { ...formData, id: timeStamp, app_id: app_id } },
          ]);
          setInputValue("");
          setLoading(false);
          navigate(`/${_orgId}/audyt-ai/${chatId}`);
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      return;
    }
  };
  const fetchThreads = async () => {
    try {
      const resp = await getMultipleFilters(
        [{ key: "userId", value: String(userDetails.id) }],
        spaceInfo[0]?.attributes?.Name
      );
      setThreads(resp);
      setInitialLoader(false);
    } catch (e) {
      console.log(e);
    }
  };

  const groupedThreads = useMemo(() => {
    return threads
      .sort((a, b) => b.payload.updatedAt - a.payload.updatedAt)
      .reduce((acc, item) => {
        // Check if the item is pinned
        if (item.payload.pinned) {
          if (!acc["Pinned"]) {
            acc["Pinned"] = [];
          }
          acc["Pinned"].push(item);
        } else {
          // Group by date label if not pinned
          const dateLabel = getDateLabel(item.payload.updatedAt);
          if (!acc[dateLabel]) {
            acc[dateLabel] = [];
          }
          acc[dateLabel].push(item);
        }
        return acc;
      }, {});
  }, [threads]);

  useEffect(() => {
    if (spaceInfo[0]?.attributes?.Name && userDetails?.id) {
      fetchThreads();
    }
  }, [spaceInfo, userDetails]);
  useEffect(() => {

    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        event.preventDefault();
        setIsSearchOpen((prev) => !prev);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSearchOpen]);

  // RETURN UI
  if (initialLoader) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <LoadingPage />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full overflow-y-auto overflow-x-hidden">
      <SearchModal
        threads={threads}
        isOpen={isSearchOpen}
        setIsOpen={setIsSearchOpen}
      />
      <div className="flex flex-col relative flex-1 justify-between overflow-y-auto scroll-smooth">
        {_threadId ? (
          <DataContext.Provider value={{ setDisableNavigation }}>
            <Outlet />
          </DataContext.Provider>
        ) : (
          <InitialSearch
            loading={loading}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleKeyPress={handleKeyPress}
          />
        )}

        {!toggle && (
          <Button
            onClick={() => setToggle(true)}
            className=" absolute top-4 right-8"
            isIconOnly
            variant="light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
              />
            </svg>
          </Button>
        )}
      </div>

      <div
        className={` drawer ${
          toggle ? "open w-80 p-4 border-l" : "w-0 transition-opacity opacity-0"
        } transition-width   flex flex-col gap-8 border-[#eeeeee] h-full overflow-y-auto`}
      >
        <div className="flex items-center justify-between">
          <Button onClick={() => setToggle(false)} isIconOnly variant="light">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
              />
            </svg>
          </Button>
          <div className="flex items-center gap-2">
            <Tooltip
              content={
                <div className="flex flex-col items-center justify-center">
                  <div>Search Chat</div>
                  <div>
                    {window.navigator.userAgent.toLowerCase().indexOf("mac") !==
                    -1 ? (
                      <Kbd keys={["command"]} size="sm">
                        K
                      </Kbd>
                    ) : (
                      <div className="text-xs text-gray-400">Ctrl + K</div>
                    )}
                  </div>
                </div>
              }
            >
              <Button
                disabled={disableNavigation}
                onClick={() => setIsSearchOpen(true)}
                isIconOnly
                variant="light"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/kkvxgpti.json"
                  colors={`primary:"#000000"`}
                  style={{ height: "24px", width: "24px" }}
                ></lord-icon>
              </Button>
            </Tooltip>
            <Tooltip content="Start a new thread">
              <Button
                disabled={disableNavigation}
                onClick={() => {
                  setInputValue("");
                  navigate(`/${_orgId}/audyt-ai`);
                }}
                isIconOnly
                variant="light"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </Button>
            </Tooltip>
          </div>
        </div>

        {threads.length === 0 ? (
          <div>No threads present</div>
        ) : (
          <div className=" flex-1 flex flex-col overflow-x-auto">
            <div className="flex flex-col gap-4">
              {Object.entries(groupedThreads)
                // Sort the groups to ensure "Pinned" is first, then sort by date
                .sort(([a], [b]) => {
                  if (a === "Pinned") return -1; // "Pinned" goes first
                  if (b === "Pinned") return 1;
                  // Sort remaining groups in descending date order
                  return new Date(b) - new Date(a);
                })
                .map(([dateLabel, items]) => (
                  <div className="flex flex-col gap-2" key={dateLabel}>
                    {/* Date Label */}
                    <div className="flex items-center gap-2 ">
                      {dateLabel === "Pinned" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide lucide-pin text-black"
                        >
                          <path d="M12 17v5" />
                          <path d="M9 10.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24V16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-.76a2 2 0 0 0-1.11-1.79l-1.78-.9A2 2 0 0 1 15 10.76V7a1 1 0 0 1 1-1 2 2 0 0 0 0-4H8a2 2 0 0 0 0 4 1 1 0 0 1 1 1z" />
                        </svg>
                      )}
                      <p className="text-gray-500 font-semibold">{dateLabel}</p>
                    </div>
                    {/* List of Threads under the Date Label */}
                    {items
                      .sort((a, b) => b.payload.updatedAt - a.payload.updatedAt)
                      .map((item) => (
                        <ThreadList
                          disableNavigation={disableNavigation}
                          key={item.payload.chatId} // Add a unique key for each item
                          handleDelete={handleDelete}
                          item={item}
                          setInputValue={setInputValue}
                          setThreads={setThreads}
                        />
                      ))}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Haya;
