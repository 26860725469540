import "./App.css";
import { NextUIProvider } from "@nextui-org/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RoutesList from "./routes.json";
import { useEffect, useState } from "react";
import getComponent from "./getComponent.js";
import ErrorBoundary from "./components/ErrorPage/ErrorBoundary.jsx";
import ServerIssue from "./components/ErrorPage/ServerIssue.jsx";
import LoadingPage from "./components/Loader/LoadingPage.jsx";

const renderRoutes = (routesList) => {
  return routesList.map((route, index) => {
    const { path, element, props, nestedRoutes } = route;
    const Component = getComponent(element);
    return (
      <Route key={index} path={path} element={<Component {...props} />}>
        {nestedRoutes && renderRoutes(nestedRoutes)}
      </Route>
    );
  });
};

const App = () => {
  if (process.env.NODE_ENV === "production") {
    console.log = console.warn = () => {};
  }

  // console.log =  console.warn = console.error = () => {};

  const [serverIssue, setServerIssue] = useState(false);
  const [loader, setLoader] = useState(true);

  const fetchWithTimeout = (url, options, timeout = 5000) => {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Timeout")), timeout)
      ),
    ]);
  };

  useEffect(() => {
    document.title = "Audyt";

    const checkBackendConnection = async () => {
      try {
        const response = await fetchWithTimeout(
          `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        setServerIssue(true);
      } finally {
        setLoader(false);
      }
    };

    checkBackendConnection();
  }, []);

  useEffect(() => {
    document.title = "Audyt ESG | Sustainability Reporting"; // replace it with the name of the application for the Tab Name. To change the favicon cahnge inside public
  }, []);

  if (serverIssue) {
    return <ServerIssue />;
  }

  if (loader) {
    return (
      <div className="flex h-[100vh] items-center justify-center">
        <LoadingPage isLoading={true} />
      </div>
    );
  }
  return (
    <ErrorBoundary>
      <NextUIProvider>
        {/* <NextThemesProvider> */}
        {/* <ChatWidget/> */}
        <div>
          <BrowserRouter>
            <Routes>{renderRoutes(RoutesList)}</Routes>
          </BrowserRouter>
        </div>
        {/* </NextThemesProvider> */}
      </NextUIProvider>
    </ErrorBoundary>
  );
};

export default App;
