import { Card, CardBody, Image } from "@nextui-org/react";
import React from "react";
import Completed from "../../Images/done.svg";
import incompleteIcon from "../../Images/incompleteIcon.svg";
import lastUpdate from "../../Images/lastUpdate.svg";
import { DateFormatorDD } from "../../utils/helper";

const CardItem = ({ icon, title, value }) => {
  return (
    <Card
      className="bg-[#F8F8F8] h-[75px] w-[250px] cursor-pointer"
      shadow="none"
    >
      <CardBody>
        <div className="flex gap-8">
          <div className="flex items-center gap-4">
            <Image width={50} alt="NextUI hero Image" src={icon} />
            <div>
              <div className="text-sm">{title}</div>
              <div className="font-medium">{value}</div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
const MetricInfo = ({ data }) => {
  const answeredCount = data.CurrentCount || 0;
  const requiredCount = data?.RequiredCount;
  const lastUpdatedAt = data?.lastUpdatedAt
    ? DateFormatorDD(data.lastUpdatedAt)
    : "--";
  return (
   
      <div className="flex items-center gap-4 my-4">
        <CardItem icon={Completed} title={"Status"} value={data?.Status} aria-label="status-card"/>
        <CardItem icon={Completed} title={"Total questions answered"} value={answeredCount} aria-label="total-question-card"/>
        <CardItem icon={incompleteIcon} title={"Total question Required"} value={requiredCount} aria-label="total-required-card"/>
        <CardItem icon={lastUpdate} title={"Last Updated at"} value={lastUpdatedAt} aria-label="last=updated-card"/>
      </div>
 
  );
};

export default MetricInfo;
