import {
  Button,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from "@nextui-org/react";
import React, { useState } from "react";
import { exportDownloadHandler } from "../../utils/helper";

const ExportModal = ({
  isOpen,
  onOpen,
  onOpenChange,
  data,
  metricName,
  formFieldsList,
}) => {
  const [selectedFields, setSelectedField] = useState([]);
  const [DownloadAs, setDownloadAs] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const submitHandler = (onClose) => {
    if (DownloadAs.length === 0) {
      setErrorMessage("This field is required.");
      return;
    }
    exportDownloadHandler(metricName, DownloadAs[0], data, selectedFields);
    // onClose();
  };


  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      setSelectedField([]);
      setDownloadAs([]);
      setErrorMessage("");
    }
    onOpenChange(isOpen); // Call the original onOpenChange if needed
  };

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={handleOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Export the Responses
              </ModalHeader>
              <ModalBody>
                <div className="max-h-[400px] hide-scollbar overflow-auto">
                  <Select
                    label={"Downloaded As"}
                    placeholder={" "}
                    variant="faded"
                    selectionMode="single"
                    labelPlacement="outside"
                    className="my-8"
                    isInvalid={errorMessage !== ""}
                    errorMessage={errorMessage}
                    selectedKeys={new Set(DownloadAs)}
                    onSelectionChange={(event) => {
                      setDownloadAs(Array.from(event));
                    }}
                  >
                    <SelectItem key="Excel" value="Excel" className="py-2">
                      Excel
                    </SelectItem>
                    <SelectItem key="CSV" value="CSV" className="py-2">
                      CSV
                    </SelectItem>
                    <SelectItem key="JSON" value="JSON" className="py-2">
                      JSON
                    </SelectItem>
                  </Select>

                  <div className="flex flex-col gap-4">
                    <Checkbox
                      color="secondary"
                      isIndeterminate={selectedFields.length > 0}
                      onValueChange={(value) => {
                        if (value) {
                          setSelectedField([...formFieldsList]);
                        } else setSelectedField([]);
                      }}
                    >
                      Select All the fields
                    </Checkbox>
                    <CheckboxGroup
                      value={selectedFields}
                      onValueChange={(value) => {
                        setSelectedField(value);
                      }}
                      className="pl-6"
                    >
                      {formFieldsList?.map((item) => (
                        <Checkbox color="secondary" value={item}>
                          {item}
                        </Checkbox>
                      ))}
                    </CheckboxGroup>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitHandler(onClose)}>
                  Download
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExportModal;
