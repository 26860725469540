import {
  Avatar,
  Button,
  Card,
  CardBody,
  Input,
  ModalBody,
  ModalHeader,
  useDisclosure,
  NextUIProvider,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import {
  DeleteOnedata,
  UpdateOneData,
  getOnedata,
  uploadFile,
} from "../../controllers/APIController";
import ModalPopup from "../../components/ModalPopup";
import DeleteModal from "../../components/DeleteModal";
import { useNavigate } from "react-router-dom";
import { Switch } from "@nextui-org/react";
import LoadingPage from "../../components/Loader/LoadingPage";
const Profile = () => {
  const [UserDetails, setUserDetails] = useState(null);
  const [Loader, setLoader] = useState(true);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const fileInputRef = useRef();
  const [showInput, setShowInput] = useState(false);
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onOpenChange: onModalOpenChange,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
  } = useDisclosure();
  const [FileUpload, setFileUpload] = useState(null);
  const [fileUploaded, setFileUploaded] = useState();
  const [Response, setResponse] = useState("");
  const Navigate = useNavigate();
  // const [Theme, setTheme] = useState(null);

  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    ProfilePicture: "",
    // Theme: '',
  });

  useEffect(() => {
    setLoader(true);
    getOnedata("users", UserId).then((data) => {
      setLoader(false);

      setFormData({
        FirstName: data?.FirstName,
        LastName: data?.LastName,
        ProfilePicture:
          data && data?.ProfilePictureUrl !== null && data?.ProfilePictureUrl,
        // Theme: data && data.Theme
      });
      setUserDetails(data);
    });
  }, [Response, UserId]);

  const UploadFileHandler = async () => {
    if (!FileUpload) return;
    setLoader(true);

    let formData = new FormData();
    formData.append("uploaded_file", FileUpload);
    const response = await uploadFile(formData);
    if (response) {
      setLoader(false);
      const URLresponse = response && response[0];
      URLresponse && setFileUploaded(URLresponse);
    }
  };

  useEffect(() => {
    UploadFileHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FileUpload]);

  const UserProfile =
    UserDetails && UserDetails.ProfilePictureUrl !== null
      ? UserDetails.ProfilePictureUrl
      : "";
  const UploadedUser =
    fileUploaded && fileUploaded !== null && `${fileUploaded.url}`;

  const UpdateProfile = async (onClose) => {
    setLoader(true);
    const payload = {
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      ProfilePictureUrl: fileUploaded?.url,
      // Theme: formData.Theme
    };

    const response = await UpdateOneData("users", UserId, payload);
    if (response) {
      onClose();
      setLoader(false);
      setResponse(response);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];
      setFileUpload(file);
    } else {
      setFileUpload(null);
    }
  };

  const Handleremove = async () => {
    setFileUploaded(null);
    setFormData({ ...formData, ProfilePicture: "" });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const DeleteHandler = async () => {
    setLoader(true);
    const response = await DeleteOnedata("users", UserId);
    if (response) {
      setLoader(false);
      Navigate("/sign-in");
      sessionStorage.clear();
    }
  };

  // const HandleThemeChange = async (e) => {

  //   console.log("e.anchorKey", e.anchorKey)
  //   console.log("UserDetails.Theme", UserDetails.Theme)
  //   setFormData({ ...formData, Theme: e.anchorKey === "undefined" ? UserDetails.Theme : e.anchorKey });

  //   let payload = {
  //     Theme: e.anchorKey
  //   }
  //   const response = await UpdateOneUserData("users", UserId, payload);
  //   if (response) {
  //     setLoader(false);
  //     setResponse(response);
  //     console.log("response", response)

  //   }
  // }

  const Colors = ["secondary", "success", "warning", "danger", "primary"];

  const numColors = Colors.length;
  const colorIndex = UserDetails && UserDetails.id % numColors;

  const ModalBodyData = () => {
    return (
      <>
        <ModalHeader>Edit profile</ModalHeader>
        <ModalBody>
          <div className="flex flex-row items-center gap-6">
            <Avatar
              radius="full"
              size="lg"
              src={UploadedUser ? UploadedUser : formData.ProfilePicture}
              color={Colors[colorIndex]}
              name={
                UserDetails && UserDetails.FirstName !== null
                  ? UserDetails &&
                    UserDetails.FirstName.slice(0, 1).toUpperCase()
                  : UserDetails && UserDetails.email.slice(0, 1).toUpperCase()
              }
            />
            <input
              ref={fileInputRef}
              type="file"
              onChange={(event) => handleFileChange(event)}
              className="hidden"
            />
            <Button onClick={handleButtonClick} size="sm" variant="bordered">
              Select profile image
            </Button>
            {UserProfile ? (
              <p className="text-xs cursor-pointer" onClick={Handleremove}>
                Remove
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-col gap-4 pt-4">
            <div>
              <p className="text-sm font-normal text-slate-500 pb-1">
                First name
              </p>
              <Input
                key="outside"
                type="text"
                size="sm"
                label=""
                defaultValue={formData && formData.FirstName}
                onChange={(e) =>
                  setFormData({ ...formData, FirstName: e.target.value })
                }
                labelPlacement="outside"
                placeholder="Enter your full name"
              />
            </div>
            <div>
              <p className="text-sm font-normal text-slate-500 pb-1">
                Last name
              </p>
              <Input
                key="outside"
                type="text"
                size="sm"
                label=""
                defaultValue={formData && formData.LastName}
                onChange={(e) =>
                  setFormData({ ...formData, LastName: e.target.value })
                }
                labelPlacement="outside"
                placeholder="Enter your last name"
              />
            </div>
            <div className="cursor-not-allowed">
              <p className="text-sm font-normal text-slate-500 pb-1">Email</p>
              <Input
                isDisabled
                key="outside"
                type="text"
                size="sm"
                label=""
                value={UserDetails && UserDetails.email}
                labelPlacement="outside"
                placeholder="Enter your email"
              />
            </div>
          </div>
        </ModalBody>
      </>
    );
  };

  const footerCreate = (onClose) => {
    return (
      <>
        <Button variant="bordered" onClick={() => UpdateProfile(onClose)}>
          Update
        </Button>
      </>
    );
  };

  return (
    <div>
      <LoadingPage isLoading={Loader}>
        <div className="max-w-2xl m-auto flex flex-col gap-6 px-4">
          <NextUIProvider>
            <Card className="shadow-none border border-divider rounded-2xl p-4">
              <CardBody>
                <div className="flex flex-row items-center gap-8">
                  <Avatar
                    radius="full"
                    size="md"
                    classname=""
                    color={Colors[colorIndex]}
                    src={UserProfile}
                    name={
                      UserDetails && UserDetails.FirstName !== null
                        ? UserDetails &&
                          UserDetails.FirstName.slice(0, 1).toUpperCase()
                        : UserDetails &&
                          UserDetails.email.slice(0, 1).toUpperCase()
                    }
                  />
                  <p className="text-lg font-medium">
                    {UserDetails && UserDetails.FirstName !== null
                      ? `${UserDetails.FirstName} ${UserDetails.LastName}`
                      : UserDetails && UserDetails.email}
                  </p>
                </div>
                <div className="flex flex-row w-full text-base pt-7 font-normal">
                  <p className="w-1/3 text-slate-400">Display Name</p>
                  <p className="w-full ">
                    {UserDetails &&
                    (UserDetails.FirstName !== null ||
                      UserDetails.LastName !== null)
                      ? UserDetails &&
                        `${
                          UserDetails.FirstName !== null
                            ? UserDetails.FirstName
                            : ""
                        } ${
                          UserDetails.LastName !== null
                            ? UserDetails.LastName
                            : ""
                        }`
                      : ""}
                  </p>
                </div>
                <div className="flex flex-row w-full  text-base pt-3 font-normal">
                  <p className="w-1/3 text-slate-400">Email</p>
                  <p className="w-full">
                    {UserDetails && UserDetails.email !== null
                      ? UserDetails.email
                      : ""}
                  </p>
                </div>
                <div className="pt-6">
                  <Button
                    variant="bordered"
                    className="text-base"
                    size="sm"
                    onPress={onModalOpen}
                  >
                    Edit profile
                  </Button>
                </div>
              </CardBody>
            </Card>
          </NextUIProvider>

          <NextUIProvider>
            <Card className="shadow-none border border-divider min-h-28 rounded-2xl p-4">
              <CardBody>
                <p className="text-lg font-normal">Enable Notifications</p>
                <p className="text-sm pt-4">
                  Enable Notifications to get notified about your knowledge base
                  activities to Slack, Teams, Discord and email.
                </p>
                <div className="mt-8">
                  {/* Slack */}
                  <div className="flex justify-between">
                    <div className="flex flex-col gap-1">
                      <p className="text-medium">Slack</p>
                      <p className="text-tiny text-default-400">
                        Get notifed through Slack
                      </p>
                    </div>

                    <Switch
                      color="danger"
                      size="sm"
                      checked={showInput}
                      onChange={(checked) => setShowInput(checked)}
                    />
                  </div>
                  <div className="mt-4">
                    {showInput && (
                      <div className="mt-12">
                        <Input
                          key="outside"
                          type="text"
                          radius="sm"
                          size="sm"
                          className="border rounded-lg border-content4 w-1/2"
                          labelPlacement="outside"
                          placeholder="Add your slack channel URL"
                          label="Slack channel"
                          color=""
                        />
                      </div>
                    )}
                  </div>

                  {/* Teams */}
                  <div className="flex justify-between mt-4">
                    <div className="flex flex-col gap-1">
                      <p className="text-medium">Teams</p>
                      <p className="text-tiny text-default-400">
                        Get notifed through Teams
                      </p>
                    </div>
                    <Switch color="secondary" size="sm" />
                  </div>

                  {/* Discord */}
                  {/* Email */}
                </div>
              </CardBody>
            </Card>
          </NextUIProvider>
          <NextUIProvider>
            <Card className="shadow-none border rounded-2xl p-4 border-red-400">
              <CardBody>
                <p className="text-lg font-normal">Delete your account</p>
                <p className="text-sm pt-4">
                  This will permanently remove your account you will lose access
                  to all your data inside your space.
                </p>
                <div className="pt-8">
                  <Button
                    size="sm"
                    onPress={onDeleteOpen}
                    className="text-base"
                    color="danger"
                  >
                    Delete account
                  </Button>
                </div>
              </CardBody>
            </Card>
          </NextUIProvider>
        </div>
      </LoadingPage>

      <ModalPopup
        isOpen={isModalOpen}
        onOpenChange={onModalOpenChange}
        ModalBodyData={ModalBodyData}
        footer={footerCreate}
      />

      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteData={""}
        setDeleteData={""}
        Collectionname={"user"}
        setLoader={setLoader}
        DeleteHandler={DeleteHandler}
      />
    </div>
  );
};

export default Profile;
