export const checkValidation = (formSchema, formdata, setValidationErrors) => {
  var numberRegex = /^[0-9]*$/;
  let isValid = true;
  const errors = {};

  formSchema.forEach((row) => {
    // [fieldName, fieldProps, fieldId, fieldValues]
    const fieldType = row[1]?.type;
    const fieldValue = formdata[row[0]];
    const isRequired = row[1]?.required;
    const fieldName = row[0];
   
    // You can add more validation rules based on the field type

    if (
      (fieldType === "text" ||
        fieldType === "password" ||
        fieldType === "string" ||
        fieldType === "textarea") &&
      isRequired
    ) {
      if (fieldValue === "") {
        errors[fieldName] = `${fieldName} is required`;
        isValid = false;
      }
    }

    if (fieldType === "date" && isRequired) {
      if (fieldValue === "" || fieldValue === "NaN/NaN/NaN") {
        errors[fieldName] = `${fieldName} is required`;
        isValid = false;
      }
    }
    if (fieldType === "dropdown" && isRequired) {
      if (fieldValue === "") {
        errors[fieldName] = `${fieldName} is required`;
        isValid = false;
      }
    }
    if (fieldType === "enumeration" && isRequired) {
      if (fieldValue === "") {
        errors[fieldName] = `${fieldName} is required`;
        isValid = false;
      }
    }

    if (
      isRequired &&
      (fieldType === "integer" ||
        fieldType === "biginteger" ||
        fieldType === "decimal" ||
        fieldType === "float")
    ) {
      if (!numberRegex.test(fieldValue)) {
        errors[fieldName] = `${fieldName} accept only numbers`;
        isValid = false;
      } else if (typeof fieldValue === "string") {
        errors[fieldName] = `${fieldName} can't be empty`;
        isValid = false;
      }
    }

    if (isRequired && (fieldType === "media" || fieldType === "file")) {
      if (fieldValue?.length === 0) {
        errors[fieldName] = `Upload a file `;
        isValid = false;
      }
    }

    if (isRequired && fieldType === "relation") {
      if (fieldValue === "") {
        errors[fieldName] = `Select the ${fieldName} ralated`;
        isValid = false;
      }
    }

    if (isRequired && (fieldType === "datetime" || fieldType === "date")) {
      if (fieldValue === "") {
        errors[fieldName] = `${fieldName} is required.`;
        isValid = false;
      }
    }

    if (isRequired && fieldType === "richtext") {
      if (fieldValue === "") {
        errors[fieldName] = `${fieldName} is required `;
        isValid = false;
      }
    }
  });

  setValidationErrors(errors);
  return isValid;
};
