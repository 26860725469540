import { Avatar, Button, Image } from "@nextui-org/react";
import React, { useEffect } from "react";
import logo from "../../Images/logo.svg";
import { Colors, numColors } from "../../utils/constant";
import OrgCard from "./OrgCard";
import useGetAllOrganization from "../../hooks/useGetAllOrganization";
import CreateOrgButtton from "./CreateOrgButton";
import { useNavigate } from "react-router-dom";
import {
  FugitiveCalculator,
  MobileCombustionCalculator,
  StationaryCombustionCalculator,
} from "../../utils/formulaCalulator";
import { getUserRoleFromLocal } from "../../utils/helper";

const OrgList = () => {
  const { OrgList, isLoading: isLoadingOrgList, err } = useGetAllOrganization();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const email = UserLogged && UserLogged.user.email;
  const navigate = useNavigate();

  useEffect(() => {
    if (!UserLogged) {
      navigate("/sign-in");
    }

    const data = {
      Quantity: 12,
      "Fuel Type": "Coal Tar",
    };

    const data1 = {
      Quantity: 23,
      "Greenhouse gas": "C10F18 (AR4)",
    };

    const data2 = {
      "Vehicle Type": "Gasoline heavy-duty vehicles 1999",
      Quantity: 23,
      Distance: 21,
      "Fuel Type": "Coal Tar",
    };

    // FugitiveCalculator(data1)
    //   .then((data) => console.log(data))
    //   .catch((err) => console.log(err));

    StationaryCombustionCalculator(data)
      .then((data) => {})
      .catch((err) => console.log(err));

    // MobileCombustionCalculator(data2)
    //   .then((data) => console.log(data))
    //   .catch((err) => console.log(err));
  }, []);

  return (
    <div className="w-full min-h-screen px-52 py-12">
      <div className="flex items-center justify-between w-full">
        <Image width={80} alt="NextUI hero Image" src={logo} />
        <Avatar
          showFallback
          className="h-8 w-8 cursor-pointer"
          color={Colors[UserId % numColors]}
          name={email?.toUpperCase().slice(0, 1)}
          src="https://images.unsplash.com/broken"
        />
      </div>

      <div className="mt-24 flex items-center justify-between">
        <div className="flex flex-col items-start gap-2">
          <div className="flex items-center justify-between w-full mb-4">
            <p className="text-lg font-medium">Choose your Organization</p>
            <CreateOrgButtton />
          </div>

          <p className="">
            To get started with your ESG (Environmental, Social, and Governance)
            metric scoring, you can either select an existing organization or
            create a new one. This will enable you to comprehensively evaluate
            and manage your organization's sustainability and ethical impact.
          </p>
        </div>
      </div>

      <div className="my-12 flex items-center flex-wrap gap-24">
        {OrgList?.map((name) => (
          <OrgCard title={name} />
        ))}
      </div>
    </div>
  );
};

export default OrgList;
