import React from "react";
import CardSkeleten from "./CardSkeleten";

const CardSkeletonContainer = ({ width = "400px" }) => {
  return (
    <>
      <div className="flex flex-wrap item-center gap-6">
        <CardSkeleten width={width} />
        <CardSkeleten width={width} />
        <CardSkeleten width={width} />
        <CardSkeleten width={width} />
        <CardSkeleten width={width} />
        <CardSkeleten width={width} />
        <CardSkeleten width={width} />
      </div>
    </>
  );
};

export default CardSkeletonContainer;
