import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import { PlusIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { StringLimit } from "../../controllers/basicControllers";

const dashboardIntialDetails = {
  newDashboard: false,
  name: "",
  description: "",
  dashboard: null,
};
const AddChartToDashboard = ({
  isOpen,
  onOpenChange,
  dashboardList,
  saveCharHandler,
}) => {
  const [errorMessage, setErrorMessage] = useState({
    dashboardName: "",
    dashboardSelect: "",
  });

  const [dashboardDetails, setDashboardDetails] = useState(
    dashboardIntialDetails
  );

  const changeHandler = (key, value) => {
    setDashboardDetails({ ...dashboardDetails, [key]: value });
  };

  const dashboradSelectionHandler = (e) => {

    if (Array.from(e)?.[0] === "New Dashbaord") {
      setDashboardDetails((pre) => {
        return {
          ...pre,
          newDashboard: true,
          dashboard: Array.from(e)?.[0] || "",
        };
      });
      return;
    }
    setDashboardDetails((pre) => {
      return {
        ...pre,
        newDashboard: false,
        name: "",
        description: "",
        dashboard: Array.from(e)?.[0] || "",
      };
    });
  };

  const validitionHandler = () => {
    const err = {};
    let isValid = true;

    if (dashboardDetails?.newDashboard && dashboardDetails.name === "") {
      err.dashboardName = "Dashboard name is required.";
      isValid = false;
    }
    if (!dashboardDetails?.newDashboard && !dashboardDetails.dashboard) {
      err.dashboardSelect = "Please select a dashboard.";
      isValid = false;
    }

    setErrorMessage(err);
    return isValid;
  };

  const submitHandler = (onClose) => {
    if (!validitionHandler()) {
      return;
    }

    saveCharHandler(dashboardDetails);
    onClose();
  };

  const openChangeHandler = (isOpen) => {
    setDashboardDetails(dashboardIntialDetails);
    onOpenChange(isOpen);
  };


  return (
    <Modal isOpen={isOpen} onOpenChange={openChangeHandler} size="2xl">
      <ModalContent>
        {(onClose) => (
          <div
            // onKeyDown={(event) => handleKeyDown(event, onClose)}
            tabIndex="0"
            className="outline-none"
          >
            <ModalHeader className="flex flex-col gap-1">
              Add to Dashboard
            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col justify-between my-4 gap-6">
                <Select
                  variant={"faded"}
                  label="Attached to dashboard"
                  labelPlacement="outside"
                  placeholder=" "
                  className="w-full"
                  //   selectedKeys={new Set([dashboardDetails?.dashboard])}
                  onSelectionChange={dashboradSelectionHandler}
                  isInvalid={
                    errorMessage?.dashboardSelect &&
                    errorMessage?.dashboardSelect !== ""
                  }
                  errorMessage={errorMessage?.dashboardSelect}
                >
                  {
                    <SelectItem
                      key={"New Dashbaord"}
                      textValue={"New Dashbaord"}
                    >
                      <div className="flex items-center justify-between py-2 gap-2">
                        <p className="">New Dashboard</p>
                        <PlusIcon className="size-4" />
                      </div>
                    </SelectItem>
                  }
                  {dashboardList?.map((data) => (
                    <SelectItem
                      key={data?.id}
                      textValue={data?.attributes?.name}
                    >
                      <div>
                        <p className="">{data?.attributes?.name}</p>
                        <p className="text-xs">
                          {StringLimit(
                            data?.attributes?.description || "",
                            140
                          )}
                        </p>
                      </div>
                    </SelectItem>
                  ))}
                </Select>
                {dashboardDetails?.newDashboard && (
                  <>
                    <Input
                      type="text"
                      label={"Name"}
                      isRequired
                      placeholder={"Name of the Dashboard"}
                      variant="faded"
                      labelPlacement="outside"
                      value={dashboardDetails?.name}
                      onChange={(e) => changeHandler("name", e.target.value)}
                      isInvalid={
                        errorMessage?.dashboardName &&
                        errorMessage?.dashboardName !== ""
                      }
                      errorMessage={errorMessage?.dashboardName}
                    />
                    <Textarea
                      label={"Description"}
                      placeholder={"Description of the Dashboard "}
                      variant="faded"
                      labelPlacement="outside"
                      value={dashboardDetails?.description}
                      onChange={(e) =>
                        changeHandler("description", e.target.value)
                      }
                    />
                  </>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button color="danger" onClick={() => submitHandler(onClose)}>
                Add
              </Button>
            </ModalFooter>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AddChartToDashboard;
