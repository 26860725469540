import {
  Button,
  Chip,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import NullData from "../../../components/ErrorPage/NullData";
import LoadingPage from "../../../components/Loader/LoadingPage";
import TableSkeleton from "../../../components/Skeleten/TableSkeleton";
import { DeleteTableItem, EditTableItem } from "../../../utils/helper";
import ReportingYearModal from "./ReportingYearModal";
import {
  DeleteOnedata,
  UpdateOneData,
  getDataWithFilterUrl
} from "../../../controllers/APIController";
import EditReportYearModal from "./EditReportYearModal";

const ReportingYear = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [reRender, setReRender] = useState(true);
  const [reportYears, setReportYears] = useState([]);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onOpenChange: onOpenChangeEdit,
  } = useDisclosure();

  const [loader, setLoader] = useState(true);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const deleteHandler = async (id) => {
    await DeleteOnedata("financial-years", id);
    setReRender((pre) => !pre);
  };

  const statusChangeHandler = async (data) => {
    if (data?.attributes?.isActive) {
      return;
    }

    const result = reportYears?.map(async (item) => {
      const payload = {
        isActive: data?.id === item?.id ? true : false,
      };

      await UpdateOneData("financial-years", item?.id, payload);
    });
    await Promise.all(result);

    setReRender((pre) => !pre);
  };
  useEffect(() => {
    getDataWithFilterUrl(
      "financial-years",
      `filters[Organization][id][$eqi]=${OrgInfo}`
    )
      .then((data) => {
        const dataList = data?.data;

        dataList?.sort(
          (a, b) =>
            new Date(b?.attributes?.createdAt) -
            new Date(a?.attributes?.createdAt)
        );

        setReportYears(dataList);
        if (loader === true) setLoader(false);
      })
      .catch((err) => console.log(err));
  }, [reRender]);

  return (
    <div>
      <EditReportYearModal
        onSubmitHandler={1}
        isOpen={isOpenEdit}
        onOpen={onOpenEdit}
        onOpenChange={onOpenChangeEdit}
        id={Date.now()}
        data={selectedItem}
        setReRender={setReRender}
      />
      <ReportingYearModal
        onSubmitHandler={1}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        id={Date.now()}
        setReRender={setReRender}
      />
     <div className="my-8">
        Manage your reporting years to ensure accurate financial tracking and compliance within your organization.
      </div>
      <div className="flex justify-end cursor-pointer">
        <Button color="danger" onClick={() => onOpen()}>
          Create New Report Year
        </Button>
      </div>

      <LoadingPage isLoading={loader} alterElement={<TableSkeleton />}>
        {reportYears.length > 0 ? (
          <div className="pt-8">
            <Table removeWrapper aria-label="Example static collection table">
              <TableHeader>
                <TableColumn>IsActive</TableColumn>
                <TableColumn>Name</TableColumn>
                <TableColumn>Start Date</TableColumn>
                <TableColumn>End Date</TableColumn>
                <TableColumn>Action</TableColumn>
              </TableHeader>
              <TableBody>
                {reportYears && reportYears.length > 0
                  ? reportYears &&
                    reportYears.map((item, index) => {
                      const isActive = item?.attributes?.isActive || false;

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Switch
                              isSelected={isActive}
                              color="danger"
                              size="sm"
                              onValueChange={() => {
                                statusChangeHandler(item);
                              }}
                            />
                          </TableCell>
                          <TableCell>{item?.attributes?.Name}</TableCell>
                          <TableCell>{item?.attributes?.StartDate}</TableCell>
                          <TableCell>{item?.attributes?.EndDate}</TableCell>
                          <TableCell>
                            <div className="flex items-center mx-auto gap-8">
                              <EditTableItem
                                userRole={"Admin"}
                                editHandler={() => {
                                  setSelectedItem(item);
                                  onOpenEdit();
                                }}
                                item={item}
                                toolTiptext="Edit Report Year"
                              />
                              <DeleteTableItem
                                userRole={"Admin"}
                                deleteHandler={deleteHandler}
                                item={item}
                                toolTiptext="Delete Report Year"
                              />
                            </div>
                          </TableCell>
                          {/* <TableCell>
                            <Chip variant="flat" color="success">
                              Active
                            </Chip>
                          </TableCell> */}
                        </TableRow>
                      );
                    })
                  : ""}
              </TableBody>
            </Table>
          </div>
        ) : (
          <NullData />
        )}
      </LoadingPage>
    </div>
  );
};

export default ReportingYear;
