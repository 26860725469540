import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Chip } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import {
  getDataWithFilterUrl,
  StringLimit,
} from "../../controllers/APIController";
// import {DocumentChartBar}
import { DocumentChartBarIcon } from "@heroicons/react/24/outline";
import CardSkeletonContainer from "../../components/Skeleten/CardSkeletonContainer";
import { DateFormator } from "../../utils/helper";
const Colors = ["secondary", "success", "warning", "danger", "default"];

const SingleReportCard = ({ data, color }) => {
  const textColor = `text-${color}`;
  const navigate = useNavigate();

  return (
    <div
      className="cursor-pointer w-[320px]"
      onClick={() => {
        navigate(`${data?.id}`);
      }}
    >
      <Card
        key={23}
        className="h-[250px] p-2 border border-divider"
        shadow="none"
      >
        <CardHeader className="p-1">
          <div className="flex flex-col w-full">
            <div className="flex gap-2">
              <DocumentChartBarIcon
                className={`size-16 ${textColor}`}
                color={color}
              />
              <div className="flex flex-col w-full">
                <p className="text-start text-lg font-semibold">
                  {StringLimit(data?.attributes?.name, 25)}
                </p>
                <p className="text-xs text-gray">
                  {DateFormator(data?.attributes?.createdAt)}
                </p>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-2 flex-wrap my-2">
                {(data?.user_tags || []).map((tag, idx) => {
                  if (idx > 5) return null;
                  return (
                    <Chip size="sm" variant="solid" color={color}>
                      {tag}
                    </Chip>
                  );
                })}
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="p-1">
          <div className="text-grey-600">
            {StringLimit(data?.attributes?.description || "", 120)}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
const Report = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getDataWithFilterUrl("reports")
      .then((data) => {
        setReports(data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching reports:", error);
        setIsLoading(false);
      });
  }, []);
  return (
    <BreadCrumbsPage>
      <>
        <h2 className="text-3xl font-medium">ESG Report</h2>
        <div className="mt-4">
          This section provides an overview of the company’s Environmental,
          Social, and Governance (ESG) performance. The report tracks the
          company’s efforts to minimize environmental impact, improve social
          responsibility, and ensure strong governance practices.
        </div>
        <div className="flex items-center justify-end my-4">
          <Button
            color="danger"
            onClick={() => navigate("create")}
            className="w-max"
          >
            Create Report
          </Button>
        </div>
        {/* <CreateReportWizard /> */}

        {isLoading ? (
          <CardSkeletonContainer width={"300px"} />
        ) : (
          <div className="flex items-center flex-wrap gap-8 my-8">
            {reports?.map((data, index) => {
              if (index > 5) return null;
              const len = Colors.length;
              const color = Colors[index % len];
              return <SingleReportCard data={data} color={color} />;
            })}
          </div>
        )}
      </>
    </BreadCrumbsPage>
  );
};

export default Report;
