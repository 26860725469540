import { useEffect, useState } from "react";
import { getDataWithFilterUrl } from "../controllers/APIController";

export default function useGetOrgFramework(orgId) {

  const [frameworkList, setFrameworkList] = useState([]);
  const [orgFrameworkDetails, setOrgFrameworkDetails] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const url = `filters[organizations][id][$eqi]=${orgId}`;

  useEffect(() => {

    getDataWithFilterUrl("frameworks", url)
      .then((data) => {
        let response = data?.data;
        response?.sort((a, b) => a?.id - b?.id);
        const list = response?.map((item) => {
          return item.id;
        });
        
        setOrgFrameworkDetails(response);
        setFrameworkList(list);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orgId]);

  return { frameworkList, isLoading, orgFrameworkDetails };
}
