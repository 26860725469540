import React, { useEffect, useState } from "react";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import {
  DeleteOnedata,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import LoadingPage from "../../components/Loader/LoadingPage";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import NullData from "../../components/ErrorPage/NullData";
import { useNavigate } from "react-router-dom";
import { LeftArrow } from "../../Images/LeftArrow";
import { DeleteTableItem, formatDate, UserCell } from "../../utils/helper";

const InviteRequest = () => {
  const [invitationList, setInvitationList] = useState([]);
  const [Loader, setLoader] = useState(true);
  const [reRenderPage, setReRenderPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const deleteInvitationHandler = async (id) => {
    const deleteResponse = await DeleteOnedata("invitations", id);
    setReRenderPage((pre) => !pre);
  };

  useEffect(() => {
    getDataWithFilterUrl(
      "invitations",
      `filters[organization][id][$eqi]=${OrgInfo}`
    ).then((data) => {
      setInvitationList(data?.data);
      setLoader(false);
    });
  }, [reRenderPage]);

  const ItemPerPage = 20;
  const totalPage = Math.ceil(invitationList?.length / ItemPerPage);


  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={Loader}>
        <div className="text-3xl font-medium flex items-center gap-2">
          <span
            className="cursor-pointer hover:opacity-50 mt-1"
            onClick={() => navigate("/default/user-management")}
          >
            <LeftArrow height={28} width={20} />
          </span>
          <span>Invite Request</span>
        </div>
        <div className="mt-4 leading-relaxed">
          View and manage account invitation made by organization.
        </div>
        <div className="pt-8 min-h-[400px]">
          <Table removeWrapper aria-label="Example static collection table">
            <TableHeader>
              <TableColumn>User Email</TableColumn>
              <TableColumn>Status</TableColumn>
              <TableColumn>Date</TableColumn>
              <TableColumn>Send By</TableColumn>
              <TableColumn>Action</TableColumn>
            </TableHeader>
            <TableBody
              emptyContent={<NullData title={"No Invitation found"} />}
            >
              {invitationList &&
                invitationList.map((item, index) => {
                  const email = item?.attributes?.email;
                  const sendByEmail =
                    item?.attributes?.sendBy?.data?.attributes?.email;
                  const sendByavatarUrl =
                    item?.attributes?.sendBy?.data?.attributes
                      ?.ProfilePictureUrl;
                  const sendById = item?.attributes?.sendBy?.data?.id;
                  const currentStatus = item?.attributes?.Status;
                  const createdAt = formatDate(item?.attributes?.createdAt);

                  return (
                    <TableRow key={index}>
                      <TableCell>{email}</TableCell>
                      <TableCell>{currentStatus}</TableCell>
                      <TableCell>{createdAt}</TableCell>
                      {UserCell(sendByEmail, sendById, sendByavatarUrl)}
                      <TableCell>
                        <DeleteTableItem
                          userRole={"Admin"}
                          deleteHandler={deleteInvitationHandler}
                          item={item}
                          toolTiptext="Delete Invitation"
                          DropDownText={`Delete ${email} invitation`}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>

        {invitationList?.length > 0 && (
          <div className="w-full mt-8 flex items-center justify-center">
            <Pagination
              showControls
              total={totalPage}
              color="secondary"
              page={currentPage}
              onChange={setCurrentPage}
            />
          </div>
        )}
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default InviteRequest;
