import React, { useState } from "react";
import { DateInput, TextInput } from "../FormBuilder/InputField/InputField";
import { OnboardingOrgDataSchema } from "../../constant/OnboardingOrganization/OnboardingOrganization";
import { Button } from "@nextui-org/react";
import { ProgessColors } from "../../utils/constant";

const OrgFinancialInfo = ({
  DataList,
  setDataList,
  TitleText,
  DescriptionText,
  currentPage,
  setCurrrentPage,
  totalStep,
}) => {
  let schemaData = OnboardingOrgDataSchema;

  const [validationErrors, setValidationErrors] = useState({});

  const ValidationHandler = () => {
    const err = {};
    let isValid = true;
    setValidationErrors({});

    if (DataList["Financial Name"]?.trim() === "") {
      err["Financial Name"] = "Name is required!";
      isValid = false;
    }
    if (DataList["Start Date"]?.trim() === "") {
      err["Start Date"] = "Start Date is required!";
      isValid = false;
    }
    if (DataList["End Date"]?.trim() === "") {
      err["End Date"] = "End Date is required!";
      isValid = false;
    }

    setValidationErrors(err);
    return isValid;
  };
  const handleNext = () => {
    if (ValidationHandler()) {
      setCurrrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center mt-8 font-semibold text-2xl">
        {TitleText || "Create an organization"}
      </div>
      <div className="w-full flex justify-center">
        <div className="mt-8  w-[650px]">
          {DescriptionText ||
            `Create an organization to embark on your ESG (Environmental, Social,
      and Governance) reporting journey. Begin evaluating and improving your
      sustainability and ethical practices today.`}
        </div>
      </div>
      <div className="flex flex-col mx-auto mt-8 min-h-[360px] w-[500px]">
        <TextInput
          data={schemaData["Financial Name"]}
          value={DataList["Financial Name"]}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) => {
            setDataList({ ...DataList, [title]: value });
          }}
          cssStyle="mb-8"
        />
        <DateInput
          data={schemaData["Start Date"]}
          value={DataList["Start Date"]}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) => {
            setDataList({ ...DataList, [title]: value });
          }}
          cssStyle="mb-8"
        />
        <DateInput
          data={schemaData["End Date"]}
          value={DataList["End Date"]}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) => {
            setDataList({ ...DataList, [title]: value });
          }}
          cssStyle="mb-4"
        />
      </div>
      <div className={`w-full flex items-center my-2 justify-between`}>
        <Button
          color={ProgessColors[currentPage]}
          variant="light"
          className="text-center cursor-pointer"
          isDisabled={currentPage === 0}
          onClick={() => {
            setCurrrentPage(currentPage - 1);
          }}
        >
          Previous
        </Button>
        <Button
          color={ProgessColors[currentPage]}
          className={`text-center cursor-pointer`}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default OrgFinancialInfo;
