import React, { useEffect, useState } from "react";
import {
  Avatar,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import {
  getFilterDataWithPagination,
  StringLimit,
} from "../../controllers/APIController";
import { DateFormatorDD } from "../../utils/helper";
import { Colors, numColors } from "../../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import LoadingPage from "../../components/Loader/LoadingPage";
import TableSkeleton from "../../components/Skeleten/TableSkeleton";

const Logs = () => {
  const { _orgId: currOrg } = useParams();
  const [responseData, setResponseData] = useState([]);
  const [Loader, setLoader] = useState(true);
  const [categroyName, setCategoryName] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const ItemPerPage = 20;

  const navigate = useNavigate();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const navigateHandler = (metric, id) => {
    const category = categroyName[metric]?.toLowerCase().replaceAll(" ", "-");
    const Navigator = metric?.toLowerCase().replaceAll(" ", "-");
    if (category === "reference-data" || category === "framework") {
      navigate(`/${currOrg}/${category}/${Navigator}/${id}`);
    } else navigate(`/${currOrg}/metrics/${category}/${Navigator}/${id}`);
  };

  useEffect(() => {
    getFilterDataWithPagination(
      "responses",
      `${orgFilterUrl}&sort[0]=updatedAt:desc`,
      currentPage,
      ItemPerPage
    )
      .then((data) => {
        setResponseData(data?.data);
        if (totalPage !== 0) setTotalPage(data?.meta?.pagination?.pageCount);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage, totalPage, orgFilterUrl]);

  useEffect(() => {
    const categoryList = {};
    const result = responseData.map(async (item) => {
      const url =
        orgFilterUrl + "&filters[Name][$eqi]=" + item?.attributes?.Metric_Id;
      const response = await getFilterDataWithPagination("metrics", url, 1, 1);
      categoryList[item?.attributes?.Metric_Id] =
        response?.data[0]?.attributes?.Category || "Unknown";
      return response;
    });

    Promise.all(result).then(() => {
      setCategoryName(categoryList);
    });
  }, [responseData, orgFilterUrl]);

  return (
    <BreadCrumbsPage>
      <>
        <div className="">
          <h1 className="text-3xl font-medium">Logs</h1>
          <p className="mt-8">
            View the comprehensive logs of all your ESG (Environmental, Social,
            and Governance) metric responses here. Track and analyze your
            organization's sustainability and ethical performance in detail,
            ensuring transparency and accountability in your ESG reporting
            process.
          </p>
        </div>
        <LoadingPage isLoading={Loader} alterElement={<TableSkeleton />}>
          <Table
            removeWrapper
            aria-label="Example static collection table"
            className="mt-8"
            bottomContent={
              <div className="flex items-center justify-center mt-4">
                <Pagination
                  showControls
                  color="secondary"
                  page={currentPage}
                  total={totalPage}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            }
          >
            <TableHeader>
              <TableColumn>Metric</TableColumn>
              <TableColumn>Updated at</TableColumn>
              <TableColumn>Updated by</TableColumn>
            </TableHeader>
            <TableBody>
              {responseData?.map((data, index) => {
                const colorIndex =
                  data?.attributes?.users_permissions_user?.data?.id %
                  numColors;
                return (
                  <TableRow
                    key={data?.id || index}
                    className="cursor-pointer hover:bg-grey-200"
                    onClick={() =>
                      navigateHandler(data?.attributes?.Metric_Id, data?.id)
                    }
                  >
                    <TableCell>
                      <div className="flex gap-4 items-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/nizfqlnk.json"
                          trigger="hover"
                          target="#Metrics"
                          state="morph-home-2"
                          colors={`primary: "#000000"`}
                          style={{ width: "25px", height: "25px" }}
                        ></lord-icon>
                        {data?.attributes?.Metric_Id}
                      </div>
                    </TableCell>
                    <TableCell>
                      {DateFormatorDD(data?.attributes?.updatedAt)}
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-4 items-center">
                        <Avatar
                          className="h-6 w-6"
                          color={Colors[colorIndex]}
                          name={data?.attributes?.users_permissions_user?.data?.attributes?.email
                            .toUpperCase()
                            .slice(0, 1)}
                          src={
                            data?.attributes?.users_permissions_user?.data
                              ?.attributes?.ProfilePicture &&
                            `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data?.attributes?.users_permissions_user?.data?.attributes?.ProfilePicture.url}`
                          }
                        />
                        <div className="text-tiny text-default-500 font-bold">
                          {StringLimit(
                            data?.attributes?.users_permissions_user?.data
                              ?.attributes?.email,
                            30
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </LoadingPage>
      </>
    </BreadCrumbsPage>
  );
};

export default Logs;
