import {
  Button,
  Input,
  Select,
  SelectItem,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  PublishData,
  capitalize,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import MarkdownEditor from "../Dashboard/MarkdownEditor";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import useGetAllOrganization from "../../hooks/useGetAllOrganization";
import LoadingPage from "../../components/Loader/LoadingPage";
import CreateMarkDownWithAI from "./CreateMarkDownWithAI";

const createUploadDataForPolicyBuilder = (
  data,
  OrgInfo,
  markDownContent,
  metricValueList
) => {
  const uploadData = {
    Name: data.name,
    Description: data.description,
    Organization: OrgInfo,
  };
  if (data?.policyType === "policy") {
    uploadData.DomainId = data.domain;
    uploadData.PolicyFramework = markDownContent;
  } else if (data?.policyType === "procedure") {
    uploadData.PolicyId = data.policy;
    uploadData.ControlMetrics = { controlMetrics: metricValueList };
    uploadData.ProcedureFramework = markDownContent;
  }

  return uploadData;
};
const createpolicyDetailFromExtenalData = (data, type) => {
  const policyDetail = {
    name: data?.attributes?.Name,
    description: data?.attributes?.Description,
    policyType: type,
    domain: data?.attributes?.DomainId?.data?.id || null,
    policy: data?.attributes?.PolicyId?.data?.id || null,
  };

  const markDownContent =
    data?.attributes?.PolicyFramework ||
    data?.attributes?.ProcedureFramework ||
    "";

  const metricValueList =
    data?.attributes?.ControlMetrics?.controlMetrics || [];

  return [policyDetail, markDownContent, metricValueList];
};
const pulizirePolicyName = {
  domain: "domains",
  policy: "policies",
  procedure: "procedures",
};
const EditPoliciesBuilder = () => {
  const [isLoading, setisLoading] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [metricValueList, setMetricValueList] = useState([]);
  const [markDownContent, setMarkDownContent] = useState("");

  const [policyDetails, setPolicyDetails] = useState({
    name: "",
    description: "",
    policyType: "",
    domain: null,
    policy: null,
  });
  const [allDomainPolicyProcedure, setAllDomainPolicyProcedure] = useState({
    domainList: [],
    policyList: [],
  });
  const [metricFormList, setMetricFormList] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onOpenChange: onModalOpenChange,
  } = useDisclosure();

  const [searchParams, setSearchParams] = useSearchParams();
  const policyType = searchParams.get("type");

  const navigate = useNavigate();
  const { _orgId: currOrg, _responseId } = useParams();

  const policyTypeList = [
    { id: "domain", title: "Domain" },
    { id: "policy", title: "Policy" },
    { id: "procedure", title: "Procedure" },
  ];

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const changeHandler = (key, value) => {
    setPolicyDetails((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const validationHandler = () => {
    let isValid = true;
    const errorMessage = {};
    if (!policyDetails?.name || policyDetails?.name?.trim() === "") {
      errorMessage["name"] = "Name is required!";
      isValid = false;
    }
    if (
      !policyDetails?.description ||
      policyDetails?.description?.trim() === ""
    ) {
      errorMessage["description"] = "Description is required!";
      isValid = false;
    }
    if (
      !policyDetails?.policyType ||
      policyDetails?.policyType?.trim() === ""
    ) {
      errorMessage["policyType"] = "Type is required!";
      isValid = false;
    }
    if (
      policyDetails?.policyType === "policy" &&
      (!policyDetails?.domain || policyDetails?.domain?.trim() === "")
    ) {
      errorMessage["domain"] = "domain is required!";
      isValid = false;
    }
    if (
      policyDetails?.policyType === "procedure" &&
      (!policyDetails?.policy || policyDetails?.policy?.trim() === "")
    ) {
      errorMessage["policy"] = "Policy is required!";
      isValid = false;
    }
    setValidationErrors(errorMessage);

    return isValid;
  };
  const submitHandler = async () => {
    const isValid = validationHandler();
    if (!isValid) {
      return;
    }

    const uploadData = createUploadDataForPolicyBuilder(
      policyDetails,
      OrgInfo,
      markDownContent,
      metricValueList
    );

    const collectionName = pulizirePolicyName?.[policyDetails?.policyType];

    let result = await PublishData({ data: uploadData }, collectionName);

    navigate(-1);
  };
  //   const data = {};

  useEffect(() => {
    getDataWithFilterUrl("metrics", orgFilterUrl)
      .then((data) => {
        let list = data.data;
        list.sort((a, b) => {
          if (
            a?.attributes?.Name.trim().toLowerCase() <
            b?.attributes?.Name.trim().toLowerCase()
          ) {
            return -1;
          }
          if (
            a?.attributes?.Name.trim().toLowerCase() >
            b?.attributes?.Name.trim().toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });

        list = list.map((item) => {
          return {
            id: item.id,
            title: item?.attributes?.Name,
          };
        });
        setMetricFormList(list);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const policyList = [];
    const domainList = [];
    let randomId = 0;
    getDataWithFilterUrl("domains", orgFilterUrl)
      .then((domainData) => {
        domainData?.data?.forEach((element) => {
          domainList.push({
            randomId: randomId++,
            id: element.id,
            name: element?.attributes?.Name,
            description: element?.attributes?.Description || "--",
          });
        });
        getDataWithFilterUrl("policies", orgFilterUrl).then((policyData) => {
          policyData?.data?.forEach((element) => {
            policyList.push({
              randomId: randomId++,
              id: element.id,
              name: element?.attributes?.Name,
              description: element?.attributes?.Description || "--",
              type: "policy",
            });
          });
          setAllDomainPolicyProcedure({
            domainList,
            policyList,
          });
          // setAllDomainPolicyProcedure(li);
          setisLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const filteredUrl = `${orgFilterUrl}&filters[id][$eqi]=${_responseId}`;
    const collectionName = pulizirePolicyName[policyType];

    getDataWithFilterUrl(collectionName, filteredUrl).then((data) => {

      const [policyDetail, markDownContentData, selectedMetricList] =
        createpolicyDetailFromExtenalData(data?.data?.[0], policyType);
      setMarkDownContent(markDownContentData);
      setPolicyDetails(policyDetail);
      setMetricValueList(selectedMetricList);
    });
  }, []);

  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={isLoading}>
        <div>
          <p className="text-3xl font-medium">Edits Policy Builder</p>
          <div className="flex flex-col gap-4 my-12 w-[50%]">
            {/* Policy Type */}

            <Select
              label="Type"
              isDisabled
              placeholder="Choose a Category"
              variant="faded"
              selectionMode="single"
              labelPlacement="outside"
              className=""
              selectedKeys={new Set([policyDetails?.policyType])}
              onSelectionChange={(event) =>
                changeHandler("policyType", Array.from(event)[0])
              }
              errorMessage={validationErrors["policyType"]}
              isInvalid={validationErrors["policyType"]}
            >
              {policyTypeList?.map((item) => (
                <SelectItem key={item.id} value={item.title} className="py-2">
                  {item.title}
                </SelectItem>
              ))}
            </Select>

            {/* Name */}
            <Input
              type="text"
              className=""
              isRequired
              label={"Name"}
              placeholder={" "}
              variant="faded"
              labelPlacement="outside"
              value={policyDetails?.name}
              onChange={(event) => {
                changeHandler("name", event.target.value);
              }}
              isInvalid={validationErrors["name"]}
              errorMessage={validationErrors["name"]}
            />
            {/* Description */}
            <Textarea
              className=""
              label={"Description"}
              isRequired
              value={policyDetails?.description}
              placeholder={" "}
              variant="faded"
              labelPlacement="outside"
              onChange={(event) => {
                changeHandler("description", event.target.value);
              }}
              isInvalid={validationErrors["description"]}
              errorMessage={validationErrors["description"]}
            />

            {/* Domain for policy  */}
            {policyDetails?.policyType === "policy" && (
              <Select
                label="Domain"
                placeholder="Choose a Domain"
                variant="faded"
                selectionMode="single"
                labelPlacement="outside"
                className="my-4"
                selectedKeys={new Set([policyDetails?.domain.toString()])}
                onSelectionChange={(event) =>
                  changeHandler("domain", Array.from(event)[0])
                }
                errorMessage={validationErrors["domain"]}
                isInvalid={validationErrors["domain"]}
              >
                {allDomainPolicyProcedure?.domainList?.map((item) => (
                  <SelectItem key={item.id} value={item.name} className="py-2">
                    {item.name}
                  </SelectItem>
                ))}
              </Select>
            )}

            {/* policy for procedure type */}

            {policyDetails?.policyType === "procedure" && (
              <Select
                label="Choose Policy"
                placeholder="Choose a policy"
                variant="faded"
                selectionMode="single"
                labelPlacement="outside"
                className="my-4"
                selectedKeys={new Set([policyDetails?.policy.toString()])}
                onSelectionChange={(event) =>
                  changeHandler("policy", Array.from(event)[0])
                }
                errorMessage={validationErrors["policy"]}
                isInvalid={validationErrors["policy"]}
              >
                {allDomainPolicyProcedure?.policyList?.map((item) => (
                  <SelectItem key={item.id} value={item.name} className="py-2">
                    {item.name}
                  </SelectItem>
                ))}
              </Select>
            )}

            {/* Form-Metric Choosen Input */}

            {policyDetails?.policyType === "procedure" && (
              <Select
                label="Control Metrics"
                placeholder="Select any Metrics"
                variant="faded"
                selectionMode="multiple"
                labelPlacement="outside"
                className="my-8"
                selectedKeys={new Set(metricValueList)}
                onSelectionChange={(event) =>
                  setMetricValueList(Array.from(event))
                }
              >
                {metricFormList?.map((item) => (
                  <SelectItem key={item.id} value={item.title} className="py-2">
                    {item.title}
                  </SelectItem>
                ))}
              </Select>
            )}

            {(policyDetails?.policyType === "policy" ||
              policyDetails?.policyType === "procedure") && (
              <div>
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium">
                    Describe {capitalize(policyDetails?.policyType || "")}
                  </p>
                  <Button size="sm" color="warning" onClick={onModalOpen}>
                    Create with AI
                  </Button>
                </div>
                <MarkdownEditor
                  descriptionContent={markDownContent}
                  setDescriptionContent={setMarkDownContent}
                />
              </div>
            )}
            <div className="flex justify-end gap-4 my-4 w-full">
              <Button color="secondary" variant="light" size="sm" onClick={1}>
                Cancel
              </Button>
              <Button
                color="danger"
                size="sm"
                onClick={submitHandler}
                className={`cursor-pointer`}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
        <CreateMarkDownWithAI
          isOpen={isModalOpen}
          onOpen={onModalOpen}
          onOpenChange={onModalOpenChange}
          onSubmitHandler={(data) => {
            setMarkDownContent(data);
          }}
        />
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default EditPoliciesBuilder;
