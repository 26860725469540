import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Image,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { getImageForDomain } from "../../constant/Policies/policyConstant";
import { StringLimit } from "../../controllers/APIController";

const ProcedureCard = ({ currOrg, _policy, procedure }) => {
  const navigate = useNavigate();
  const handleOnNavigate = (path) => {
    const formattedPath = path.replace(/\s+/g, "-");
    navigate(`/${currOrg}/policies/${_policy}/${formattedPath}`);
  };
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        handleOnNavigate(procedure.attributes.Name);
      }}
    >
      <Card
        key={procedure.id}
        className="h-[250px] p-2 border border-divider"
        shadow="none"
      >
        <CardHeader className="flex gap-3">
          <Image
            alt="policy logo"
            height={40}
            radius="sm"
            src={getImageForDomain(procedure.attributes.DomainName)}
            width={40}
          />
          <div className="flex flex-col">
            <p className="text-md">{procedure.attributes.Name}</p>
            <p className="text-small text-default-500">
              {procedure.attributes.Policy}
            </p>
          </div>
        </CardHeader>
        <CardBody>
          <div>{StringLimit(procedure?.attributes?.Description, 150)}</div>
        </CardBody>
        <CardFooter>
          <div className="flex justify-between w-full items-center">
            <Chip color="danger" variant="flat">
              Incomplete
            </Chip>

            <div className="flex gap-2 items-center">
              <lord-icon
                src="https://cdn.lordicon.com/rbbnmpcf.json"
                trigger="hover"
                target="#Metrics"
                state="morph-home-2"
                colors={`primary: "#000000"`}
                style={{ width: "20px", height: "20px" }}
              ></lord-icon>
              {procedure.attributes.ControlMetrics &&
                procedure.attributes.ControlMetrics.controlMetrics.length}
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ProcedureCard;
