export const getItemLayout = (list, documentId) => {

  return list.find((item) => {
    return item?.i === documentId;
  });
};

export const getUpdatedDashabordLayout = (
  chartInfoData,
  dashboardList,
  dashboardId
) => {

  const dashboardItem = dashboardList.find((item) => item.id === +dashboardId);

  const oldchartInfoList = dashboardItem?.attributes?.chartsInfo || [];


  let newChartInfoList = [];

  if (
    oldchartInfoList.some(
      (item) => item?.poisitionInfo?.x === 0 && item?.poisitionInfo?.y === 0
    )
  ) {
    newChartInfoList = oldchartInfoList
      .map((item) => {
        if (item?.poisitionInfo?.x === 0) {
          return {
            poisitionInfo: { y: item.poisitionInfo.y++, ...item.poisitionInfo },
            ...item,
          };
        }

        return item;
      })
      .concat([chartInfoData]);
  } else {
    newChartInfoList = oldchartInfoList.concat([chartInfoData]);
  }

  return newChartInfoList;
};
