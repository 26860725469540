import React, { useEffect, useState } from "react";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import LoadingPage from "../../components/Loader/LoadingPage";
import TableSkeleton from "../../components/Skeleten/TableSkeleton";
import NullData from "../../components/ErrorPage/NullData";
import { useNavigate } from "react-router-dom";
import {
  getDataWithFilterUrl,
  StringLimit,
} from "../../controllers/APIController";

const SingleFrameworkTable = ({ data, frameworkName }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [controlMetrics, setControlMetrics] = useState([]);
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();

  const HandleToNavigate = (item) => {
    navigate(`${item?.id}?framework=${frameworkName}`);
  };

  useEffect(() => {
    const metricsidList = data?.attributes?.ControlMetrics?.id || [];
    const list = [];
    const result = metricsidList?.map(async (id) => {
      const url = `filters[id][$eqi]=${+id}`;

      const response = await getDataWithFilterUrl("metrics", url);
      if (response?.data[0]) list.push(response?.data[0]);
    });

    Promise.all(result).then((data) => {
      list.sort((a, b) => a.id - b.id);
      setControlMetrics(list);
      setIsLoading(false);
    });
  }, [data]);

  const rowsPerPage = 15;
  const pages = Math.ceil(controlMetrics?.length / rowsPerPage);

  return (
    <div className="my-8">
      <div className="mt-8">
        <LoadingPage isLoading={isLoading} alterElement={<TableSkeleton />}>
          <Table
            aria-label="Controlled table metrics"
            removeWrapper
            isHeaderSticky
            selectionMode="single"
          >
            <TableHeader>
              <TableColumn>Framework's Metric Name</TableColumn>
              <TableColumn>Category</TableColumn>
              <TableColumn>Description</TableColumn>
            </TableHeader>
            <TableBody emptyContent={<NullData />}>
              {controlMetrics?.map((item, index) => {
                const lowerPageLimit = (page - 1) * rowsPerPage;
                const upperPageLimit = page * rowsPerPage;
                if (index < lowerPageLimit || index >= upperPageLimit)
                  return null;
                return (
                  <TableRow
                    key={item?.id}
                    onClick={() => HandleToNavigate(item)}
                    className="cursor-pointer "
                  >
                    <TableCell>
                      {item?.attributes?.NameList?.[frameworkName] ||
                        item?.attributes?.NameList?.Generic ||
                        item?.attributes?.Name}
                    </TableCell>
                    <TableCell>{item?.attributes?.Category}</TableCell>
                    <TableCell>
                      {StringLimit(item?.attributes?.Description || "--", 70)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {controlMetrics?.length > 0 && (
            <div className="flex items-center justify-center mt-4">
              <Pagination
                isCompact
                showShadow
                showControls
                color="secondary"
                page={page}
                total={pages}
                onChange={(page) => setPage(page)}
              />
            </div>
          )}
        </LoadingPage>
      </div>
    </div>
  );
};

export default SingleFrameworkTable;
