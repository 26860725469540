import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Select,
  SelectItem,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";

import React, { useEffect, useState } from "react";

import {
  ApprovalWorkFlowDataSchemaObj,
  CategoryList,
  FieldList,
  FrequecyCountMap,
  FrequencyDataObj,
} from "../../utils/constant";
import PreviewForm from "./PreviewForm";
import { FaChevronDown } from "react-icons/fa";
import {
  UpdateOneData,
  UpdateSingleCollectionData,
  getDataWithFilterUrl,
  getSingleCollectionData,
} from "../../controllers/APIController";
import AddModal from "./AddModal";
import FieldInfoTable from "./FieldInfoTable";
import { useNavigate, useParams } from "react-router-dom";
import AddFormulaModal from "./AddFormulaModal";
import FormulaInfoTable from "./FormulaInfoTable";
import {
  CustomFrequencyInput,
  DropDownInput,
  MultiSelectInput,
} from "./InputField/InputField";
import MetricTagInput from "./InputField/MetricTagInput";
import useGetAllModels from "../../hooks/useGetAllModels";
import {
  getAllApproverOfOrgnization,
  getAllReviewerOfOrgnization,
  getAllUserOfOrgnization,
} from "../../utils/helper";
import {
  ApproverUserSchemaObj,
  AssignedToUserSchemaObj,
  ReviewerUserSchemaObj,
  sectorSchema,
} from "../../constant/FormBuilder/FormBuilderConstant";
import useGetSectorName from "../../hooks/useGetSectorName";
import useGetDefaultOrg from "../../hooks/useGetDefaultOrg";
import FrameworFieldAccodian from "./FrameworFieldAccodian";
import {
  getJSonSchemaFromFieldInfoList,
  getLinkJsonReponse,
} from "../../utils/frombuilder.utils";

const initialFormData = {
  metricName: "",
  categoryName: "",
  className: "",
  description: "",
  frameWork: [],
  SectorText: "",
};

const getFieldInfoListFromGetJSonSchema = (List) => {
  const response = [];
  Object.keys(List).forEach((key) => {
    const moreInfo = {
      Reference: List[key].reference,
      Overview: List[key].overview,
      Guidance: List[key].guidance,
    };
    if (List[key].type === "relation") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        relatedTo: List[key].relatedto,
        metricName: List[key].metricname,
        isMultiSelect: List[key].ismultiselect,
        framework: List[key].framework,
        ...moreInfo,
      });
    } else if (List[key].type === "dropdown") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        values: List[key].values,
        isMultiSelect: List[key].ismultiselect,
        framework: List[key].framework,
        ...moreInfo,
      });
    } else if (List[key].type == "integer") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        min_val: List[key].min_val,
        max_val: List[key].max_val,
        framework: List[key].framework,
        ...moreInfo,
      });
    } else if (List[key].type == "slider") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        min_val: List[key].min_val,
        max_val: List[key].max_val,
        step_val: List[key].step_val,
        framework: List[key].framework,
        ...moreInfo,
      });
    } else if (List[key].type === "text" || List[key].type === "textarea") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        char_limit: List[key]?.char_limit || "",
        framework: List[key].framework,
        ...moreInfo,
      });
    } else if (List[key].type === "file") {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        acceptFieldType: List[key]?.acceptfieldtype || [],
        framework: List[key].framework,
        ...moreInfo,
      });
    } else {
      response.push({
        id: List[key].id,
        Title: key,
        Required: List[key].required,
        Type: List[key].type,
        name: FieldList[List[key].type].title,
        framework: List[key].framework,
        ...moreInfo,
      });
    }
  });

  return response;
};

const getLinkInfoListFromJson = (list) => {
  const response = [];

  list.forEach((item) => {
    response.push({
      id: item.id,
      Title: item.name,
      link: item.link,
      Type: "link",
      name: "link",
    });
  });
  return response;
};

const UIMetricBuilderValidation = (formData, fieldInfoList, requiredArr) => {
  const err = {};
  let isValid = true;

  if (fieldInfoList.length === 0) {
    err["BuildData"] = "Build Data should have atleast one field";
    isValid = false;
  }

  Object.keys(formData).map((key) => {
    if (requiredArr.includes(key) && formData[key] === "") {
      err[key] = `${key} is required`;
      isValid = false;
    }
  });
  return { isValid: isValid, error: err };
};

const EditAIFormBuilder = () => {
  const { allModels, setAllModels } = useGetAllModels();

  const [formData, setFormData] = useState(initialFormData);
  const [jsonData, setJsonData] = useState("");

  const { allSectorName } = useGetSectorName();
  const { OrgDetail: DefaultOrgDetail } = useGetDefaultOrg();

  const [sectionText, setSectorText] = useState("");
  const [frameWorkList, setFrameWorkList] = useState([]);
  const [frameWorkDetails, setFrameWorkDetails] = useState({});
  const [MetricNameList, setMetricNameList] = useState({});
  const [overviewList, setOverviewList] = useState({});
  const [guidanceList, setGuidanceList] = useState({});

  const requiredArr = ["metricName", "categoryName", "jsonSchema"];
  const [validationErrors, setValidationErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const [fieldType, setFieldType] = useState("");
  const [fieldInfoList, setFieldInfoList] = useState([]); // JsonSchema Input field info
  const [LinkInfoList, setLinkInfoList] = useState([]); // Link field info in Metric
  const [selectedFormulaList, setSelectedFormulaList] = useState([]);

  const [FormulaInfoList, setFormulaInfoList] = useState([]); // Formula field info in Metric

  // const [frequency, setFrequency] = useState("");

  const [frequency, setFrequency] = useState(FrequencyDataObj?.values[0]?.name);
  const [customFrequency, setCustomFrequency] = useState(0.01);

  const showCustomFrequencyInput = frequency === "Custom";

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged?.user?.id;
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const userRole = UserLogged && UserLogged.user.userRole;

  const navigate = useNavigate();

  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: isOpenLink,
    onOpen: onOpenLink,
    onOpenChange: onOpenChangeLink,
  } = useDisclosure();

  const {
    isOpen: isOpenFormula,
    onOpen: onOpenFormula,
    onOpenChange: onOpenChangeFormula,
  } = useDisclosure();

  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [tagsValidation, setTagsValidation] = useState("");

  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedApproverTo, setSelectedApproverTo] = useState([]);
  const [selectedReviewerTo, setSelectedReviewerTo] = useState([]);
  const [AllUserList, setAllUserList] = useState([]);
  const [AllApproverList, setAllApproverList] = useState([]);
  const [AllReviewerList, setAllReviewerList] = useState([]);

  const { _id, _orgId } = useParams();
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const fieldListValues = Object.keys(FieldList).map((key) => {
    return [key, FieldList[key]];
  });
  const changeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleJsonChange = (editor, data, value) => {
    setJsonData(value);
  };

  const showPreviewHandler = () => {
    setShowPreview(false);
    const validationResponse = UIMetricBuilderValidation(
      formData,
      fieldInfoList,
      requiredArr
    );

    setValidationErrors(validationResponse.error);
    if (!validationResponse.isValid) {
      return;
    }

    const JsonSchemaData = getJSonSchemaFromFieldInfoList(
      fieldInfoList,
      FormulaInfoList
    );

    const frameWorkResponse = {
      id: formData?.frameWork || [],
    };
    const publishData = {
      Name: formData.metricName,
      Category: formData.categoryName,
      ClassName: formData.className,
      Description: formData.description,
      Framework: frameWorkResponse,
      JsonSchema: JsonSchemaData,
      Links: getLinkJsonReponse(LinkInfoList),
      Frequency: frequency,
    };

    setPreviewData(publishData);
    setShowPreview(true);
  };
  const submitHandler = async () => {
    const validationResponse = UIMetricBuilderValidation(
      formData,
      fieldInfoList,
      requiredArr
    );

    if (!validationResponse.isValid) {
      setValidationErrors(validationResponse.error);
      return;
    }
    const JsonSchemaData = getJSonSchemaFromFieldInfoList(
      fieldInfoList,
      FormulaInfoList
    );
    const frameWorkResponse = {
      id: formData?.frameWork || [],
    };
    const AssignedToData = selectedAssignedTo?.map((id) => +id);
    const ApproverToData =
      formData?.ApprovalWorkFlow === "1-Step Approval" ||
      formData?.ApprovalWorkFlow === "2-Step Approval"
        ? selectedReviewerTo?.map((id) => +id)
        : [];
    const ReviewerToData =
      formData?.ApprovalWorkFlow === "2-Step Approval"
        ? selectedApproverTo?.map((id) => +id)
        : [];
    const publishData = {
      Name: formData?.metricName,
      Category: formData?.categoryName,
      ClassName: formData?.className,
      Description: formData?.description,
      Framework: frameWorkResponse,
      WorkFlow: formData?.ApprovalWorkFlow,
      JsonSchema: JsonSchemaData,
      Links: getLinkJsonReponse(LinkInfoList),
      Frequency: frequency,
      FrequencyValue:
        frequency === "Custom" ? +customFrequency : FrequecyCountMap[frequency],
      TagList: tags,
      AssignedTo: AssignedToData,
      ApproverTo: ApproverToData,
      ReviewerTo: ReviewerToData,
    };

    let result = await UpdateOneData("metrics", _id, publishData);

    const newTags = tags?.filter((name) => !allTags?.includes(name));
    const updatedTags = [...allTags, ...newTags];
    await UpdateSingleCollectionData("tag", { List: updatedTags });

    navigate(`/${_orgId}/metrics-builder`);
    window.location.reload();
  };

  const addFieldHandler = (listItem) => {
    setFieldInfoList([...fieldInfoList, listItem]);
  };
  const addLinkHandler = (listItem) => {
    setLinkInfoList([...LinkInfoList, listItem]);
  };
  const addFormulaHandler = (listItem) => {
    setFormulaInfoList([...FormulaInfoList, listItem]);
  };

  useEffect(() => {
    const filteredUrl = `${orgFilterUrl}&filters[Category][$eqi]=framework`;
    getDataWithFilterUrl("frameworks")
      .then((data) => {
        let frameWorkData = data.data;
        const f = {};
        frameWorkData = frameWorkData?.map((item) => {
          f[item.id] = item.attributes.Name;
          return { id: item.id, name: item?.attributes?.Name };
        });
        setFrameWorkDetails(f);
        setFrameWorkList(frameWorkData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const filteredUrl = `filters[id][$eqi]=${_id}`;
    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        let response = data.data[0];

        setFormData({
          metricName: response?.attributes?.Name || "",
          categoryName: response?.attributes?.Category || "",
          className: response?.attributes?.ClassName || "",
          description: response?.attributes?.Description || "",
          frameWork: response?.attributes?.Framework?.id || [],
          ApprovalWorkFlow: response?.attributes?.WorkFlow,
          SectorText: response?.attributes?.Sector || "",
          formulaeAIText:
            response?.attributes?.JsonSchema?.formulaeAIText || "",
        });
        const AssignedToData = response?.attributes?.AssignedTo?.data?.map(
          (user) => user?.id?.toString()
        );
        const ApproverToData = response?.attributes?.ApproverTo?.data?.map(
          (user) => user?.id?.toString()
        );
        const ReviewerToData = response?.attributes?.ReviewerTo?.data?.map(
          (user) => user?.id?.toString()
        );
        setSelectedAssignedTo(AssignedToData);
        setSelectedApproverTo(ApproverToData);
        setSelectedReviewerTo(ReviewerToData);

        const frequencyNumber = +response?.attributes?.FrequencyValue;
        const frequencyval =
          frequencyNumber === -1
            ? "Custom"
            : frequencyNumber === 30
            ? "Every month"
            : frequencyNumber === 7
            ? "Every week"
            : frequencyNumber === 1
            ? "Everyday"
            : "Only Once";

        setFrequency(frequencyval);

        getFieldInfoListFromGetJSonSchema(
          response?.attributes?.JsonSchema?.properties
        );
        setFieldInfoList(
          getFieldInfoListFromGetJSonSchema(
            response?.attributes?.JsonSchema?.properties
          )
        );
        setLinkInfoList(
          getLinkInfoListFromJson(response?.attributes?.Links?.Links)
        );

        setFormulaInfoList(response?.attributes?.JsonSchema?.formula || []);
        setTags(response?.attributes?.TagList || []);
        let formulaList = response?.attributes?.JsonSchema?.formula || [];
        formulaList = formulaList.map((data) => data?.id?.toString());
        setSelectedFormulaList(formulaList);
        setOverviewList(response?.attributes?.Overview || {});
        setGuidanceList(response?.attributes?.Guidance || {});
        setMetricNameList(response?.attributes?.NameList || {});
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getSingleCollectionData("tag")
      .then((data) => {
        setAllTags(data?.data?.attributes?.List);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fun = async () => {
      let allUser = await getAllUserOfOrgnization();
      let allReviewer = await getAllReviewerOfOrgnization();
      let allApprover = await getAllApproverOfOrgnization();

      allUser = allUser?.map((user) => {
        return { id: user.id, name: user.username };
      });
      allReviewer = allReviewer?.map((user) => {
        return { id: user.id, name: user.username };
      });
      allApprover = allApprover?.map((user) => {
        return { id: user.id, name: user.username };
      });

      setAllUserList(allUser);
      setAllApproverList(allApprover);
      setAllReviewerList(allReviewer);
    };
    fun();
  }, []);

  return (
    <div className="flex items-start justify-between my-2">
      <div className="flex flex-col items-center w-[50%]">
        {/* Metric Name Input  */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          isRequired
          label="Metric Name"
          labelPlacement="outside"
          placeholder=" "
          name="metricName"
          value={formData["metricName"]}
          onChange={changeHandler}
          isInvalid={validationErrors["metricName"]}
          errorMessage={
            validationErrors["metricName"] && validationErrors["metricName"]
          }
        />

        {/* Category DropDown */}

        <div className="flex flex-col gap-2 text-sm w-full">
          <p>
            Category <span style={{ color: "red" }}>*</span>
          </p>
          {validationErrors["categoryName"] && (
            <p className="text-[#f76497]">{validationErrors["categoryName"]}</p>
          )}
          <Dropdown>
            <DropdownTrigger
              isInvalid={validationErrors["categoryName"]}
              errorMessage={
                validationErrors["categoryName"] &&
                validationErrors["categoryName"]
              }
              className="w-full"
            >
              <Button className="mb-8" variant="faded">
                <div className="w-full flex justify-between">
                  <div className="flex justify-start w-full items-start">
                    {formData.categoryName === "" ? "" : formData.categoryName}
                  </div>
                  <div className="flex justify-end w-full items-end">
                    <FaChevronDown />
                  </div>
                </div>
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Static Actions"
              className="w-144"
              selectionMode="single"
              name="categoryName"
              onSelectionChange={(event) => {
                setFormData({
                  ...formData,
                  categoryName: Array.from(event)
                    .join(", ")
                    .replaceAll("_", " "),
                });
              }}
            >
              {CategoryList?.map((item) => (
                <DropdownItem key={item.name}>{item.name}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>

        {/* ClassName Input */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          label="Class Name"
          value={formData["className"]}
          placeholder=" "
          labelPlacement="outside"
          name="className"
          onChange={changeHandler}
        />

        {/* Description Input  */}
        <Textarea
          label="Description"
          labelPlacement="outside"
          variant="faded"
          value={formData["description"]}
          className="my-4"
          name="description"
          onChange={changeHandler}
        />

        <Textarea
          className="my-4"
          variant="faded"
          label={`General Overview`}
          labelPlacement="outside"
          placeholder=" "
          value={overviewList["Generic"]}
          onChange={(event) =>
            setOverviewList({
              ...overviewList,
              Generic: event.target.value,
            })
          }
        />
        <Textarea
          className="my-4"
          variant="faded"
          label={`General Guidance`}
          labelPlacement="outside"
          placeholder=" "
          value={guidanceList["Generic"]}
          onChange={(event) =>
            setGuidanceList({
              ...guidanceList,
              Generic: event.target.value,
            })
          }
        />
        {DefaultOrgDetail?.id === OrgInfo && (
          <DropDownInput
            data={{
              ...sectorSchema,
              values: allSectorName,
            }}
            value={formData.SectorText}
            validationErrors={validationErrors}
            onChangeHandler={(title, value, type) =>
              setFormData({ ...formData, SectorText: value })
            }
            buttonCSS="mb-4"
          />
        )}
        {/* FrameWork Input */}

        <Select
          label="Select Framework"
          placeholder="Select any Framework"
          variant="faded"
          selectionMode="multiple"
          labelPlacement="outside"
          className="my-8"
          selectedKeys={new Set(formData["frameWork"])}
          onSelectionChange={(event) => {
            setFormData({
              ...formData,
              frameWork: Array.from(event),
            });
          }}
        >
          {frameWorkList?.map((item) => (
            <SelectItem key={item.id} value={item.name} className="py-2">
              {item.name}
            </SelectItem>
          ))}
        </Select>

        {formData?.frameWork &&
          formData?.frameWork?.map((id) => {
            return (
              <FrameworFieldAccodian
                title={frameWorkDetails[+id]}
                setMetricNameList={setMetricNameList}
                MetricNameList={MetricNameList}
                setGuidanceList={setGuidanceList}
                guidanceList={guidanceList}
                setOverviewList={setOverviewList}
                overviewList={overviewList}
              />
            );
          })}

        {/* AssignedTo User DropDown */}
        <MultiSelectInput
          data={AssignedToUserSchemaObj}
          value={selectedAssignedTo}
          valuesList={AllUserList}
          onChangeHandler={(title, value, type) => setSelectedAssignedTo(value)}
        />

        {/* Approval Workfflow DropDown */}

        <DropDownInput
          buttonCSS={"mb-8"}
          data={ApprovalWorkFlowDataSchemaObj}
          value={formData?.ApprovalWorkFlow || ""}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) =>
            setFormData({
              ...formData,
              ApprovalWorkFlow: value,
            })
          }
        />

        {/* Approver User DropDown */}
        {(formData?.ApprovalWorkFlow === "1-Step Approval" ||
          formData?.ApprovalWorkFlow === "2-Step Approval") && (
          <MultiSelectInput
            data={ApproverUserSchemaObj}
            value={selectedApproverTo}
            valuesList={AllApproverList}
            onChangeHandler={(title, value, type) =>
              setSelectedApproverTo(value)
            }
          />
        )}

        {/* Reviewer User DropDown */}
        {formData?.ApprovalWorkFlow === "2-Step Approval" && (
          <MultiSelectInput
            data={ReviewerUserSchemaObj}
            value={selectedReviewerTo}
            valuesList={AllReviewerList}
            onChangeHandler={(title, value, type) =>
              setSelectedReviewerTo(value)
            }
          />
        )}

        <DropDownInput
          buttonCSS={"mb-8"}
          data={FrequencyDataObj}
          value={frequency}
          validationErrors={1}
          onChangeHandler={(title, value, type) => setFrequency(value)}
        />

        {showCustomFrequencyInput && (
          <CustomFrequencyInput
            value={customFrequency}
            onChangeHandler={(value) => setCustomFrequency(value)}
          />
        )}

        <MetricTagInput
          tags={tags}
          setTags={setTags}
          IntialvalidationError={tagsValidation}
          allTags={allTags}
        />

        {/* Formula Input */}

        <Textarea
          className="my-4"
          variant="faded"
          label={`Formulae`}
          labelPlacement="outside"
          placeholder=" Describe the Formulae that you want to be added."
          name="formulaeAIText"
          onChange={changeHandler}
          value={formData["formulaeAIText"]}
          //   value={}
        />

        {/* <Select
            label="Select Formules"
            placeholder="Choose any Formules"
            variant="faded"
            selectionMode="multiple"
            labelPlacement="outside"
            className="my-8"
            selectedKeys={new Set(selectedFormulaList)}
            onSelectionChange={(event) => {
              setSelectedFormulaList(Array.from(event));
            }}
          >
            {allModels?.map((item) => (
              <SelectItem key={item.id} value={item.id} className="py-2">
                {item.title}
              </SelectItem>
            ))}
          </Select> */}

        {/* JsonSchema Input */}

        <div className="w-full min-h-96">
          <div className="flex items-center gap-4">
            <p className="text-lg font-medium">Build your Metric Schema</p>
          </div>
          {validationErrors["BuildData"] && (
            <p className="text-[#f76497]">{validationErrors["BuildData"]}</p>
          )}
          <p className="my-4 text-sm">
            Choose a data type and begin building your metric forms.
          </p>

          <div className="flex justify-end my-4 mr-4">
            <Dropdown>
              <DropdownTrigger>
                <Button color="secondary" size="sm">
                  Add a new field
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Static Actions"
                selectionMode="single"
                onSelectionChange={(event) => {
                  setFieldType(
                    Array.from(event).join(", ").replaceAll("_", " ")
                  );
                  onOpen();
                }}
              >
                {fieldListValues.map(
                  (row) =>
                    row[1].name !== "linkUrl" && (
                      <DropdownItem key={row[1].name} name={row[1].name}>
                        {row[1].title}
                      </DropdownItem>
                    )
                )}
              </DropdownMenu>
            </Dropdown>
          </div>

          {isOpen && (
            <AddModal
              onSubmitHandler={addFieldHandler}
              // id={deleteMetricId}
              modalHeading={"Are you sure you want to delete this Entry?"}
              isOpen={isOpen}
              onOpen={onOpen}
              onOpenChange={onOpenChange}
              fieldType={fieldType}
              id={Date.now()}
              frameWorkDetails={frameWorkDetails}
              frameWorkList={frameWorkList}
            />
          )}

          <FieldInfoTable
            fieldInfoList={fieldInfoList}
            setFieldInfoList={setFieldInfoList}
            frameWorkDetails={frameWorkDetails}
            frameWorkList={frameWorkList}
            category={formData.categoryName}
          />
        </div>

        {/* Links Input */}

        <div className="w-full min-h-96">
          <div className="flex items-center gap-4">
            <p className="text-lg font-medium">Attach your reference links</p>
          </div>
          {validationErrors["BuildData"] && (
            <p className="text-[#f76497]">{validationErrors["BuildData"]}</p>
          )}
          <p className="my-4 text-sm">
            Attach reference links for users to fill your metrics easier
          </p>

          <div className="flex justify-end my-4 mr-4">
            <Button color="secondary" size="sm" onClick={() => onOpenLink()}>
              Add a new link
            </Button>
          </div>

          {isOpenLink && (
            <AddModal
              onSubmitHandler={addLinkHandler}
              // id={deleteMetricId}
              modalHeading={"Are you sure you want to delete this Entry?"}
              isOpen={isOpenLink}
              onOpen={onOpenLink}
              onOpenChange={onOpenChangeLink}
              fieldType="link"
              id={Date.now()}
            />
          )}

          <FieldInfoTable
            fieldInfoList={LinkInfoList}
            setFieldInfoList={setLinkInfoList}
            headersList={["Name", "Link", ""]}
          />
        </div>

        {/* Formula Input */}

        <div className="w-full min-h-96">
          <div className="flex items-center gap-4">
            <p className="text-lg font-medium">Design your Formula</p>
          </div>
          {validationErrors["BuildData"] && (
            <p className="text-[#f76497]">{validationErrors["BuildData"]}</p>
          )}
          <p className="my-4 text-sm">
            Design your formula to handle mathematical calculations like
            emissions, diversity and many more.
          </p>

          <div className="flex justify-end my-4 mr-4">
            <Button color="secondary" size="sm" onClick={() => onOpenFormula()}>
              Add a new formula
            </Button>
          </div>

          {isOpenFormula && (
            <AddFormulaModal
              onSubmitHandler={addFormulaHandler}
              // id={deleteMetricId}
              modalHeading={"Are you sure you want to delete this Entry?"}
              isOpen={isOpenFormula}
              onOpen={onOpenFormula}
              onOpenChange={onOpenChangeFormula}
              fieldType="formula"
              id={Date.now()}
            />
          )}

          <FormulaInfoTable
            fieldInfoList={FormulaInfoList}
            setFieldInfoList={setFormulaInfoList}
          />
        </div>

        {/* Preview,Submit button */}
        <div className="flex gap-4 my-4 w-full">
          <Button
            color="danger"
            variant="light"
            size="sm"
            onClick={showPreviewHandler}
          >
            Preview
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={submitHandler}
            className={`cursor-pointer ${
              userRole !== "Admin" ? "pointer-events-none opacity-50" : ""
            }`}
          >
            Submit
          </Button>
        </div>
      </div>

      {/* Preview Card Open */}
      {showPreview && (
        <div className="flex flex-col items-center w-[48%]">
          <PreviewForm data={previewData} />
        </div>
      )}
    </div>
  );
};

export default EditAIFormBuilder;
