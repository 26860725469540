import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";

import logo from "../../Images/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  capitalize,
  getOnedata,
  updateUserLastOrg,
} from "../../controllers/APIController";

const OrgPopUp = ({ List, currOrg,isCollapsed }) => {
  const [allOrgInfo, setAllOrgInfo] = useState([]);
  const navigate = useNavigate();
  const handleSelectionChange = async (orgURL) => {
    let orgId = null;
    allOrgInfo?.forEach((ele) => {
      if (ele?.URL === orgURL) {
        orgId = ele.id;
      }
    });

    await updateUserLastOrg(UserId, orgURL, orgId);

    navigate(`/${orgURL}/dashboard`);
  };

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;

  useEffect(() => {
    getOnedata("users", UserId)
      .then((data) => {
        setAllOrgInfo(data?.OrganizationLists);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [UserId]);

  return (
    <>
      <Dropdown>
        <DropdownTrigger className="w-full">
          <div className="flex items-center pt-4 gap-2 cursor-pointer">
            <Image width={30} alt="NextUI hero Image" src={logo} />
            {!isCollapsed && <p className="font-bold">Audyt</p>}
          </div>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Static Actions"
          className="custom-dropdown-menu"
          disabledKeys={["top", currOrg]}
          disallowEmptySelection
          selectedKeys={[currOrg]}
          defaultSelectedKeys={[currOrg]}
          // onSelectionChange={(event) => {}}
        >
          <DropdownSection className="w-full" aria-label="bottom-section">
            <DropdownItem key="top" className="w-full" textValue={"top"}>
              <span className="text-sm"> Switch to...</span>
            </DropdownItem>
          </DropdownSection>
          <DropdownSection
            className="max-h-28 overflow-auto hide-scrollbar"
            aria-label="mid-section"
          >
            {List?.map((item) => (
              <DropdownItem
                key={item}
                value={item}
                textValue={item}
                endContent={
                  currOrg === item && <FontAwesomeIcon icon={faCheck} />
                }
                onPress={() => handleSelectionChange(item)}
              >
                {capitalize(item)}
              </DropdownItem>
            ))}
          </DropdownSection>

          <DropdownSection className="p-0" aria-label="bottom-section">
            <DropdownItem
              key="bottom"
              className="p-0"
              textValue="bottom-section-button"
            >
              <Button
                size="sm"
                className="bg-black w-full text-white text-center cursor-pointer"
                onPress={() => navigate("/org-list")}
              >
                View All Orgnization
              </Button>
            </DropdownItem>
          </DropdownSection>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default OrgPopUp;
