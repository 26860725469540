import { Image } from "@nextui-org/react";
import React from "react";

const ServerIssue = ({
  message = "This page does not exist. It may be issue with server.",
}) => {
  return (
    <div className="flex justify-center bg-slate-50 items-center z-50 absolute top-0 left-0 right-0 h-screen w-full">
      <div>
        <Image
          width={80}
          radius="sm"
          alt="NextUI hero Image"
          src="/Assets/logo.svg"
        />
        <h1 className="mb-1.5 text-md mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold ">
          500: INTERNAL SERVER ERROR
        </h1>
        <p className="mt-8 text-sm">
          Internal server issue. Backend is not connected.
        </p>
      </div>
    </div>
  );
};

export default ServerIssue;
