import React from "react";
import { Card, CardBody } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
const ComingSoon = ({ height = "800px" }) => {
  return (
    <div>
      <div>
        <div className="mt-8">
          <Card
            className="max-w-1/2 h-[800px] border border-divider"
            shadow="none"
            style={{
              height: height,
            }}
          >
            <CardBody>
              <Card
                isBlurred
                shadow="none"
                className="border z-10 blur-sm border-divider flex h-full"
              >
                {/* Knowledge Tree */}
              </Card>
              <div className="inset-x-0 inset-y-0 absolute flex flex-col justify-center z-[100]  items-center">
                <FontAwesomeIcon icon={faLock} />
                <p>Coming Soon</p>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
