import { Button, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";

import { getDataWithFilterUrl } from "../../controllers/APIController";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";

import { useParams } from "react-router-dom";
import LoadingPage from "../../components/Loader/LoadingPage";
import CardSkeletonContainer from "../../components/Skeleten/CardSkeletonContainer";
import PolicyCard from "../../components/Policies/PolicyCard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocument } from "./Documenttest";

const Policies = () => {
  const [domains, setDomains] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reportDataFetched, setReportDataFetched] = useState(false);
  const [proceduresDetails, setProceduresDetails] = useState([]);

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const { _orgId: currOrg } = useParams();

  useEffect(() => {
    getDataWithFilterUrl("domains")
      .then((data) => {
        setDomains(data.data);
        getDataWithFilterUrl("policies", orgFilterUrl)
          .then((data) => {
            setPolicies(data.data);
            setIsLoading(false);
            getDataWithFilterUrl("procedures", orgFilterUrl).then((data) => {
              setProceduresDetails(data?.data || []);
              setReportDataFetched(true);
            });
          })
          .catch((error) => console.error("Error fetching policies:", error));
      })
      .catch((error) => console.error("Error fetching domains:", error));
  }, [orgFilterUrl]);

  return (
    <BreadCrumbsPage>
      <div>
        <div className="flex items-center justify-between">
          <p className="text-3xl font-medium">ESG Policies</p>
          {reportDataFetched && (
            <div>
              <Button
                variant="light"
                className="border border-divider"
                // onClick={() => navigate("print-report")}
              >
                <PDFDownloadLink
                  document={
                    <MyDocument
                      policies={policies}
                      procedures={proceduresDetails}
                    />
                  }
                  fileName="sustainability-policy-report.pdf"
                >
                  Download Policy Report
                </PDFDownloadLink>
              </Button>
            </div>
          )}
        </div>
        <div className="mt-4">
          Our ESG software empowers organizations to implement and manage
          sustainable practices effectively. By ensuring compliance with
          regulations, our platform enhances your ability to make a positive
          impact on society and the environment. We provide tools to foster a
          culture of accountability and transparency, enabling continuous
          improvement in processes that reduce environmental footprints and
          promote social equity.
        </div>

        <div className="mt-8">
          <LoadingPage
            isLoading={isLoading}
            alterElement={<CardSkeletonContainer />}
          >
            <Tabs variant="light">
              {domains &&
                domains.map((domain) => (
                  <Tab key={domain.id} title={domain.attributes.Name}>
                    <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                      {policies &&
                        policies
                          .filter(
                            (policy) =>
                              policy?.attributes?.DomainId?.data?.attributes
                                ?.Name === domain.attributes.Name
                          )
                          .map((filteredPolicy) => (
                            <PolicyCard
                              domain={domain}
                              filteredPolicy={filteredPolicy}
                              currOrg={currOrg}
                            />
                          ))}
                    </div>
                  </Tab>
                ))}
            </Tabs>
          </LoadingPage>
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default Policies;
