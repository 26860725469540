import { Link, useNavigate, useParams } from "react-router-dom";
import {
  PublishData,
  capitalize,
  getDataWithFilterUrl,
  separateDasFromUrl,
  updateFormSchemaWithRealtion,
} from "../../controllers/APIController";
import { Accordion, AccordionItem, Button } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { getFormulaData, handleCopyClick } from "../../utils/helper";
import {
  BooleanInput,
  DateInput,
  DropDownInput,
  FileInput,
  IntegerInput,
  PasswordInput,
  RelationDropDownInput,
  SliderInput,
  TextAreaInput,
  TextInput,
} from "../FormBuilder/InputField/InputField";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import LoadingPage from "../../components/Loader/LoadingPage";

const getInitailState = (arr) => {
  const data = {};

  arr?.forEach((item) => {
    if (item[1].type === "boolean") {
      data[item[0]] = "false";
    }
    if (item[1].type === "slider") {
      data[item[0]] = item[1].value || 0;
    } else data[item[0]] = "";
  });
  return data;
};

const CreateReferenceData = () => {
  const [loader, setLoader] = useState(true);
  const [CollectionInfo, setCollectionInfo] = useState();
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [links, setLinks] = useState([]);
  const navigate = useNavigate();
  const { _pathId, _orgId: currOrg } = useParams();

  const isFormulaVisiable =
    CollectionInfo?.attributes?.JsonSchema?.formula?.length > 0 ? true : false;

  const _categoryId = "reference-data";

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  let HeadingTitle = capitalize(separateDasFromUrl(_pathId));
  let description = CollectionInfo?.attributes?.Description;
  const requiredArr = CollectionInfo?.attributes?.JsonSchema?.required;

  const filteredUrl =
    "filters[Category][$eqi]=" +
    _categoryId.replaceAll("-", " ") +
    "&filters[Name][$eqi]=" +
    _pathId.replaceAll("-", " ");

  const changeHandler = async (title, value, type) => {
    if (type === "file") {
      let formData = new FormData();
      formData.append("files", value);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();

        setFormData({
          ...formdata,
          [title]: { value: data[0].id, url: data[0].url },
        });
      } catch (error) {
        console.error("Upload error!", error);
      }
    } else if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };

  const curlMessage = `curl --location '${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send' \n
  --header 'Content-Type: application/json' \n
  --header 'apikey: <Enter Your API Key>' \n
  --data '{
      "metric": "${HeadingTitle}",
      "response": <Enter Your JSON Response>
      
  }'`;
  const ValidationForm = () => {
    var numberRegex = /^[0-9]*$/;
    let isValid = true;
    const errors = {};

    formSchema.forEach((row) => {
      // [fieldName, fieldProps, fieldId, fieldValues]
      const fieldType = row[1].type;
      const fieldValue = formdata[row[0]];
      const isRequired = requiredArr.includes(row[0]);
      const fieldName = row[0];
      // You can add more validation rules based on the field type

      if (fieldType === "text" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }

      if (fieldType === "string" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "date" && isRequired) {
        if (fieldValue === "" || fieldValue === "NaN/NaN/NaN") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "dropdown" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }
      if (fieldType === "enumeration" && isRequired) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required`;
          isValid = false;
        }
      }

      if (
        isRequired &&
        (fieldType === "integer" ||
          fieldType === "biginteger" ||
          fieldType === "decimal" ||
          fieldType === "float")
      ) {
        if (!numberRegex.test(fieldValue)) {
          errors[fieldName] = `${fieldName} accept only numbers`;
          isValid = false;
        } else if (typeof fieldValue === "string") {
          errors[fieldName] = `${fieldName} can't be empty`;
          isValid = false;
        }
      }

      if (isRequired && (fieldType === "media" || fieldType === "file")) {
        if (fieldValue === "") {
          errors[fieldName] = `Upload a file `;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "relation") {
        if (fieldValue === "") {
          errors[fieldName] = `Select the ${fieldName} ralated`;
          isValid = false;
        }
      }

      if (isRequired && (fieldType === "datetime" || fieldType === "date")) {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required.`;
          isValid = false;
        }
      }

      if (isRequired && fieldType === "richtext") {
        if (fieldValue === "") {
          errors[fieldName] = `${fieldName} is required `;
          isValid = false;
        }
      }
    });

    setValidationErrors(errors);
    return isValid;
  };

  const submitHandler = async () => {
    const isValid = ValidationForm();
    if (!isValid) {
      return;
    }
    Object.keys(formdata).forEach((key) => {
      if (formdata[key] === "") {
        formdata[key] = "null";
      }
    });

    const formulaData = await getFormulaData(
      CollectionInfo?.attributes?.JsonSchema?.formula,
      formdata
    );

    const publishData = {
      Metric_Id: CollectionInfo.attributes.Name,
      Status:
        CollectionInfo?.attributes?.WorkFlow === null ||
        CollectionInfo?.attributes?.WorkFlow === "" ||
        CollectionInfo?.attributes?.WorkFlow === "Default"
          ? "Completed"
          : "Created",
      JsonSchema: { ...formdata, ...formulaData },
      Organization: OrgInfo,
      users_permissions_user: {
        disconnect: [],
        connect: [
          {
            id: UserId,
            position: {
              end: true,
            },
          },
        ],
      },
    };
    let result = await PublishData({ data: publishData }, "responses");

    navigate(`/${currOrg}/${_categoryId}/${_pathId}`);
  };

  const testCalculationHandler = async () => {
    const isValid = ValidationForm();
    if (!isValid) {
      return;
    }
    Object.keys(formdata).forEach((key) => {
      if (formdata[key] === "") {
        formdata[key] = "null";
      }
    });

    const formulaData = await getFormulaData(
      CollectionInfo?.attributes?.JsonSchema?.formula,
      formdata
    );
  };

  const clickHandler = (metricName, relatedTo) => {
    let metric = metricName.toLowerCase().replaceAll(" ", "-");
    let relatedToName = relatedTo.toLowerCase().replaceAll(" ", "-");
    window.open(`/${currOrg}/metrics/${metric}/${relatedToName}/create`);
    // navigate(`/${currOrg}/metrics/${metric}/${relatedToName}/create`);
    // window.location.reload();
  };

  useEffect(() => {
    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;
        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);
        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });
        setCollectionInfo(data.data[0]);
        setformSchema(responseArr);
        setLinks(data?.data[0]?.attributes?.Links?.Links || []);
        setFormData(getInitailState(responseArr));
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={loader}>
        <>
          <div className="metric-header">
            <p className="text-3xl font-medium">{HeadingTitle}</p>
            <p className="mt-8">{description}</p>
          </div>
          <div className="flex justify-end my-4 gap-4">
            {isFormulaVisiable && (
              <Button
                color="secondary"
                variant="ghost"
                size="md"
                onClick={testCalculationHandler}
              >
                Test Calculation
              </Button>
            )}
            <Button
              color="danger"
              variant="ghost"
              size="md"
              onClick={submitHandler}
            >
              Submit
            </Button>
          </div>
          <div className="flex gap-[160px]">
            <div className="w-[50%]">
              {formSchema &&
                formSchema.map((row, index) => {
                  const fieldError = validationErrors[row[0]];
                  const isRequired = requiredArr.includes(row[0]);
                  const uniqueId = Date.now().toString + index;
                  
                  if (row[1].type === "string" || row[1].type === "text") {
                    return (
                      <TextInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "password") {
                    return (
                      <PasswordInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "integer") {
                    return (
                      <IntegerInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "dropdown") {
                    return (
                      <DropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "slider") {
                    return (
                      <SliderInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "boolean") {
                    return (
                      <BooleanInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "textarea") {
                    return (
                      <TextAreaInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "date") {
                    return (
                      <DateInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "file") {
                    return (
                      <FileInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        inputfiledId={uniqueId}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "relation") {
                    updateFormSchemaWithRealtion(
                      formSchema,
                      index,
                      setformSchema
                    );
                    return (
                      <RelationDropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                        onClickHandler={clickHandler}
                      />
                    );
                  }
                  return null;
                })}
            </div>
            <div className="w-[45%]">
              <div className="flex flex-col relative w-full">
                <div className={`w-full ${links?.length === 0 && "hidden"}`}>
                  <div className="text-lg font-semibold">
                    Helpful Background Reading:
                  </div>
                  <div>
                    <div className="link-list my-2 text-blue-600 font-semibold">
                      {links?.map((link, index) => (
                        <div className="links">
                          <Link
                            key={index}
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.name}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <Accordion size="lg" isflust={true}>
                    <AccordionItem
                      title="Webhook API"
                      className="curl-accordion-item"
                    >
                      <p style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                        Integrate our form apis to your workflow
                      </p>

                      <div className="relative rounded-xl bg-black my-2">
                        <pre className="p-2 text-[#899fef]">{curlMessage}</pre>
                        <MdOutlineContentCopy
                          className="absolute top-0 right-0 m-4 text-white cursor-pointer"
                          onClick={() => handleCopyClick(curlMessage)}
                        />
                      </div>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
              {isFormulaVisiable && (
                <div className="w-[40%] bg-grey-400 rounded-sm p-2 h-max">
                  Test Calculation
                </div>
              )}
            </div>
          </div>
        </>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default CreateReferenceData;
