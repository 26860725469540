import { Button } from "@nextui-org/react";
import React, { useRef } from "react";
import { PublishData, uploadFile } from "../../controllers/APIController";

const ImportDataButton = () => {
  const fileInputRef = useRef(null);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const SubmitHandler = async (value) => {
    let fileData = new FormData();
    fileData.append("files", value);

    try {
      const fileResponse = await uploadFile(fileData);

      const publishData = {
        URL: fileResponse[0]?.url,
        organization: OrgInfo,
      };

      await PublishData({ data: publishData }, "imports");
    } catch (error) {
      console.error("Upload error!", error);
    }
  };

  return (
    <>
      <label htmlFor="fileui" className="flex gap-2 w-max">
        <Button
          color="danger"
          size="md"
          onClick={() => fileInputRef.current.click()}
        >
          Import Data
        </Button>
      </label>

      <input
        type="file"
        id="fileui"
        accept={
          "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
        className="mb-16 hidden"
        ref={fileInputRef}
        onChange={(event) => SubmitHandler(event?.target?.files[0])}
      />
    </>
  );
};

export default ImportDataButton;
