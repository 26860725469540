import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import { Tab, Tabs } from "@nextui-org/react";

import useGetOrgFramework from "../../hooks/useGetOrgFramework";
import SingleFrameworkTable from "./SingleFrameworkTable";
import LoadingPage from "../../components/Loader/LoadingPage";
import useGetDefaultOrg from "../../hooks/useGetDefaultOrg";

const BrowseFramework = () => {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const { OrgDetail: DefaultOrgDetail } = useGetDefaultOrg();
  const { orgFrameworkDetails: frameworkList, isLoading } =
    useGetOrgFramework(OrgInfo);

  const Navigate = useNavigate();
  const { _orgId: currOrg } = useParams();

  const orgFilterUrl = "filters[organizations][id][$eqi]=" + OrgInfo;

  const handleCreateNavigate = () => {
    Navigate(`/${currOrg}/browse-framework/create`);
  };

  return (
    <BreadCrumbsPage>
      <div>
        <div className="flex items-center justify-between text-3xl font-medium">
          <span>Browse Frameworks</span>
          {/* {DefaultOrgDetail?.id === OrgInfo && (
            <Button color="secondary" size="sm" onClick={handleCreateNavigate}>
              + Create your own framework
            </Button>
          )} */}
        </div>
        <div className="mt-8">
          Browse ESG frameworks or create your own framework for ESG reporting.
        </div>
      </div>
      <div className="mt-4">
        <LoadingPage isLoading={isLoading}>
          <Tabs variant="light">
            {frameworkList?.map((item, index) => {
              return (
                <Tab key={index} title={item?.attributes?.Name || "Others"}>
                  <SingleFrameworkTable
                    data={item}
                    frameworkName={item?.attributes?.Name}
                  />
                </Tab>
              );
            })}
          </Tabs>
        </LoadingPage>
      </div>
    </BreadCrumbsPage>
  );
};

export default BrowseFramework;
