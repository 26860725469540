import {
  Button,
  Card,
  CardBody,
  Input,
  Select,
  SelectItem,
  Textarea,
  Tooltip,
} from "@nextui-org/react";
import { Controlled as CodeMirror } from "react-codemirror2";

import React, { useEffect, useState } from "react";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/javascript-hint";

import {
  getAllApproverOfOrgnization,
  getAllReviewerOfOrgnization,
  getAllUserOfOrgnization,
  isJsonString,
} from "../../utils/helper";
import {
  getSingleCollectionData,
  PublishData,
  UpdateSingleCollectionData,
} from "../../controllers/APIController";
import {
  ApprovalWorkFlowDataSchemaObj,
  FrequecyCountMap,
  FrequencyDataObj,
  JSonResponseInfoContent,
  CategoryDataSchemaObj,
} from "../../utils/constant";

import {
  ApproverUserSchemaObj,
  AssignedToUserSchemaObj,
  ReviewerUserSchemaObj,
  sectorSchema,
} from "../../constant/FormBuilder/FormBuilderConstant";

import PreviewForm from "./PreviewForm";
import { getDataWithFilterUrl } from "../../controllers/APIController";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFrequencyInput,
  DropDownInput,
  MultiSelectInput,
} from "./InputField/InputField";
import MetricTagInput from "./InputField/MetricTagInput";
import useGetAllModels from "../../hooks/useGetAllModels";

import useGetSectorName from "../../hooks/useGetSectorName";
import useGetDefaultOrg from "../../hooks/useGetDefaultOrg";
import FrameworFieldAccodian from "./FrameworFieldAccodian";

const initialFormData = {
  metricName: "",
  Category: "",
  className: "",
  description: "",
  frameWork: [],
  ApprovalWorkFlow: "Default",
  SectorText: "",
};

const MetricBuilderValidation = (
  formData,
  jsonData,
  linkData,
  requiredArr,
  allMetricName
) => {
  const err = {};
  let isValid = true;

  if (!isJsonString(jsonData)) {
    err["JsonSchema"] = "Invalid JsonSchema";
    isValid = false;
  }
  if (!isJsonString(linkData)) {
    err["linkData"] = "Invalid Link Data";
    isValid = false;
  }
  if (allMetricName?.includes(formData["metricName"]?.trim()?.toLowerCase())) {
    err["metricName"] = "Metric Name already have been used";
    isValid = false;
  }

  Object.keys(formData).forEach((key) => {
    if (requiredArr.includes(key) && formData[key] === "") {
      err[key] = `${key} is required`;
      isValid = false;
    }
  });
  return { isValid: isValid, error: err };
};

const JSonResponseInfoContentComp = () => {
  return (
    <pre className="text-xs font-semibold ">
      {JSON.stringify(JSonResponseInfoContent, null, 2)}
    </pre>
  );
};

const JsonFormBuilder = ({ metricInfo }) => {
  const navigator = useNavigate();
  const { _orgId } = useParams();
  const [loader, setLoader] = useState(true);
  const { allModels, setAllModels } = useGetAllModels();

  const [formData, setFormData] = useState(initialFormData);
  const [jsonData, setJsonData] = useState("");
  const [linkData, setLinkData] = useState("");

  const { allSectorName } = useGetSectorName();
  const { OrgDetail: DefaultOrgDetail } = useGetDefaultOrg();
  const [allMetricName, setAllMetricName] = useState([]);
  const [frameWorkList, setFrameWorkList] = useState([]);
  const [frameWorkDetails, setFrameWorkDetails] = useState({});
  const [MetricNameList, setMetricNameList] = useState({});
  const [overviewList, setOverviewList] = useState({});
  const [guidanceList, setGuidanceList] = useState({});

  const [frequency, setFrequency] = useState("");
  const [customFrequency, setCustomFrequency] = useState(0.01);
  const showCustomFrequencyInput = frequency === "Custom";

  const requiredArr = ["metricName", "categoryName", "jsonSchema"];
  const [validationErrors, setValidationErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const [selectedFormulaList, setSelectedFormulaList] = useState([]);

  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedApproverTo, setSelectedApproverTo] = useState([]);
  const [selectedReviewerTo, setSelectedReviewerTo] = useState([]);
  const [AllUserList, setAllUserList] = useState([]);
  const [AllApproverList, setAllApproverList] = useState([]);
  const [AllReviewerList, setAllReviewerList] = useState([]);

  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const [tagsValidation, setTagsValidation] = useState("");

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const changeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleJsonChange = (editor, data, value) => {
    setJsonData(value);
  };
  const handleLinkChange = (editor, data, value) => {
    setLinkData(value);
  };
  const showPreviewHandler = () => {
    setShowPreview(false);

    const validationResponse = MetricBuilderValidation(
      formData,
      jsonData,
      linkData,
      requiredArr,
      allMetricName
    );

    setValidationErrors(validationResponse.error);
    if (!validationResponse.isValid) {
      return;
    }

    const frameWorkResponse = { id: formData?.frameWork || [] };
    const publishData = {
      Name: formData.metricName,
      Category: formData.Category,
      ClassName: formData.className,
      Description: formData.description,
      Framework: frameWorkResponse,
      JsonSchema: JSON.parse(jsonData),
      Links: JSON.parse(linkData),
      Frequency: frequency,
    };

    setPreviewData(publishData);
    setShowPreview(true);
  };
  const submitHandler = async () => {
    const validationResponse = MetricBuilderValidation(
      formData,
      jsonData,
      linkData,
      requiredArr,
      allMetricName
    );

    if (!validationResponse.isValid) {
      setValidationErrors(validationResponse.error);
      return;
    }
    const frameWorkResponse = { id: formData?.frameWork || [] };

    const AssignedToData = selectedAssignedTo?.map((id) => +id);
    const ApproverToData = selectedApproverTo?.map((id) => +id);
    const ReviewerToData = selectedReviewerTo?.map((id) => +id);

    const publishData = {
      Name: formData.metricName,
      Category: formData.Category,
      ClassName: formData.className,
      Description: formData.description,
      Framework: frameWorkResponse,
      JsonSchema: JSON.parse(jsonData),
      Links: JSON.parse(linkData),
      FrequencyValue:
        frequency === "Custom" ? +customFrequency : FrequecyCountMap[frequency],
      TagList: tags,
      Organization: OrgInfo,
      AssignedTo: AssignedToData,
      ApproverTo: ApproverToData,
      ReviewerTo: ReviewerToData,
    };

    let result = await PublishData({ data: publishData }, "metrics");

    const newTags = tags?.filter((name) => !allTags?.includes(name));
    const updatedTags = [...allTags, ...newTags];
    await UpdateSingleCollectionData("tag", { List: updatedTags });

    navigator(`/${_orgId}/metrics-builder`);
  };

  function jsonHint(editor) {
    const jsonWords = ["{}", "name", "version", "author"]; // Add your JSON keywords here
    const cursor = editor.getCursor();
    const token = editor.getTokenAt(cursor);

    // Filter JSON words that match the current token
    const matches = jsonWords.filter((word) => word.startsWith(token.string));

    // Return hint object with filtered matches
    return {
      list: matches,
      from: { line: cursor.line, ch: token.start },
      to: { line: cursor.line, ch: token.end },
    };
  }

  // Handler for inputRead event to trigger autocomplete
  const handleInputRead = (editor, data, value) => {
    const cursor = editor.getCursor();
    const line = editor.getLine(cursor.line);
    const lastChar = line.charAt(cursor.ch - 1);

    // Check if the last character typed is a letter or a digit
    if (/[a-zA-Z0-9]/.test(lastChar)) {
      editor.execCommand("autocomplete");
    }
  };

  useEffect(() => {
    getDataWithFilterUrl("frameworks")
      .then((data) => {
        let frameWorkData = data.data;
        const f = {};
        frameWorkData = frameWorkData?.map((item) => {
          f[item.id] = item.attributes.Name;
          return { id: item.id, name: item?.attributes?.Name };
        });
        setFrameWorkDetails(f);
        setFrameWorkList(frameWorkData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getSingleCollectionData("tag")
      .then((data) => {
        setAllTags(data?.data?.attributes?.List || []);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getDataWithFilterUrl("metrics",orgFilterUrl)
      .then((data) => {
        const list = data?.data?.map((a) => {
          return a?.attributes?.Name.trim().toLowerCase();
        });
        setAllMetricName(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const fun = async () => {
      let allUser = await getAllUserOfOrgnization();
      let allReviewer = await getAllReviewerOfOrgnization();
      let allApprover = await getAllApproverOfOrgnization();

      allUser = allUser?.map((user) => {
        return { id: user.id, name: user.username };
      });
      allReviewer = allReviewer?.map((user) => {
        return { id: user.id, name: user.username };
      });
      allApprover = allApprover?.map((user) => {
        return { id: user.id, name: user.username };
      });

      setAllUserList(allUser);
      setAllApproverList(allApprover);
      setAllReviewerList(allReviewer);
    };
    fun();
  }, []);

  useEffect(() => {
    let response = metricInfo;

    if (!response) {
      // setLoader(false);
      return;
    }
    setFormData({
      metricName: response?.attributes?.Name || "",
      Category: response?.attributes?.Category || "",
      className: response?.attributes?.ClassName || "",
      description: response?.attributes?.Description || "",
      frameWork: response?.attributes?.Framework?.id || [],
      ApprovalWorkFlow: response?.attributes?.WorkFlow,
      SectorText: response?.attributes?.Sector || "",
    });
    const AssignedToData = response?.attributes?.AssignedTo?.data?.map((user) =>
      user?.id?.toString()
    );
    const ApproverToData = response?.attributes?.ApproverTo?.data?.map((user) =>
      user?.id?.toString()
    );
    const ReviewerToData = response?.attributes?.ReviewerTo?.data?.map((user) =>
      user?.id?.toString()
    );
    setSelectedAssignedTo(AssignedToData);
    setSelectedApproverTo(ApproverToData);
    setSelectedReviewerTo(ReviewerToData);

    const frequencyNumber = +response?.attributes?.FrequencyValue;
    const frequencyval =
      frequencyNumber === -1
        ? "Custom"
        : frequencyNumber === 30
        ? "Every month"
        : frequencyNumber === 7
        ? "Every week"
        : frequencyNumber === 1
        ? "Everyday"
        : "Only Once";
   

    setFrequency(frequencyval);
    setJsonData(JSON.stringify(response?.attributes?.JsonSchema, null, 2));
    setLinkData(JSON.stringify(response?.attributes?.Links, null, 2));
    setTags(response?.attributes?.TagList || []);

    let formulaList = response?.attributes?.JsonSchema?.formula || [];
    formulaList = formulaList.map((data) => data?.id?.toString());
    setSelectedFormulaList(formulaList);
    setOverviewList(response?.attributes?.Overview || {});
    setGuidanceList(response?.attributes?.Guidance || {});
    setMetricNameList(response?.attributes?.NameList || {});
    setLoader(false);
  }, [metricInfo]);


  return (
    <div className="flex items-start justify-between my-2">
      <div className="flex flex-col items-center w-[50%]">
        {/* Metric Name Input  */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          isRequired
          label="Metric Name"
          labelPlacement="outside"
          placeholder=" "
          name="metricName"
          onChange={changeHandler}
          value={formData["metricName"]}
          isInvalid={validationErrors["metricName"]}
          errorMessage={
            validationErrors["metricName"] && validationErrors["metricName"]
          }
        />
        {/* Category DropDown */}

        <DropDownInput
          buttonCSS={"mb-8"}
          data={CategoryDataSchemaObj}
          value={formData?.Category === "" ? "" : formData.Category}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) =>
            setFormData({
              ...formData,
              categoryName: value,
            })
          }
        />

        {/* ClassName Input */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          label="Class Name"
          placeholder=" "
          labelPlacement="outside"
          name="className"
          value={formData["className"]}
          onChange={changeHandler}
        />

        {/* Description Input  */}
        <Textarea
          label="Description"
          labelPlacement="outside"
          variant="faded"
          className="my-4"
          name="description"
          value={formData["description"]}
          onChange={changeHandler}
        />

        <Textarea
          className="my-4"
          variant="faded"
          label={`General Overview`}
          labelPlacement="outside"
          placeholder=" "
          value={overviewList["Generic"]}
          onChange={(event) =>
            setOverviewList({
              ...overviewList,
              Generic: event.target.value,
            })
          }
        />

        <Textarea
          className="my-4"
          variant="faded"
          label={`General Guidance`}
          labelPlacement="outside"
          placeholder=" "
          value={guidanceList["Generic"]}
          onChange={(event) =>
            setGuidanceList({
              ...guidanceList,
              Generic: event.target.value,
            })
          }
        />

        {DefaultOrgDetail?.id === OrgInfo && (
          <DropDownInput
            data={{
              ...sectorSchema,
              values: allSectorName,
            }}
            value={formData?.SectorText}
            validationErrors={validationErrors}
            onChangeHandler={(title, value, type) =>
              setFormData({ ...formData, SectorText: value })
            }
            buttonCSS="mb-4"
          />
        )}

        {/* FrameWork Input */}

        {formData?.Category?.toLowerCase() !== "reference data" && (
          <Select
            label="Select Framework"
            placeholder="Select any Framework"
            variant="faded"
            selectionMode="multiple"
            labelPlacement="outside"
            className="my-6"
            selectedKeys={new Set(formData["frameWork"])}
            onSelectionChange={(event) =>
              setFormData({
                ...formData,
                frameWork: Array.from(event).join(",").replaceAll("_", " "),
              })
            }
          >
            {frameWorkList?.map((item) => (
              <SelectItem key={item.id} value={item.name} className="py-2">
                {item.name}
              </SelectItem>
            ))}
          </Select>
        )}

        {formData?.Category?.toLowerCase() !== "reference data" &&
          formData?.frameWork &&
          formData?.frameWork?.map((id) => {
            return (
              <FrameworFieldAccodian
                title={frameWorkDetails[+id]}
                setMetricNameList={setMetricNameList}
                MetricNameList={MetricNameList}
                setGuidanceList={setGuidanceList}
                guidanceList={guidanceList}
                setOverviewList={setOverviewList}
                overviewList={overviewList}
              />
            );
          })}

        {/* AssignedTo User DropDown */}
        <MultiSelectInput
          data={AssignedToUserSchemaObj}
          value={selectedAssignedTo}
          valuesList={AllUserList}
          onChangeHandler={(title, value, type) => setSelectedAssignedTo(value)}
        />

        {/* Approval Workfflow DropDown */}

        <DropDownInput
          buttonCSS={"mb-8"}
          data={ApprovalWorkFlowDataSchemaObj}
          value={formData?.ApprovalWorkFlow || ""}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) =>
            setFormData({
              ...formData,
              ApprovalWorkFlow: value,
            })
          }
        />

        {/* Approver User DropDown */}
        {(formData?.ApprovalWorkFlow === "1-Step Approval" ||
          formData?.ApprovalWorkFlow === "2-Step Approval") && (
          <MultiSelectInput
            data={ApproverUserSchemaObj}
            value={selectedApproverTo}
            valuesList={AllApproverList}
            onChangeHandler={(title, value, type) =>
              setSelectedApproverTo(value)
            }
          />
        )}
        {/* Reviewer User DropDown */}
        {formData?.ApprovalWorkFlow === "2-Step Approval" && (
          <MultiSelectInput
            data={ReviewerUserSchemaObj}
            value={selectedReviewerTo}
            valuesList={AllReviewerList}
            onChangeHandler={(title, value, type) =>
              setSelectedReviewerTo(value)
            }
          />
        )}

        {/* Frequency DropDown */}

        <DropDownInput
          buttonCSS={"mb-8"}
          data={FrequencyDataObj}
          value={frequency}
          validationErrors={1}
          onChangeHandler={(title, value, type) => setFrequency(value)}
        />
        {showCustomFrequencyInput && (
          <CustomFrequencyInput
            value={customFrequency}
            onChangeHandler={(value) => setCustomFrequency(value)}
          />
        )}

        {/* Metrics Tags Input */}
        <MetricTagInput
          tags={tags}
          setTags={setTags}
          IntialvalidationError={tagsValidation}
          allTags={allTags}
        />

        {/* Formula Input */}

        <Select
          label="Select Formules"
          placeholder="Choose any Formules"
          variant="faded"
          selectionMode="multiple"
          labelPlacement="outside"
          className="my-8"
          selectedKeys={new Set(selectedFormulaList)}
          onSelectionChange={(event) => {
            setSelectedFormulaList(Array.from(event));
          }}
        >
          {allModels?.map((item) => (
            <SelectItem key={item.id} value={item.id} className="py-2">
              {item.title}
            </SelectItem>
          ))}
        </Select>

        {/* JsonSchema Input */}

        <div className="w-full my-2 mb-12">
          <p className="flex items-center gap-1 text-gray text-sm mb-2">
            <span>Json Schema</span>
            <Tooltip
              content={<JSonResponseInfoContentComp />}
              className="flex items-center cursor-pointer"
            >
              <lord-icon
                src="https://cdn.lordicon.com/yxczfiyc.json"
                style={{ width: "18px", height: "18px" }}
                trigger="hover"
                colors="primary:#8492a6"
              ></lord-icon>
            </Tooltip>
            <p className="text-[#f76497] text-">
              {validationErrors["JsonSchema"] && validationErrors["JsonSchema"]}
            </p>
          </p>

          <Card>
            <CardBody className="p-0">
              <CodeMirror
                value={jsonData}
                options={{
                  mode: "javascript",
                  theme: "material",
                  lineNumbers: true,
                  tabSize: 2,
                  extraKeys: { "Ctrl-Space": "autocomplete" },
                }}
                onChange={handleInputRead}
                onBeforeChange={handleJsonChange}
                editorDidMount={(editor) => {
                  editor.on("inputRead", handleInputRead);
                  editor.setOption("hintOptions", {
                    hint: jsonHint,
                  });
                }}

                //   onKeyDown={handleKeyDown} // Handle key down event
              />
            </CardBody>
          </Card>
        </div>

        {/* Links Input */}

        <div className="w-full my-2 mb-12">
          <p className="flex items-center gap-1 text-gray text-sm mb-2">
            <span>Link Schema</span>
            <Tooltip
              content={<JSonResponseInfoContentComp />}
              className="flex items-center cursor-pointer"
            >
              <lord-icon
                src="https://cdn.lordicon.com/yxczfiyc.json"
                style={{ width: "18px", height: "18px" }}
                trigger="hover"
                colors="primary:#8492a6"
              ></lord-icon>
            </Tooltip>
            <p className="text-[#f76497] text-">
              {validationErrors["linkData"] && validationErrors["linkData"]}
            </p>
          </p>

          <Card>
            <CardBody className="p-0">
              <CodeMirror
                value={linkData}
                options={{
                  mode: "javascript",
                  theme: "material",
                  lineNumbers: true,
                  tabSize: 2,
                  extraKeys: { "Ctrl-Space": "autocomplete" },
                }}
                onChange={handleInputRead}
                onBeforeChange={handleLinkChange}
                editorDidMount={(editor) => {
                  editor.on("inputRead", handleInputRead);
                  editor.setOption("hintOptions", {
                    hint: jsonHint,
                  });
                }}

                //   onKeyDown={handleKeyDown} // Handle key down event
              />
            </CardBody>
          </Card>
        </div>

        {/* Preview,Submit button */}
        <div className="flex gap-4 my-4 w-full">
          <Button
            color="danger"
            variant="light"
            size="sm"
            onClick={showPreviewHandler}
          >
            Preview
          </Button>
          <Button color="danger" size="sm" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </div>

      {/* Preview Card Open */}
      {showPreview && (
        <div className="flex flex-col items-center w-[48%]">
          <PreviewForm data={previewData} />
        </div>
      )}
    </div>
  );
};

export default JsonFormBuilder;

/* 

---Check the Validation
---creating the payload and push to strapi


*/
