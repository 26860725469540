import React, { useEffect, useRef, useState } from "react";
import {
  fetchReportChunk,
  StringLimit,
} from "../../../controllers/APIController";
import ChatBox from "../RenderHTML/ChatBox";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { templateList } from "../RenderHTML/template";
import { DocumentTextIcon } from "@heroicons/react/24/solid";
import LoadingPage from "../../../components/Loader/LoadingPage";
import { useReactToPrint } from "react-to-print";
const colors = ["default", "secondary", "danger", "warning", "success"];
const SingleTemplateCard = ({
  data,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const isSelected = selectedTemplate?.id === data?.id;
  const textColor = colors[data?.id % colors.length];
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        setSelectedTemplate(data);
      }}
    >
      <Card
        key={data.id}
        className="h-[200px] p-2 border border-divider"
        shadow="none"
      >
        <CardHeader className="flex gap-3 justify-between">
          <div className="flex items-center gap-3 ">
            <DocumentTextIcon className={`size-8 text-${textColor}`} />
            <div className="flex flex-col">
              <p className="text-md font-semibold">{data?.title}</p>
            </div>
          </div>
          <Checkbox
            isSelected={isSelected}
            radius="full"
            color={textColor}
            onValueChange={() => setSelectedTemplate(data)}
          ></Checkbox>
        </CardHeader>
        <CardBody>
          <div className="text-grey-600">
            {StringLimit(data?.description, 150)}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const ExportReportButton = ({ onExport }) => {
  const exportHandler = (key) => {
    onExport(key);
  };
  return (
    <Dropdown>
      <DropdownTrigger>
        <Button className="" color="danger" size="sm">
          Save Report
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Static Actions"
        disabledKeys={["doc"]}
        onAction={(key) => exportHandler(key)}
      >
        <DropdownItem key="pdf">Export as PDF</DropdownItem>
        <DropdownItem key="doc">Export as Doc</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
const ViewReportTab = ({ reportDetails }) => {
  const [chunkReportData, setChunkReportData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(templateList[0]);
  const [isLoading, setIsLoading] = useState(true);
  const cardRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => cardRef.current,
  });

  const exportHandler = (key) => {
    if (key === "pdf") {
      handlePrint();
    }
  };
  useEffect(() => {
    const id = reportDetails?.id;
    fetchReportChunk(id)
      .then((data) => {
        setChunkReportData(data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => console.log(err));
  }, [reportDetails]);

  return (
    <div className="flex gap-4 w-full h-full">
      <LoadingPage isLoading={isLoading}>
        <>
          <div className="flex max-h-[60vh] w-full overflow-auto ">
            <Card
              key={"view-document"}
              className="w-full p-2 border border-divider overflow-auto custom-scrollbar"
              shadow="none"
              ref={cardRef}
            >
              <CardBody>
                <div className="flex flex-col gap-4 p-2 overflow-auto custom-scrollbar">
                  {chunkReportData?.map((chunk) => {
                    let content = chunk?.payload?.response;
                    return (
                      <div className="flex flex-col gap-2">
                        <ChatBox
                          messages={content}
                          templateInfo={selectedTemplate}
                        />
                      </div>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="relative flex flex-col w-[25%] flex-shrink-0">
            <div className="absolute -top-12 flex w-full justify-end mb-2">
              <ExportReportButton onExport={exportHandler} />
            </div>
            <p className="text-lg font-semibold text-grey-600">
              Template's List
            </p>
            <div className="flex flex-col gap-4 my-4">
              {templateList?.map((template) => {
                return (
                  <SingleTemplateCard
                    data={template}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                  />
                );
              })}
            </div>
          </div>
        </>
      </LoadingPage>
    </div>
  );
};

export default ViewReportTab;
