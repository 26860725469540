import pluralize from "pluralize";
import { getUserRoleFromOrg } from "../utils/helper";

// here we fetch the collection form backend and display the schema in the form of a form

// Testing Signup

export const getNavbar = async (collectionName) => {
  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}/?populate=*`,
    {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //   'Content-Type': 'application/json',
        // "x-jwt-token": process.env.REACT_APP_X_JWT_TOKEN,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
  );

  let data = await response.json();
  return data;
};

export const fetchCollection = async (
  collectionName = null,
  filterUrl = null
) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }
  const response = await fetch(
    `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/collections`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ collectionName, filterUrl, jwt }),
    }
  );

  const data = await response.json();

  return data?.data;
};

export const getAlldata = async (collectionName) => {
  const result = await fetchCollection(collectionName);
  return result;
};

export const getDataWithFilterUrlwithLimit = async (
  collectionName,
  filteredValue
) => {
  const url = `${filteredValue}&populate=*`;
  const result = await fetchCollection(collectionName, url);
  return result;
};

export const getDataWithFilterUrl = async (collectionName, filteredValue) => {
  const url = `${filteredValue}&populate=*&pagination[limit]=10000000000000000000000000`;
  const result = await fetchCollection(collectionName, url);
  return result;
};

export const getFilterDataWithPagination = async (
  collectionName,
  filteredValue,
  pageNo = 1,
  pageSize = 100000000000
) => {
  const url = `${filteredValue}&populate=*&pagination[page]=${+pageNo}&pagination[pageSize]=${+pageSize}`;
  const result = await fetchCollection(collectionName, url);
  return result;
};

//...
export const getAlldataInclude = async (collectionName) => {
  const url = `publicationState=preview&populate=*`;
  const result = await fetchCollection(collectionName, url);
  return result;
};

export const getOnedata = async (collectionName, id) => {
  const url = `filters[id][$eqi]=${id}&populate=*&pagination[limit]=10000000000000000000000000`;
  const result = await fetchCollection(collectionName, url);
  const data = (result && result.length > 0 && result[0]) || null;

  return data;
};

export const createPayload = async (elements) => {
  const payload = { data: {} };

  (await elements) &&
    elements.forEach((data) => {
      if (data[1].type === "media" && data[3].value === "") {
        let leftkey = data[0];
        let rightkey = null;
        payload.data[leftkey] = rightkey;
      } else if (data[1].type === "relation" && data[3].value === "") {
        let leftkey = data[0];
        let rightkey = null;
        payload.data[leftkey] = rightkey;
      } else {
        let leftkey = data[0];
        let rightkey = data[3].value;
        payload.data[leftkey] = rightkey;
      }
    });

  (await elements) &&
    elements.forEach((data) => {
      if (data[0] === "users_permissions_user") {
        payload.data["users_permissions_user"] = {
          connect: [
            {
              id:
                JSON.parse(sessionStorage.getItem("userData")) != null
                  ? JSON.parse(sessionStorage.getItem("userData")).user.id
                  : "",
              position: { end: true },
            },
          ],
          disconnect: [],
        };

        return payload;
      }
    });

  (await elements) &&
    elements.forEach((data) => {
      if (data[0] === "organization") {
        payload.data["organization"] = {
          connect: [
            {
              id: data[3].value,
              position: { end: true },
            },
          ],
          disconnect: [],
        };

        return payload;
      }
    });

  return payload;
};

// Create an new entry in collection......

export const PublishData = async (payload, collectionName) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/collections/publish`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ collectionName, payload, jwt }),
    }
  );

  let data = await response.json();

  return data?.data;
};

export const doPluralize = (text) => {
  // let temp = text;
  if (text === pluralize(text)) {
    return `${text}`;
  }
  return pluralize(text);
};

export const shortenString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + "...";
  }
  return str;
};

export const DeleteOnedata = async (collectionName, id) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/collections`,
    {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ collectionName, entryId: id, jwt }),
    }
  );

  const data = await response.json();

  return data?.data;
};

// Update data function

export const UpdateOneData = async (collectionName, id, newData) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  let payload = newData;
  if (collectionName !== "users") {
    payload = {
      data: newData,
    };
  }

  const response = await fetch(
    `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/collections`,
    {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ collectionName, id, payload, jwt }),
    }
  );

  const data = await response.json();

  return data?.data;
};

/*

Inviting a new user to the CRCL
payload to pass -> email
Steps to creating a new user:

1. Does the User exist API needs to be done here.
2. Creating the new user (done)
3. The default payload must be:
  a. email (sent through the payload) (done)
  b. username (equal to the email) (done)
  c. password: try something complex. Current Default password is: N!0r@2023 (done)
  d. Making the connection link to this user that created the invite. 
4. Novu [keep it independent]
  a. Novu Email Template create
  b. Novu API create for invited user
  c. Make the new link inside this itself and call novu 9000 endpoint API to send the email.

  
*/

export const FilterUser = async (email) => {
  const data = await fetchCollection("users", "populate=*");

  const filteredData = data && data.find((item) => item.email === email);
  if (!filteredData) {
    return false;
  }
  return filteredData;
};

// Get all media files

export const GetAllMedia = async () => {
  const res = await fetchCollection("upload/files");
  return res;
};

// STRING LIMIT FUNCTION
export const StringLimit = (word, maxLength) => {
  if (word?.length > maxLength) {
    return word.substring(0, maxLength - 3) + "...";
  }
  return word;
};

// Function for Get the Items for a realtion (Like for Dropdown) :-
export const getItemForRelation = async (metricID) => {
  let values = [];

  const filterURL = "filters[Metric_Id][$eqi]=" + metricID;

  try {
    const data = await getDataWithFilterUrl("responses", filterURL);
    const responseData = data.data;

    responseData.forEach((item) => {
      values.push({
        id: item.id,
        name:
          item.attributes.JsonSchema.name || item.attributes.JsonSchema.Name,
      });
    });
    return values;
  } catch (err) {
    console.log(err);
    throw err; // re-throw the error to propagate it to the caller
  }
};

export async function updateFormSchemaWithRealtion(
  formSchema,
  index,
  setFormSchema
) {
  if (formSchema[index][1].values === undefined) {
    let newFormSchema = [...formSchema];
    
    const relatedToList =
      (formSchema[index][1].relatedTo || formSchema[index][1].relatedto).split(
        ","
      ) || [];
    newFormSchema[index][1].values = [];

    const promises = relatedToList.map(async (metricID) => {
      const data = await getItemForRelation(metricID);
      return data;
    });
    const results = await Promise.all(promises);

    newFormSchema[index][1].values = results.flat();


    setFormSchema(newFormSchema);
  }
}

export const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export const separateDasFromUrl = (value) => {
  return value?.replaceAll("-", " ");
};

// ROLES APIs
export const getAllRoles = async () => {
  const res = await fetchCollection("users-permissions/roles");

  let data = res.roles;

  const FilterData = data && data.find((role) => role.name === "Public");

  return FilterData;
};

// Keys Generate and Stored in DB

export const submitAPIKey = async (api_key, key_name, user_email) => {
  const PayloadData = {
    api_key: api_key,
    users_permissions_user: user_email,
    Name: key_name,
  };

  let result = await PublishData({ data: PayloadData }, "key");

  return result;
};

export const UpdateSingleCollectionData = async (MetricName, newData) => {
  const payload = { data: newData };
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/collections/single`,
    {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ MetricName, payload, jwt }),
    }
  );

  const data = await response.json();
  return data?.data;
};

export const getSingleCollectionData = async (collectionName) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/collections/single`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ collectionName, jwt }),
    }
  );

  const data = await response.json();

  return data?.data;
};

// FILE UPLOAD TO STRAPI
export const uploadFile = async (file) => {
  const res = await fetch(
    `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/files/upload`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      body: file,
    }
  );

  let data = await res.json();
  return data?.data;
};

export const InviteUser = async (payload) => {
  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send-email`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = response.json();

  return data;
};

export const sendSurvey = async (payload) => {
  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send-survey`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = await response.json();

  return data;
};

export const updateUserLastOrg = async (id, OrgUrl, orgId = 0) => {
  const userRole = await getUserRoleFromOrg(orgId, id);

  const data = {
    userRole: userRole,
    LastOrg: OrgUrl,
    LastOrgId: orgId,
  };

  const result = await UpdateOneData("users", id, data);
  const { Password2, ...userDataWithoutPassword } = result;
  let userData = JSON.parse(sessionStorage.getItem("userData"));

  userData["user"] = userDataWithoutPassword;
  sessionStorage.setItem("userData", JSON.stringify(userData));

  return result;
};

// Get Status,CurrentCount,RequiredCount of Metric Responses for an organization
export const getStatusOfMetric = async (
  metricName,
  OrgId,
  metricID,
  frequencyCounter
) => {
  const url = `filters[Organization][id][$eqi]=${OrgId}&filters[Metric_Id][$eqi]=${metricName}&sort[0]=updatedAt:desc`;

  const result = await getFilterDataWithPagination("responses", url, 1, 1);
  const lastUpdatedAt = result?.data[0]?.attributes?.updatedAt;
  const currCount = result?.meta?.pagination.total || 0;
  let requiredCount = 0;

  frequencyCounter?.forEach((element) => {
    if (element.metric_id === metricID) {
      requiredCount = element?.counter;
    }
  });

  const status = currCount >= requiredCount ? "Completed" : "Incompleted";

  return {
    Status: status,
    RequiredCount: requiredCount,
    CurrentCount: currCount,
    lastUpdatedAt: lastUpdatedAt,
  };
};

export const createOrg = async (payload) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/create-org`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify({ ...payload, jwt }),
  });

  let data = response.json();

  return data;
};

export const deleteOrg = async (payload) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/delete-org`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify({ payload, jwt }),
  });

  let data = response.json();

  return data;
};

export const sendResetPasswordEmail = async (payload) => {
  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/reset-password-email`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = await response.json();

  return data;
};

export const sendCreateAccountOTP = async (payload) => {
  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send-create-otp`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = await response.json();

  return data;
};

export const sendSubmissionUpdationAlert = async (payload) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }
  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send-submission-alert`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify({ ...payload, jwt }),
  });

  let data = await response.json();

  return data;
};
