import {
  Badge,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { getDataWithFilterUrl } from "../../controllers/APIController";
import SingleInvitationNotification from "./SingleInvitationNotification";

const Notification = () => {
  const [userRole, setUserRole] = useState("");
  const [reRenderPage, setReRenderPage] = useState(false);

  const [invitationList, setInvitationList] = useState([]);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged?.user?.id;
  const UserEmail = UserLogged && UserLogged?.user?.email;

  useEffect(() => {
    const userFilteredUrl = "filters[id][$eqi]=" + UserId;
    getDataWithFilterUrl("users", userFilteredUrl)
      .then((data) => {
        setUserRole(data[0]?.role?.name);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const url = `filters[email][$eqi]=${UserEmail}&filters[Status][$eqi]=pending`;
    getDataWithFilterUrl("invitations", url)
      .then((data) => setInvitationList(data?.data))
      .catch((err) => console.log(err));
  }, [reRenderPage]);

  const notificationCount = invitationList?.length;

  return (
    <>
      <div className="relative h-8 rounded-md">
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <div>
              <Badge
                content={notificationCount > 0 && notificationCount}
                color="danger"
              >
                <lord-icon
                  src="https://cdn.lordicon.com/vspbqszr.json"
                  trigger="hover"
                  colors={`primary:#000000`}
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
              </Badge>
            </div>
          </PopoverTrigger>
          <PopoverContent>
            {notificationCount === 0 ? (
              <p className="text-xs text-center text-bg p-2">
                No Notifications
              </p>
            ) : (
              <div className="p-2 w-72">
                {invitationList?.map((item) => {
                  return (
                    <SingleInvitationNotification
                      data={item}
                      onRender={setReRenderPage}
                      UserId={UserId}
                    />
                  );
                })}
              </div>
            )}
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
};

export default Notification;
