import {
  Chip,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";

import React, { useState } from "react";
import { metricColor } from "../utils/constant";
import { capitalize } from "../controllers/APIController";
import {
  BooleanInput,
  DateInput,
  DropDownInput,
  IntegerInput,
  PasswordInput,
  RelationDropDownInput,
  SliderInput,
  TextAreaInput,
  TextInput,
} from "../pages/FormBuilder/InputField/InputField";
import FileEvidenceInput from "../pages/FormBuilder/InputField/FileEvidenceInput";

const MetricDrawer = ({ frameworkId, metricInfo, isOpen, onOpenChange }) => {
  const [formdata, setFormData] = useState({});

  let formSchema = Object.keys(
    metricInfo?.attributes?.JsonSchema?.properties || {}
  )
    .filter((key) => {
      const frameWorkIdList =
        metricInfo?.attributes?.JsonSchema?.properties[key]?.framework || [];

      if (frameWorkIdList?.includes(frameworkId.toString() || frameworkId)) {
        return true;
      }
      return false;
    })
    ?.map((key) => {
      return [key, metricInfo?.attributes?.JsonSchema?.properties[key]];
    });

  formSchema?.sort((a, b) => {
    return a[1].id - b[1].id;
  });

  const changeHandler = async (title, value, type) => {
    if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="absolute top-0 right-0 !m-0 h-screen z-100 overflow-auto"
        backdrop="transparent"
        hideCloseButton={true}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="gap-0">
                {/* <DrawerBody User={User} OrgInfo={OrgInfo}/> */}
                <div className="flex items-center justify-between mt-8">
                  <h1 className="text-xl font-medium">
                    {metricInfo?.attributes?.Name}
                  </h1>
                </div>
                <div className="flex items-center gap-2 my-4">
                  <Chip
                    color={
                      metricColor[
                        metricInfo?.attributes?.Category?.toLowerCase() ||
                          "default"
                      ]
                    }
                    variant="flat"
                    size="sm"
                  >
                    {metricInfo?.attributes?.Category}
                  </Chip>
                  {metricInfo?.attributes?.TagList?.map((item, index) => (
                    <Chip
                      key={index}
                      className=""
                      size="sm"
                      color={
                        metricColor[
                          metricInfo?.attributes?.Category?.toLowerCase()
                        ]
                      }
                      variant="flat"
                    >
                      {capitalize(item)}
                    </Chip>
                  ))}
                </div>
                <div className="mt-2 text-sm">
                  {metricInfo?.attributes?.Description &&
                    metricInfo?.attributes?.Description}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                  explicabo iste atque, perspiciatis aspernatur consequuntur
                  voluptatibus dolorem sapiente labore, laborum itaque deserunt
                  doloremque.
                </div>
                <div className="my-8">
                  {formSchema &&
                    formSchema?.map((row, index) => {
                      if (row[1].type === "string" || row[1].type === "text") {
                        return (
                          <TextInput
                            data={row[1]}
                            value={formdata[row[0]]}
                            validationErrors={{}}
                            onChangeHandler={changeHandler}
                          />
                        );
                      }
                      if (row[1].type === "password") {
                        return (
                          <PasswordInput
                            data={row[1]}
                            value={formdata[row[0]]}
                            validationErrors={{}}
                            onChangeHandler={1}
                          />
                        );
                      }
                      if (row[1].type === "integer") {
                        return (
                          <IntegerInput
                            data={row[1]}
                            value={formdata[row[0]]}
                            validationErrors={{}}
                            onChangeHandler={changeHandler}
                          />
                        );
                      }
                      if (row[1].type === "dropdown") {
                        return (
                          <DropDownInput
                            data={row[1]}
                            value={formdata[row[0]]}
                            validationErrors={{}}
                            onChangeHandler={changeHandler}
                          />
                        );
                      }
                      if (row[1].type === "slider") {
                        return (
                          <SliderInput
                            data={row[1]}
                            value={formdata[row[0]]}
                            validationErrors={{}}
                            onChangeHandler={changeHandler}
                          />
                        );
                      }
                      if (row[1].type === "boolean") {
                        return (
                          <BooleanInput
                            data={row[1]}
                            value={formdata[row[0]]}
                            validationErrors={{}}
                            onChangeHandler={changeHandler}
                          />
                        );
                      }
                      if (row[1].type === "textarea") {
                        return (
                          <TextAreaInput
                            data={row[1]}
                            value={formdata[row[0]]}
                            validationErrors={{}}
                            onChangeHandler={changeHandler}
                          />
                        );
                      }
                      if (row[1].type === "date") {
                        return (
                          <DateInput
                            data={row[1]}
                            value={formdata[row[0]]}
                            validationErrors={{}}
                            onChangeHandler={changeHandler}
                          />
                        );
                      }
                      if (row[1].type === "file") {
                        return (
                          <FileEvidenceInput
                            data={row[1]}
                            value={formdata[row[0]]}
                            onChangeHandler={changeHandler}
                            validationErrors={{}}
                          />
                        );
                      }
                      if (row[1].type === "relation") {
                        // updateFormSchemaWithRealtion(
                        //   formSchema,
                        //   index,
                        //   setformSchema
                        // );
                        return (
                          <RelationDropDownInput
                            data={row[1]}
                            value={formdata[row[0]]}
                            onChangeHandler={changeHandler}
                            validationErrors={{}}

                            // onClickHandler={clickHandler}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default MetricDrawer;
