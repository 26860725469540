import { Avatar, Skeleton } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import {
  capitalize,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import { Colors } from "../../utils/constant";
import { formatDate } from "../../utils/helper";

/*
[
  {
    "by": 7,
    "operation": "update",
    "timestamp": 1727068659180
  },
  {
    "by": 7,
    "operation": "create",
    "timestamp": 1727068438419
  }
]
  ProfilePictureUrl
*/
const LogSkeleton = () => {
  return (
    <div className="w-full flex items-center gap-3 my-2">
      <div>
        <Skeleton className="flex rounded-full w-8 h-8" />
      </div>
      <div className="w-full flex flex-col gap-2">
        <Skeleton className="h-3 w-full rounded-lg" />
      </div>
    </div>
  );
};
const LogsInfo = ({ data }) => {
  console.log(data);
  const [loader, setLoader] = useState(true);
  const [UserDataList, setUserDataList] = useState({});

  useEffect(() => {
    const userInfo = {};
    if (!data) {
      setUserDataList({});
      return;
    }

    const fun = data?.map(async (item) => {
      const response = await getDataWithFilterUrl(
        "users",
        `filters[id][$eqi]=${item?.by}`
      );
      console.log(response);
      userInfo[item.by] = {
        id: response?.[0]?.id,
        email: response?.[0]?.email || "",
        ProfilePictureUrl: response?.[0]?.ProfilePictureUrl || "",
        FirstName: response?.[0]?.FirstName,
      };
      return response;
    });

    Promise.all(fun)
      .then((data) => {
        setUserDataList(userInfo);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }, [data]);

  console.log(UserDataList);

  if (!data?.length || data?.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-col h-96 overflow-scroll custom-scrollbar">
      {loader ? (
        <>
          <LogSkeleton />
          <LogSkeleton />
          <LogSkeleton />
        </>
      ) : (
        data?.map((item) => {
          const CurrentUserInfo = UserDataList?.[item?.by];
          console.log(CurrentUserInfo, UserDataList, item);
          const name = CurrentUserInfo?.FirstName
            ? CurrentUserInfo?.FirstName
            : capitalize(CurrentUserInfo?.email?.split("@")[0] || "Unknown");
          const dateValue = formatDate(item?.timestamp);
          const numColors = Colors.length;
          const colorIndex = CurrentUserInfo && CurrentUserInfo.id % numColors;
          return (
            <div className="flex items-center my-2 gap-2">
              <Avatar
                radius="md"
                size="sm"
                src={
                  CurrentUserInfo?.ProfilePictureUrl &&
                  CurrentUserInfo?.ProfilePictureUrl
                }
                color={Colors[colorIndex]}
                name={
                  CurrentUserInfo && CurrentUserInfo?.FirstName !== null
                    ? CurrentUserInfo &&
                      CurrentUserInfo?.FirstName?.slice(0, 1).toUpperCase()
                    : CurrentUserInfo &&
                      CurrentUserInfo?.email?.slice(0, 1).toUpperCase()
                }
              />
              <p className="flex items-center gap-2 w-full text-sm">
                <span>{name}</span>
                <span>{item?.operation} the response</span>{" "}
                <span className="">{dateValue}</span>
              </p>
            </div>
          );
        })
      )}
    </div>
  );
};

export default LogsInfo;
