import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from "@nextui-org/react";

import React, { useEffect, useState } from "react";
import { isValidFunctionDefination } from "../../utils/constant";
import { AILogo } from "../../constant/FormBuilder/FormBuilderConstant";

const initalValidationError = {
  title: "",
  functionDefination: "",
};

const EditFormulaModal = ({
  onSubmitHandler,
  id,
  modalHeading,
  isOpen,
  onOpen,
  onOpenChange,
  fieldType,
  item,
}) => {
  const [title, setTitle] = useState("");
  const [functionDefintation, setFunctionDefination] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    ...initalValidationError,
  });

  const validationHandler = () => {
    let isValid = true;
    const newValidationError = { ...initalValidationError };

    if (title === "") {
      newValidationError["title"] = "Title can't be empty";
      isValid = false;
    }

    if (functionDefintation === "") {
      newValidationError["functionDefination"] =
        "Functiona defination is required";
      isValid = false;
    }

    setValidationErrors({ ...newValidationError });

    return isValid;
  };
  const submitHandler = (onClose) => {
    const isValid = validationHandler();
    if (isValid === false) return;

    onSubmitHandler({
      id: id,
      title: title,
      fun: functionDefintation,
    });
    onClose();
  };

  const handleKeyDown = (event, onClose) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action of form submission on Enter key press
      submitHandler(onClose);
    }
  };

  useEffect(() => {
    setValidationErrors({ ...initalValidationError });
  }, [isOpen]);

  useEffect(() => {
    setTitle(item.title);
    setFunctionDefination(item.fun);
  }, []);
  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <div className="outline-none">
              <ModalHeader className="flex flex-col gap-1">
                Add a New Formula
              </ModalHeader>
              <ModalBody>
                <div>
                  <Input
                    type="text"
                    className="mb-8"
                    isRequired
                    label={fieldType === "link" ? "Name" : "Title"}
                    placeholder=" "
                    variant="faded"
                    labelPlacement="outside"
                    value={title || ""}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                    isInvalid={validationErrors["title"] !== ""}
                    errorMessage={validationErrors["title"]}
                    // onKeyDown={(event) => handleKeyDown(event, onClose)}
                  />
                  <Textarea
                    className="my-4"
                    variant="faded"
                    isRequired
                    label={`Formula`}
                    labelPlacement="outside"
                    placeholder=" Describe the Formula that you want to be added."
                    onChange={(e) => setFunctionDefination(e.target.value)}
                    value={functionDefintation}
                    isInvalid={validationErrors["functionDefination"] !== ""}
                    errorMessage={validationErrors["functionDefination"]}
                  />
                  <div className="flex items-center gap-2">
                    <span>{AILogo}</span>
                    <span className="text-xs text-gray">
                      Powered by{" "}
                      <span className="font-semibold"> Audyt AI</span>
                    </span>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitHandler(onClose)}>
                  Update
                </Button>
              </ModalFooter>
            </div>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
export default EditFormulaModal;
