import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./input.css";
import { NextUIProvider } from "@nextui-org/react";
// import '@nextui-org/react/styles.css';
const root = ReactDOM.createRoot(document.getElementById("root"));

// const theme = sessionStorage.getItem("Theme");
// console.log("theme****************", theme);

root.render(
  <React.StrictMode className="min-h-full text-foreground bg-background ">
    <NextUIProvider
      className={`light text-foreground bg-background min-h-full max-h-screen max-w-screen-3xl relative container mx-auto flex-grow`}
      style={{ width: "100%" }}
    >
      <main className="min-h-full">
        {/* <ResolvProvider appId={"ede7d5c0a9943d2bb8fb15ab7c568be5"} customerId={"vipul@nanoheal.com"}> */}
        <App />
        {/* </ResolvProvider> */}
      </main>
    </NextUIProvider>
  </React.StrictMode>
);

reportWebVitals();
