import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import "./tiptapstyles.css";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import Heading from "@tiptap/extension-heading";
import Code from "@tiptap/extension-code";

const TipTap = ({ content, setContent, classNames, placeHolder }) => {
  const editor = useEditor({
    extensions: [
      BulletList,
      OrderedList,
      Heading.configure({ levels: [1, 2, 3] }),
      Code,
      ListItem,
      StarterKit,
      Placeholder.configure({
        placeholder: `${placeHolder}`,
        emptyEditorClass: "is-editor-empty",
      }),
    ],
    content,
    editorProps: {
      attributes: {
        spellcheck: "false",
        class: `${classNames} focus:outline-none`,
      },
    },
  });
  setContent(editor?.getHTML() ?? "");
  if (editor) {
    return <EditorContent editor={editor} />;
  }
};
export default TipTap;
