import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DeleteOnedata,
  capitalize,
  getFilterDataWithPagination,
  separateDasFromUrl,
} from "../../controllers/APIController";
import { getDataWithFilterUrl } from "../../controllers/APIController";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Pagination,
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from "@nextui-org/react";

import DangerModal from "../Metrics/DangerModal";
import LoadingPage from "../../components/Loader/LoadingPage";
import {
  DeleteTableItemWithOpenModal,
  EmptyTableCell,
  FileTableCell,
  GenericTableCell,
  UserCell,
} from "../../utils/helper";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import NullData from "../../components/ErrorPage/NullData";
import TableSkeleton from "../../components/Skeleten/TableSkeleton";
const sortingItemList = [
  {
    key: "desc",
    name: "Recent",
    order: "desc",
  },
  {
    key: "asc",
    name: "Oldest",
    order: "asc",
  },
];
const SingleReferenceData = () => {
  const [CollectionInfo, setCollectionInfo] = useState();
  const [isLoading, setisLoading] = useState(true);

  let description = CollectionInfo?.attributes?.Description;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const ItemPerPage = 10;
  const [metricsList, setmetricsList] = useState([]);
  const [reRenderPage, setReRenderPage] = useState(0);
  const [deleteMetricId, setDeleteMetricId] = useState(-1);

  const [sortedBy, setSortedBy] = useState(sortingItemList[0].key);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { _pathId } = useParams();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  let HeadingTitle = capitalize(separateDasFromUrl(_pathId));

  let filteredUrl = orgFilterUrl + "&filters[Metric_Id][$eqi]=" + HeadingTitle;

  const [customizeCompleteList, setCustomizeCompleteList] = useState([]);
  const [customizeItems, setCustomizeItems] = useState(new Set([]));

  const customizeHeaderList = Array.from(customizeItems).map((name) => {
    return {
      id: name.toLowerCase(),
      label: name,
    };
  });

  const naviageHandler = () => {
    navigate(pathname + "/create");
  };

  const EditHandler = (id) => {
    navigate(pathname + `/${id}`);
  };

  const deleteHandler = async (id) => {
    await DeleteOnedata("responses", id);
    setReRenderPage((pre) => 1 - pre);
  };

  useEffect(() => {
    const newUrl = filteredUrl + `&sort[0]=updatedAt:${sortedBy}`;
    getFilterDataWithPagination("responses", newUrl, currentPage, ItemPerPage)
      .then((data) => {
        setTotalPage(data?.meta?.pagination?.pageCount || 1);
        setmetricsList(data.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reRenderPage, currentPage, sortedBy, filteredUrl]);

  useEffect(() => {
    const url = "filters[Name][$eqi]=" + HeadingTitle;
    getDataWithFilterUrl("metrics", url)
      .then((data) => {
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;
        const HeaderList = Object.keys(responseData);

        setCustomizeCompleteList([...HeaderList, "Status"]);
        setCustomizeItems(new Set([...HeaderList, "Status"]));

        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);

        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });
        setCollectionInfo(data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [HeadingTitle]);

  return (
    <BreadCrumbsPage>
      <div>
        {
          <DangerModal
            onSubmitHandler={deleteHandler}
            id={deleteMetricId}
            modalHeading={"Are you sure you want to delete this Entry?"}
            isOpen={isOpen}
            onOpen={onOpen}
            onOpenChange={onOpenChange}
          />
        }
        <div className="">
          <h1 className="text-3xl font-medium">{HeadingTitle}</h1>
          <div className="mt-8">{description && description}</div>
        </div>

        <div className="flex items-center gap-4 justify-end my-4">
          <>
            <Dropdown className="w-max">
              <DropdownTrigger>
                <Button
                  size="sm"
                  color="default"
                  variant="light"
                  className="w-max"
                >
                  Sort
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Static Actions"
                variant="flat"
                selectionMode="single"
                defaultSelectedKeys={new Set([sortedBy])}
                selectedKeys={new Set([sortedBy])}
                onSelectionChange={(event) => {
                  if (Array.from(event).join(", ").replaceAll("_", " ") === "")
                    return;
                  setSortedBy(
                    Array.from(event).join(", ").replaceAll("_", " ")
                  );
                }}
              >
                {sortingItemList?.map((item) => (
                  <DropdownItem key={item.key}>{item.name}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown className="w-max">
              <DropdownTrigger>
                <Button
                  size="sm"
                  color="default"
                  variant="light"
                  className="w-max"
                >
                  Customize
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection"
                variant="flat"
                closeOnSelect={false}
                selectionMode="multiple"
                disallowEmptySelection
                selectedKeys={customizeItems}
                onSelectionChange={setCustomizeItems}
                className="custom-dropdown-menu"
              >
                {customizeCompleteList?.map((name) => (
                  <DropdownItem key={name}>{name}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </>

          <Button
            color="secondary"
            onClick={naviageHandler}
            className="w-max"
            size="sm"
          >
            Create Entry
          </Button>
        </div>

        <LoadingPage isLoading={isLoading} alterElement={<TableSkeleton />}>
          <>
            <div className="overflow-x-auto pb-8">
              <>
                <Table removeWrapper className="mt-8" selectionMode="single">
                  <TableHeader>
                    {customizeHeaderList.map((header) => (
                      <TableColumn key={header.id}>{header.label}</TableColumn>
                    ))}
                    <TableColumn key={customizeCompleteList.length + 1}>
                      Submitted By
                    </TableColumn>
                    <TableColumn key={customizeCompleteList.length + 2}>
                      Actions
                    </TableColumn>
                  </TableHeader>
                  <TableBody
                    emptyContent={
                      <NullData
                        title={`No reference-data found. Click on "create" to make a new entry`}
                      />
                    }
                  >
                    {metricsList?.length !== 0 &&
                      metricsList.map((item, index) => {
                        // const UserProfile =
                        //   UserDetails && UserDetails.ProfilePicture !== null
                        //     ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                        //         UserDetails.ProfilePicture &&
                        //         UserDetails.ProfilePicture.url
                        //       }`
                        //     : "";

                        return (
                          <TableRow
                            key={item.id}
                            onClick={() => EditHandler(item.id)}
                            className="cursor-pointer"
                          >
                            {customizeHeaderList.map(({ label: key }) => {
                              if (
                                item?.attributes?.JsonSchema[key] === "null" ||
                                item?.attributes[key] === "null" ||
                                item?.attributes?.JsonSchema[key] === ""
                              ) {
                                return EmptyTableCell();
                              }
                              if (
                                typeof item?.attributes?.JsonSchema[key] ===
                                "object"
                              ) {
                                return FileTableCell(
                                  process.env.REACT_APP_STRAPI_IP_ADDRESS +
                                    item?.attributes?.JsonSchema[key].url
                                );
                              } else
                                return GenericTableCell(
                                  item?.attributes?.JsonSchema[key] ||
                                    item?.attributes[key]
                                );
                            })}

                            {UserCell(
                              item?.attributes?.users_permissions_user?.data
                                ?.attributes?.email,
                              item.attributes?.users_permissions_user?.data?.id,
                              item?.attributes?.users_permissions_user?.data
                                ?.attributes?.ProfilePictureUrl
                            )}
                            {DeleteTableItemWithOpenModal(
                              item.id,
                              item.attributes?.users_permissions_user?.data
                                ?.id === UserId,
                              setDeleteMetricId,
                              onOpen
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </>
            </div>
            {metricsList?.length > 0 && (
              <div className="w-full mt-8 flex items-center justify-center">
                <Pagination
                  showControls
                  total={totalPage}
                  color="secondary"
                  page={currentPage}
                  onChange={setCurrentPage}
                />
              </div>
            )}
          </>
        </LoadingPage>
      </div>
    </BreadCrumbsPage>
  );
};

export default SingleReferenceData;
