import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React, { useState } from "react";
import {
  changeHandler,
  FileInput,
  TextAreaInput,
  TextInput,
} from "../FormBuilder/InputField/InputField";
import {
  EvidenceDescriptionSchema,
  EvidenceNameSchema,
  fileInputSchema,
  initialData,
} from "./evidence.constants";
import { PublishData } from "../../controllers/APIController";

const AddEvidenceModal = ({
  id,
  isOpen,
  onOpen,
  onOpenChange,
  setReRenderPage,
}) => {
  const [evidenceData, setEvidenceData] = useState(initialData);
  const [validationErrors, setValidationErrors] = useState({});

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const validationHandler = () => {
    setValidationErrors({});
    let isValid = true;
    const err = {};
    if (evidenceData["Name"].trim() === "") {
      err["Name"] = "Name is required!";
      isValid = false;
    }
    if (
      evidenceData["Upload Evidence"] === "" ||
      evidenceData["Upload Evidence"] === null ||
      evidenceData["Upload Evidence"] === undefined
    ) {
      err["Upload Evidence"] = "File is required!";
      isValid = false;
    }

    setValidationErrors(err);

    return isValid;
  };
  const submitHandler = async (onClose) => {
    const validationReponse = validationHandler();
    if (!validationReponse) return;

    const publishData = {
      Name: evidenceData["Name"],
      Description: evidenceData["Description"],
      EvidenceUrl: evidenceData["Upload Evidence"]?.url,
      EvidenceId: evidenceData["Upload Evidence"]?.id,
      Organization: OrgInfo,
    };

    await PublishData({ data: publishData }, "evidences");
    setReRenderPage((pre) => !pre);
    onClose();
  };

  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      setValidationErrors({});
      setEvidenceData(initialData);
    }
    onOpenChange(isOpen); // Call the original onOpenChange if needed
  };

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={handleOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Evidence
              </ModalHeader>
              <ModalBody>
                <TextInput
                  data={EvidenceNameSchema}
                  value={evidenceData["Name"]}
                  validationErrors={validationErrors}
                  onChangeHandler={(title, value, type) =>
                    changeHandler(
                      title,
                      value,
                      type,
                      evidenceData,
                      setEvidenceData
                    )
                  }
                  cssStyle="my-4"
                />
                <TextAreaInput
                  data={EvidenceDescriptionSchema}
                  value={evidenceData["Description"]}
                  validationErrors={validationErrors}
                  onChangeHandler={(title, value, type) =>
                    changeHandler(
                      title,
                      value,
                      type,
                      evidenceData,
                      setEvidenceData
                    )
                  }
                  cssStyle="mb-4"
                />

                <FileInput
                  data={fileInputSchema}
                  value={evidenceData["Upload Evidence"]}
                  inputfiledId="upload-evidence"
                  onChangeHandler={(title, value, type) => {
                    changeHandler(
                      title,
                      value,
                      type,
                      evidenceData,
                      setEvidenceData
                    );
                  }}
                  validationErrors={validationErrors}
                  cssStyle="mb-8"
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitHandler(onClose)}>
                  Upload
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddEvidenceModal;
