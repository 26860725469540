import {
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Select,
  SelectItem,
  Textarea,
  Tooltip,
} from "@nextui-org/react";
import { Controlled as CodeMirror } from "react-codemirror2";

import React, { useEffect, useState } from "react";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/javascript-hint";

import {
  getAllApproverOfOrgnization,
  getAllReviewerOfOrgnization,
  getAllUserOfOrgnization,
  isJsonString,
} from "../../utils/helper";
import {
  ApprovalWorkFlowDataSchemaObj,
  CategoryList,
  JSonResponseInfoContent,
} from "../../utils/constant";
import PreviewForm from "./PreviewForm";
import { FaChevronDown } from "react-icons/fa";
import {
  UpdateOneData,
  UpdateSingleCollectionData,
  getDataWithFilterUrl,
  getSingleCollectionData,
} from "../../controllers/APIController";
import { useNavigate, useParams } from "react-router-dom";
import useGetAllModels from "../../hooks/useGetAllModels";
import MetricTagInput from "./InputField/MetricTagInput";
import { DropDownInput, MultiSelectInput } from "./InputField/InputField";
import {
  ApproverUserSchemaObj,
  AssignedToUserSchemaObj,
  ReviewerUserSchemaObj,
} from "../../constant/FormBuilder/FormBuilderConstant";

const initialFormData = {
  metricName: "",
  categoryName: "",
  className: "",
  description: "",
  frameWork: "",
};

const MetricBuilderValidation = (formData, jsonData, linkData, requiredArr) => {
  const err = {};
  let isValid = true;

  if (!isJsonString(jsonData)) {
    err["JsonSchema"] = "Invalid JsonSchema";
    isValid = false;
  }
  if (!isJsonString(linkData)) {
    err["linkData"] = "Invalid Link Data";
    isValid = false;
  }

  Object.keys(formData).map((key) => {
    if (requiredArr.includes(key) && formData[key] === "") {
      err[key] = `${key} is required`;
      isValid = false;
    }
  });
  return { isValid: isValid, error: err };
};

const JSonResponseInfoContentComp = () => {
  return (
    <pre className="text-xs font-semibold ">
      {JSON.stringify(JSonResponseInfoContent, null, 2)}
    </pre>
  );
};
const EditJsonFormBuilder = () => {
  const { allModels, setAllModels } = useGetAllModels();

  const [formData, setFormData] = useState(initialFormData);
  const [jsonData, setJsonData] = useState("");
  const [linkData, setLinkData] = useState("");

  const [frequency, setFrequency] = useState("");
  const [frameWorkList, setFrameWorkList] = useState([]);
  const requiredArr = ["metricName", "categoryName", "jsonSchema"];
  const [validationErrors, setValidationErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const [selectedFormulaList, setSelectedFormulaList] = useState([]);

  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedApproverTo, setSelectedApproverTo] = useState([]);
  const [selectedReviewerTo, setSelectedReviewerTo] = useState([]);
  const [AllUserList, setAllUserList] = useState([]);
  const [AllApproverList, setAllApproverList] = useState([]);
  const [AllReviewerList, setAllReviewerList] = useState([]);

  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [tagsValidation, setTagsValidation] = useState("");

  const navigate = useNavigate();
  const { _id, _orgId } = useParams();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const userRole = UserLogged && UserLogged.user.userRole;

  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const changeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleJsonChange = (editor, data, value) => {
    setJsonData(value);
  };
  const handleLinkChange = (editor, data, value) => {
    setLinkData(value);
  };

  const showPreviewHandler = () => {
    setShowPreview(false);

    const validationResponse = MetricBuilderValidation(
      formData,
      jsonData,
      linkData,
      requiredArr
    );

    setValidationErrors(validationResponse.error);
    if (!validationResponse.isValid) {
      return;
    }
    const frameWorkResponse =
      formData?.frameWork !== ""
        ? { id: formData?.frameWork?.split(",") }
        : { id: [] };
    const publishData = {
      Name: formData.metricName,
      Category: formData.categoryName,
      ClassName: formData.className,
      Description: formData.description,
      Framework: frameWorkResponse,
      JsonSchema: JSON.parse(jsonData),
      Links: JSON.parse(linkData),
      Frequency: frequency,
    };

    setPreviewData(publishData);
    setShowPreview(true);
  };

  const submitHandler = async () => {
    const validationResponse = MetricBuilderValidation(
      formData,
      jsonData,
      linkData,
      requiredArr
    );

    if (!validationResponse.isValid) {
      setValidationErrors(validationResponse.error);
      return;
    }

    const frameWorkResponse = {
      id: formData?.frameWork || [],
    };

    const publishData = {
      Name: formData.metricName,
      Category: formData.categoryName,
      ClassName: formData.className,
      Description: formData.description,
      Framework: frameWorkResponse,
      JsonSchema: JSON.parse(jsonData),
      Links: JSON.parse(linkData),
      Frequency: frequency,
      TagList: tags,
    };

    let result = await UpdateOneData("metrics", _id, publishData);

    const newTags = tags?.filter((name) => !allTags?.includes(name));
    const updatedTags = [...allTags, ...newTags];
    await UpdateSingleCollectionData("tag", { List: updatedTags });

    navigate(`/${_orgId}/metrics-builder`);
    // window.location.reload();
  };

  function jsonHint(editor) {
    const jsonWords = ["{}", "name", "version", "author"]; // Add your JSON keywords here
    const cursor = editor.getCursor();
    const token = editor.getTokenAt(cursor);

    // Filter JSON words that match the current token
    const matches = jsonWords.filter((word) => word.startsWith(token.string));

    // Return hint object with filtered matches
    return {
      list: matches,
      from: { line: cursor.line, ch: token.start },
      to: { line: cursor.line, ch: token.end },
    };
  }

  // Handler for inputRead event to trigger autocomplete
  const handleInputRead = (editor, data, value) => {
    const cursor = editor.getCursor();
    const line = editor.getLine(cursor.line);
    const lastChar = line.charAt(cursor.ch - 1);

    // Check if the last character typed is a letter or a digit
    if (/[a-zA-Z0-9]/.test(lastChar)) {
      editor.execCommand("autocomplete");
    }
  };

  useEffect(() => {
    const filteredUrl = `${orgFilterUrl}&filters[Category][$eqi]=framework`;

    getDataWithFilterUrl("frameworks")
      .then((data) => {
        let frameWorkData = data.data;

        frameWorkData = frameWorkData.map((item) => {
          return { id: item?.id, name: item?.attributes?.Name };
        });

        setFrameWorkList(frameWorkData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const filteredUrl = `filters[id][$eqi]=${_id}`;
    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        let response = data.data[0];
        setFormData({
          metricName: response?.attributes?.Name || "",
          categoryName: response?.attributes?.Category || "",
          className: response?.attributes?.ClassName || "",
          description: response?.attributes?.Description || "",
          frameWork: response?.attributes?.Framework?.id || [],
          ApprovalWorkFlow: response?.attributes?.WorkFlow,
        });
        const AssignedToData = response?.attributes?.AssignedTo?.data?.map(
          (user) => user?.id?.toString()
        );
        const ApproverToData = response?.attributes?.ApproverTo?.data?.map(
          (user) => user?.id?.toString()
        );
        const ReviewerToData = response?.attributes?.ReviewerTo?.data?.map(
          (user) => user?.id?.toString()
        );
        setSelectedAssignedTo(AssignedToData);
        setSelectedApproverTo(ApproverToData);
        setSelectedReviewerTo(ReviewerToData);

        setFrequency(response?.attributes?.Frequency);
        setJsonData(JSON.stringify(response?.attributes?.JsonSchema, null, 2));
        setLinkData(JSON.stringify(response?.attributes?.Links, null, 2));

        setTags(response?.attributes?.TagList || []);
        let formulaList = response?.attributes?.JsonSchema?.formula || [];
        formulaList = formulaList.map((data) => data?.id?.toString());
        setSelectedFormulaList(formulaList);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    getSingleCollectionData("tag")
      .then((data) => {
        setAllTags(data?.data?.attributes?.List);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fun = async () => {
      let allUser = await getAllUserOfOrgnization();
      let allReviewer = await getAllReviewerOfOrgnization();
      let allApprover = await getAllApproverOfOrgnization();

      allUser = allUser?.map((user) => {
        return { id: user.id, name: user.username };
      });
      allReviewer = allReviewer?.map((user) => {
        return { id: user.id, name: user.username };
      });
      allApprover = allApprover?.map((user) => {
        return { id: user.id, name: user.username };
      });

      setAllUserList(allUser);
      setAllApproverList(allApprover);
      setAllReviewerList(allReviewer);
    };
    fun();
  }, []);

  return (
    <div className="flex items-start justify-between my-2">
      <div className="flex flex-col items-center w-[50%]">
        {/* Metric Name Input  */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          isRequired
          label="Metric Name"
          labelPlacement="outside"
          placeholder=" "
          name="metricName"
          value={formData["metricName"] || ""}
          onChange={changeHandler}
          isInvalid={validationErrors["metricName"]}
          errorMessage={
            validationErrors["metricName"] && validationErrors["metricName"]
          }
        />

        {/* Category DropDown */}

        <div className="flex flex-col gap-2 text-sm w-full">
          <p>
            Category <span style={{ color: "red" }}>*</span>
          </p>
          {validationErrors["categoryName"] && (
            <p className="text-[#f76497]">{validationErrors["categoryName"]}</p>
          )}
          <Dropdown>
            <DropdownTrigger
              isInvalid={validationErrors["categoryName"]}
              errorMessage={
                validationErrors["categoryName"] &&
                validationErrors["categoryName"]
              }
              className="w-full"
            >
              <Button className="mb-8" variant="faded">
                <div className="w-full flex justify-between">
                  <div className="flex justify-start w-full items-start">
                    {formData.categoryName === "" ? "" : formData.categoryName}
                  </div>
                  <div className="flex justify-end w-full items-end">
                    <FaChevronDown />
                  </div>
                </div>
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Static Actions"
              className="w-144"
              selectionMode="single"
              name="categoryName"
              onSelectionChange={(event) => {
                setFormData({
                  ...formData,
                  categoryName: Array.from(event)
                    .join(", ")
                    .replaceAll("_", " "),
                });
              }}
            >
              {CategoryList?.map((item) => (
                <DropdownItem key={item.name}>{item.name}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>

        {/* ClassName Input */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          label="Class Name"
          placeholder=" "
          labelPlacement="outside"
          name="className"
          value={formData["className"] || ""}
          onChange={changeHandler}
        />

        {/* Description Input  */}
        <Textarea
          label="Description"
          labelPlacement="outside"
          variant="faded"
          className="my-4"
          name="description"
          value={formData["description"] || ""}
          onChange={changeHandler}
        />

        {/* FrameWork Input */}

        <Select
          label="Select Framework"
          placeholder="Select any Framework"
          variant="faded"
          selectionMode="multiple"
          labelPlacement="outside"
          className="my-8"
          selectedKeys={new Set(formData["frameWork"])}
          onSelectionChange={(event) => {
            setFormData({
              ...formData,
              frameWork: Array.from(event),
            });
          }}
        >
          {frameWorkList?.map((item) => (
            <SelectItem key={item.id} value={item.name} className="py-2">
              {item.name}
            </SelectItem>
          ))}
        </Select>

        {/* AssignedTo User DropDown */}
        <MultiSelectInput
          data={AssignedToUserSchemaObj}
          value={selectedAssignedTo}
          valuesList={AllUserList}
          onChangeHandler={(title, value, type) => setSelectedAssignedTo(value)}
        />

        {/* Approval Workfflow DropDown */}

        <DropDownInput
          buttonCSS={"mb-8"}
          data={ApprovalWorkFlowDataSchemaObj}
          value={formData?.ApprovalWorkFlow || ""}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) =>
            setFormData({
              ...formData,
              ApprovalWorkFlow: value,
            })
          }
        />

        {/* Approver User DropDown */}
        {(formData?.ApprovalWorkFlow === "1-Step Approval" ||
          formData?.ApprovalWorkFlow === "2-Step Approval") && (
          <MultiSelectInput
            data={ApproverUserSchemaObj}
            value={selectedApproverTo}
            valuesList={AllApproverList}
            onChangeHandler={(title, value, type) =>
              setSelectedApproverTo(value)
            }
          />
        )}
        {/* Reviewer User DropDown */}
        {formData?.ApprovalWorkFlow === "2-Step Approval" && (
          <MultiSelectInput
            data={ReviewerUserSchemaObj}
            value={selectedReviewerTo}
            valuesList={AllReviewerList}
            onChangeHandler={(title, value, type) =>
              setSelectedReviewerTo(value)
            }
          />
        )}

        <MetricTagInput
          tags={tags}
          setTags={setTags}
          IntialvalidationError={tagsValidation}
          allTags={allTags}
        />

        {/* Formula Input */}

        <Select
          label="Select Formules"
          placeholder="Choose any Formules"
          variant="faded"
          selectionMode="multiple"
          labelPlacement="outside"
          className="my-8"
          selectedKeys={new Set(selectedFormulaList)}
          onSelectionChange={(event) => {
            setSelectedFormulaList(Array.from(event));
          }}
        >
          {allModels?.map((item) => (
            <SelectItem key={item.id} value={item.id} className="py-2">
              {item.title}
            </SelectItem>
          ))}
        </Select>

        {/* JsonSchema Input */}

        <div className="w-full my-2 mb-12">
          <p className="flex items-center gap-1 text-gray text-sm mb-2">
            <span>Json Schema</span>
            <Tooltip
              content={<JSonResponseInfoContentComp />}
              className="flex items-center cursor-pointer"
            >
              <lord-icon
                src="https://cdn.lordicon.com/yxczfiyc.json"
                style={{ width: "18px", height: "18px" }}
                trigger="hover"
                colors="primary:#8492a6"
              ></lord-icon>
            </Tooltip>
            <p className="text-[#f76497] text-">
              {validationErrors["JsonSchema"] && validationErrors["JsonSchema"]}
            </p>
          </p>

          <Card>
            <CardBody className="p-0">
              <CodeMirror
                value={jsonData}
                options={{
                  mode: "javascript",
                  theme: "material",
                  lineNumbers: true,
                  tabSize: 2,
                  extraKeys: { "Ctrl-Space": "autocomplete" },
                }}
                onChange={handleInputRead}
                onBeforeChange={handleJsonChange}
                editorDidMount={(editor) => {
                  editor.on("inputRead", handleInputRead);
                  editor.setOption("hintOptions", {
                    hint: jsonHint,
                  });
                }}

                //   onKeyDown={handleKeyDown} // Handle key down event
              />
            </CardBody>
          </Card>
        </div>

        {/* Link JSon Input */}

        <div className="w-full my-2 mb-12">
          <p className="flex items-center gap-1 text-gray text-sm mb-2">
            <span>Link Schema</span>
            <Tooltip
              content={<JSonResponseInfoContentComp />}
              className="flex items-center cursor-pointer"
            >
              <lord-icon
                src="https://cdn.lordicon.com/yxczfiyc.json"
                style={{ width: "18px", height: "18px" }}
                trigger="hover"
                colors="primary:#8492a6"
              ></lord-icon>
            </Tooltip>
            <p className="text-[#f76497] text-">
              {validationErrors["linkData"] && validationErrors["linkData"]}
            </p>
          </p>

          <Card>
            <CardBody className="p-0">
              <CodeMirror
                value={linkData}
                options={{
                  mode: "javascript",
                  theme: "material",
                  lineNumbers: true,
                  tabSize: 2,
                  extraKeys: { "Ctrl-Space": "autocomplete" },
                }}
                onChange={handleInputRead}
                onBeforeChange={handleLinkChange}
                editorDidMount={(editor) => {
                  editor.on("inputRead", handleInputRead);
                  editor.setOption("hintOptions", {
                    hint: jsonHint,
                  });
                }}

                //   onKeyDown={handleKeyDown} // Handle key down event
              />
            </CardBody>
          </Card>
        </div>
        {/* Preview,Submit button */}
        <div className="flex gap-4 my-4 w-full">
          <Button
            color="danger"
            variant="light"
            size="sm"
            onClick={showPreviewHandler}
          >
            Preview
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={submitHandler}
            className={`cursor-pointer ${
              userRole !== "Admin" ? "pointer-events-none opacity-50" : ""
            }`}
          >
            Submit
          </Button>
        </div>
      </div>

      {/* Preview Card Open */}
      {showPreview && (
        <div className="flex flex-col items-center w-[48%]">
          <PreviewForm data={previewData} />
        </div>
      )}
    </div>
  );
};

export default EditJsonFormBuilder;

/* 

---Check the Validation
---creating the payload and push to strapi


*/
