import React from "react";
import ChartGenerator from "./ChartGenrator";

const areaData = [
  { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
];

const barData = [
  { name: "arun.n", value: 1708 },
  { name: "root", value: 73 },
  { name: "null", value: 26 },
  { name: "Manoj", value: 23 },
  { name: "jeadam", value: 10 },
];

const pieData = [
  { name: "Group A", value: 400, color: "#8884d8" },
  { name: "Group B", value: 300, color: "#82ca9d" },
  { name: "Group C", value: 300, color: "#ffc658" },
  { name: "Group D", value: 200, color: "#ff7300" },
];

const scatterData = [
  { x: 1, y: 4000 },
  { x: 2, y: 3000 },
  { x: 3, y: 2000 },
  { x: 4, y: 2780 },
  { x: 5, y: 1890 },
  { x: 6, y: 2390 },
  { x: 7, y: 3490 },
];

const lineData = [
  { name: "Jan", Emission: 229459 },
  { name: "Feb", Emission: 169459 },
  { name: "Mar", Emission: 269459 },
  { name: "Apr", Emission: 179459 },
  { name: "May", Emission: 219459 },
  { name: "Jun", Emission: 185059 },
  { name: "Jul", Emission: 182359 },
  { name: "Aug", Emission: 201239 },
  { name: "Sep", Emission: 185159 },
  { name: "Oct", Emission: 237659 },
  { name: "Nov", Emission: 134559 },
  { name: "Dec", Emission: 194559 },
];

const radarData = [
  { subject: "Math", A: 120, B: 110, fullMark: 150 },
  { subject: "Chinese", A: 98, B: 130, fullMark: 150 },
  { subject: "English", A: 86, B: 130, fullMark: 150 },
  { subject: "Geography", A: 99, B: 100, fullMark: 150 },
  { subject: "Physics", A: 85, B: 90, fullMark: 150 },
];

const Charts = ({ type }) => {

  switch (type) {
    case "area":
      return <ChartGenerator chartType="area" data={areaData} />;
    case "bar":
      return <ChartGenerator chartType="bar" data={barData} />;
    case "pie":
      return <ChartGenerator chartType="pie" data={pieData} />;
    case "scatter":
      return <ChartGenerator chartType="scatter" data={scatterData} />;
    case "line":
      return <ChartGenerator chartType="line" data={lineData} />;
    case "radar":
      return <ChartGenerator chartType="radar" data={radarData} />;
    default:
      return <h1>Invalid chart type.</h1>;
  }
};

export default Charts;
