import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getMultipleFilters,
  patchData,
} from "../../controllers/vectordbController";

import { ThreadManager } from "../../utils/ThreadSocket";
import { Avatar, Button, Image, Textarea, Tooltip } from "@nextui-org/react";
import Logo from "../../Images/logo.svg";
import { RightArrow } from "../../Images/RightArrow";
import { colorPicker } from "../../controllers/basicControllers";
import HayaTableRender from "../Haya/HayaTableRender";
import RenderDocs from "./RenderDocs";

import {
  ClipboardDocumentIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import ViewChart from "../Charts/ViewChart";
import { DataContext } from "../Haya/Haya";
import Anomaly from "./Anomaly";
import useGetUserDetail from "../../hooks/useGetUserInfo";
import useGetOrgDetail from "../../hooks/useGetOrgDetail";
import { renderBoldText } from "../../utils/helper";

const Thread = () => {
  const navigate = useNavigate();
  const { setDisableNavigation } = useContext(DataContext);
  const [disabled, setDisabled] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const responseTable = [];
  const [thread, setThread] = useState([]);
  const [id, setId] = useState(null);
  const [error, setError] = useState(false);
  const { OrgDetail: spaceInfo } = useGetOrgDetail();
  const { userDetails: userInfo } = useGetUserDetail();
  const { _orgId, _threadId } = useParams();

  const handleButtonClick = (val) => {
    if (disabled || !thread[thread.length - 1]?.response) {
      return;
    }
    ThreadManager.getInstance(
      setThread,
      spaceInfo[0]?.attributes?.Name,
      spaceInfo[0]?.attributes?.VerctorDBCollection
    ).sendMessage(val.trim(), setThread, userInfo.id);
  };
  const handleKeyPress = () => {
    if (inputValue.trim() !== "") {
      setInputValue("");
      ThreadManager.getInstance(
        setThread,
        spaceInfo[0]?.attributes?.SpaceName,
        spaceInfo[0]?.attributes?.VerctorDBCollection
      ).sendMessage(inputValue.trim(), setThread, userInfo.id);
    }
  };
  const messagesEndRef = useRef(null);
  function apphend_table(props) {
    responseTable.push(props);
  }
  const copyRespHandler = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Response copied successfully.");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };
  const updateThread = async () => {
    if (thread.length > 0 && thread[thread.length - 1]?.resp_id) {
      try {
        await patchData({ thread: thread, updatedAt: Date.now() }, id, "Audyt");
      } catch (e) {
        console.log(e);
      }
    }
  };
  useEffect(() => {
    if ((disabled || !thread[thread.length - 1]?.response) && error) {
      setDisableNavigation(true);
    } else {
      setDisableNavigation(false);
    }
  }, [thread, disabled]);
  useEffect(() => {
    const fetchThread = async () => {
      try {
        const resp = await getMultipleFilters([
          { key: "chatId", value: String(_threadId) },
        ]);

        if (resp.length === 0) {
          toast("Thread doesn't exist.");
          navigate(`/${_orgId}/audyt-ai`);
          return;
        }
        setId(resp?.[0]?.payload?.id);
        if (
          resp?.[0]?.payload?.thread?.length === 1 &&
          !resp?.[0]?.payload?.thread[0]?.resp_id &&
          resp?.[0]?.payload?.thread[0]?.query &&
          !disabled
        ) {
          ThreadManager.getInstance(
            setThread,
            spaceInfo[0]?.attributes?.SpaceName,
            spaceInfo[0]?.attributes?.VerctorDBCollection
          ).sendMessage(
            resp?.[0]?.payload?.thread[0]?.query,
            setThread,
            userInfo.id
          );
        } else {
          setThread(resp?.[0]?.payload?.thread);
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (_threadId) {
      setThread([]);
      fetchThread();
    }
  }, [_threadId]);
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }

    setDisabled(true);
    const disableTimeout = setTimeout(() => setDisabled(false), 1000);

    const updateTimeout = setTimeout(async () => {
      await updateThread();
    }, 1000);
    const interval = setInterval(() => {
      if (
        ThreadManager.getInstance(
          setThread,
          spaceInfo[0]?.attributes?.SpaceName,
          spaceInfo[0]?.attributes?.VerctorDBCollection
        ).checkConnection() === false &&
        !thread[thread.length - 1]?.response
      ) {
        setError(true);
      } else {
        setError(false);
      }
    }, 4000);
    return () => {
      clearTimeout(disableTimeout);
      clearTimeout(updateTimeout);
      clearInterval(interval);
    };
  }, [thread]);
  return (
    <div className="w-full h-full flex pb-4 overflow-y-auto flex-col gap-4">
      <div className="flex-1 flex flex-col gap-2 overflow-y-auto">
        <div
          ref={messagesEndRef}
          className="h-full flex flex-col gap-4 px-8 pt-4 overflow-y-auto custom-scrollbar"
        >
          {thread.map((it, index) => (
            <div className="flex flex-col gap-4 w-full">
              <div className="flex px-2 flex-col">
                <div className="flex flex-row gap-4 items-center">
                  <Avatar
                    size="sm"
                    src={userInfo?.Picture?.url}
                    color={colorPicker(userInfo?.id)}
                    name={userInfo?.email?.slice(0, 1).toUpperCase()}
                  />
                  <p className="font-semibold">You</p>
                </div>
                <p className="pl-12">
                  {it?.query?.split("\n")?.map((value) => (
                    <p>{value}</p>
                  ))}
                  {/* {loading && index === chatMessages.length - 1 && (
                              <span className="cursor-dot"></span>
                            )} */}
                </p>
              </div>
              <div className="flex flex-col px-2 pb-4 rounded-md group">
                <div className="flex flex-row gap-4 items-center pt-10">
                  <Avatar src={Logo} size="sm" />
                  <p className="font-semibold">Audyt</p>
                </div>
                <>
                  {it?.response ? (
                    <div className={`mb-2 `}>
                      <div className="flex items-center pl-12">
                        <div className="w-full">
                          {it?.response?.split(/\n|\\n/).map((part, index) => {
                            const tablepart = part.trim().startsWith("|");

                            if (part.trim().startsWith("-")) {
                              return (
                                <p key={`${index}`} className="leading-loose">
                                  &bull; {renderBoldText(part.substring(1))}
                                  <br />
                                </p>
                              );
                            } else if (tablepart) {
                              if (part.trim().includes("----")) {
                                return (
                                  responseTable && (
                                    <>
                                      <div className="max-h-100px overflow-y-auto">
                                        <HayaTableRender
                                          markdownTable={responseTable}
                                        />
                                      </div>
                                    </>
                                  )
                                );
                              } else {
                                const appendedPart = part.trim() + "\n";
                                apphend_table(appendedPart);
                                return null;
                              }
                            } else {
                              return (
                                <p key={index} className="leading-loose">
                                  {renderBoldText(part)}
                                  <br />
                                </p>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  ) : index === thread.length - 1 ? (
                    error ? (
                      <div className="pl-12">Error connecting to model</div>
                    ) : (
                      <div className="w-full pl-12 pt-2 items-center justify-start">
                        <lord-icon
                          src="https://cdn.lordicon.com/lqxfrxad.json"
                          trigger="loop"
                          delay="100"
                          style={{ width: "30px", height: "30px" }}
                        ></lord-icon>
                      </div>
                    )
                  ) : (
                    <div className="w-full pl-12 pt-2 items-center justify-start">
                      Error 404
                    </div>
                  )}
                  {it?.chart && (
                    <div className=" w-full flex items-center justify-center">
                      {" "}
                      <ViewChart
                        id={it?.resp_id}
                        dataProductId={it?.data_product}
                        chartInfo={{
                          name: it?.chart?.chart_title ?? "",
                          description: it?.chart?.chart_description ?? "",
                        }}
                        sql={it?.chart?.sql_statement}
                        chartType={it?.chart?.chart}
                        data={it?.chart?.sql_result}
                      />{" "}
                    </div>
                  )}
                  {it?.anomaly && it?.anomaly !== "None" && (
                    <Anomaly threadResp={it} />
                  )}
                  {it.docs && it.docs.length > 0 && (
                    <RenderDocs filteredDocuments={it.docs} />
                  )}
                </>
                <div className="flex opacity-0 group-hover:opacity-100 flex-row pl-4">
                  <Tooltip content={"Like"}>
                    <Button isIconOnly variant="light" size="sm">
                      <HandThumbUpIcon className="size-4 text-transporent" />
                    </Button>
                  </Tooltip>
                  <Tooltip content={"Copy"}>
                    <Button
                      onClick={() => copyRespHandler(it?.response)}
                      size="sm"
                      isIconOnly
                      variant="light"
                    >
                      <ClipboardDocumentIcon className="size-4 text-transporent" />
                    </Button>
                  </Tooltip>
                  <Tooltip content={"Dislike"}>
                    <Button isIconOnly variant="light" size="sm">
                      <HandThumbDownIcon className="size-4 text-transporent" />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          ))}
          <div className="h-0" ref={messagesEndRef} />
        </div>
      </div>
      <div className="w-full h-auto flex flex-col gap-4 ">
        {/* {thread.length > 0 &&
          thread?.[thread.length - 1]?.similar_questions && (
            <div className=" px-8 w-full flex items-center gap-4 ">
              <Card
                onClick={() =>
                  handleButtonClick(
                    thread?.[thread.length - 1]?.similar_questions[0]
                  )
                }
                isPressable
                shadow="none"
                className="w-full p-2 h-full border border-divider "
              >
                <CardBody>
                  <div>{thread?.[thread.length - 1]?.similar_questions[0]}</div>
                </CardBody>
              </Card>

              <Card
                onClick={() =>
                  handleButtonClick(
                    thread?.[thread.length - 1]?.similar_questions[1]
                  )
                }
                isPressable
                shadow="none"
                className="w-full p-2 h-full border border-divider "
              >
                <CardBody>
                  <div>{thread?.[thread.length - 1]?.similar_questions[1]}</div>
                </CardBody>
              </Card>
            </div>
          )} */}
        <Textarea
          disabled={disabled || !thread[thread.length - 1]?.response}
          className={`w-full ${
            inputValue === "" ? "h-12" : "h-auto"
          } py-1 px-8`}
          size="sm"
          radius="md"
          startContent={
            <Image
              src={Logo}
              width={25}
              height={25}
              radius="full"
              className="text-large text-default-400 pointer-events-none flex-shrink-0 "
            />
          }
          endContent={
            (disabled || !thread[thread.length - 1]?.response) && !error ? (
              <div className="pt-1 mr-4">
                <lord-icon
                  src="https://cdn.lordicon.com/lqxfrxad.json"
                  trigger="loop"
                  delay="100"
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
              </div>
            ) : (
              <div className="mr-4">
                <RightArrow textdisabledState={inputValue} />
              </div>
            )
          }
          placeholder="Message Haya..."
          variant="flat"
          value={inputValue}
          minRows={1}
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          onKeyPress={(event) => {
            if (disabled || !thread[thread.length - 1]?.response) {
              return;
            }
            if (event.key === "Enter") {
              event.preventDefault();
              if (event.shiftKey) {
                setInputValue((prev) => prev + "\n");
              } else {
                handleKeyPress();
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default Thread;
