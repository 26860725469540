import React, { useEffect, useState } from "react";
import BreadCrumbsPage from "../../../components/BreadCrumbsPage";
import { Tab, Tabs } from "@nextui-org/react";
import ViewReportTab from "./ViewReportTab";
import SettingReportTab from "./SettingReportTab";
import { useParams } from "react-router-dom";
import { getDataWithFilterUrl } from "../../../controllers/APIController";

const EditReport = () => {
  const { _id } = useParams();
  const [reportDetails, setReportDetails] = useState(null);

  useEffect(() => {
    const url = "filters[id][$eqi]=" + _id;
    getDataWithFilterUrl("reports", url)
      .then((data) => {
        setReportDetails(data?.data?.[0] || null);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [_id]);

  return (
    <BreadCrumbsPage>
      <h2 className="text-3xl font-medium">ESG Report</h2>
      <div className="mt-4">
        This section provides an overview of the company’s Environmental,
        Social, and Governance (ESG) performance. The report tracks the
        company’s efforts to minimize environmental impact, improve social
        responsibility, and ensure strong governance practices.
      </div>

      <Tabs variant="light" className="mt-8">
        <Tab key={"view"} title={"View"}>
          <ViewReportTab reportDetails={reportDetails} />
        </Tab>

        <Tab key={"setting"} title={"Setting"}>
          <SettingReportTab reportDetails={reportDetails} />
        </Tab>
      </Tabs>
    </BreadCrumbsPage>
  );
};

export default EditReport;
