import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { DateFormatorDD, getFrameworkUrl } from "../../utils/helper";
import { Card, CardBody, CardHeader, Image } from "@nextui-org/react";
import Completed from "../../Images/done.svg";
import incompleteIcon from "../../Images/incompleteIcon.svg";
import lastUpdate from "../../Images/lastUpdate.svg";
import { fetchMetricInfo } from "../../utils/helper";
import { capitalize } from "../../controllers/APIController";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import LoadingPage from "../../components/Loader/LoadingPage";
import MetricSkeletonContainer from "../../components/Skeleten/MetricSkeletonContainer";
import useGetOrgFramework from "../../hooks/useGetOrgFramework";

const Metrics = () => {
  const [isLoading, setisLoading] = useState(true);
  const [metricInfo, setMetricInfo] = useState({
    environment: {},
    social: {},
    governance: {},
    survey: {},
  });
  const metricNameList = [
    "environment",
    "social",
    "governance",
    "survey",
    "vendor details",
  ];

  const navigate = useNavigate();
  const { _orgId: currOrg } = useParams();
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const { frameworkList, isLoading: isLoadFramework } =
    useGetOrgFramework(OrgInfo);


  const frameworkUrl = getFrameworkUrl(frameworkList);

  useEffect(() => {
    if (isLoadFramework) return;
    fetchMetricInfo(metricNameList, OrgInfo, frameworkUrl)
      .then((data) => {
        setMetricInfo(data);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadFramework,OrgInfo]);

  const naviageHandler = (name) => {
    const categoryId = name.replaceAll(" ", "-");
    navigate(`/${currOrg}/metrics/${categoryId}`);
  };

  return (
    <BreadCrumbsPage>
      <>
        <div className="text-3xl font-medium">ESG Metrics </div>
        <div className="mt-8">
          Here are a list of your ESG Metrics that are calculated to generate
          your ESG report. These metrics will help Audyt to generate a report.
        </div>

        <LoadingPage
          isLoading={isLoading}
          alterElement={<MetricSkeletonContainer />}
        >
          <>
            {metricNameList.map((name) => {
              const metric = metricInfo[name] || {};
              const totalCount = metric.totalCount || 0;
              const answeredCount = metric.CompletedCount || 0;
              const remainingCount = metric.InCompleteCount || 0;
              const lastUpdatedAt = metric?.lastUpdatedAt
                ? DateFormatorDD(metric.lastUpdatedAt)
                : "--";

              return (
                <div
                  key={name}
                  className=""
                  onClick={() => naviageHandler(name)}
                >
                  <Card
                    className="bg-[#F8F8F8] h-[160px] w-full mt-8 p-4 cursor-pointer"
                    shadow="none"
                  >
                    <CardHeader>
                      <div className="text-xl font-medium">
                        {capitalize(name)} Metrics
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="flex gap-16">
                        <div>
                          <div className="flex items-center gap-4">
                            <Image
                              width={50}
                              alt="NextUI hero Image"
                              src={Completed}
                            />
                            <div>
                              <div className="text-sm">Total Metrics</div>
                              <div className="font-bold">{totalCount}</div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex items-center gap-4">
                            <Image
                              width={50}
                              alt="NextUI hero Image"
                              src={Completed}
                            />
                            <div>
                              <div className="text-sm">
                                Total Completed Metrics
                              </div>
                              <div className="font-semibold">
                                {answeredCount}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex items-center gap-4">
                            <Image
                              width={50}
                              alt="NextUI hero Image"
                              src={incompleteIcon}
                            />
                            <div>
                              <div className="text-sm">
                                Total Pending Metrics
                              </div>
                              <div className="font-semibold">
                                {remainingCount}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-4">
                          <Image
                            width={50}
                            alt="NextUI hero Image"
                            src={lastUpdate}
                          />
                          <div>
                            <div className="text-sm">Last Updated at</div>
                            <div className="font-semibold">{lastUpdatedAt}</div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              );
            })}
          </>
        </LoadingPage>
      </>
    </BreadCrumbsPage>
  );
};

export default Metrics;
