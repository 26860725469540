import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Select,
  SelectItem,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";

import React, { useEffect, useState } from "react";
import { AILogo } from "../../constant/FormBuilder/FormBuilderConstant";

import {
  getSingleCollectionData,
  PublishData,
  UpdateSingleCollectionData,
} from "../../controllers/APIController";
import {
  ApprovalWorkFlowDataSchemaObj,
  CategoryDataSchemaObj,
  FieldList,
  FrameWorkDataSchemaObj,
  FrequecyCountMap,
  FrequencyDataObj,
} from "../../utils/constant";
import PreviewForm from "./PreviewForm";
import { getDataWithFilterUrl } from "../../controllers/APIController";
import AddModal from "./AddModal";
import FieldInfoTable from "./FieldInfoTable";
import { useNavigate, useParams } from "react-router-dom";
import AddFormulaModal from "./AddFormulaModal";
import FormulaInfoTable from "./FormulaInfoTable";
import {
  CustomFrequencyInput,
  DropDownInput,
  MultiSelectInput,
} from "./InputField/InputField";
import MetricTagInput from "./InputField/MetricTagInput";
import useGetAllModels from "../../hooks/useGetAllModels";
import {
  getAllApproverOfOrgnization,
  getAllReviewerOfOrgnization,
  getAllUserOfOrgnization,
  getFieldInfoListFromGetJSonSchema,
  getLinkInfoListFromJson,
} from "../../utils/helper";
import {
  ApproverUserSchemaObj,
  AssignedToUserSchemaObj,
  ReviewerUserSchemaObj,
  sectorSchema,
} from "../../constant/FormBuilder/FormBuilderConstant";
import useGetSectorName from "../../hooks/useGetSectorName";
import useGetDefaultOrg from "../../hooks/useGetDefaultOrg";
import FrameworFieldAccodian from "./FrameworFieldAccodian";
import ComingSoon from "../../components/ComingSoon";

const initialFormData = {
  metricName: "",
  Category: "",
  className: "",
  description: "",
  frameWork: [],
  ApprovalWorkFlow: "Default",
  SectorText: "",
};

const getJSonSchemaFromFieldInfoList = (fieldInfoList, selectedFormulaList) => {
  const propertiesList = {};

  const requiredArr = [];
  fieldInfoList.forEach((data) => {
    const fieldInput = {};

    if (data["Required"]) {
      requiredArr.push(data["Title"]);
    }
    Object.keys(data).map((key) => {
      fieldInput[key.toLocaleLowerCase()] = data[key];
    });

    propertiesList[data["Title"]] = fieldInput;
  });

  const response = {
    type: "object",
    title: "",
    required: requiredArr,
    properties: propertiesList,
    description: "",
    formula: selectedFormulaList,
  };
  return response;
};

const getLinkJsonReponse = (list) => {
  const reponse = [];
  list.forEach((item) => {
    reponse.push({
      id: item?.id,
      name: item.Title,
      link: item.link,
    });
  });
  return {
    Links: reponse,
  };
};

const UIMetricBuilderValidation = (
  formData,
  fieldInfoList,
  requiredArr,
  allMetricName
) => {
  const err = {};
  let isValid = true;

  if (fieldInfoList.length === 0) {
    err["BuildData"] = "Build Data should have atleast one field";
    isValid = false;
  }
  if (allMetricName?.includes(formData["metricName"]?.trim()?.toLowerCase())) {
    err["metricName"] = "Metric Name already have been used";
    isValid = false;
  }

  Object.keys(formData).forEach((key) => {
    if (requiredArr.includes(key) && formData[key] === "") {
      err[key] = `${key} is required`;
      isValid = false;
    }
  });
  return { isValid: isValid, error: err };
};

const AIFormBuilder = ({ metricInfo }) => {
  const navigator = useNavigate();
  const { _orgId } = useParams();

  const { allModels, setAllModels } = useGetAllModels();
  const [loader, setLoader] = useState(true);
  const { allSectorName } = useGetSectorName();
  const { OrgDetail: DefaultOrgDetail } = useGetDefaultOrg();
  const [allMetricName, setAllMetricName] = useState([]);
  const [frameWorkList, setFrameWorkList] = useState([]);
  const [frameWorkDetails, setFrameWorkDetails] = useState({});
  const [MetricNameList, setMetricNameList] = useState({
    TCFD: "",
    BRSR: "",
    SASB: "",
    GRI: "",
  });
  const [overviewList, setOverviewList] = useState({
    TCFD: "",
    BRSR: "",
    SASB: "",
    GRI: "",
  });
  const [guidanceList, setGuidanceList] = useState({
    TCFD: "",
    BRSR: "",
    SASB: "",
    GRI: "",
  });

  const [formData, setFormData] = useState(initialFormData);

  const [frequency, setFrequency] = useState(FrequencyDataObj?.values[0]?.name);
  const [customFrequency, setCustomFrequency] = useState(0.01);

  const showCustomFrequencyInput = frequency === "Custom";

  const [validationErrors, setValidationErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const [fieldType, setFieldType] = useState(""); // field type that we want to create
  const [fieldInfoList, setFieldInfoList] = useState([]); // JsonSchema Input field info
  const [LinkInfoList, setLinkInfoList] = useState([]); // Link field info in Metric
  const [selectedFormulaList, setSelectedFormulaList] = useState([]);
  const [FormulaInfoList, setFormulaInfoList] = useState([]); //  field info in Metric

  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedApproverTo, setSelectedApproverTo] = useState([]);
  const [selectedReviewerTo, setSelectedReviewerTo] = useState([]);
  const [AllUserList, setAllUserList] = useState([]);
  const [AllApproverList, setAllApproverList] = useState([]);
  const [AllReviewerList, setAllReviewerList] = useState([]);

  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const [tagsValidation, setTagsValidation] = useState("");

  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: isOpenLink,
    onOpen: onOpenLink,
    onOpenChange: onOpenChangeLink,
  } = useDisclosure();

  const {
    isOpen: isOpenFormula,
    onOpen: onOpenFormula,
    onOpenChange: onOpenChangeFormula,
  } = useDisclosure();

  const requiredArr = [
    "metricName",
    "Category",
    "jsonSchema",
    "ApprovalWorkFlow",
  ];

  const FrameWorkDataSchema = FrameWorkDataSchemaObj;
  FrameWorkDataSchema.values = frameWorkList;

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const fieldListValues = Object.keys(FieldList).map((key) => {
    return [key, FieldList[key]];
  });
  const changeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const showPreviewHandler = () => {
    setShowPreview(false);
    const validationResponse = UIMetricBuilderValidation(
      formData,
      fieldInfoList,
      requiredArr,
      allMetricName
    );

    setValidationErrors(validationResponse.error);
    if (!validationResponse.isValid) {
      return;
    }

    const JsonSchemaData = getJSonSchemaFromFieldInfoList(
      fieldInfoList,
      FormulaInfoList
    );
    const frameWorkResponse = { id: formData?.frameWork || [] };

    const publishData = {
      Name: formData.metricName,
      Category: formData.Category,
      ClassName: formData.className,
      Description: formData.description,
      WorkFlow: formData.ApprovalWorkFlow,
      Framework: frameWorkResponse,
      JsonSchema: JsonSchemaData,
      Links: getLinkJsonReponse(LinkInfoList),
      FrequencyValue:
        frequency === "Custom" ? +customFrequency : FrequecyCountMap[frequency],
      Organization: OrgInfo,
    };

    setPreviewData(publishData);
    setShowPreview(true);
  };

  const submitHandler = async () => {
    const validationResponse = UIMetricBuilderValidation(
      formData,
      fieldInfoList,
      requiredArr,
      allMetricName
    );

    if (!validationResponse.isValid) {
      setValidationErrors(validationResponse.error);
      return;
    }
    const JsonSchemaData = getJSonSchemaFromFieldInfoList(
      fieldInfoList,
      FormulaInfoList
    );
    const frameWorkResponse = { id: formData?.frameWork || [] };
    const AssignedToData = selectedAssignedTo?.map((id) => +id);
    const ApproverToData = selectedApproverTo?.map((id) => +id);
    const ReviewerToData = selectedReviewerTo?.map((id) => +id);

    const moreInfo = {
      Guidance: guidanceList,
      Overview: overviewList,
      NameList: { ...MetricNameList, Generic: formData?.metricName },
    };

    const publishData = {
      Name: formData.metricName,
      Category: formData.Category,
      ClassName: formData.className,
      Description: formData.description,
      Sector: formData.SectorText,
      WorkFlow: formData.ApprovalWorkFlow,
      Framework: frameWorkResponse,
      JsonSchema: JsonSchemaData,
      Links: getLinkJsonReponse(LinkInfoList),
      FrequencyValue:
        frequency === "Custom" ? +customFrequency : FrequecyCountMap[frequency],
      TagList: tags,
      Organization: OrgInfo,
      AssignedTo: AssignedToData,
      ApproverTo: ApproverToData,
      ReviewerTo: ReviewerToData,
      ...moreInfo,
    };


    let result = await PublishData({ data: publishData }, "metrics");

    const newTags = tags?.filter((name) => !allTags?.includes(name));
    const updatedTags = [...allTags, ...newTags];
    await UpdateSingleCollectionData("tag", { List: updatedTags });

    navigator(`/${_orgId}/metrics-builder`);
  };

  const addFieldHandler = (listItem) => {
    setFieldInfoList([...fieldInfoList, listItem]);
  };

  const addLinkHandler = (listItem) => {
    setLinkInfoList([...LinkInfoList, listItem]);
  };
  const addFormulaHandler = (listItem) => {
    setFormulaInfoList([...FormulaInfoList, listItem]);
  };

  useEffect(() => {
    getDataWithFilterUrl("frameworks")
      .then((data) => {
        let frameWorkData = data.data;
        const f = {};
        frameWorkData = frameWorkData?.map((item) => {
          f[item.id] = item.attributes.Name;
          return { id: item.id, name: item?.attributes?.Name };
        });
        setFrameWorkDetails(f);
        setFrameWorkList(frameWorkData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getDataWithFilterUrl("metrics", orgFilterUrl)
      .then((data) => {
        const list = data?.data?.map((a) => {
          return a?.attributes?.Name.trim().toLowerCase();
        });
        setAllMetricName(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getSingleCollectionData("tag")
      .then((data) => {
        setAllTags(data?.data?.attributes?.List || []);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fun = async () => {
      let allUser = await getAllUserOfOrgnization();
      let allReviewer = await getAllReviewerOfOrgnization();
      let allApprover = await getAllApproverOfOrgnization();

      allUser = allUser?.map((user) => {
        return { id: user.id, name: user.username };
      });

      allReviewer = allReviewer?.map((user) => {
        return { id: user.id, name: user.username };
      });
      allApprover = allApprover?.map((user) => {
        return { id: user.id, name: user.username };
      });

      setAllUserList(allUser);
      setAllApproverList(allApprover);
      setAllReviewerList(allReviewer);
    };
    fun();
  }, []);

  useEffect(() => {
    let response = metricInfo;

    if (!response) {
      // setLoader(false);
      return;
    }
    setFormData({
      metricName: response?.attributes?.Name || "",
      Category: response?.attributes?.Category || "",
      className: response?.attributes?.ClassName || "",
      description: response?.attributes?.Description || "",
      frameWork: response?.attributes?.Framework?.id || [],
      ApprovalWorkFlow: response?.attributes?.WorkFlow,
      SectorText: response?.attributes?.Sector || "",
    });
    const AssignedToData = response?.attributes?.AssignedTo?.data?.map((user) =>
      user?.id?.toString()
    );
    const ApproverToData = response?.attributes?.ApproverTo?.data?.map((user) =>
      user?.id?.toString()
    );
    const ReviewerToData = response?.attributes?.ReviewerTo?.data?.map((user) =>
      user?.id?.toString()
    );
    setSelectedAssignedTo(AssignedToData);
    setSelectedApproverTo(ApproverToData);
    setSelectedReviewerTo(ReviewerToData);

    const frequencyNumber = +response?.attributes?.FrequencyValue;
    const frequencyval =
      frequencyNumber === -1
        ? "Custom"
        : frequencyNumber === 30
        ? "Every month"
        : frequencyNumber === 7
        ? "Every week"
        : frequencyNumber === 1
        ? "Everyday"
        : "Only Once";
   

    setFrequency(frequencyval);

    setFieldInfoList(
      getFieldInfoListFromGetJSonSchema(
        response?.attributes?.JsonSchema?.properties
      )
    );
    setLinkInfoList(
      getLinkInfoListFromJson(response?.attributes?.Links?.Links)
    );

    setFormulaInfoList(response?.attributes?.JsonSchema?.formula || []);
    setTags(response?.attributes?.TagList || []);
    let formulaList = response?.attributes?.JsonSchema?.formula || [];
    formulaList = formulaList.map((data) => data?.id?.toString());
    setSelectedFormulaList(formulaList);
    setOverviewList(response?.attributes?.Overview || {});
    setGuidanceList(response?.attributes?.Guidance || {});
    setMetricNameList(response?.attributes?.NameList || {});
    setLoader(false);
  }, [metricInfo]);


  return (
    <div className="flex items-start justify-between my-2">
      <div className="flex flex-col items-center w-[50%]">
        {/* Metric Name Input  */}
        <Input
          type="text"
          className="my-8"
          variant="faded"
          isRequired
          label="Metric Name"
          labelPlacement="outside"
          placeholder=" "
          name="metricName"
          onChange={changeHandler}
          value={formData["metricName"]}
          isInvalid={validationErrors["metricName"]}
          errorMessage={
            validationErrors["metricName"] && validationErrors["metricName"]
          }
        />

        {/* Category DropDown */}

        <DropDownInput
          buttonCSS={"mb-8"}
          data={CategoryDataSchemaObj}
          value={formData?.Category === "" ? "" : formData.Category}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) =>
            setFormData({
              ...formData,
              Category: value,
            })
          }
        />

        {/* ClassName Input */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          label="Class Name"
          placeholder=" "
          labelPlacement="outside"
          name="className"
          onChange={changeHandler}
          value={formData["className"]}
        />

        {/* Description Input  */}
        <Textarea
          label="Description"
          labelPlacement="outside"
          variant="faded"
          className="my-4"
          name="description"
          onChange={changeHandler}
          value={formData["description"]}
        />

        <Textarea
          className="my-4"
          variant="faded"
          label={`General Overview`}
          labelPlacement="outside"
          placeholder=" "
          value={overviewList["Generic"]}
          onChange={(event) =>
            setOverviewList({
              ...overviewList,
              Generic: event.target.value,
            })
          }
        />

        <Textarea
          className="my-4"
          variant="faded"
          label={`General Guidance`}
          labelPlacement="outside"
          placeholder=" "
          value={guidanceList["Generic"]}
          onChange={(event) =>
            setGuidanceList({
              ...guidanceList,
              Generic: event.target.value,
            })
          }
        />

        {DefaultOrgDetail?.id === OrgInfo && (
          <DropDownInput
            data={{
              ...sectorSchema,
              values: allSectorName,
            }}
            value={formData?.SectorText}
            validationErrors={validationErrors}
            onChangeHandler={(title, value, type) =>
              setFormData({ ...formData, SectorText: value })
            }
            buttonCSS="mb-4"
          />
        )}

        {/* FrameWork Input */}

        {formData?.Category?.toLowerCase() !== "reference data" && (
          <Select
            label="Select Framework"
            placeholder="Select any Framework"
            variant="faded"
            selectionMode="multiple"
            labelPlacement="outside"
            className="my-6"
            // selectedKeys={new Set(formData["frameWork"])}
            onSelectionChange={(event) => {
             

              setFormData({
                ...formData,
                frameWork: Array.from(event),
              });
            }}
          >
            {frameWorkList?.map((item) => (
              <SelectItem key={item.id} value={item.name} className="py-2">
                {item.name}
              </SelectItem>
            ))}
          </Select>
        )}

        {formData?.Category?.toLowerCase() !== "reference data" &&
          formData?.frameWork &&
          formData?.frameWork?.map((id) => {
            return (
              <FrameworFieldAccodian
                title={frameWorkDetails[+id]}
                setMetricNameList={setMetricNameList}
                MetricNameList={MetricNameList}
                setGuidanceList={setGuidanceList}
                guidanceList={guidanceList}
                setOverviewList={setOverviewList}
                overviewList={overviewList}
              />
            );
          })}

        {/* AssignedTo User DropDown */}
        <MultiSelectInput
          data={AssignedToUserSchemaObj}
          value={selectedAssignedTo}
          valuesList={AllUserList}
          onChangeHandler={(title, value, type) => setSelectedAssignedTo(value)}
        />

        {/* Approval Workfflow DropDown */}
        <DropDownInput
          buttonCSS={"mb-8"}
          data={ApprovalWorkFlowDataSchemaObj}
          value={formData?.ApprovalWorkFlow || ""}
          validationErrors={validationErrors}
          onChangeHandler={(title, value, type) =>
            setFormData({
              ...formData,
              ApprovalWorkFlow: value,
            })
          }
        />

        {/* Approver User DropDown */}
        {(formData?.ApprovalWorkFlow === "1-Step Approval" ||
          formData?.ApprovalWorkFlow === "2-Step Approval") && (
          <MultiSelectInput
            data={ApproverUserSchemaObj}
            value={selectedApproverTo}
            valuesList={AllApproverList}
            onChangeHandler={(title, value, type) =>
              setSelectedApproverTo(value)
            }
          />
        )}
        {/* Reviewer User DropDown */}
        {formData?.ApprovalWorkFlow === "2-Step Approval" && (
          <MultiSelectInput
            data={ReviewerUserSchemaObj}
            value={selectedReviewerTo}
            valuesList={AllReviewerList}
            onChangeHandler={(title, value, type) =>
              setSelectedReviewerTo(value)
            }
          />
        )}

        {/* Frequency DropDown */}

        <DropDownInput
          buttonCSS={"mb-8"}
          data={FrequencyDataObj}
          value={frequency}
          validationErrors={1}
          onChangeHandler={(title, value, type) => setFrequency(value)}
        />

        {showCustomFrequencyInput && (
          <CustomFrequencyInput
            value={customFrequency}
            onChangeHandler={(value) => setCustomFrequency(value)}
          />
        )}

        {/* Metrics Tags Input */}
        <MetricTagInput
          tags={tags}
          setTags={setTags}
          IntialvalidationError={tagsValidation}
          allTags={allTags}
        />

        {/* JsonSchema Input */}

        <div className="w-full mb-8">
          <div className="flex items-center gap-4">
            <p className="text-lg font-medium">
              Build your Metric Schema with AI
            </p>
            <span>{AILogo}</span>
          </div>
          {validationErrors["BuildData"] && (
            <p className="text-[#f76497]">{validationErrors["BuildData"]}</p>
          )}
          {/* <p className="my-4 text-sm">
            Write in simple NLP for the AI to create your metric forms
          </p> */}
          <ComingSoon height={"150px"} />
          {/* <Textarea
            placeholder="Describe what type of metric you would like to create"
            variant="faded"
            className="my-4 h-full"
            name="description"
            onChange={changeHandler}
            value={formData["description"]}
          /> */}

          {/* <div className="flex justify-end my-4 mr-4">
            <Dropdown>
              <DropdownTrigger>
                <Button color="secondary" size="sm">
                  Add a new field
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Static Actions"
                selectionMode="single"
                onSelectionChange={(event) => {
                  setFieldType(
                    Array.from(event).join(", ").replaceAll("_", " ")
                  );
                  onOpen();
                }}
              >
                {fieldListValues.map(
                  (row) =>
                    row[1].name !== "linkUrl" && (
                      <DropdownItem key={row[1].name} name={row[1].name}>
                        {row[1].title}
                      </DropdownItem>
                    )
                )}
              </DropdownMenu>
            </Dropdown>
          </div>

          {isOpen && (
            <AddModal
              onSubmitHandler={addFieldHandler}
              isOpen={isOpen}
              onOpen={onOpen}
              onOpenChange={onOpenChange}
              fieldType={fieldType}
              id={Date.now()}
              frameWorkDetails={frameWorkDetails}
              frameWorkList={frameWorkList}
              category={formData?.Category}
            />
          )}

          <FieldInfoTable
            fieldInfoList={fieldInfoList}
            setFieldInfoList={setFieldInfoList}
            frameWorkDetails={frameWorkDetails}
            frameWorkList={frameWorkList}
          /> */}
        </div>

        {/* Links Input */}

        <div className="w-full min-h-96">
          <div className="flex items-center gap-4">
            <p className="text-lg font-medium">Attach your reference links</p>
          </div>
          {validationErrors["BuildData"] && (
            <p className="text-[#f76497]">{validationErrors["BuildData"]}</p>
          )}
          <p className="my-4 text-sm">
            Attach reference links for users to fill your metrics easier
          </p>

          <div className="flex justify-end my-4 mr-4">
            <Button color="secondary" size="sm" onClick={() => onOpenLink()}>
              Add a new link
            </Button>
          </div>

          {isOpenLink && (
            <AddModal
              onSubmitHandler={addLinkHandler}
              // id={deleteMetricId}
              modalHeading={"Are you sure you want to delete this Entry?"}
              isOpen={isOpenLink}
              onOpen={onOpenLink}
              onOpenChange={onOpenChangeLink}
              fieldType="link"
              id={Date.now()}
            />
          )}

          <FieldInfoTable
            fieldInfoList={LinkInfoList}
            setFieldInfoList={setLinkInfoList}
            headersList={["Name", "Link", ""]}
          />
        </div>

        {/* Formula Input */}

        <div className="w-full min-h-96">
          <div className="flex items-center gap-4">
            <p className="text-lg font-medium">Design your Formula</p>
          </div>
          {validationErrors["BuildData"] && (
            <p className="text-[#f76497]">{validationErrors["BuildData"]}</p>
          )}
          <p className="my-4 text-sm">
            Design your formula to handle mathematical calculations like
            emissions, diversity and many more.
          </p>

          <div className="flex justify-end my-4 mr-4">
            <Button color="secondary" size="sm" onClick={() => onOpenFormula()}>
              Add a new formula
            </Button>
          </div>

          {isOpenFormula && (
            <AddFormulaModal
              onSubmitHandler={addFormulaHandler}
              // id={deleteMetricId}
              modalHeading={"Are you sure you want to delete this Entry?"}
              isOpen={isOpenFormula}
              onOpen={onOpenFormula}
              onOpenChange={onOpenChangeFormula}
              fieldType="formula"
              id={Date.now()}
            />
          )}

          <FormulaInfoTable
            fieldInfoList={FormulaInfoList}
            setFieldInfoList={setFormulaInfoList}
          />
        </div>
        {/* Preview,Submit button */}
        <div className="flex gap-4 my-4 w-full">
          <Button
            color="danger"
            variant="light"
            size="sm"
            onClick={showPreviewHandler}
          >
            Preview
          </Button>
          <Button color="danger" size="sm" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </div>

      {/* Preview Card Open */}
      {showPreview && (
        <div className="flex flex-col items-center w-[48%]">
          <PreviewForm data={previewData} />
        </div>
      )}
    </div>
  );
};

export default AIFormBuilder;
