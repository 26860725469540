import React from "react";
import SinglePolicyReport from "./report/SinglePolicyReport";

import SingleProcedureReport from "./report/SingleProcedureReport";

const PrintPolicyReport = ({ proceduresDetails, policiesDetails }) => {
  return (
    <>
      <div className="flex items-center justify-between px-4">
        <p className="text-3xl font-medium">Policy Report</p>
      </div>
      <div className="px-4 my-2">
        <p className="text-center font-medium text-2xl">Policies Information</p>
        <div className="my-4">
          {policiesDetails?.map((data) => {
            return <SinglePolicyReport data={data} />;
          })}
        </div>
        <hr className="text-gray my-2" />
        <p className="text-center font-medium text-2xl">
          Procedure Information
        </p>

        <div className="my-4">
          {proceduresDetails?.map((data) => {
            return <SingleProcedureReport data={data} />;
          })}
        </div>
      </div>
    </>
  );
};

export default PrintPolicyReport;
