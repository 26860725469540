import React from "react";
const DashboardCharts = () => {
  return (
    <div className="w-full h-screen">
      <iframe
        title="Dashboard-charts"
        src="https://haya-visualisation.unifie.io/org1/view-dashboard/p5tn3xz20rqe72v23524wcgl?key=I407M3JAP5a3Gh8j22j9BtgJucFFVgA1"
        width={"100%"}
        height={"100%"}
      ></iframe>
    </div>
  );
};

export default DashboardCharts;
