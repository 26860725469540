import React from "react";

const EmissionInsight = () => {
  return (
    <div className="my-8">
      <iframe
        src="http://localhost:3000/emissions-insight/1/"
        // src={`${process.env.REACT_APP_EVIDENCE_DEV_IP_ADDRESS}/orgnization/${OrgInfo}`}
        title="description"
        style={{
          width: "70vw",
          height: "70vh",
          border: "none",
        }}
        className="hide-scollbar"
      />
    </div>
  );
};

export default EmissionInsight;
