export const getJSonSchemaFromFieldInfoList = (
  fieldInfoList,
  selectedFormulaList
) => {
  const propertiesList = {};

  const requiredArr = [];
  fieldInfoList.forEach((data) => {
    const fieldInput = {};

    if (data["Required"]) {
      requiredArr.push(data["Title"]);
    }
    Object.keys(data).map((key) => {
      fieldInput[key.toLocaleLowerCase()] = data[key];
    });

    propertiesList[data["Title"]] = fieldInput;
  });

  const response = {
    type: "object",
    title: "",
    required: requiredArr,
    properties: propertiesList,
    description: "",
    formula: selectedFormulaList,
  };
  return response;
};

export const getLinkJsonReponse = (list) => {
  const reponse = [];
  list.forEach((item) => {
    reponse.push({
      id: item?.id,
      name: item.Title,
      link: item.link,
    });
  });
  return {
    Links: reponse,
  };
};
