import { Button } from "@nextui-org/react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  view: {
    marginTop: 12,
    marginBottom: 12,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 8,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  subtext: {
    margin: 0,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "start",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  listItem: {
    fontSize: 8,
    marginBottom: 2,
  },
});
// Create Document Component
const replacePlaceholders = (text, placeholders) => {
  let result = text;
  for (const key in placeholders) {
    const regex = new RegExp(`{{${key}}}`, "g");
    result = result.replace(regex, placeholders[key] || "--");
  }
  return result;
};
export const MyDocument = ({ policies, procedures }) => {
  return (
    <Document
      pageMode="useOutlines"
      onLoadSuccess={(e) => {
        console.log(e);
      }}
    >
      <Page style={styles.body} pageNumber={1}>
        <Text style={styles.header} fixed>
          ~ Created with react-pdf ~
        </Text>

        <Text style={styles.title}>Policies Information</Text>
        {policies.map((policy, index) => {
          const { Name, Description, Domain, PolicyFramework, Response } =
            policy?.attributes;
          const updatedPolicyFramework = replacePlaceholders(
            PolicyFramework,
            Response
          );
          return (
            <View key={index} style={styles.view}>
              <Text style={styles.text}>Policy Name: {Name}</Text>
              <Text style={styles.text}>Policy Domain: {Domain}</Text>
              <Text style={styles.text}>Policy Description: {Description}</Text>

              <Text style={styles.text}>
                Policy Framework: {updatedPolicyFramework}
              </Text>
              <View style={styles.divider} />
            </View>
          );
        })}

        <Text style={styles.title}>Procedure Information</Text>
        {procedures.map((procedure, index) => {
          const {
            Name,
            DomainName,
            Policy,
            Description,
            ProcedureFramework,
            ControlMetrics,
          } = procedure.attributes;

          const updatedProcedureFramework = replacePlaceholders(
            ProcedureFramework,
            Response
          );

          return (
            <View key={index} style={styles.view}>
              <Text style={styles.text}>Name: {Name}</Text>
              <Text style={styles.text}>Domain: {DomainName}</Text>
              <Text style={styles.text}>Policy: {Policy}</Text>
              <Text style={styles.text}>
                Procedure Framework:{updatedProcedureFramework}
              </Text>
              {ControlMetrics && ControlMetrics.controlMetrics && (
                <>
                  <Text style={styles.text}>
                    Control Metrics -
                    {ControlMetrics.controlMetrics.map((metric, index) => {
                      const x = `${metric}, `;
                      return x;
                    })}
                  </Text>
                </>
              )}
              <View style={styles.divider} />
            </View>
          );
        })}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
