import { useNavigate, useParams } from "react-router-dom";
import {
  PublishData,
  UpdateOneData,
  capitalize,
  getDataWithFilterUrl,
  sendSubmissionUpdationAlert,
  separateDasFromUrl,
  updateFormSchemaWithRealtion,
} from "../../controllers/APIController";
import { Button, Chip, Spinner } from "@nextui-org/react";
import { useEffect, useState } from "react";
import {
  getFieldMoreInfoContent,
  getFormulaData,
  getSchemaForOrg,
  increasedMetricCurrentCount,
} from "../../utils/helper";
import {
  BooleanInput,
  DateInput,
  DropDownInput,
  IntegerInput,
  PasswordInput,
  RelationDropDownInput,
  SliderInput,
  TextAreaInput,
  TextInput,
} from "../FormBuilder/InputField/InputField";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import { checkValidation } from "../../utils/checkValidation";
import FileEvidenceInput from "../FormBuilder/InputField/FileEvidenceInput";

import useGetOrgFramework from "../../hooks/useGetOrgFramework";
import { metricColor } from "../../utils/constant";
import LoadingPage from "../../components/Loader/LoadingPage";
import MetricFrameworkInfo from "./MetricFrameworkInfo";

const getInitailState = (arr) => {
  const data = {};

  arr?.forEach((item) => {
    if (item[1].type === "boolean") {
      data[item[0]] = "false";
    }
    if (item[1].type === "slider") {
      data[item[0]] = item[1].value || 0;
    } else if (item[1].type === "file") data[item[0]] = [];
    else data[item[0]] = "";
  });
  return data;
};
const updateEvidenceRefrences = async (
  formdata,
  formschemaArray,
  referenceId
) => {
  const PromisesReponses = formschemaArray.map(async (row) => {
    if (row[1]?.type === "file") {
      const EvidenceIdList = formdata[row[0]];

      const result = EvidenceIdList?.map(async (id) => {
        const uploadData = {
          ReferenceList: {
            connect: [+referenceId],
          },
        };

        return await UpdateOneData("evidences", +id, uploadData);
      });
      return result;
    }
    return null;
  });

  await Promise.all(PromisesReponses);
};
const CreateMetricForm = () => {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const UserId = UserLogged && UserLogged.user.id;

  const [loader, setLoader] = useState(true);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [CollectionInfo, setCollectionInfo] = useState();
  const [formdata, setFormData] = useState({});
  const [formSchema, setformSchema] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const { orgFrameworkDetails } = useGetOrgFramework(OrgInfo);
  const navigate = useNavigate();

  const { _orgId: currOrg, _categoryId, _metricId } = useParams();

  // eslint-disable-next-line no-unused-vars
  const isFormulaVisiable =
    CollectionInfo?.attributes?.JsonSchema?.formula?.length > 0 ? true : false;

  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  let HeadingTitle = capitalize(separateDasFromUrl(_metricId));
  let description = CollectionInfo?.attributes?.Description;


  const filteredUrl =
    orgFilterUrl +
    "&filters[Category][$eqi]=" +
    _categoryId.replaceAll("-", " ") +
    "&filters[Name][$eqi]=" +
    _metricId.replaceAll("-", " ");

  const changeHandler = async (title, value, type) => {
    if (type === "boolean") {
      if (value === "") {
        setFormData({ ...formdata, [title]: "false" });
      } else {
        setFormData({ ...formdata, [title]: value });
      }
    } else setFormData({ ...formdata, [title]: value });
  };

  // eslint-disable-next-line no-unused-vars
  const curlMessage = `curl --location '${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send' \n
  --header 'Content-Type: application/json' \n
  --header 'apikey: <Enter Your API Key>' \n
  --data '{
      "metric_id": "${CollectionInfo?.id}",
      "response": <Enter Your JSON Response>
      
  }'`;

  const submitHandler = async () => {
    const isValid = checkValidation(formSchema, formdata, setValidationErrors);
    if (!isValid) {
      return;
    }

    Object.keys(formdata).forEach((key) => {
      if (formdata[key] === "") {
        formdata[key] = "null";
      }
    });

    const formulaData = await getFormulaData(
      CollectionInfo?.attributes?.JsonSchema?.formula,
      formdata
    );

    const logsInfo = {
      by: UserId,
      operation: "created",
      timestamp: Date.now(),
    };

    const publishData = {
      Metric_Id: CollectionInfo.attributes.Name,
      Status:
        CollectionInfo?.attributes?.WorkFlow === "Default" ? "Done" : "Created",
      JsonSchema: { ...formdata, ...formulaData },
      Organization: OrgInfo,
      users_permissions_user: {
        disconnect: [],
        connect: [
          {
            id: UserId,
            position: {
              end: true,
            },
          },
        ],
      },
      Logs: [logsInfo],
    };

    setIsSubmittingForm(true);
    let result = await PublishData({ data: publishData }, "responses");

    const submissionalertData = {
      orgId: OrgInfo,
      UserId: UserLogged && UserLogged.user.email,
      operation: "created",
      entryId: CollectionInfo?.attributes?.Name,
      linkurl: `${process.env.REACT_APP_IP_ADDRESS}/${currOrg}/metrics/${_categoryId}/${_metricId}/${result?.data?.id}`,
    };
    await sendSubmissionUpdationAlert(submissionalertData);
    await increasedMetricCurrentCount(
      CollectionInfo?.id,
      result?.data?.attributes?.createdAt
    );

    await updateEvidenceRefrences(formdata, formSchema, result?.data?.id);

    if (CollectionInfo?.attributes?.FrequencyValue === 0) {
      await UpdateOneData("metrics", CollectionInfo?.id, {
        lastResponseId: result?.data?.id,
      });
    }

    setIsSubmittingForm(false);
    navigate(`/${currOrg}/metrics/${_categoryId}/${_metricId}`);
  };

  // const testCalculationHandler = async () => {
  //   const isValid = checkValidation(formSchema, formdata, setValidationErrors);
  //   if (!isValid) {
  //     return;
  //   }
  //   Object.keys(formdata).forEach((key) => {
  //     if (formdata[key] === "") {
  //       formdata[key] = "null";
  //     }
  //   });

  //   const formulaData = await getFormulaData(
  //     CollectionInfo?.attributes?.JsonSchema?.formula,
  //     formdata
  //   );
  // };

  const clickHandler = (metricName, relatedTo) => {
    let metric = metricName.toLowerCase().replaceAll(" ", "-");
    let relatedToName = relatedTo.toLowerCase().replaceAll(" ", "-");

    navigate(`/${currOrg}/metrics/${metric}/${relatedToName}/create`);
    window.location.reload();
  };

  useEffect(() => {
    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        const responseData = data.data[0]?.attributes?.JsonSchema?.properties;

        let responseArr = Object.keys(responseData).map((key) => [
          key,
          responseData[key],
        ]);
        responseArr.sort((a, b) => {
          return a[1].id - b[1].id;
        });

        getSchemaForOrg(responseData, OrgInfo).then((data) => {
          setformSchema(data);
        });

        setCollectionInfo(data.data[0]);
        setFormData(getInitailState(responseArr));
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [OrgInfo, filteredUrl]);

  const Tags = CollectionInfo?.attributes?.TagList;

  return (
    <BreadCrumbsPage>
      <LoadingPage isLoading={loader}>
        <div
          className=""
          style={{
            opacity: isSubmittingForm ? 0.8 : 1,
          }}
        >
          <div className="metric-header">
            <p className="text-3xl font-medium">{HeadingTitle}</p>
            <div className="flex items-center gap-2 my-4">
              {Tags?.map((item, index) => (
                <Chip
                  key={index}
                  className=""
                  size="sm"
                  color={
                    metricColor[
                      CollectionInfo?.attributes?.Category?.toLowerCase()
                    ]
                  }
                  variant="flat"
                >
                  {capitalize(item)}
                </Chip>
              ))}
            </div>
            <p className="my-4">
              {description ||
                `No description is available at the moment. Please check back later for updates.`}
            </p>

            <MetricFrameworkInfo
              CollectionInfo={CollectionInfo}
              orgFrameworkDetails={orgFrameworkDetails}
            />
          </div>

          <div className="fixed w-[calc(100vw-16rem)] bg-white z-[800] right-0 bottom-0 flex justify-end gap-4">
            <Button
              color="danger"
              // variant={isSubmittingForm ? "ghost" : "solid"}
              size="md"
              onClick={submitHandler}
              className="mx-8 my-4"
            >
              {!isSubmittingForm ? (
                "Submit"
              ) : (
                <Spinner color="white" size="sm" />
              )}
            </Button>
          </div>
          <div className="flex gap-[60px]">
            <div className="w-[50%]">
              {formSchema &&
                formSchema.map((row, index) => {
                  const moreInfo = getFieldMoreInfoContent(row[1]);
                  if (row[1].type === "string" || row[1].type === "text") {
                    return (
                      <TextInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "password") {
                    return (
                      <PasswordInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "integer") {
                    return (
                      <IntegerInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "dropdown") {
                    return (
                      <DropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "slider") {
                    return (
                      <SliderInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "boolean") {
                    return (
                      <BooleanInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "textarea") {
                    return (
                      <TextAreaInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "date") {
                    return (
                      <DateInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "file") {
                    return (
                      <FileEvidenceInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                      />
                    );
                  }
                  if (row[1].type === "relation") {
                    updateFormSchemaWithRealtion(
                      formSchema,
                      index,
                      setformSchema
                    );
                    return (
                      <RelationDropDownInput
                        data={row[1]}
                        value={formdata[row[0]]}
                        moreInfo={moreInfo}
                        orgFrameworkDetails={orgFrameworkDetails}
                        validationErrors={validationErrors}
                        onChangeHandler={changeHandler}
                        onClickHandler={clickHandler}
                      />
                    );
                  }
                  return null;
                })}
            </div>
            <div className="w-[45%]">
              {/* <div className="flex flex-col relative w-full">
                <div className={`w-full ${links?.length === 0 && "hidden"}`}>
                  <div className="text-lg font-semibold">
                    Helpful Background Reading:
                  </div>
                  <div>
                    <div className="link-list my-2 text-blue-600 font-semibold">
                      {links?.map((link, index) => (
                        <div className="links">
                          <Link
                            key={index}
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.name}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <Accordion size="lg" isflust={true}>
                    <AccordionItem
                      title="Webhook API"
                      className="curl-accordion-item"
                    >
                      <p style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                        Integrate our form apis to your workflow
                      </p>

                      <div className="relative rounded-xl bg-black my-2">
                        <pre className="p-2 text-[#899fef]">{curlMessage}</pre>
                        <MdOutlineContentCopy
                          className="absolute top-0 right-0 m-4 text-white cursor-pointer"
                          onClick={() => handleCopyClick(curlMessage)}
                        />
                      </div>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </LoadingPage>
    </BreadCrumbsPage>
  );
};

export default CreateMetricForm;
