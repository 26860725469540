import environmentImage from "../../Images/environment.svg";
import governanceImage from "../../Images/governance.svg";
import socialImage from "../../Images/social.svg";
import surveyImage from "../../Images/survey.svg";
import generalImage from "../../Images/General.svg";

export const PoliciesCategoryList = [
  {
    key: "general",
    title: "General",
    description:
      "Comprises foundational policies that govern the organization's core operations, ensuring alignment with strategic objectives and regulatory requirements.",
  },
  {
    key: "enviroment",
    title: "Environment",
    description:
      "Includes policies designed to promote sustainability, environmental stewardship, and compliance with global environmental standards, aimed at minimizing the organization's ecological footprint.",
  },
  {
    key: "social",
    title: "Social",
    description:
      "Focuses on social responsibility initiatives, including corporate diversity, equity, inclusion programs, and engagement with stakeholders to foster a responsible corporate culture.",
  },
  {
    key: "governance",
    title: "Governance",
    description:
      "Covers governance frameworks and practices that ensure robust corporate oversight, accountability, and adherence to both internal policies and external regulatory obligations.",
  },
  {
    key: "surveymetric",
    title: "Survey Metric",
    description:
      "Aggregates insights from key metrics such as employee satisfaction, diversity indicators, and organizational climate assessments, serving as a basis for continuous improvement.",
  },
];

export const getImageForDomain = (domainName) => {
  switch (domainName?.toLowerCase()) {
    case "environment":
      return environmentImage;
    case "governance":
      return governanceImage;
    case "social":
      return socialImage;
    case "survey":
      return surveyImage;
    case "general":
      return generalImage;
    default:
      return generalImage; // Fallback image
  }
};
