import React from "react";

const replacePlaceholders = (text, placeholders) => {
  let result = text;
  for (const key in placeholders) {
    const regex = new RegExp(`{{${key}}}`, "g");
    result = result.replace(regex, placeholders[key] || "--");
  }
  return result;
};
const SingleProcedureReport = ({ data }) => {
  const { Name, DomainName, Policy, ProcedureFramework, ControlMetrics } =
    data.attributes;

  const updatedProcedureFramework = replacePlaceholders(
    ProcedureFramework,
    Response
  );
  return (
    <div className="flex flex-col gap-1 w-full my-8">
      <h1>
        <strong>Name:</strong> {Name}
      </h1>
      <p>
        <strong>Domain:</strong> {DomainName}
      </p>
      <p>
        <strong>Policy:</strong> {Policy}
      </p>

      <div>
        <h2>Procedure Framework</h2>
        <p>{updatedProcedureFramework}</p>
      </div>

      {ControlMetrics && ControlMetrics.controlMetrics && (
        <div>
          <strong>Control Metrics:</strong>
          <ul className="list-disc ml-5">
            {ControlMetrics.controlMetrics.map((metric, index) => (
              <li key={index}>{metric}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SingleProcedureReport;
