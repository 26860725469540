import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React, {  useState } from "react";
import TagsInput from "../FormBuilder/InputField/TagsInput";
import { sendSurvey } from "../../controllers/APIController";

const SendSurveyModal = ({ isOpen, onOpenChange, metricID }) => {
  const [tags, setTags] = useState([]);
  const [validationError, setValidationErrors] = useState("");

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const submitHandler = async (onClose) => {
    // await onSubmitHandler(id);
    if (tags.length === 0) {
      setValidationErrors("Please enter a email");
      return;
    }

    const data = tags?.map((data) => {
      const urlPath = `${process.env.REACT_APP_IP_ADDRESS}/${OrgInfo}/external-survey/${metricID}?username=${data}`;
      return {
        url: urlPath,
        email: data,
      };
    });

    const payload = {
      data: data,
    };

    const result = await sendSurvey(payload);
    onClose();
  };


  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      setValidationErrors("");
      setTags([]);
    }
    onOpenChange(isOpen); // Call the original onOpenChange if needed
  };

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={handleOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Send Survey
              </ModalHeader>
              <ModalBody className="mb-4">
                <TagsInput
                  tags={tags}
                  setTags={setTags}
                  IntialvalidationError={validationError}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitHandler(onClose)}>
                  Send
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default SendSurveyModal;
