// DropDown Data Schema for AssignedToUser  in  Playground
export const AssignedToUserSchemaObj = {
  id: "assignedToUser",
  title: "Assigned To User",
  placeholder: "Select Assigned To User",
  values: [],
};

// DropDown Data Schema for AssignedToUser  in  Playground
export const ApproverUserSchemaObj = {
  id: "approverUser",
  title: "Approver Users",
  placeholder: "Select Approver User",
  values: [],
};

// DropDown Data Schema for AssignedToUser  in  Playground
export const ReviewerUserSchemaObj = {
  id: "reviewerUser",
  title: "Reviewer User",
  placeholder: "Select Reviewer User",
  values: [],
};

export const sectorSchema = {
  id: 4,
  name: "Drop Down",
  type: "dropdown",
  title: "Choose Industry Sector",
  placeholder: "Enter Industry Sector",
  values: [],
  required: true,
  ismultiselect: false,
};

export const AILogo = (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={"20px"} height={"20px"}>
    <path
      d="M16 8.016A8.522 8.522 0 008.016 16h-.032A8.521 8.521 0 000 8.016v-.032A8.521 8.521 0 007.984 0h.032A8.522 8.522 0 0016 7.984v.032z"
      fill="url(#prefix__paint0_radial_980_20147)"
    />
    <defs>
      <radialGradient
        id="prefix__paint0_radial_980_20147"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(16.1326 5.4553 -43.70045 129.2322 1.588 6.503)"
      >
        <stop offset=".067" stop-color="#9168C0" />
        <stop offset=".343" stop-color="#5684D1" />
        <stop offset=".672" stop-color="#1BA1E3" />
      </radialGradient>
    </defs>
  </svg>
);
