export const myCollections = async (SpaceName) => {
  const userDetails = JSON.parse(sessionStorage.getItem("userData"));
  const userID = userDetails && userDetails.user.id;

  var URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/collections/?filters[$or][0][author][id][$eq]=${userID}&filters[$or][1][inviteds][id][$eq]=${userID}&filters[space][Name][$in][2]=${SpaceName}`;

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  var data = await response.json();
  const CollectionId = data && data.data && data.data.map((val) => val.id);

  return CollectionId;
};

// Upload a file
export const UploadDocument = async (collectionName, url) => {};
// searches through all tags, descriptions,
export const basic_search = async () => {};
//  Haya LLM
export const search = async (query) => {
  // Accept query as a parameter
  try {
    // Concatenate the query parameter with the URL string
    const url = `${
      process.env.REACT_APP_HAYA_IP_ADDRESS
    }/testing/streaming-response/search?app_id=${
      process.env.REACT_APP_APP_ID
    }&query=${encodeURIComponent(query)}&collection_name=${
      process.env.REACT_APP_COLLECTION_NAME
    }`;

    const response = await fetch(url, {
      // Use the constructed URL
      method: "GET",
      mode: "cors",
      headers: {
        accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return null; // Or you can handle the error in a different way
  }
};

export const DynamicSearch = async (vectorName, spaceName, query) => {
  try {
    // Concatenate the query parameter with the URL string
    const url = `${
      process.env.REACT_APP_HAYA_IP_ADDRESS
    }/search/?app_id=${spaceName}&query=${encodeURIComponent(
      query
    )}&collection_name=${vectorName}`;
    // https://haya-core.nanoheal.work/search/?app_id=audyt-esg&query=governance%20data&collection_name=dev
    const response = await fetch(url, {
      // Use the constructed URL
      method: "GET",
      mode: "cors",
      headers: {
        accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return null;
  }
};

// To get all documents in a collection
export const getAllDocuments = async (vectorname, collection, spaceName) => {
  const lowerCaseString = vectorname.toLowerCase();
  const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/filter?qdrant_collection=${spaceName}&app_id=${spaceName}`;
  const body = {
    filters: [
      {
        key: "collection",
        value: collection,
      },
      // {
      //   "key": "status",
      //   "value": "active"
      // }
    ],
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Set content type to JSON
      },
      body: JSON.stringify(body), // Stringify the body object
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.documents;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return null;
  }
};

export const get_one_document = async (vectorName, id, spaceName) => {
  const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/filter?qdrant_collection=${vectorName}&app_id=${spaceName}`;
  const body = {
    filters: [
      {
        key: "file_document_id",
        value: `${id}`,
      },
    ],
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Set content type to JSON
      },
      body: JSON.stringify(body), // Stringify the body object
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    return data.documents;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return null;
  }
};

export const get_trash = async (vectorName, spaceName) => {
  let user_id;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    user_id = JSON.parse(sessionStorage.getItem("userData")).user.id.toString();
  } else {
    user_id = "";
  }

  try {
    // Concatenate the query parameter with the URL string
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/filter?qdrant_collection=${vectorName}&app_id=${spaceName}`;

    const body = {
      filters: [
        {
          key: "status",
          value: "trash",
        },
        {
          key: "user_id",
          value: user_id,
        },
      ],
    };

    const response = await fetch(url, {
      // Use the constructed URL
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Set content type to JSON
      },
      body: JSON.stringify(body), // Stringify the body object
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return null; // Or you can handle the error in a different way
  }
};

export const delete_document = async (vectorName, documentId, spaceName) => {
  // Concatenate the query parameter with the URL string
  const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?id=${documentId}&app_id=${spaceName}&collection_name=${vectorName}`;

  const response = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
  });

  let data = await response.json();
  return data;
};

export const permanent_delete_document = async () => {};

export const get_archive = async (vectorname, spaceName) => {
  let user_id;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    user_id = JSON.parse(sessionStorage.getItem("userData")).user.id.toString();
  } else {
    user_id = "";
  }

  try {
    // Concatenate the query parameter with the URL string
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/filter?qdrant_collection=${vectorname}&app_id=${spaceName}`;

    const body = {
      filters: [
        {
          key: "status",
          value: "archive",
        },
        {
          key: "user_id",
          value: user_id,
        },
      ],
    };

    const response = await fetch(url, {
      // Use the constructed URL
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Set content type to JSON
      },
      body: JSON.stringify(body), // Stringify the body object
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return null; // Or you can handle the error in a different way
  }
};

export const archive_docuemnt = async () => {};

export const regular_search = async () => {
  // Search based on documents haya's APIs
  // Search based on collections
  // Search based on tags
  // Search based on sources
  // Add all the documents. ORDER =
};

export const DocumentUploadApi = async (
  vectorName,
  document_id,
  spaceName,
  payload
) => {
  var URL = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/knowledge-management/queue-doc?app_id=${spaceName}&collection_name=${vectorName}&document_id=${document_id}`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    body: JSON.stringify(payload),
  });

  let data = await response.json();

  return data;
};

export const singleUserdocuments = async (vectorName, spaceName, id) => {
  const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/filter?qdrant_collection=${vectorName}&app_id=${spaceName}`;

  const body = {
    filters: [
      {
        key: "user_id",
        value: `${id}`,
      },
    ],
  };

  const response = await fetch(url, {
    // Use the constructed URL
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json", // Set content type to JSON
    },
    body: JSON.stringify(body), // Stringify the body object
  });

  const data = await response.json();
  return data;
};

export const getAllAppDocuments = async (vectorName, spaceName) => {
  var url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/all?collection_name=${vectorName}&app_id=${spaceName}`;

  const response = await fetch(url, {
    method: "GET",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  var data = response.json();

  return data;
};

export const createCollection = async (collection) => {
  var url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/create-collection?collection_name=${collection}`;
  const response = await fetch(url, {
    method: "POST",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  var data = response.json();

  return data;
};

export const view_schema = async (ConnectionPayload) => {
  var url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/db/view`;

  const response = await fetch(url, {
    method: "POST",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ConnectionPayload),
  });

  const data = await response.json();
  return data;
};

//  DB testing
export const test_schema = async (ConnectionPayload) => {
  var url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/db/test`;

  const response = await fetch(url, {
    method: "POST",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ConnectionPayload),
  });

  const data = await response.json();
  return data;
};

export const getAllDataProducts = async (
  document_id,
  spaceName,
  collectionName,
  payload
) => {
  var URL = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?id=${document_id}&app_id=${spaceName}&collection_name=${collectionName}`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    body: JSON.stringify(payload),
  });

  let data = await response.json();

  return data;
};

export const getSingleConnection = async (vectorName, spaceName, id) => {
  const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/filter?qdrant_collection=${vectorName}&app_id=Audyt`;

  const body = {
    filters: [
      {
        key: "collection",
        value: `${id}`,
      },
    ],
  };

  const response = await fetch(url, {
    // Use the constructed URL
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json", // Set content type to JSON
    },
    body: JSON.stringify(body), // Stringify the body object
  });

  const data = await response.json();
  return data;
};

export const createThread = async (id, formData, tenant) => {
  try {
    // Generate timestamp as ID
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?id=${id}&app_id=${tenant}&collection_name=${process.env.REACT_APP_THREAD_COLLECTION}`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMultipleFilters = async (value, tenant) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/knowledge-doc/filter?qdrant_collection=${process.env.REACT_APP_THREAD_COLLECTION}&app_id=audyt`;

    const body = {
      filters: value,
    };
    const response = await fetch(url, {
      // Use the constructed URL
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.documents;
  } catch (error) {
    throw error;
  }
};

export const patchData = async (formData, id, tenant) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/meta?id=${id}&app_id=audyt&collection_name=audyt-threads`;

    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const patchAnyCollection = async (formData, id, tenant, collection) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/meta?id=${id}&app_id=${tenant}&collection_name=${collection}`;

    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const patchDataProduct = async (formData, id, tenant, collection) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/meta?id=${id}&app_id=${tenant}&collection_name=${collection}`;

    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteData = async (id, tenant) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?id=${id}&app_id=${tenant}&collection_name=${process.env.REACT_APP_THREAD_COLLECTION}`;

    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllDocs = async (tenant, collection) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/all?app_id=${tenant}&collection_name=${collection}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    throw err;
  }
};

export const getSingleDocument = async (id, tenant, collection) => {
  try {
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?collection_name=${collection}&app_id=${tenant}&id=${id}`;

    const response = await fetch(url, {
      // Use the constructed URL
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const postData = async (id, formData, tenant, collection) => {
  try {
    // Generate timestamp as ID
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/doc/?id=${id}&app_id=${tenant}&collection_name=${collection}`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getPromptData = async (requestData) => {
  try {
    // Generate timestamp as ID
    const url = `${process.env.REACT_APP_HAYA_IP_ADDRESS}/prompt/execute`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
