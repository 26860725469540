import { Chip } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { getSingleCollectionData } from "../../../controllers/APIController";
import SuggestedTagsList from "./SuggestedTagsList";
const RenderTags = ({ data, id, onHandlerTagRemove }) => {
  return (
    <Chip
      key={id}
      className=""
      size="sm"
      color={["secondary", "success", "warning", "danger", "primary"][id % 6]}
      variant="flat"
      onClose={() => onHandlerTagRemove(data)}
    >
      {data}
    </Chip>
  );
};
const MetricTagInput = ({
  tags,
  setTags,
  IntialvalidationError,
  onValidation,
  allTags
}) => {
  const [showSuggestedTag, setShowSuggestedTag] = useState(false);
  
  const [tagInput, setTagInput] = useState("");
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [Validatetags, setValidatetags] = useState("");
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
 
  const clickHandler = (event) => {
    const value = event.target.value;
    const newList = allTags?.filter((name) =>
      name.startsWith(value.trim().toLowerCase())
    );

    setSuggestedTags(newList);
    setTagInput(value);
  };

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const Validation = (Text) => {
    let isValid = true;

    if (tags.includes(Text)) {
      setValidatetags("Entered tags already exists in the list.");
      isValid = false;
    } else if (Text === "") {
      setValidatetags("Please Enter tag.");
      isValid = false;
    } else {
      setValidatetags(null);
    }

    return isValid;
  };

  const addHandler = (value) => {
    const Validate = Validation(value);
    if (Validate) {
      setTags((previous) => [...previous, value]);
      setValidatetags("");
      setTagInput("");
    }
  };
  const handleKeyPress = (e) => {
    const Text = tagInput.trim().toLowerCase();
    if (tagInput.trim() !== "" && e.key === "Enter") {
      const Validate = Validation(Text);
      if (Validate) {
        setTags((previous) => [...previous, Text]);
        setValidatetags("");
        setTagInput("");
      }
    }
  };

  useEffect(() => {
    setValidatetags(IntialvalidationError);
  }, [IntialvalidationError]);


  return (
    <div className="w-full mb-8">
      <div className="text-sm font-medium mb-2">Tags</div>
      <div
        className={`relative border-2 rounded-xl  flex flex-col p-2 bg-[#f4f4f4] min-h-20 border-[#e6e6e6]`}
        variant="faded"
      >
        <input
          className={`bg-transparent border-0 rounded text-sm`}
          key={"outside"}
          style={{ outline: "none" }}
          type="text"
          variant="bordered"
          labelPlacement={"outside"}
          placeholder="Enter the Tags that you want to added"
          value={tagInput && tagInput.trim()}
          onChange={clickHandler}
          onFocus={() => setShowSuggestedTag(true)}
            onBlur={() => {
              setTimeout(() => {
                setShowSuggestedTag(false);
              }, 300);
            }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleKeyPress(e);
            }
          }}
        />
        <div className="pt-4 flex flex-wrap gap-2">
          {tags &&
            tags?.map((item, index) => (
              <RenderTags
                data={item}
                id={index}
                onHandlerTagRemove={handleTagRemove}
              />
            ))}
        </div>
        <p className="pt-2 text-xs text-red-400">{Validatetags || " "}</p>
        {showSuggestedTag && (
          <SuggestedTagsList
            list={suggestedTags}
            onClickHandler={(value) => {
              addHandler(value);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MetricTagInput;
