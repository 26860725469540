import {
  Avatar,
  Button,
  Image,
  Input,
  Pagination,
  Select,
  SelectItem,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { ProgessColors } from "../../utils/constant";
import SearchIcon from "../../Images/Search";
import {
  getDataWithFilterUrl,
  StringLimit,
} from "../../controllers/APIController";
import { useNavigate } from "react-router-dom";

const OrgFrameworkInfo = ({
  DataList,
  setDataList,
  TitleText,
  DescriptionText,
  defaultOrgId,
  currentPage,
  setCurrrentPage,
  totalStep,
}) => {
  const [searchText, setSearchText] = useState("");

  const [frameWorkData, setFrameWorkData] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);

  const navigate = useNavigate();

  const searchHandler = (event) => {
    const text = event.target.value;
    const list = frameWorkData.filter((item) => {
      return item?.attributes?.Name?.toLowerCase()?.includes(
        text?.toLowerCase()
      );
    });
    setSearchText(text);
    setFilterDataList(list);
  };

  useEffect(() => {
    const filteredUrl = `filters[organizations][id][$eqi]=${defaultOrgId}`;

    getDataWithFilterUrl("frameworks", filteredUrl)
      .then((data) => {
        let frameWorkData = data.data;

        setFrameWorkData(frameWorkData);
        setFilterDataList(frameWorkData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="flex justify-center items-center mt-8 font-semibold text-2xl">
        {TitleText || "Create an organization"}
      </div>
      <div className="w-full flex justify-center">
        <div className="mt-8  w-[650px]">
          {DescriptionText ||
            `Create an organization to embark on your ESG (Environmental, Social,
          and Governance) reporting journey. Begin evaluating and improving your
          sustainability and ethical practices today.`}
        </div>
      </div>
      <div className="flex flex-col mx-auto mt-8 h-[360px] w-[500px]">
        <div className="w-full">
          <Input
            classNames=""
            placeholder="Search framework"
            className="mt-4 w-full"
            startContent={
              <SearchIcon
                className={`text-large text- pointer-events-none flex-shrink-0 `}
              />
            }
            value={searchText}
            onChange={searchHandler}
          />

          <div className="mt-8 min-h-[200px]">
            <Table
              color="warning"
              aria-label="Controlled table metrics"
              removeWrapper
              selectionMode="multiple"
              isHeaderSticky
              selectedKeys={new Set(DataList["Select Framework"])}
              onSelectionChange={(event) =>
                setDataList({
                  ...DataList,
                  "Select Framework": Array.from(event),
                })
              }
              classNames={{
                wrapper: "min-h-[150px]",
              }}
            >
              <TableHeader>
                <TableColumn>Name</TableColumn>
                <TableColumn>Description</TableColumn>
                <TableColumn>Action</TableColumn>
              </TableHeader>
              <TableBody
                emptyContent={
                  <div className="flex items-center justify-center font-medium gap-2 text-gray-500">
                    No frameworks found
                  </div>
                }
              >
                {filterDataList?.map((item, index) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <Avatar
                            className="w-8 h-8 "
                            src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
                          />
                          {StringLimit(item?.attributes?.Name || "", 15)}
                        </div>
                      </TableCell>
                      <TableCell>
                        {StringLimit(item?.attributes?.Description || "", 15)}
                      </TableCell>

                      <TableCell>
                        <div
                          className="text-warning-500 font-semibold cursor-pointer"
                          onClick={() => {
                            window.open(`/framework/${item?.id}`);
                          }}
                        >
                          View
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      <div className={`w-full flex items-center my-2 justify-between`}>
        <Button
          color={ProgessColors[currentPage]}
          variant="light"
          className="text-center cursor-pointer"
          isDisabled={currentPage === 0}
          onClick={() => {
            setCurrrentPage(currentPage - 1);
          }}
        >
          Previous
        </Button>
        <Button
          color={ProgessColors[currentPage]}
          className={`text-center cursor-pointer`}
          onClick={() => {
            setCurrrentPage(currentPage + 1);
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default OrgFrameworkInfo;
