import { getDataWithFilterUrl } from "../controllers/APIController";

//Should Have "Quantity" and "GreenHouse gas" property in formaData to calculate the Fugitive Value
export const getFugitive = async (quantity, gas_name, data) => {
  let result = null;
  data?.forEach((element) => {
    if (
      element?.attributes?.JsonSchema?.Name?.toLowerCase() ===
      gas_name.toLowerCase()
    ) {
      result = element;
    }
  });
  if (result === null) return 0;

  const gwp =
    +result?.attributes?.JsonSchema?.["GWP (Global Warming Potential) factor"];

  const value = quantity * gwp;

  return value;
};

// Should have field [ Quantity, Greenhouse gas ]
export const FugitiveCalculator = async function (formdata) {
  const quantity = formdata["Quantity"];
  const gas_name = formdata["Greenhouse gas"];

  try {
    const filteredUrl = "filters[Metric_Id][$eqi]=Greenhouse Gas";
    const response = await getDataWithFilterUrl("responses", filteredUrl);

    const data = response?.data || [];
    const val = await getFugitive(quantity, gas_name, data);

    return val;
  } catch (error) {
    console.log("error is here in Fugitve Calculator", error);
  }
};

/*
formData  --> quantity, Fuel Type,
Emission Factor[Fuel Type] ->  We take CO2,NO,O2 values
*/

// Should have field [ Fuel(Quantity), Fuel Type ]

const GasList = ["CO2", "CH4", "NF3", "SF6", "PFCS", "HFCS", "N2O", "CO2E"];
export const StationaryCombustionCalculator = async function (formdata) {
  const quantity = formdata["Quantity"];
  const fuel_type = formdata["Fuel Type"];

  const filteredUrl = "filters[Metric_Id][$eqi]=Emisson Factor";
  const response = await getDataWithFilterUrl("responses", filteredUrl);
  const data = response?.data;

  let result = {};

  data?.forEach((element) => {
    if (
      element?.attributes?.JsonSchema?.Name?.toLowerCase() ===
      fuel_type.toLowerCase()
    ) {
      result = element;
    }
  });

  let totalEF = 0;

  const GasfilteredUrl = "filters[Metric_Id][$eqi]=Greenhouse Gas";
  const allGasListResponse = await getDataWithFilterUrl(
    "responses",
    GasfilteredUrl
  );

  const EFResponse = GasList?.map(async (name) => {
    const val = await getFugitive(
      +result?.attributes?.JsonSchema?.[name] || 1,
      name,
      allGasListResponse?.data || []
    );
    totalEF += val;
    return val;
  });

  await Promise.all(EFResponse);

  const value = quantity * totalEF;
  return value;
};

// Should have field [ Vehicle Type,Distance, Fuel(Quantity), Fuel Type ]
export const MobileCombustionCalculator = async function (formdata) {
  const vehicle = formdata["Vehicle Type"];

  const filteredUrl = "filters[Metric_Id][$eqi]=Vehicle Type";
  const response = await getDataWithFilterUrl("responses", filteredUrl);
  const data = response?.data;

  let result = {};

  data?.forEach((element) => {
    if (
      element?.attributes?.JsonSchema?.Name?.toLowerCase() &&
      element?.attributes?.JsonSchema?.Name?.toLowerCase() ===
        vehicle?.toLowerCase()
    ) {
      result = element;
    }
  });


  const isOnRoad = result?.attributes?.JsonSchema?.["Is on-road"];

  if (isOnRoad?.toLowerCase() === "yes") {
    const dataobj = {
      Quantity: formdata["Distance"],
      "Fuel Type": formdata["Fuel Type"],
    };
    const value = await StationaryCombustionCalculator(dataobj);

    return value;
  } else if (isOnRoad.toLowerCase() === "no") {
    const dataobj = {
      Quantity: formdata["Quantity"],
      "Fuel Type": formdata["Fuel Type"],
    };
    const value = await StationaryCombustionCalculator(dataobj);
    return value;
  } else return 0;
};

// const prompt =
//   "Calculate this word problem based on the calculation as well as the data given below. Strictly give me the interger value to this calculation do not give any such reasoning or preamble. Only use the required data points do not use unecessary data points. Calculation:{formula}. Data: {data}";
// export const getFormulaData = async (formules, formData) => {
//   const response = {};
//   const processFormules = async () => {
//     try {
//       const results = await Promise.all(
//         formules.map(async (item) => {
//           const queryData = {
//             prompt: prompt,
//             variables: [
//               {
//                 variable: "formula",
//                 value: item?.fun,
//               },
//               {
//                 variable: "data",
//                 value: JSON.stringify(formData),
//               },
//             ],
//           };
//           const result = await getPromptData(queryData);

//           response[item.title] = result?.response || null;
//         })
//       );
//     } catch (error) {
//       console.error("Error processing formules:", error);
//     }
//   };
//   const result = await processFormules();

//   return response;
// };

const obj = {
  FugitiveCalculator: FugitiveCalculator,
  getFugitive: getFugitive,
  StationaryCombustionCalculator: StationaryCombustionCalculator,
  MobileCombustionCalculator: MobileCombustionCalculator,
};

function getParameterNames(func) {
  const fnStr = func.toString().replace(/\n/g, "");
  const result = /\(\s*([^)]*)\s*\)/.exec(fnStr);
  return result ? result[1].split(",").map((param) => param.trim()) : [];
}

export const functionCaller = async (func, formdata) => {
  const getParamMeterList = getParameterNames(func());

  const restParams = [];

  for (let i = 1; i < getParamMeterList.length; i++) {
    restParams.push(obj[getParamMeterList[i]]);
  }
  const result = await func()(formdata, ...restParams);

  return result;
};
